import React, { Component } from "react"

// import child components / helpers
import Search from "../search/Search"
import Salesrep from "./Salesrep"

class SalesrepSearch extends Component<appProps> {
  render() {
    return (
      <Search
        title={"Salesrep"}
        closable={true}
        preventClosedUse={true}
        queries={[
          { name: "Id", value: "", databaseSearch: "id" },
          { name: "Name", value: "", databaseSearch: "full_name" },
        ]}
        resultColumns={[
          { name: "Id", databaseField: "id" },
          { name: "First Name", databaseField: "first_name" },
          { name: "Last Name", databaseField: "last_name" },
        ]}
        apiEndpoint={"salesreps/search"}
        children={<Salesrep />}
        {...this.props}
      />
    )
  }
}

export default SalesrepSearch
