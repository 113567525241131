import React, { Component } from "react"

import LoadingSpinner from "../LoadingSpinner"

import { fireAxios, parseUnderscores, toReadableDate } from "../helpers"

const initialState = {
  loading: false,
  queue: [],
}

type state = {
  loading: boolean
  queue: Array<{
    id: number
    email: string
    type: string
    message: string
    date_created: string
  }>
}

class Permissions extends Component<appProps, state> {
  constructor(props: appProps) {
    super(props)
    this.state = initialState

    this.props.setAppTitle && this.props.setAppTitle("Permissions Queue")
  }

  componentDidMount() {
    this.refreshQueue()
  }

  refreshQueue = () => {
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "users/get/permissions/queue",
          data: {
            user_id: this.props.user ? this.props.user.user_id : null,
          },
        },
        (response) => {
          this.setState({
            loading: false,
            queue: response.data,
          })
        }
      )
    })
  }

  handleAccess = (id: number, allow: boolean) => {
    fireAxios(
      {
        method: "post",
        url: "users/set/permission/access",
        data: {
          queue_id: id,
          allow: allow,
        },
      },
      () => {
        this.refreshQueue()
      }
    )
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    return (
      <div>
        <button onClick={this.refreshQueue}>Refresh</button>
        <br />
        <br />
        <table className="searchResults">
          <thead>
            <tr>
              <th>User Email</th>
              <th>Type</th>
              <th>Message</th>
              <th>Date</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {this.state.queue.map((item) => {
            return (
              <tbody key={item.id}>
                <tr className="row" style={{ cursor: "default" }}>
                  <td>{item.email}</td>
                  <td>{parseUnderscores(item.type)}</td>
                  <td>{item.message}</td>
                  <td>{toReadableDate(item.date_created)}</td>
                  <td>
                    <button onClick={() => this.handleAccess(item.id, true)}>Allow</button>
                  </td>
                  <td>
                    <button onClick={() => this.handleAccess(item.id, false)}>Deny</button>
                  </td>
                </tr>
              </tbody>
            )
          })}
        </table>
      </div>
    )
  }
}

export default Permissions
