import React, { Component } from "react"

import LoadingSpinner from "../LoadingSpinner"
import CustomerSearch from "./CustomerSearch"
// import ControlledInput from "../edit/ControlledInput"

import { fireAxios } from "../helpers"

type props = {}

type state = {
  loading: boolean
  customer?: customer
  toChooseCustomer?: boolean
  invoices?: Array<invoice>
}

type customer = {
  id: number
  customer_num: string
  company_name: string
}

type invoice = {
  id: number
  invoice_date: Date
  outstanding_amount: number
}

const initialState: state = {
  loading: false,
}

const title = "Apply Deposit"

class ApplyDeposit extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState
    this.props.setAppTitle && this.props.setAppTitle(title)
  }

  backFromSearch = () => {
    this.setState({
      toChooseCustomer: false,
    })
  }

  toCustomerSearch = () => {
    this.setState({
      toChooseCustomer: true,
    })
  }

  customerSearchResult = (customer: customer) => {
    this.setState(
      {
        customer: customer,
        toChooseCustomer: false,
      },
      () => {
        this.getOutstandingInvoices(customer.id)
      }
    )
  }

  getOutstandingInvoices = (customer_id: number) => {
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          url: "deposits/outstanding",
          method: "post",
          body: {
            customer_id: customer_id,
          },
        },
        (response: { data: Array<invoice> }) => {
          this.setState({
            loading: false,
            invoices: response.data,
          })
        }
      )
    })
  }

  render() {
    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseCustomer) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <CustomerSearch returnResult={this.customerSearchResult} />
        </div>
      )
    }

    if (!this.state.customer) {
      return (
        <div>
          Choose a customer: <button onClick={this.toCustomerSearch}>Choose Customer</button>
        </div>
      )
    }

    return <div style={headerStyle}>Wow</div>
  }
}

export default ApplyDeposit
