import React, { Component } from "react"

// import CSS
import "../../stylesheets/Checkbox.css"

// import child components / helpers
import LoadingSpinner from "../LoadingSpinner"
import CustomerSearch from "../customers/CustomerSearch"
import VendorSearch from "../vendors/VendorSearch"
import ProductSearch from "../products/ProductSearch"
import ControlledInput from "../edit/ControlledInput"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import { calculateMargin, regexStandards, fireAxios, toCurrency, inputDateToDateObj, round2 } from "../helpers"

// import required libraries
import shortid from "shortid"
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"
import arrayMove from "array-move"

/*
  ██████  ██████   ██████  ██████  ██    ██  ██████ ████████     ██      ██ ███████ ████████
  ██   ██ ██   ██ ██    ██ ██   ██ ██    ██ ██         ██        ██      ██ ██         ██
  ██████  ██████  ██    ██ ██   ██ ██    ██ ██         ██        ██      ██ ███████    ██
  ██      ██   ██ ██    ██ ██   ██ ██    ██ ██         ██        ██      ██      ██    ██
  ██      ██   ██  ██████  ██████   ██████   ██████    ██        ███████ ██ ███████    ██
  */

const DragHandle = SortableHandle(() => {
  const svgStyle = {
    cursor: "s-resize",
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" style={svgStyle}>
      <path d="M2 13.5h14V12H2v1.5zm0-4h14V8H2v1.5zM2 4v1.5h14V4H2z" />
    </svg>
  )
})

const SortableProduct = SortableElement(
  ({
    prod,
    handleInput,
    deleteProduct,
  }: {
    prod: product
    handleInput: (name: string, value: string | number, product: product) => void
    deleteProduct: (product: product) => void
  }) => {
    const inputs: Array<{ name: string }> = [{ name: "sell_price" }, { name: "cost" }, { name: "margin" }]

    return (
      <tr className="row" style={{ cursor: "default" }} key={prod.unique_id}>
        <td>
          <DragHandle />
        </td>
        <td>
          <b>
            {prod.product_line} - {prod.product_num}
          </b>{" "}
          {prod.description}
        </td>
        {inputs.map((input) => {
          return (
            <td key={input.name}>
              <ControlledInput
                name={input.name}
                handleChange={(n, v) => handleInput(n, v, prod)}
                restriction={"unsigned-float"}
                value={prod[input.name] || ""}
              />
            </td>
          )
        })}
        <td>{prod.price_per}</td>
        <td>{toCurrency(prod.avg_cost || 0)}</td>
        <td>{toCurrency(prod.landed_cost || 0)}</td>
        <td>
          <button onClick={() => deleteProduct(prod)}>Delete</button>
        </td>
      </tr>
    )
  }
)

const SortableProductList = SortableContainer(
  ({
    products,
    shipNdebit,
    handleInput,
    deleteProduct,
  }: {
    products: Array<product>
    shipNdebit: boolean
    handleInput: (name: string, value: string | number, product: product) => void
    deleteProduct: (product: product) => void
  }) => {
    return (
      <table className="searchResults">
        <tbody>
          <tr>
            <th></th>
            <th>Product</th>
            <th>Sell Price</th>
            <th>{shipNdebit ? "Cost" : "Target Cost"}</th>
            <th>Margin</th>
            <th>Price Per</th>
            <th>Avg Cost</th>
            <th>Landed Cost</th>
            <th></th>
          </tr>
          {products.map((prod, index) => {
            // generate a unique id for each detail so React can manage the state properly
            if (!prod.hasOwnProperty("unique_id")) {
              prod.unique_id = shortid.generate()
            }

            return <SortableProduct key={prod.unique_id} index={index} prod={prod} deleteProduct={deleteProduct} handleInput={handleInput} />
          })}
        </tbody>
      </table>
    )
  }
)

/*
   ██████  ██████  ███    ███ ██████   ██████  ███    ██ ███████ ███    ██ ████████
  ██      ██    ██ ████  ████ ██   ██ ██    ██ ████   ██ ██      ████   ██    ██
  ██      ██    ██ ██ ████ ██ ██████  ██    ██ ██ ██  ██ █████   ██ ██  ██    ██
  ██      ██    ██ ██  ██  ██ ██      ██    ██ ██  ██ ██ ██      ██  ██ ██    ██
   ██████  ██████  ██      ██ ██       ██████  ██   ████ ███████ ██   ████    ██
  */

type props = {
  id?: number
}

type product = {
  id?: number
  product_id?: number
  sell_price?: number
  cost?: number
  margin?: number
  unique_id?: string
  product_line?: string
  product_num?: string
  description?: string
  price_per?: number
  avg_cost?: number
  landed_cost?: number
  [index: string]: any
}

type customer = {
  id?: number
  warehouse_id?: number
  customer_num?: string
  company_name?: string
}

type vendor = {
  id?: number
  name?: string
  ship_n_debit?: boolean
  product_line?: string
}

type state = {
  loading: boolean
  pricingId?: number
  title: string
  shipNdebit: boolean
  effectiveDate: Date | string
  expiryDate: Date | string
  customer: customer
  toChooseCustomer: boolean
  vendor: vendor
  toChooseVendor: boolean
  products: Array<product>
  toChooseProduct: boolean
  dialog: dialogProps
  [index: string]: any
}

const initialState: state = {
  loading: false,
  pricingId: undefined,
  title: "",
  shipNdebit: false,
  effectiveDate: "",
  expiryDate: "",
  customer: {},
  toChooseCustomer: false,
  vendor: {},
  toChooseVendor: false,
  products: [],
  toChooseProduct: false,
  dialog: null,
}

class Pricing extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState

    if (this.props.setAppTitle) {
      this.props.setAppTitle("Create Pricing")
    }
  }

  componentDidMount() {
    const todaysDate = new Date()
    const todaysDateArr = [todaysDate.getFullYear(), ("0" + (todaysDate.getMonth() + 1)).slice(-2), ("0" + todaysDate.getDate()).slice(-2)]
    const todaysDateString = todaysDateArr.join("-")

    this.setState(
      {
        effectiveDate: todaysDateString,
      },
      () => {
        if (this.props.id) {
          this.getPricingDetails(this.props.id)
        }
      }
    )
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.setState({
      products: arrayMove(this.state.products, oldIndex, newIndex),
    })
  }

  /*
   ██████  ███████ ████████
  ██       ██         ██
  ██   ███ █████      ██
  ██    ██ ██         ██
   ██████  ███████    ██
  */

  getPricingDetails = (pricingId: number) => {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "pricing/" + pricingId }, (response) => {
        response.data.products.forEach((prod: product) => {
          prod.margin = round2(calculateMargin(prod.sell_price || 0, prod.cost || prod.landed_cost || 0))
          prod.cost = round2(prod.cost ? prod.cost : 0)
        })

        this.setState(
          {
            ...response.data,
            loading: false,
          },
          () => {
            this.props.setAppTitle && this.props.setAppTitle(this.state.customer.customer_num + " - " + this.state.title)
          }
        )
      })
    })
  }

  /*
  ███████ ███████  █████  ██████   ██████ ██   ██
  ██      ██      ██   ██ ██   ██ ██      ██   ██
  ███████ █████   ███████ ██████  ██      ███████
       ██ ██      ██   ██ ██   ██ ██      ██   ██
  ███████ ███████ ██   ██ ██   ██  ██████ ██   ██
  */

  backFromSearch = () => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(0)
    }

    this.setState({
      toChooseCustomer: false,
      toChooseVendor: false,
      toChooseProduct: false,
    })
  }

  toCustomerSearch = () => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(1)
    }

    this.setState({
      toChooseCustomer: true,
    })
  }

  customerSearchResult = (customer: customer) => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(0)
    }

    this.setState({
      customer: customer,
      toChooseCustomer: false,
    })
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  toggleShipNdebit = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      vendor: {},
      shipNdebit: e.target.checked,
    })
  }

  toVendorSearch = () => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(1)
    }

    this.setState({
      toChooseVendor: true,
    })
  }

  vendorSearchResult = (vendor: vendor) => {
    if (this.state.shipNdebit && !vendor.ship_n_debit) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "This vendorr does not support ship and debit.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (!vendor.product_line) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "This vendor does not have a product line.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(0)
    }

    this.setState({
      vendor: vendor,
      toChooseVendor: false,
    })
  }

  toProductSearch = () => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(1)
    }

    this.setState({
      toChooseProduct: true,
    })
  }

  isProductInUse = (product: product, callback: (response: boolean | product) => void) => {
    const prodsCopy = JSON.parse(JSON.stringify(this.state.products))

    if (prodsCopy.some((prod: product) => prod.product_id === product.id)) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "This product is already on this price list.",
        handleResponse: this.hideDialog,
      })
      callback(true)
    } else {
      this.setState({ loading: true }, () => {
        fireAxios(
          {
            method: "post",
            url: "pricing/isproductinuse",
            data: {
              product_id: product.id,
              customer_id: this.state.customer.id,
            },
          },
          (response) => {
            this.setState(
              {
                loading: false,
              },
              () => {
                if (response.data) {
                  this.showDialog({
                    type: "alert",
                    title: "Error!",
                    message: "This product is already in use on another active price list by the same customer.",
                    handleResponse: this.hideDialog,
                  })
                }
                callback(response.data)
              }
            )
          }
        )
      })
    }
  }

  productSearchResult = (product: product) => {
    this.isProductInUse(product, (inUse) => {
      if (inUse) {
        return
      } else {
        let newProds = JSON.parse(JSON.stringify(this.state.products))

        if (this.props.handleSearchDepth) {
          this.props.handleSearchDepth(0)
        }

        // for querying data
        product.product_id = product.id
        delete product.id

        // get the latest avg cost
        this.setState({ loading: true }, () => {
          fireAxios(
            {
              method: "post",
              url: "qtycost/avgcost",
              data: {
                product_id: product.product_id,
                warehouse_id: this.state.customer.warehouse_id,
              },
            },
            (response) => {
              product.avg_cost = response.data
              newProds.push(product)
              this.setState({
                loading: false,
                products: newProds,
                toChooseProduct: false,
              })
            }
          )
        })
      }
    })
  }

  /*
  ██ ███    ██ ██████  ██    ██ ████████ ███████
  ██ ████   ██ ██   ██ ██    ██    ██    ██
  ██ ██ ██  ██ ██████  ██    ██    ██    ███████
  ██ ██  ██ ██ ██      ██    ██    ██         ██
  ██ ██   ████ ██       ██████     ██    ███████
  */

  deleteProduct = (product: product) => {
    const deleteIndex = this.state.products.findIndex((x) => x.unique_id === product.unique_id)
    let newProds = JSON.parse(JSON.stringify(this.state.products))
    newProds.splice(deleteIndex, 1)

    this.setState({
      products: newProds,
    })
  }

  handleProductInput = (name: string, value: string | number, product: product) => {
    let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let foundProd = newProds.find((x) => x.unique_id === product.unique_id)

    if (!foundProd) return

    // calculate sell based on margin, or calculate margin based on sell/cost
    if (name === "margin" && !value) {
      foundProd.sell_price = 0
    } else if (name === "sell_price" && !value) {
      foundProd.margin = 0
    } else if (name === "margin" && foundProd.cost) {
      foundProd.sell_price = round2(-(100 * parseFloat((foundProd.cost || 0).toString())) / (parseFloat(value.toString()) - 100))
    } else if (name === "margin" && !foundProd.cost) {
      foundProd.sell_price = round2(-(100 * parseFloat((foundProd.landed_cost || 0).toString())) / (parseFloat(value.toString()) - 100))
    } else if (name === "sell_price" && foundProd.cost) {
      foundProd.margin = round2(calculateMargin(value, foundProd.cost))
    } else if (name === "sell_price" && !foundProd.cost) {
      foundProd.margin = round2(calculateMargin(value, foundProd.landed_cost || 0))
    } else if (name === "cost" && !value && foundProd.sell_price) {
      foundProd.margin = round2(calculateMargin(foundProd.sell_price, foundProd.landed_cost || 0))
    } else if (name === "cost" && foundProd.sell_price) {
      foundProd.margin = round2(calculateMargin(foundProd.sell_price, value))
    } else {
      foundProd.margin = 0
    }

    foundProd[name] = value

    this.setState({
      products: newProds,
    })
  }

  handleInputs = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  validateInputs = () => {
    const floatRegex = regexStandards("unsigned-float")

    if (
      this.state.products.some((x) => {
        return (
          (x.cost && !floatRegex.test(x.cost.toString())) ||
          (x.margin && !floatRegex.test(x.margin.toString())) ||
          (x.sell_price && !floatRegex.test(x.sell_price.toString()))
        )
      })
    ) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "Some inputs are invalid.",
        handleResponse: this.hideDialog,
      })
      return false
    }

    if (!this.state.title) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "You must have a title",
        handleResponse: this.hideDialog,
      })
      return false
    }

    if (!this.state.effectiveDate || !this.state.expiryDate) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "Effective and expiry date must be entered.",
        handleResponse: this.hideDialog,
      })
      return false
    }

    if (this.state.products.some((x) => !x.sell_price)) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "All products must have a sell price.",
        handleResponse: this.hideDialog,
      })
      return false
    }

    if (this.state.shipNdebit && this.state.products.some((x) => !x.cost)) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "For ship & debit, all products must have a cost.",
        handleResponse: this.hideDialog,
      })
      return false
    }

    return true
  }

  /*
  ███████ ██    ██ ██████  ███    ███ ██ ████████
  ██      ██    ██ ██   ██ ████  ████ ██    ██
  ███████ ██    ██ ██████  ██ ████ ██ ██    ██
       ██ ██    ██ ██   ██ ██  ██  ██ ██    ██
  ███████  ██████  ██████  ██      ██ ██    ██
  */

  submitSave = () => {
    if (!this.validateInputs()) {
      return
    }

    const effectiveDate = inputDateToDateObj(this.state.effectiveDate.toString())
    const expiryDate = inputDateToDateObj(this.state.expiryDate.toString())

    const dataToSubmit = {
      id: this.state.pricingId,
      title: this.state.title,
      ship_n_debit: this.state.shipNdebit,
      effective_date: effectiveDate,
      expiry_date: expiryDate,
      customer_id: this.state.customer.id,
      vendor_id: this.state.vendor.id,
      products: this.state.products,
    }

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: this.state.pricingId ? "pricing/update" : "pricing/create", data: dataToSubmit }, (response) => {
        const pricingId = this.state.pricingId ? JSON.parse(JSON.stringify(this.state.pricingId)) : response.data
        this.setState(initialState, () => {
          this.getPricingDetails(pricingId)
        })
      })
    })
  }

  /*
  ██████  ███████ ███    ██ ██████  ███████ ██████
  ██   ██ ██      ████   ██ ██   ██ ██      ██   ██
  ██████  █████   ██ ██  ██ ██   ██ █████   ██████
  ██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
  ██   ██ ███████ ██   ████ ██████  ███████ ██   ██
  */

  render() {
    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseCustomer) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Pricing</button>
          <br />
          <br />
          <CustomerSearch returnResult={this.customerSearchResult} />
        </div>
      )
    }

    if (this.state.toChooseVendor) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Pricing</button>
          <br />
          <br />
          <VendorSearch returnResult={this.vendorSearchResult} />
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    if (this.state.toChooseProduct) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Pricing</button>
          <br />
          <br />
          <ProductSearch
            returnResult={this.productSearchResult}
            defaultedFields={this.state.shipNdebit && this.state.vendor.product_line ? { line: this.state.vendor.product_line } : undefined}
          />
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    if (!this.state.customer.customer_num) {
      return (
        <div>
          To begin, choose a customer: <button onClick={this.toCustomerSearch}>Choose Customer</button>
        </div>
      )
    }

    const moreThanOneProd = this.state.products.length > 0

    return (
      <div>
        <div style={headerStyle}>Customer</div>
        <div>
          <b>{this.state.customer.customer_num}</b> - {this.state.customer.company_name}
          &nbsp;
          <button onClick={this.toCustomerSearch} disabled={!!this.state.pricingId || moreThanOneProd}>
            Edit
          </button>
        </div>

        <div>
          <div style={headerStyle}>Title</div>
          <input style={{ width: "250px" }} name={"title"} onChange={this.handleInputs} value={this.state.title || ""} />
        </div>

        <div>
          <div style={headerStyle}>Dates</div>
          Effective Date: <input type="date" name={"effectiveDate"} onChange={this.handleInputs} value={this.state.effectiveDate.toString() || ""} />
          &nbsp; Expiry Date: <input type="date" name={"expiryDate"} onChange={this.handleInputs} value={this.state.expiryDate.toString() || ""} />
        </div>

        {this.state.pricingId ? (
          this.state.shipNdebit ? (
            <div style={headerStyle}>Ship & Debit</div>
          ) : null
        ) : (
          <div style={headerStyle}>
            Ship & Debit:
            <input type="checkbox" disabled={moreThanOneProd} checked={this.state.shipNdebit} onChange={this.toggleShipNdebit} />
          </div>
        )}

        {this.state.shipNdebit ? (
          <div>
            <div style={headerStyle}>Vendor</div>
            <div>
              <b>{this.state.vendor.product_line}</b> - {this.state.vendor.name}
              &nbsp;
              <button onClick={this.toVendorSearch} disabled={!!this.state.pricingId || moreThanOneProd}>
                {this.state.vendor.name ? "Edit" : "Choose Vendor"}
              </button>
            </div>
          </div>
        ) : null}

        {this.state.shipNdebit && !this.state.vendor.name ? null : (
          <div>
            <div style={headerStyle}>Products</div>
            <SortableProductList
              products={this.state.products}
              shipNdebit={this.state.shipNdebit}
              onSortEnd={this.onSortEnd}
              deleteProduct={this.deleteProduct}
              handleInput={this.handleProductInput}
              useDragHandle={true}
            />
            <br />
            <button onClick={this.toProductSearch}>Add Product</button>
          </div>
        )}

        {moreThanOneProd ? (
          <div>
            <br />
            <br />
            <div>
              <button onClick={this.submitSave}>{this.state.pricingId ? "Save" : "Submit"}</button>
              {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default Pricing
