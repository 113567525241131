import React, { Component } from "react"

// import child components / helpers
import LoadingSpinner from "../LoadingSpinner"
import { fireAxios, toReadableDate } from "../helpers"

type props = {
  id?: number
}

type state = {
  loading: boolean
  currency: {
    country_name: string
    country_code: string
    currency_code: string
  }
  history: Array<{
    id: number
    entry_date: string
    cad_conversion: number
  }>
}

const initialState: state = {
  currency: {
    country_name: "",
    country_code: "",
    currency_code: "",
  },
  history: [],
  loading: false,
}

class Currency extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState

    if (this.props.setAppTitle) {
      this.props.setAppTitle("Currency")
    }
  }

  componentDidMount() {
    this.getCurrencyDetails(this.props.id ? this.props.id : 0)
  }

  getCurrencyDetails = (id: number) => {
    // get currency details
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "currencies/" + id }, (response) => {
        this.setState(
          {
            currency: response.data.currency,
            history: response.data.history,
            loading: false,
          },
          () => {
            this.props.setAppTitle && this.props.setAppTitle("Currency: " + this.state.currency.country_name)
          }
        )
      })
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    return (
      <div>
        Country Code: {this.state.currency.country_code}
        <br />
        Country Name: {this.state.currency.country_name}
        <br />
        Currency Code: {this.state.currency.currency_code}
        <br />
        <br />
        <table>
          <tbody>
            <tr>
              <th>Entry Date</th>
              <th>CD Conversion</th>
            </tr>
            {this.state.history.map((hist) => {
              return (
                <tr key={hist.id}>
                  <td>{toReadableDate(hist.entry_date)}</td>
                  <td style={{ textAlign: "right" }}>{hist.cad_conversion}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Currency
