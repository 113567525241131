import React, { Component } from "react"

type props = {
  menuItems: Array<{
    name: string
    onClick: () => void
  }>
  elementType: "span" | "tbody"
} & React.HTMLAttributes<HTMLSpanElement | HTMLTableRowElement>

type state = {
  visible: boolean
  hoveredItem?: string
  xPos?: number
  yPos?: number
}

const initialState: state = {
  visible: false,
}

class ContextMenu extends Component<props, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState
  }

  handleContextMenu = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault()
    this.setState({
      visible: true,
      xPos: e.clientX - 5,
      yPos: e.clientY - 5,
    })
  }

  hide = () => {
    this.setState({ visible: false })
  }

  hoverItem = (itemName: string) => {
    this.setState({
      hoveredItem: itemName,
    })
  }

  leaveItem = (itemName: string) => {
    this.setState({
      hoveredItem: itemName,
    })
  }

  render() {
    const overlayBody: React.CSSProperties = {
      position: "fixed",
      top: this.state.yPos,
      left: this.state.xPos,
      zIndex: 1000,
      border: "1px solid black",
      backgroundColor: "white",
      padding: "10px",
      overflow: "auto",
    }

    const hoveredStyle: React.CSSProperties = {
      color: "black",
    }

    const itemStyle: React.CSSProperties = {
      cursor: "pointer",
      border: "none",
      padding: "0px",
      margin: "0px",
    }

    const { menuItems, elementType: ElementType, ...allOtherProps } = this.props
    const SubElementType = ElementType === "tbody" ? "tr" : "span"
    const FinalElementType = SubElementType === "tr" ? "td" : "span"

    return (
      <ElementType onContextMenu={(e) => !this.state.visible && this.handleContextMenu(e)} {...allOtherProps}>
        {this.state.visible && this.props.menuItems.length > 0 ? (
          <SubElementType style={overlayBody} onMouseLeave={this.hide}>
            {this.props.menuItems.map((x) => (
              <FinalElementType
                style={{ ...itemStyle, ...(x.name === this.state.hoveredItem && hoveredStyle) }}
                onMouseEnter={() => this.hoverItem(x.name)}
                onMouseLeave={() => this.leaveItem(x.name)}
                key={x.name}
                onClick={() => {
                  x.onClick()
                  this.hide()
                }}
              >
                {x.name}
              </FinalElementType>
            ))}
          </SubElementType>
        ) : null}
        {this.props.children}
      </ElementType>
    )
  }
}

export default ContextMenu
