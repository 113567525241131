import React, { Component } from "react"

import BasicCreate from "../create/BasicCreate"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import SalesrepSearch from "../salesreps/SalesrepSearch"
import Customer from "./Customer"

class CreateCustomer extends Component {
  render() {
    return (
      <BasicCreate
        {...{
          title: "Customer",
          resultChild: <Customer />,
          inputs: [
            // required fields
            { value: "", required: true, name: "Number", databaseField: "customer_num", inputType: "text", restriction: "customer-num" },
            {
              value: "1",
              required: true,
              name: "Cash Account",
              databaseField: "cod_status",
              inputType: "select",
              options: [
                { value: "0", name: "No" },
                { value: "1", name: "Yes" },
              ],
            },
            {
              value: "",
              required: true,
              name: "Warehouse",
              databaseField: "warehouse_id",
              inputType: "search",
              searchName: "Warehouse",
              searchChild: <WarehouseSearch />,
              requiredSearchResult: "id",
              displaySearchResult: "name",
            },
            {
              value: "",
              required: true,
              name: "Salesrep",
              databaseField: "salesrep_id",
              inputType: "search",
              searchName: "Salesrep",
              searchChild: <SalesrepSearch />,
              requiredSearchResult: "id",
              displaySearchResult: "first_name",
            },
            { value: "", required: true, name: "Company Name", databaseField: "company_name", inputType: "text" },
            { value: "", required: true, name: "Phone Number", databaseField: "phone_num", inputType: "text", restriction: "phone-number" },
            { value: "", required: true, name: "Address Line 1", databaseField: "address_1", inputType: "text" },
            { value: "", required: true, name: "City", databaseField: "city", inputType: "text" },
            { value: "", required: true, name: "Province", databaseField: "province", inputType: "text", restriction: "province" },
            { value: "", required: true, name: "Postal Code", databaseField: "postal_code", inputType: "text" },
            { value: "Canada", required: true, name: "Country", databaseField: "country", inputType: "text" },
            {
              value: "0",
              required: true,
              name: "Credit Limit Days",
              databaseField: "credit_limit_days",
              inputType: "text",
              restriction: "unsigned-int",
            },
            {
              value: "0",
              required: true,
              name: "Credit Limit Amount",
              databaseField: "credit_limit_amount",
              inputType: "text",
              restriction: "unsigned-int",
            },
            // optional fields
            { value: "", required: false, name: "Description", databaseField: "description", inputType: "text" },
            { value: "", required: false, name: "Address Line 2", databaseField: "address_2", inputType: "text" },
            { value: "", required: false, name: "Fax Number", databaseField: "fax_num", inputType: "text", restriction: "phone-number" },
            {
              value: "None",
              required: false,
              name: "Discount",
              databaseField: "discount_code",
              inputType: "select",
              options: [
                { value: "2%", name: "2%" },
                { value: "None", name: "None" },
              ],
            },
          ],
          endpoint: "customers/create",
        }}
        {...this.props}
      />
    )
  }
}

export default CreateCustomer
