import React, { Component } from "react"

// import child components / helpers
import Search from "../search/Search"
import PurchaseOrder from "./PurchaseOrder"

class POSearch extends Component<appProps> {
  render() {
    return (
      <Search
        title={"PO"}
        queries={[
          { name: "ID", value: "", databaseSearch: "id", restriction: "non-zero-unsigned-int" },
          { name: "Vendor ID", value: "", databaseSearch: "vendor_id", restriction: "non-zero-unsigned-int" },
          { name: "Vendor Name - TODO", value: "", databaseSearch: "" },
          { name: "Product - TODO", value: "", databaseSearch: "" },
        ]}
        resultColumns={[
          { name: "ID", databaseField: "id" },
          { name: "Vendor ID", databaseField: "vendor_id" },
        ]}
        apiEndpoint={"purchaseOrders/searchPOs"}
        children={<PurchaseOrder />}
        {...this.props}
      />
    )
  }
}

export default POSearch
