import React, { Component } from "react"

// import child components
import LoadingSpinner from "../../LoadingSpinner"
import PurchaseOrder from "../PurchaseOrder"
import NewTabText from "../../newTabText/NewTabText"
import ControlledInput from "../../edit/ControlledInput"
import { toCurrency } from "../../helpers"

type props = {
  detail: {
    id: number
    name: string
    product_line: string
    min_order_val: number
    min_prepaid_val: number
    products: Array<product>
  }
}

type order = {
  order_id: number
  order_product_id: number
  quantity: number
}

type warehouse = {
  id: number
  name: string
  qty_required: number
  orders: Array<order>
  quantity?: number
  qty_error_style?: React.CSSProperties
  [index: string]: any
}

type product = {
  unique_id?: string
  id: number
  os_id: number
  pur_mult: number
  quantity: number
  cost: number
  product_line: string
  product_num: string
  description: string
  vendor_cost: number
  warehouses: Array<warehouse>
  mult_error_style?: React.CSSProperties
}

type state = {
  loading: boolean
  products: Array<product>
  toPO: boolean
  errorMessage?: string
  productInfo?: product
}

class OpenToBuyDetail extends Component<props & appProps, state> {
  state: state = {
    loading: false,
    products: this.props.detail.products,
    toPO: false,
    errorMessage: undefined,
    productInfo: undefined,
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEscapeKey, false)

    // set app title if called, otherwise it's probably
    // being called as a child to a component other than App.js
    if (this.props.setAppTitle && this.props.detail) {
      this.props.setAppTitle("Open to Buy: " + this.props.detail.product_line + " - " + this.props.detail.name)
      this.setupProds()
    } else {
      console.log("No AppTitle and/or Details: OpenToBuyDetail")
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEscapeKey, false)
  }

  handleEscapeKey = () => {
    this.hideProductInfo()
  }

  handleSearchDepth = (depth: number) => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(depth)
    }
  }

  setupProds = () => {
    let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    newProds.forEach((prod) => {
      prod.warehouses.forEach((whse) => {
        let qtyReq = whse.qty_required
        whse.quantity = qtyReq
      })
    })

    newProds.forEach((prod) => {
      prod.warehouses.forEach((whse) => {
        prod = this.findError(prod, whse)
      })
    })

    this.setState({ products: newProds }, () => {
      this.countErrors()
    })
  }

  handleProductInputChange = (name: string, value: string | number, prod: product, whse: warehouse) => {
    let newArray: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    // find the desired input to change the value of, then change that value
    let prodIndex = newArray.findIndex((x) => {
      return x.unique_id === prod.unique_id
    })
    let whseIndex = newArray[prodIndex].warehouses.findIndex((y) => {
      return y.id === whse.id
    })

    newArray[prodIndex].warehouses[whseIndex][name] = value
    newArray[prodIndex] = this.findError(newArray[prodIndex], newArray[prodIndex].warehouses[whseIndex])

    this.setState({ products: newArray }, () => {
      this.countErrors()
    })
  }

  findError = (prod: product, whse: warehouse) => {
    let whseIndex = prod.warehouses.findIndex((y) => y.id === whse.id)

    // find total order quantity accross warehouses to compare against purchase multiple
    let totalQtyToOrder = 0
    prod.warehouses.forEach((whse) => (totalQtyToOrder += whse.quantity ? whse.quantity : 0))

    if (!/^(\d)+$/.test(String(whse.quantity))) {
      // input is not a positive integer
      prod.warehouses[whseIndex].qty_error_style = {
        borderStyle: "solid",
        borderColor: "red",
      }
    } else {
      prod.warehouses[whseIndex].qty_error_style = undefined
    }

    if (totalQtyToOrder % prod.pur_mult > 0) {
      prod.mult_error_style = {
        border: "2px solid",
        borderColor: "red",
      }
    } else {
      prod.mult_error_style = undefined
    }

    return prod
  }

  countErrors = () => {
    var numErrors = 0 // find the total number of invalid inputs
    this.state.products.forEach((prod) => {
      numErrors += prod.mult_error_style !== null ? 1 : 0
      prod.warehouses.forEach((whse) => {
        numErrors += whse.qty_error_style !== null ? 1 : 0
      })
    })

    if (numErrors > 0) {
      // display error
      this.setState({ errorMessage: "Error, invalid values in some inputs." })
      return numErrors
    } else {
      this.setState({ errorMessage: undefined })
      return numErrors
    }
  }

  createPO = () => {
    if (this.countErrors() === 0) {
      this.setState({
        toPO: true,
      })
    }
  }

  showProductInfo = (product: product) => {
    this.setState({
      productInfo: product,
    })
  }

  hideProductInfo = () => {
    this.setState({
      productInfo: undefined,
    })
  }

  stopPropagation = (e: any) => {
    // prevents the overlay from closing when the body of the overlay is clicked
    e.stopPropagation()
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toPO) {
      return (
        <PurchaseOrder
          {...this.props}
          loadDetails={{
            vendor: {
              id: this.props.detail.id,
              product_line: this.props.detail.product_line,
              name: this.props.detail.name,
            },
            products: this.state.products,
          }}
        />
      )
    }

    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    const overlay: React.CSSProperties = {
      position: "fixed" /* Sit on top of the page content */,
      display: "block" /* Hidden by default */,
      width: "100%" /* Full width (cover the whole page) */,
      height: "100%" /* Full height (cover the whole page) */,
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.5)" /* Black background with opacity */,
      zIndex: 99 /* Specify a stack order in case you're using a different order for other elements */,
      cursor: "pointer" /* Add a pointer on hover */,
    }

    const overlayBody: React.CSSProperties = {
      position: "absolute",
      top: "15%",
      left: "25%",
      width: "50%",
      height: "70%",
      zIndex: 100,
      boxShadow: "0px 0px 30px #000000",
      backgroundColor: "white",
      cursor: "default",
      padding: "20px",
      overflow: "auto",
    }

    let totalCost = 0

    return (
      <div>
        {this.state.productInfo ? (
          <div style={overlay} onClick={() => this.hideProductInfo()}>
            <div style={overlayBody} onClick={(e) => this.stopPropagation(e)}>
              <div style={headerStyle}>
                {this.state.productInfo.os_id ? (
                  <span>
                    <span style={{ color: "red" }}>OS</span>
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>{this.state.productInfo.product_num}</span>
                    &nbsp;
                    <span style={{ fontWeight: "normal" }}>{this.state.productInfo.description}</span>
                  </span>
                ) : (
                  <span>
                    <NewTabText id={this.state.productInfo.id} link={"product"} text={this.state.productInfo.product_num} />
                    &nbsp;
                    <span style={{ fontWeight: "normal" }}>{this.state.productInfo.description}</span>
                  </span>
                )}
              </div>
              <div style={headerStyle}>For Stock: {this.state.productInfo.warehouses.reduce((acc, curr) => (acc += curr.qty_for_min_max), 0)}</div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <th>Warehouse</th>
                      <th>Qty Onhand</th>
                      <th>Min/Max</th>
                      <th>Qty Required</th>
                    </tr>
                    {this.state.productInfo.warehouses.map((warehouse) =>
                      parseInt(warehouse.qty_for_min_max, 10) > 0 ? (
                        <tr key={warehouse.id}>
                          <td>{warehouse.name}</td>
                          <td>{warehouse.qty_onhand}</td>
                          <td>
                            {warehouse.min}/{warehouse.max}
                          </td>
                          <td>{warehouse.qty_for_min_max}</td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                </table>
              </div>
              <div style={headerStyle}>For Orders: {this.state.productInfo.warehouses.reduce((acc, curr) => (acc += curr.qty_for_orders), 0)}</div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <th>Warehouse</th>
                      <th>Order Number</th>
                      <th>Qty Required</th>
                    </tr>
                    {this.state.productInfo.warehouses.map((warehouse) =>
                      warehouse.orders.map((order) => (
                        <tr key={warehouse.id + " " + order.order_id}>
                          <td>{warehouse.name}</td>
                          <td>
                            <NewTabText id={order.order_id} link="order" text={order.order_id.toString()} />
                          </td>
                          <td>{order.quantity}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : null}
        <table className="searchResults">
          <tbody>
            <tr>
              <th>Product</th>
              <th>Cost</th>
              <th>Pur. Mult.</th>
              <th>Ship To</th>
              <th>Qty Required</th>
              <th>Qty to Order</th>
              <th>Total Qty</th>
            </tr>
            {this.state.products.map((prod) => {
              const splitDiv0 = { borderBottom: "1px solid #000", padding: "8px" }
              const splitDiv1 = { padding: "8px" }
              const noPadding = { padding: "0px" }
              const warehouses = prod.warehouses
              let totalQtyToOrder = 0
              warehouses.forEach((whse) => (totalQtyToOrder += whse.quantity ? whse.quantity : 0))
              let cost = prod.id ? prod.vendor_cost : prod.cost
              let totalWhseCost = totalQtyToOrder * cost
              totalCost += totalWhseCost

              return (
                <tr className="row" key={prod.unique_id} onClick={() => this.showProductInfo(prod)} style={{ cursor: "pointer" }}>
                  <td>
                    {prod.os_id ? <span style={{ color: "red", fontWeight: "bold" }}>OS </span> : null}
                    <b>{prod.product_num}</b> {prod.description}
                  </td>
                  <td>{toCurrency(cost)}</td>
                  <td style={prod.mult_error_style}>{prod.pur_mult}</td>
                  <td style={noPadding}>
                    {warehouses.map((whse, i) => {
                      return (
                        <div style={i + 1 < warehouses.length ? splitDiv0 : splitDiv1} key={whse.id}>
                          {whse.name}
                        </div>
                      )
                    })}
                  </td>
                  <td style={noPadding}>
                    {warehouses.map((whse, i) => {
                      return (
                        <div style={i + 1 < warehouses.length ? splitDiv0 : splitDiv1} key={whse.id}>
                          {whse.qty_required}
                        </div>
                      )
                    })}
                  </td>
                  <td style={noPadding} onClick={this.stopPropagation}>
                    {warehouses.map((whse, i) => {
                      return (
                        <div style={i + 1 < warehouses.length ? splitDiv0 : splitDiv1} key={whse.id}>
                          <ControlledInput
                            style={whse.qty_error_style}
                            value={whse.quantity || ""}
                            name="quantity"
                            restriction={"unsigned-int"}
                            handleChange={(n, v) => this.handleProductInputChange(n, v, prod, whse)}
                          />
                        </div>
                      )
                    })}
                  </td>
                  <td style={prod.mult_error_style}>{totalQtyToOrder.toString()}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <br />
        <br />
        <table>
          <tbody>
            <tr>
              <td>Total cost: </td>
              <td style={{ float: "right" }}>{toCurrency(totalCost)}</td>
            </tr>
            <tr>
              <td>Min Order: </td>
              <td style={{ float: "right" }}>{toCurrency(this.props.detail.min_order_val)}</td>
            </tr>
            <tr>
              <td>Min Prepaid: </td>
              <td style={{ float: "right" }}>{toCurrency(this.props.detail.min_prepaid_val)}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <div>
          {this.state.errorMessage ? (
            <div style={{ color: "red" }}>{this.state.errorMessage}</div>
          ) : (
            <button onClick={this.createPO}>Create PO</button>
          )}
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    )
  }
}

export default OpenToBuyDetail
