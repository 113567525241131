import React, { Component } from "react"

// import child components / helpers
import Search from "../search/Search"
import Order from "./Order"

class OrderSearch extends Component<appProps> {
  render() {
    return (
      <Search
        title={"Order"}
        closable={true}
        preventClosedUse={true}
        queries={[
          { name: "Order Number", value: "", databaseSearch: "number", restriction: "non-zero-unsigned-int" },
          { name: "Customer Number", value: "", databaseSearch: "customer_num", restriction: "customer-num" },
          { name: "Customer Name", value: "", databaseSearch: "customer_name" },
          { name: "PO Number", value: "", databaseSearch: "po_number" },
          { name: "Product Line", value: "", databaseSearch: "product_line", restriction: "product-line" },
          { name: "Product Number", value: "", databaseSearch: "product_num" },
          { name: "Salesrep", value: "", databaseSearch: "salesrep" },
          { name: "Invoice Number", value: "", databaseSearch: "invoice_num", restriction: "non-zero-unsigned-int" },
          { type: "date_range", name: "Date Created", value: "", databaseSearch: "date_range" },
          { type: "checkbox", name: "Quote", value: false, databaseSearch: "is_quote" },
          { type: "checkbox", name: "Cancelled", value: false, databaseSearch: "cancelled" },
        ]}
        resultColumns={[
          { name: "Order Number", databaseField: "number" },
          { name: "Customer Number", databaseField: "customer_num" },
          { optional: true, name: "Customer Name", databaseField: "customer_name" },
          { name: "PO Number", databaseField: "po_number" },
          { optional: true, name: "Product Line", databaseField: "product_line" },
          { optional: true, name: "Product Number", databaseField: "product_num" },
          { optional: true, name: "Salesrep", databaseField: "salesrep" },
          { optional: true, link_to: "Salesrep", name: "Taken By", databaseField: "taken_by" },
          { optional: true, name: "Invoice Number", databaseField: "invoice_num" },
          { type: "date", name: "Date Created", databaseField: "date_created" },
        ]}
        apiEndpoint={"orders/search"}
        children={<Order />}
        {...this.props}
      />
    )
  }
}

export default OrderSearch
