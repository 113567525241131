import React, { Component } from "react"

import ControlledInput from "../edit/ControlledInput"
import { regexStandards, restrictions } from "../helpers"

export type inputTypeProps = {
  required: boolean
  databaseField: string
  restriction?: restrictions
  value: string
  name: string
  inputType?: "search" | "select" | "text"
  options?: Array<{
    value: string
    name: string
  }>
  isValid?: boolean
  searchChild?: React.ReactNode
  searchName?: string
  requiredSearchResult?: string
  displaySearchResult?: string
  display?: boolean
  toSearch?: (child: React.ReactNode, name: string) => void
  onChangeValue?: (name: string, value: string | number, isValid: boolean) => void
}

export class CreateInput extends Component<inputTypeProps> {
  isValid = (value: string) => {
    // empty input
    if (value.length === 0) {
      return !this.props.required
    }

    const regex = regexStandards(this.props.restriction)

    if (regex) {
      return regex.test(String(value))
    }
    return true
  }

  render() {
    if (this.props.inputType === "search") {
      return (
        <tr key={this.props.name}>
          <td>{this.props.name}</td>
          <td>
            {!this.props.value ? (
              <button onClick={() => this.props.toSearch && this.props.toSearch(this.props.searchChild, this.props.name)}>
                Choose {this.props.searchName}
              </button>
            ) : (
              <div>
                <b>{this.props.display}</b> &nbsp;
                <button onClick={() => this.props.toSearch && this.props.toSearch(this.props.searchChild, this.props.name)}>Edit</button>
              </div>
            )}
          </td>
        </tr>
      )
    }

    if (this.props.inputType === "select") {
      return (
        <tr key={this.props.name}>
          <td>{this.props.name}</td>
          <td>
            <select
              name={this.props.name}
              value={this.props.value}
              onChange={(e) => this.props.onChangeValue && this.props.onChangeValue(e.target.name, e.target.value, true)}
            >
              {this.props.options &&
                this.props.options.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  )
                })}
            </select>
          </td>
        </tr>
      )
    }

    return (
      <tr key={this.props.name}>
        <td>{this.props.name}</td>
        <td>
          <ControlledInput
            type={this.props.inputType}
            placeholder={this.props.name}
            value={this.props.value}
            name={this.props.name}
            restriction={this.props.restriction}
            handleChange={(n, v) => this.props.onChangeValue && this.props.onChangeValue(n, v, this.isValid(v.toString()))}
          />
        </td>
      </tr>
    )
  }
}

export default CreateInput
