import React, { Component } from "react"

import LoadingSpinner from "../LoadingSpinner"

import { fireAxios } from "../helpers"

export type props = {
  userId: number
  permissionType: string
  message: string
  messageForQueue: string
  handleResponse: (response: boolean) => void
} | null

type state = {
  loading: boolean
  queueId: number | null
  requestingAccess: boolean
  accessGranted: boolean | null
}

const initialState: state = {
  loading: false,
  queueId: null,
  requestingAccess: false,
  accessGranted: null,
}

export class AccessDialog extends Component<props, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState
  }

  requestAccess = () => {
    this.setState({ requestingAccess: true, loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "users/request/access",
          data: {
            user_id: this.props.userId,
            type: this.props.permissionType,
            message: this.props.messageForQueue,
          },
        },
        (response: myAny) => {
          this.setState({
            loading: false,
            queueId: response.data,
          })
        }
      )
    })
  }

  cancelRequest = () => {
    this.setState({ requestingAccess: true, loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "users/cancel/access",
          data: {
            queue_id: this.state.queueId,
          },
        },
        (_response: myAny) => {
          this.setState(
            {
              loading: false,
            },
            () => this.props.handleResponse(false)
          )
        }
      )
    })
  }

  checkAccess = () => {
    this.setState({ requestingAccess: true, loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "users/verify/access",
          data: {
            queue_id: this.state.queueId,
          },
        },
        (response: myAny) => {
          this.setState({
            loading: false,
            accessGranted: response.data,
          })
        }
      )
    })
  }

  stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // prevents the overlay from closing when the body of the overlay is clicked
    e.stopPropagation()
  }

  render() {
    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      color: "#d03a29",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    const overlay: React.CSSProperties = {
      position: "fixed" /* Sit on top of the page content */,
      display: "block" /* Hidden by default */,
      width: "100%" /* Full width (cover the whole page) */,
      height: "100%" /* Full height (cover the whole page) */,
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.5)" /* Black background with opacity */,
      zIndex: 300 /* Specify a stack order in case you're using a different order for other elements */,
    }

    const overlayBody: React.CSSProperties = {
      position: "absolute",
      top: "30%",
      left: "30%",
      width: "40%",
      height: "40%",
      zIndex: 301,
      boxShadow: "0px 0px 30px #000000",
      backgroundColor: "white",
      cursor: "default",
      padding: "20px",
      overflow: "auto",
    }

    return (
      <div style={overlay}>
        <div style={overlayBody} onClick={(e) => this.stopPropagation(e)}>
          <div style={headerStyle}>Access Denied...</div>
          <br />
          {this.props.message} Would you like to request access?
          <br />
          <br />
          {!this.state.requestingAccess ? (
            <div style={{ textAlign: "center" }}>
              <button style={{ marginRight: "10%" }} onClick={this.requestAccess}>
                Request
              </button>
              &nbsp;
              <button onClick={() => this.props.handleResponse(false)}>Cancel</button>
            </div>
          ) : (
            <div>
              {this.state.accessGranted ? (
                <div>
                  <b style={{ color: "green" }}>Access Granted</b>
                  <br />
                  <br />
                  <div style={{ textAlign: "center" }}>
                    <button onClick={() => this.props.handleResponse(true)}>Okay</button>
                  </div>
                </div>
              ) : (
                <div>
                  {this.state.accessGranted === null ? (
                    <div>
                      <b>Requesting Access...</b>
                      <br />
                      <br />
                      <div style={{ textAlign: "center" }}>
                        <button style={{ marginRight: "10%" }} onClick={this.checkAccess}>
                          Verify
                        </button>
                        &nbsp;
                        <button onClick={this.cancelRequest}>Cancel</button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <b style={{ color: "red" }}>Access Denied</b>
                      <br />
                      <br />
                      <div style={{ textAlign: "center" }}>
                        <button onClick={() => this.props.handleResponse(false)}>Okay</button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {this.state.loading ? <LoadingSpinner /> : null}
        </div>
      </div>
    )
  }
}

export default AccessDialog
