import React, { Component } from "react"

// import child components
import Search from "../search/Search"
import Customer from "./Customer"

class CustomerSearch extends Component<appProps> {
  render() {
    return (
      <Search
        title={"Customer"}
        closable={true}
        preventClosedUse={true}
        queries={[
          { name: "Number", value: "", databaseSearch: "number", restriction: "customer-num" },
          { name: "Name", value: "", databaseSearch: "name" },
          { name: "Phone Number", value: "", databaseSearch: "phoneNumber", restriction: "phone-number" },
        ]}
        resultColumns={[
          { name: "Number", databaseField: "customer_num" },
          { name: "Name", databaseField: "company_name" },
          { name: "Phone Number", databaseField: "phone_num" },
        ]}
        apiEndpoint={"customers/search"}
        children={<Customer />}
        {...this.props}
      />
    )
  }
}

export default CustomerSearch
