import React, { Component } from "react"

// import child components / helpers
import LoadingSpinner from "../LoadingSpinner"
import EditableTD from "../edit/EditableTD"
import CurrencySearch from "../currencies/CurrencySearch"
import CreateVendorContact from "./CreateVendorContact"
import { Dialog, props as dialogProps } from "../dialog/Dialog"

// import CSS
import "../../stylesheets/Vendor.css"

// import required libraries
import { fireAxios } from "../helpers"

type props = {
  id?: number
  title?: string
}

type state = {
  vendorId?: number
  vendor: {
    id: number
    name: string
    product_line: string
    closed: boolean
    address_1: string
    address_2: string
    address_3: string
    city: string
    province: string
    postal_code: string
    country: string
    phone_num: string
    fax_num: string
    hold_status: boolean
    ship_n_debit: boolean
    duns_num: string
    min_order_val: number
    min_prepaid_val: number
    discount: number
    term_days: number
    term_start: number
    term_end: number
    term_due_months: number
    term_due_date: number
    currency: {
      currency_code: string
    }
    contacts: Array<{
      id: number
      pay_to: boolean
      name: string
      address_1: string
      address_2: string
      address_3: string
      city: string
      province: string
      postal_code: string
      country: string
      title: string
      email: string
      phone_num: string
      fax_num: string
    }>
  }
  focusedInput: string
  searchChild: React.ReactNode
  searchContext: string
  toSearch: boolean
  addingContact: boolean
  loading: boolean
  dialog: dialogProps
}

class Vendor extends Component<props & appProps, state> {
  state: state = {
    vendorId: 0,
    vendor: {
      id: -1,
      name: "",
      product_line: "",
      closed: true,
      address_1: "",
      address_2: "",
      address_3: "",
      city: "",
      province: "",
      postal_code: "",
      country: "",
      phone_num: "",
      fax_num: "",
      hold_status: false,
      ship_n_debit: false,
      duns_num: "",
      min_order_val: 0,
      min_prepaid_val: 0,
      discount: 0,
      term_days: 0,
      term_start: 0,
      term_end: 0,
      term_due_months: 0,
      term_due_date: 0,
      currency: {
        currency_code: "",
      },
      contacts: [],
    },
    focusedInput: "",
    searchChild: null,
    searchContext: "",
    toSearch: false,
    addingContact: false,
    loading: false,
    dialog: null,
  }

  componentDidMount() {
    this.setState(
      {
        vendorId: this.props.id,
      },
      () => {
        this.getVendorDetails(this.state.vendorId ? this.state.vendorId : -1)
      }
    )
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  getVendorDetails = (id: number) => {
    // get vendor details
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "vendors/all/" + id }, (response) => {
        this.setState(
          {
            vendor: response.data,
            loading: false,
          },
          () => {
            this.props.setAppTitle &&
              this.props.setAppTitle((this.state.vendor.product_line ? this.state.vendor.product_line + " - " : "") + this.state.vendor.name)
          }
        )
      })
    })
  }

  updateVendor = (_source: myAny, context: string, value: myAny) => {
    let newVendor = JSON.parse(JSON.stringify(this.state.vendor)) // copy to submit to datebase

    // remove data that isn't a column in the `vendors` table
    delete newVendor.currency
    delete newVendor.contacts

    if (context) {
      newVendor[context] = value
    } else {
      console.log("No context")
      return
    }

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "vendors/update", data: newVendor }, () => {
        this.getVendorDetails(this.state.vendor.id)
      })
    })
  }

  openCloseVendor = () => {
    const isClosed = this.state.vendor.closed

    this.showDialog({
      type: "confirm",
      title: "Delete contact?",
      message: "Are you sure you want to " + (isClosed ? "open" : "close") + " this vendor?",
      handleResponse: (response) => {
        if (response) {
          this.updateVendor(null, "closed", !isClosed)
        }
        this.hideDialog()
      },
    })
  }

  addContact = () => {
    this.setState({
      addingContact: true,
    })
  }

  createContactResult = () => {
    this.setState(
      {
        addingContact: false,
      },
      () => {
        this.getVendorDetails(this.state.vendor.id)
      }
    )
  }

  updateContact = (source: { [index: string]: string }, context: string, value: string | number) => {
    const dataToSubmit = {
      ...source,
      [context]: value,
    }

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "vendors/update/contact", data: dataToSubmit }, () => {
        this.getVendorDetails(this.state.vendor.id)
      })
    })
  }

  deleteContact = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const id = parseInt(e.currentTarget.name, 10)

    this.showDialog({
      type: "confirm",
      title: "Are you sure?",
      message: "Are you sure you want to delete this contact?",
      handleResponse: (response) => {
        if (response) {
          this.setState({ loading: true }, () => {
            fireAxios({ method: "post", url: "vendors/delete/contact", data: { id: id } }, () => {
              this.getVendorDetails(this.state.vendor.id)
            })
          })
        }
        this.hideDialog()
      },
    })
  }

  changeInputFocus = (position: string | number) => {
    this.setState({
      focusedInput: position.toString(),
    })
  }

  returnSearchResult = (result: { [index: string]: any }) => {
    this.setState(
      {
        toSearch: false,
        focusedInput: "",
      },
      () => {
        if (this.props.handleSearchDepth) {
          this.props.handleSearchDepth(0)
        }
        this.updateVendor(null, this.state.searchContext, result.id)
      }
    )
  }

  toSearch = (searchChild: React.ReactNode, searchContext: string) => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(1)
    }

    this.setState({
      toSearch: true,
      searchChild: searchChild,
      searchContext: searchContext,
    })
  }

  backFromSearch = () => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(0)
    }

    this.setState({
      toSearch: false,
    })
  }

  render() {
    if (this.state.toSearch) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {this.props.title}</button>
          <br />
          <br />
          {React.cloneElement(this.state.searchChild as React.ReactElement, { returnResult: this.returnSearchResult })}
        </div>
      )
    }

    return (
      <div>
        {this.state.loading ? (
          <LoadingSpinner />
        ) : (
          <div className="vendor">
            <div className="header">
              {this.state.vendor.closed ? (
                <span style={{ color: "red" }}>
                  VENDOR CLOSED
                  <br />
                  <br />
                </span>
              ) : null}
              <table>
                <tbody>
                  <tr style={{ fontSize: "30px" }}>
                    <td>{this.state.vendor.id} -&nbsp;</td>
                    {this.state.vendor.product_line ? <td style={{ fontWeight: "bold" }}>{this.state.vendor.product_line} -&nbsp;</td> : null}
                    <EditableTD
                      content={this.state.vendor.name}
                      placeholder={"Name"}
                      source={this.state.vendor}
                      context={"name"}
                      focused={"name" === this.state.focusedInput}
                      focusedID={"name"}
                      changeFocus={this.changeInputFocus}
                      update={this.updateVendor}
                    />
                  </tr>
                </tbody>
              </table>
            </div>

            <div style={{ display: "flex", justifyContent: "normal", flexWrap: "wrap" }}>
              <div style={{ padding: "12px" }}>
                Address:
                <br />
                <br />
                <table className="detailTable">
                  <tbody>
                    <tr>
                      <th>Line 1</th>
                      <EditableTD
                        content={this.state.vendor.address_1}
                        placeholder={"Line 1"}
                        source={this.state.vendor}
                        context={"address_1"}
                        focused={"Address Line 1" === this.state.focusedInput}
                        focusedID={"Address Line 1"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Line 2</th>
                      <EditableTD
                        content={this.state.vendor.address_2}
                        placeholder={"Line 2"}
                        source={this.state.vendor}
                        context={"address_2"}
                        focused={"Address Line 2" === this.state.focusedInput}
                        focusedID={"Address Line 2"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Line 3</th>
                      <EditableTD
                        content={this.state.vendor.address_3}
                        placeholder={"Line 3"}
                        source={this.state.vendor}
                        context={"address_3"}
                        focused={"Address Line 3" === this.state.focusedInput}
                        focusedID={"Address Line 3"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>City</th>
                      <EditableTD
                        content={this.state.vendor.city}
                        placeholder={"City"}
                        source={this.state.vendor}
                        context={"city"}
                        focused={"city" === this.state.focusedInput}
                        focusedID={"city"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Province</th>
                      <EditableTD
                        content={this.state.vendor.province}
                        placeholder={"Province"}
                        restriction={"province"}
                        source={this.state.vendor}
                        context={"province"}
                        focused={"province" === this.state.focusedInput}
                        focusedID={"province"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Postal</th>
                      <EditableTD
                        content={this.state.vendor.postal_code}
                        placeholder={"Postal Code"}
                        source={this.state.vendor}
                        context={"postal_code"}
                        focused={"postal_code" === this.state.focusedInput}
                        focusedID={"postal_code"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Country</th>
                      <EditableTD
                        content={this.state.vendor.country}
                        placeholder={"Country"}
                        source={this.state.vendor}
                        context={"country"}
                        focused={"country" === this.state.focusedInput}
                        focusedID={"country"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style={{ padding: "12px" }}>
                Contact:
                <br />
                <br />
                <table className="detailTable">
                  <tbody>
                    <tr>
                      <th>Phone</th>
                      <EditableTD
                        content={this.state.vendor.phone_num}
                        placeholder={"Phone"}
                        restriction={"phone-number"}
                        source={this.state.vendor}
                        context={"phone_num"}
                        focused={"phone_num" === this.state.focusedInput}
                        focusedID={"phone_num"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Fax</th>
                      <EditableTD
                        content={this.state.vendor.fax_num}
                        placeholder={"Fax"}
                        restriction={"phone-number"}
                        source={this.state.vendor}
                        context={"fax_num"}
                        focused={"fax_num" === this.state.focusedInput}
                        focusedID={"fax_num"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style={{ padding: "12px" }}>
                Financial Details:
                <br />
                <br />
                <table className="detailTable">
                  <tbody>
                    <tr>
                      <th>Hold Status</th>
                      <EditableTD
                        content={this.state.vendor.hold_status ? 1 : 0}
                        source={this.state.vendor}
                        options={[
                          { value: 0, name: "No Hold" },
                          { value: 1, name: "On Hold" },
                        ]}
                        context={"hold_status"}
                        focused={"hold_status" === this.state.focusedInput}
                        focusedID={"hold_status"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                        inputType={"select"}
                      />
                    </tr>
                    <tr>
                      <th>Ship and Debit</th>
                      <EditableTD
                        content={this.state.vendor.ship_n_debit ? 1 : 0}
                        source={this.state.vendor}
                        options={[
                          { value: 0, name: "No" },
                          { value: 1, name: "Yes" },
                        ]}
                        context={"ship_n_debit"}
                        focused={"ship_n_debit" === this.state.focusedInput}
                        focusedID={"ship_n_debit"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                        inputType={"select"}
                      />
                    </tr>
                    <tr>
                      <th>Currency</th>
                      <EditableTD
                        content={this.state.vendor.currency.currency_code}
                        source={this.state.vendor}
                        context={"currency_id"}
                        focused={"currency_id" === this.state.focusedInput}
                        focusedID={"currency_id"}
                        changeFocus={this.changeInputFocus}
                        inputType={"search"}
                        toSearch={this.toSearch}
                        searchChild={<CurrencySearch />}
                      />
                    </tr>
                    <tr>
                      <th>DUNS Number</th>
                      <EditableTD
                        content={this.state.vendor.duns_num}
                        placeholder={"DUNS"}
                        source={this.state.vendor}
                        context={"duns_num"}
                        focused={"duns_num" === this.state.focusedInput}
                        focusedID={"duns_num"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Min Order Value</th>
                      <EditableTD
                        content={this.state.vendor.min_order_val}
                        placeholder={"Min Order"}
                        source={this.state.vendor}
                        context={"min_order_val"}
                        focused={"min_order_val" === this.state.focusedInput}
                        focusedID={"min_order_val"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Min Prepaid Value</th>
                      <EditableTD
                        content={this.state.vendor.min_prepaid_val}
                        placeholder={"Min Prepaid"}
                        source={this.state.vendor}
                        context={"min_prepaid_val"}
                        focused={"min_prepaid_val" === this.state.focusedInput}
                        focusedID={"min_prepaid_val"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style={{ padding: "12px" }}>
                Term Details:
                <br />
                <br />
                <table className="detailTable">
                  <tbody>
                    <tr>
                      <th>Discount %</th>
                      <EditableTD
                        content={this.state.vendor.discount}
                        placeholder={"Discount"}
                        restriction={"unsigned-float"}
                        source={this.state.vendor}
                        context={"discount"}
                        focused={"discount" === this.state.focusedInput}
                        focusedID={"discount"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Term Days</th>
                      <EditableTD
                        content={this.state.vendor.term_days}
                        placeholder={"Days"}
                        restriction={"unsigned-int"}
                        source={this.state.vendor}
                        context={"term_days"}
                        focused={"term_days" === this.state.focusedInput}
                        focusedID={"term_days"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Term Start</th>
                      <EditableTD
                        content={this.state.vendor.term_start}
                        placeholder={"Start"}
                        restriction={"unsigned-int"}
                        source={this.state.vendor}
                        context={"term_start"}
                        focused={"term_start" === this.state.focusedInput}
                        focusedID={"term_start"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Term End</th>
                      <EditableTD
                        content={this.state.vendor.term_end}
                        placeholder={"End"}
                        restriction={"unsigned-int"}
                        source={this.state.vendor}
                        context={"term_end"}
                        focused={"term_end" === this.state.focusedInput}
                        focusedID={"term_end"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Term Due Months</th>
                      <EditableTD
                        content={this.state.vendor.term_due_months}
                        placeholder={"Months"}
                        restriction={"unsigned-int"}
                        source={this.state.vendor}
                        context={"term_due_months"}
                        focused={"term_due_months" === this.state.focusedInput}
                        focusedID={"term_due_months"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                    <tr>
                      <th>Term Due Date</th>
                      <EditableTD
                        content={this.state.vendor.term_due_date}
                        placeholder={"Date"}
                        restriction={"unsigned-int"}
                        source={this.state.vendor}
                        context={"term_due_date"}
                        focused={"term_due_date" === this.state.focusedInput}
                        focusedID={"term_due_date"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateVendor}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style={{ padding: "12px" }}>
                Pay to Details:
                <br />
                <br />
                {this.state.vendor.contacts.map((contact) => {
                  if (contact.pay_to) {
                    return (
                      <table className="detailTable" key={contact.id}>
                        <tbody>
                          <tr>
                            <th>Name</th>
                            <EditableTD
                              content={contact.name}
                              placeholder={"Name"}
                              source={contact}
                              context={"name"}
                              focused={contact.id + "name" === this.state.focusedInput}
                              focusedID={contact.id + "name"}
                              changeFocus={this.changeInputFocus}
                              update={this.updateContact}
                            />
                          </tr>
                          <tr>
                            <th>Address Line 1</th>
                            <EditableTD
                              content={contact.address_1}
                              placeholder={"Line 1"}
                              source={contact}
                              context={"address_1"}
                              focused={contact.id + "address_1" === this.state.focusedInput}
                              focusedID={contact.id + "address_1"}
                              changeFocus={this.changeInputFocus}
                              update={this.updateContact}
                            />
                          </tr>
                          <tr>
                            <th>Address Line 2</th>
                            <EditableTD
                              content={contact.address_2}
                              placeholder={"Line 2"}
                              source={contact}
                              context={"address_2"}
                              focused={contact.id + "address_2" === this.state.focusedInput}
                              focusedID={contact.id + "address_2"}
                              changeFocus={this.changeInputFocus}
                              update={this.updateContact}
                            />
                          </tr>
                          <tr>
                            <th>Address Line 3</th>
                            <EditableTD
                              content={contact.address_3}
                              placeholder={"Line 3"}
                              source={contact}
                              context={"address_3"}
                              focused={contact.id + "address_3" === this.state.focusedInput}
                              focusedID={contact.id + "address_3"}
                              changeFocus={this.changeInputFocus}
                              update={this.updateContact}
                            />
                          </tr>
                          <tr>
                            <th>City</th>
                            <EditableTD
                              content={contact.city}
                              placeholder={"City"}
                              source={contact}
                              context={"city"}
                              focused={contact.id + "city" === this.state.focusedInput}
                              focusedID={contact.id + "city"}
                              changeFocus={this.changeInputFocus}
                              update={this.updateContact}
                            />
                          </tr>
                          <tr>
                            <th>Province</th>
                            <EditableTD
                              content={contact.province}
                              placeholder={"Province"}
                              restriction={"province"}
                              source={contact}
                              context={"province"}
                              focused={contact.id + "province" === this.state.focusedInput}
                              focusedID={contact.id + "province"}
                              changeFocus={this.changeInputFocus}
                              update={this.updateContact}
                            />
                          </tr>
                          <tr>
                            <th>Postal Code</th>
                            <EditableTD
                              content={contact.postal_code}
                              placeholder={"Postal Code"}
                              source={contact}
                              context={"postal_code"}
                              focused={contact.id + "postal_code" === this.state.focusedInput}
                              focusedID={contact.id + "postal_code"}
                              changeFocus={this.changeInputFocus}
                              update={this.updateContact}
                            />
                          </tr>
                          <tr>
                            <th>Country</th>
                            <EditableTD
                              content={contact.country}
                              placeholder={"Country"}
                              source={contact}
                              context={"country"}
                              focused={contact.id + "country" === this.state.focusedInput}
                              focusedID={contact.id + "country"}
                              changeFocus={this.changeInputFocus}
                              update={this.updateContact}
                            />
                          </tr>
                        </tbody>
                      </table>
                    )
                  }
                  return null
                })}
              </div>
            </div>

            <br />
            <br />

            <h2>Contacts:</h2>

            <div style={{ display: "flex", justifyContent: "normal", flexWrap: "wrap" }}>
              {this.state.vendor.contacts.map((contact) => {
                if (contact.pay_to) {
                  return null
                }
                return (
                  <table className="detailTable" style={{ margin: "12px" }} key={contact.id}>
                    <tbody>
                      <tr>
                        <th>Name:</th>
                        <EditableTD
                          content={contact.name}
                          placeholder={"Name"}
                          source={contact}
                          context={"name"}
                          focused={contact.id + "name" === this.state.focusedInput}
                          focusedID={contact.id + "name"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateContact}
                        />
                      </tr>
                      <tr>
                        <th>Title:</th>
                        <EditableTD
                          content={contact.title}
                          placeholder={"Title"}
                          source={contact}
                          context={"title"}
                          focused={contact.id + "title" === this.state.focusedInput}
                          focusedID={contact.id + "title"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateContact}
                        />
                      </tr>
                      <tr>
                        <th>Email:</th>
                        <EditableTD
                          content={contact.email}
                          placeholder={"Email"}
                          source={contact}
                          restriction={"email"}
                          context={"email"}
                          focused={contact.id + "email" === this.state.focusedInput}
                          focusedID={contact.id + "email"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateContact}
                        />
                      </tr>
                      <tr>
                        <th>Phone:</th>
                        <EditableTD
                          content={contact.phone_num}
                          placeholder={"Phone"}
                          source={contact}
                          restriction={"phone-number"}
                          context={"phone_num"}
                          focused={contact.id + "phone_num" === this.state.focusedInput}
                          focusedID={contact.id + "phone_num"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateContact}
                        />
                      </tr>
                      <tr>
                        <th>Fax:</th>
                        <EditableTD
                          content={contact.fax_num}
                          placeholder={"Fax"}
                          source={contact}
                          restriction={"phone-number"}
                          context={"fax_num"}
                          focused={contact.id + "fax_num" === this.state.focusedInput}
                          focusedID={contact.id + "fax_num"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateContact}
                        />
                      </tr>
                      <tr>
                        <th style={{ border: "none" }}></th>
                        <td style={{ border: "none", textAlign: "right" }}>
                          <button name={contact.id.toString()} onClick={this.deleteContact}>
                            Delete
                          </button>
                          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )
              })}
            </div>

            <br />
            <br />

            <div>
              {this.state.addingContact ? (
                <div>
                  <span style={{ color: "green" }}>Add New Vendor Contact</span>
                  <br />
                  <br />
                  <div style={{ border: "1px solid black", padding: "25px", display: "inline-block" }}>
                    <CreateVendorContact parentId={{ vendor_id: this.state.vendor.id }} returnResult={this.createContactResult} />
                  </div>
                </div>
              ) : (
                <button onClick={this.addContact}>Add Contact</button>
              )}
            </div>

            <br />

            <h2>Other:</h2>

            <div>
              This is vendor is {this.state.vendor.closed ? "closed" : "open"}. &nbsp;{" "}
              <button onClick={this.openCloseVendor}>{this.state.vendor.closed ? "Open" : "Close"} vendor</button>
              {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Vendor
