import React, { Component } from "react"

import BasicCreate from "../create/BasicCreate"
import CurrencySearch from "../currencies/CurrencySearch"
import Vendor from "./Vendor"

class CreateVendor extends Component {
  render() {
    return (
      <BasicCreate
        {...{
          title: "Vendor",
          resultChild: <Vendor />,
          inputs: [
            // required fields
            { value: "", required: true, name: "Name", databaseField: "name", inputType: "text" },
            { value: "", required: true, name: "Phone Number", databaseField: "phone_num", inputType: "text", restriction: "phone-number" },
            { value: "", required: true, name: "Address 1", databaseField: "address_1", inputType: "text" },
            { value: "", required: true, name: "City", databaseField: "city", inputType: "text" },
            { value: "", required: true, name: "Province", databaseField: "province", inputType: "text", restriction: "province" },
            { value: "", required: true, name: "Postal Code", databaseField: "postal_code", inputType: "text" },
            { value: "Canada", required: true, name: "Country", databaseField: "country", inputType: "text" },
            {
              value: "",
              required: true,
              name: "Currency",
              databaseField: "currency_id",
              inputType: "search",
              searchName: "Currency",
              searchChild: <CurrencySearch />,
              requiredSearchResult: "id",
              displaySearchResult: "currency_code",
            },
            {
              value: "0",
              required: true,
              name: "Ship `n` Debit",
              databaseField: "ship_n_debit",
              inputType: "select",
              options: [
                { value: "1", name: "Yes" },
                { value: "0", name: "No" },
              ],
            },
            { value: "0", required: true, name: "Min Order Value", databaseField: "min_order_val", inputType: "text", restriction: "unsigned-int" },
            {
              value: "0",
              required: true,
              name: "Min Prepaid Value",
              databaseField: "min_prepaid_val",
              inputType: "text",
              restriction: "unsigned-int",
            },
            // optional fields
            { value: "", required: false, name: "Product Line", databaseField: "product_line", inputType: "text", restriction: "product-line" },
            { value: "", required: false, name: "Discount %", databaseField: "discount", inputType: "text", restriction: "unsigned-float" },
            { value: "", required: false, name: "Term Days", databaseField: "term_days", inputType: "text", restriction: "unsigned-int" },
            { value: "", required: false, name: "Term Start", databaseField: "term_start", inputType: "text", restriction: "unsigned-int" },
            { value: "", required: false, name: "Term End", databaseField: "term_end", inputType: "text", restriction: "unsigned-int" },
            { value: "", required: false, name: "Term Due Months", databaseField: "term_due_months", inputType: "text", restriction: "unsigned-int" },
            { value: "", required: false, name: "Term Due Date", databaseField: "term_due_date", inputType: "text", restriction: "unsigned-int" },
            { value: "", required: false, name: "DUNS Number", databaseField: "duns_num", inputType: "text" },
            { value: "", required: false, name: "Address 2", databaseField: "address_2", inputType: "text" },
            { value: "", required: false, name: "Address 3", databaseField: "address_3", inputType: "text" },
            { value: "", required: false, name: "Fax Number", databaseField: "fax_num", inputType: "text", restriction: "phone-number" },
          ],
          endpoint: "vendors/create",
        }}
        {...this.props}
      />
    )
  }
}

export default CreateVendor
