import React, { Component } from "react"

import BasicCreate from "../create/BasicCreate"

class CreateVendorContact extends Component<appProps> {
  render() {
    return (
      <BasicCreate
        {...{
          title: "Contact",
          resultChild: false,
          inputs: [
            // required fields
            { value: "", required: true, name: "Name", databaseField: "name", inputType: "text" },
            { value: "", required: true, name: "Email", databaseField: "email", inputType: "text", restriction: "email" },
            // optional fields
            { value: "", required: false, name: "Title", databaseField: "title", inputType: "text" },
            { value: "", required: false, name: "Phone Number", databaseField: "phone_num", inputType: "text", restriction: "phone-number" },
            { value: "", required: false, name: "Fax Number", databaseField: "fax_num", inputType: "text", restriction: "phone-number" },
          ],
          endpoint: "vendors/create/contact",
        }}
        {...this.props}
      />
    )
  }
}

export default CreateVendorContact
