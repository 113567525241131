import React, { Component } from "react"

// import child components
import SearchResults from "./SearchResults"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import LoadingSpinner from "../LoadingSpinner"
import ControlledInput from "../edit/ControlledInput"
import { fireAxios, dateObjToInputDate, inputDateToDateObj, restrictions } from "../helpers"

import axios from "axios"

type stringObjAny = { [index: string]: any }

type query = {
  type?: "date_range" | "checkbox" | "select" | string
  name: string
  value: string | boolean | number | stringObjAny | undefined
  restriction?: restrictions
  values?: Array<{
    key: string
    value: string
  }>
  databaseSearch: string
  defaulted?: boolean
  hide?: boolean
}

export type queries = Array<query>

type resultType = (result: stringObjAny) => void

type resultColumnsType = Array<{
  name: string
  optional?: boolean
  type?: "date" | "currency"
  newTabId?: boolean
  newTabLink?: boolean
  hidden?: boolean
  link_to?: "Salesrep"
  databaseField: string
}>

type props = {
  title: string
  queries: queries
  resultColumns: resultColumnsType
  apiEndpoint: string
  children?: React.ReactNode
  returnResult?: resultType
  newTabResult?: boolean
  disabled?: boolean
  hideHeaders?: boolean
  closable?: boolean
  preventClosedUse?: boolean
  defaultedFields?: stringObjAny
  defaultedEditable?: boolean
}

type state = {
  loading: boolean
  loadingMore: boolean
  queries: Array<query>
  results: Array<stringObjAny>
  resultAction: resultType
  resultColumns: resultColumnsType
  clickedResultID: number | null
  displayResult: boolean
  numResults: number
  scrollPosition: null
  partialSearch: boolean
  closedSearch: boolean
  backToScroll: number
  searchDepth: number
  dialog: dialogProps
  [index: string]: any
}

class Search extends Component<props & appProps, state> {
  state: state = {
    loading: false,
    loadingMore: false,
    queries: [],
    results: [],
    resultAction: () => {},
    resultColumns: [],
    clickedResultID: null,
    displayResult: false,
    numResults: 0,
    scrollPosition: null,
    partialSearch: false,
    closedSearch: false,
    backToScroll: 0,
    searchDepth: 0,
    dialog: null,
  }

  CancelToken: any
  cancel: any

  constructor(props: props & appProps) {
    super(props)
    this.CancelToken = axios.CancelToken
    this.cancel = undefined

    // set app title if called, otherwise it's probably
    // being called as a child to a component other than App.js
    if (this.props.setAppTitle) {
      this.props.setAppTitle(this.props.title + " Search")
    }
  }

  componentDidMount() {
    let queries: queries = JSON.parse(JSON.stringify(this.props.queries))

    if (this.props.defaultedFields) {
      Object.keys(this.props.defaultedFields).forEach((key) => {
        let queryToChange = queries.find((x) => x.databaseSearch === key)
        if (queryToChange) {
          queryToChange.value = this.props.defaultedFields ? this.props.defaultedFields[key] : ""
          queryToChange.defaulted = true
        } else {
          queries.push({
            hide: true,
            name: key,
            value: this.props.defaultedFields ? this.props.defaultedFields[key] : "",
            databaseSearch: key,
          })
        }
      })
    }

    let dateRanges = queries.filter((x) => x.type === "date_range")

    dateRanges.forEach((range) => {
      range.value = {
        start: "",
        end: dateObjToInputDate(new Date()),
      }
    })

    this.setState(
      {
        queries: queries,
      },
      () => {
        let resultAction: resultType

        if (this.props.returnResult) {
          resultAction = (result) => {
            if (this.props.preventClosedUse && result.closed) {
              this.showDialog({
                type: "alert",
                title: "Error!",
                message: "Cannot use this " + this.props.title + " as it is closed.",
                handleResponse: this.hideDialog,
              })
              return
            }
            this.props.returnResult && this.props.returnResult(result)
          }
        } else {
          resultAction = (result) => this.displayResult(result)
        }

        let resultColumns = JSON.parse(JSON.stringify(this.props.resultColumns))
        resultColumns = this.determineResultColumns(resultColumns)

        this.setState(
          {
            resultAction: resultAction,
            resultColumns: resultColumns,
          },
          () => {
            if (this.props.defaultedFields && Object.keys(this.props.defaultedFields).length > 0) {
              this.getResults()
            }
          }
        )
      }
    )
  }

  handleSearchDepth = (depth: number) => {
    this.setState({
      searchDepth: depth,
    })
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  displayResult = (result: stringObjAny) => {
    this.setState({
      clickedResultID: result.id,
      displayResult: true,
      backToScroll: document.getElementsByTagName("html")[0].scrollTop,
    })
  }

  leavePage = () => {
    this.setState(
      {
        dialog: null,
        clickedResultID: null,
        displayResult: false,
      },
      () => {
        if (this.props.setAppTitle) {
          this.props.setAppTitle(this.props.title + " Search")
        }
        document.getElementsByTagName("html")[0].scrollTop = this.state.backToScroll
      }
    )
  }

  backToSearch = () => {
    this.leavePage()

    // attempt at implementing a safety to unsaved chages upon navigation

    // if (this.props.handlePreventNav && this.props.getNavHandle && this.props.getNavHandle()) {
    //   this.showDialog({
    //     message:'Would you like to continue?',
    //     type:'confirm',
    //     title:'You may have unsaved changes...',
    //     handleResponse: (response) => {
    //
    //       if(response){//leave page
    //         this.props.handlePreventNav && this.props.handlePreventNav(false)
    //         this.leavePage()
    //         return
    //       }else{//do not leave page
    //         this.hideDialog()
    //       }
    //     }
    //   })
    // }else{
    //   this.props.handlePreventNav && this.props.handlePreventNav(false)
    //   this.leavePage()
    // }
  }

  handleInputChange = (name: string, value: string | number | undefined) => {
    let queries: queries = JSON.parse(JSON.stringify(this.state.queries))
    let queryToChange = queries.find((x) => x.name === name)

    if (queryToChange) {
      queryToChange.value = value
    }

    this.setState({ queries: queries }, () => {
      this.getResults()
    })
  }

  handleInputDateRange = (e: React.ChangeEvent<HTMLInputElement>, start?: boolean) => {
    let queries: queries = JSON.parse(JSON.stringify(this.state.queries))
    let queryToChange = queries.find((x) => x.name === e.target.name)

    if (queryToChange) {
      queryToChange.value = {
        start: start ? e.target.value : (queryToChange.value as stringObjAny).start,
        end: !start ? e.target.value : (queryToChange.value as stringObjAny).end,
      }
    }

    this.setState({ queries: queries }, () => {
      this.getResults()
    })
  }

  handleFocusTextInput = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select()
  }

  handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>, query?: query) => {
    if (query) {
      let queries: queries = JSON.parse(JSON.stringify(this.state.queries))
      let queryToChange = queries.find((x) => x.name === query.name)

      if (queryToChange) {
        queryToChange.value = !queryToChange.value
      }

      this.setState({ queries: queries }, () => {
        this.getResults()
      })
    } else {
      this.setState({ [e.target.name]: e.target.checked }, () => {
        this.getResults()
      })
    }
  }

  handleSelect = (e: React.ChangeEvent<HTMLSelectElement>, query: query) => {
    if (query) {
      let queries: queries = JSON.parse(JSON.stringify(this.state.queries))
      let queryToChange = queries.find((x) => x.name === query.name)

      if (queryToChange) {
        queryToChange.value = e.target.value
      }

      this.setState({ queries: queries }, () => {
        this.getResults()
      })
    }
  }

  loadMoreResults = () => {
    this.getResults(this.state.numResults)
  }

  determineResultColumns = (resultColumns: resultColumnsType) => {
    return resultColumns.reduce((acc, curr) => {
      if (
        curr.optional &&
        this.state.queries.some((x) => (x.name === curr.name || x.name === curr.link_to) && (!x.value || x.value === "No Selection"))
      ) {
        return acc
      }
      return acc.concat([curr])
    }, [] as resultColumnsType)
  }

  getResults = (offSet?: number) => {
    let numNotEmpty = this.state.queries.reduce((acc, curr) => {
      if (
        (curr.value && curr.type === "select" && curr.value !== "No Selection") ||
        (curr.value && curr.type === "date_range" && (curr.value as stringObjAny).start && (curr.value as stringObjAny).end) ||
        (curr.value && !curr.type && curr.value.toString().length > 0)
      ) {
        return ++acc
      } else {
        return acc
      }
    }, 0)

    let resultColumns: resultColumnsType = JSON.parse(JSON.stringify(this.props.resultColumns))
    resultColumns = this.determineResultColumns(resultColumns)

    // search if something is typed, selected, or picked; otherwise set results to nothing
    if (numNotEmpty === 0) {
      this.setState({
        results: [],
        numResults: 0,
        resultColumns: resultColumns,
      })
      return
    }

    // cancel previous request if a subsequent request is made
    // and the previous request has not completed
    if (this.cancel !== undefined) {
      this.cancel()
    }

    // get the current scroll position before the document loads new data
    // this will be used to set the same scroll position after load
    var scrollPos = document.getElementsByTagName("html")[0].scrollTop

    let queryData: stringObjAny = {}

    this.state.queries.forEach((query) => {
      if (query.type === "checkbox" || query.type === "select") {
        queryData[query.databaseSearch] = query.value
      } else if (query.type === "date_range") {
        // set end date to end of day, set start date to start of day
        let end = inputDateToDateObj((query.value as stringObjAny).end)
        if (end) {
          end.setHours(23, 59, 59)
        }
        let start = inputDateToDateObj((query.value as stringObjAny).start)
        if (start) {
          start.setHours(0, 0, 0)
        }

        queryData[query.databaseSearch] = { start: start, end: end }
      } else if (query.value || resultColumns.some((x) => x.name === query.name)) {
        queryData[query.databaseSearch] = (this.state.partialSearch ? "%" : "") + query.value + "%"
      }
    })

    queryData["offSet"] = offSet ? offSet : 0

    if (this.props.closable) {
      queryData["closed"] = this.state.closedSearch
    }

    let loadingMore = offSet ? offSet > 0 : false

    this.setState({ loading: !loadingMore, loadingMore: loadingMore }, () => {
      fireAxios(
        {
          method: "post",
          url: this.props.apiEndpoint,
          data: queryData,
          cancelToken: new this.CancelToken((c: any) => {
            this.cancel = c
          }),
        },
        (response) => {
          let newArray = JSON.parse(JSON.stringify(this.state.results))

          if (offSet) {
            newArray.push(...response.data)
          } else {
            newArray = response.data
          }
          this.setState(
            {
              results: newArray,
              resultColumns: resultColumns,
              numResults: newArray.length,
              loading: false,
              loadingMore: false,
            },
            () => {
              document.getElementsByTagName("html")[0].scrollTop = scrollPos
            }
          )
        }
      )
    })
  }

  render() {
    if (this.state.displayResult) {
      return (
        <legend style={{ width: "100%" }}>
          {this.state.searchDepth && this.state.searchDepth > 0 ? null : (
            <div>
              <button onClick={this.backToSearch}>Back to {this.props.title} Search</button>
              {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
              <br />
              <br />
            </div>
          )}
          <fieldset disabled={this.props.disabled} style={{ border: "none", margin: "0px", padding: "0px" }}>
            {React.cloneElement(this.props.children as React.ReactElement, {
              id: this.state.clickedResultID,
              handleSearchDepth: this.handleSearchDepth,
              ...this.props,
            })}
          </fieldset>
        </legend>
      )
    }

    let queryInputStyle = { paddingBottom: "10px" }

    return (
      <legend style={{ width: "100%" }}>
        {this.props.hideHeaders ? null : (
          <div>
            <div>{this.props.title} Search</div>
            <br />
            <div style={{ display: "flex", justifyContent: "normal", flexWrap: "wrap" }}>
              {this.state.queries.map((query) => {
                if (query.hide) {
                  return null
                }

                if (query.type === "date_range") {
                  return (
                    <div key={query.name} style={queryInputStyle}>
                      &nbsp;Start:{" "}
                      <input
                        type="date"
                        onChange={(e) => this.handleInputDateRange(e, true)}
                        name={query.name}
                        value={(query.value as stringObjAny).start}
                      />
                      &nbsp;End:{" "}
                      <input
                        type="date"
                        onChange={(e) => this.handleInputDateRange(e, false)}
                        name={query.name}
                        value={(query.value as stringObjAny).end}
                      />
                    </div>
                  )
                }

                if (query.type === "checkbox") {
                  return (
                    <div key={query.name} style={queryInputStyle}>
                      &nbsp;
                      {query.name}:
                      <input type="checkbox" name={query.name} onChange={(e) => this.handleCheckBox(e, query)} checked={query.value as boolean} />
                      &nbsp;
                    </div>
                  )
                }

                if (query.type === "select") {
                  return (
                    <div key={query.name} style={queryInputStyle}>
                      &nbsp;
                      <select name={query.name} onChange={(e) => this.handleSelect(e, query)} value={query.value as string}>
                        {query.values
                          ? query.values.map((x) => {
                              return (
                                <option key={x.key} value={x.value} disabled={x.value === "No Selection"}>
                                  {x.key}
                                </option>
                              )
                            })
                          : null}
                      </select>
                      &nbsp;
                    </div>
                  )
                }

                return (
                  <div key={query.name} style={queryInputStyle}>
                    <ControlledInput
                      placeholder={query.name}
                      onFocus={this.handleFocusTextInput}
                      name={query.name}
                      isSearchInput={true}
                      restriction={query.restriction}
                      value={(query.value as string) || ""}
                      disabled={query.defaulted && !this.props.defaultedEditable}
                      handleChange={(n, v) => this.handleInputChange(n, v)}
                    />
                  </div>
                )
              })}
              {this.props.closable ? (
                <div>
                  &nbsp;Closed:
                  <input type="checkbox" name="closedSearch" onChange={(e) => this.handleCheckBox(e)} checked={this.state.closedSearch} />
                </div>
              ) : null}
              <div>
                &nbsp;Match Partial:
                <input type="checkbox" name="partialSearch" onChange={(e) => this.handleCheckBox(e)} checked={this.state.partialSearch} />
              </div>
            </div>
          </div>
        )}
        {this.state.loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <SearchResults
              results={this.state.results}
              returnResult={this.state.resultAction}
              newTabResult={!!this.props.newTabResult}
              resultColumns={this.state.resultColumns}
              title={this.props.title}
            />
            {this.state.loadingMore ? <LoadingSpinner /> : null}
          </div>
        )}
        <br />
        {!this.state.loading && !this.state.loadingMore && !(this.state.numResults % 20) && this.state.numResults !== 0 ? (
          <button onClick={this.loadMoreResults}>Load more results...</button>
        ) : null}
      </legend>
    )
  }
}

export default Search
