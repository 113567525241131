import React, { Component } from "react"

import BasicCreate from "../create/BasicCreate"
import VendorSearch from "../vendors/VendorSearch"
import Product from "./Product"

class CreateProduct extends Component {
  render() {
    return (
      <BasicCreate
        {...{
          title: "Product",
          resultChild: <Product />,
          inputs: [
            {
              value: "",
              required: true,
              name: "Manufacturer",
              databaseField: "product_line",
              inputType: "search",
              searchName: "Vendor",
              searchChild: <VendorSearch />,
              requiredSearchResult: "product_line",
              displaySearchResult: "product_line",
            },
            { value: "", required: true, name: "Product Number", databaseField: "product_num", inputType: "text" },
            { value: "", required: true, name: "Item Description", databaseField: "description", inputType: "text" },
            { value: "", required: true, name: "Vendor Cost", databaseField: "vendor_cost", inputType: "text", restriction: "unsigned-float" },
            {
              value: "",
              required: true,
              name: "Purchase Multiple",
              databaseField: "pur_mult",
              inputType: "text",
              restriction: "non-zero-unsigned-int",
            },
            { value: "", required: true, name: "Sell Multiple", databaseField: "sell_mult", inputType: "text", restriction: "non-zero-unsigned-int" },
            { value: "", required: true, name: "Price Per", databaseField: "price_per", inputType: "text", restriction: "non-zero-unsigned-int" },
            { value: "", required: false, name: "Target Margin", databaseField: "target_margin", inputType: "text", restriction: "unsigned-float" },
            { value: "", required: false, name: "Walk-in Margin", databaseField: "walkin_margin", inputType: "text", restriction: "unsigned-float" },
            {
              value: "",
              required: false,
              name: "Contractor Margin",
              databaseField: "contractor_margin",
              inputType: "text",
              restriction: "unsigned-float",
            },
          ],
          endpoint: "products/create",
        }}
        {...this.props}
      />
    )
  }
}

export default CreateProduct
