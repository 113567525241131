import React, { Component } from "react"

import BasicCreate from "../create/BasicCreate"
import Currency from "./Currency"
import CurrencySearch from "./CurrencySearch"

class UpdateCurrency extends Component {
  render() {
    return (
      <BasicCreate
        {...{
          title: "Update to Currency",
          resultChild: <Currency />,
          inputs: [
            {
              value: "",
              required: true,
              name: "Country",
              databaseField: "country_name",
              inputType: "search",
              searchName: "Currency",
              searchChild: <CurrencySearch />,
              requiredSearchResult: "country_name",
              displaySearchResult: "country_name",
            },
            { value: "", required: true, name: "Conversion", databaseField: "cad_conversion", restriction: "unsigned-float" },
          ],
          endpoint: "currencies/update",
        }}
        {...this.props}
      />
    )
  }
}

export default UpdateCurrency
