import React, { Component } from "react"

import BasicCreate from "../create/BasicCreate"
import Currency from "./Currency"

class CreateCurrency extends Component {
  render() {
    return (
      <BasicCreate
        {...{
          title: "Currency",
          resultChild: <Currency />,
          inputs: [
            { value: "", required: true, name: "Country Code", databaseField: "country_code", restriction: "country-code" },
            { value: "", required: true, name: "Country Name", databaseField: "country_name" },
            { value: "", required: true, name: "Currency Code", databaseField: "currency_code", restriction: "currency-code" },
            { value: "", required: false, name: "Initial CAD Conversion", databaseField: "initial_conversion", restriction: "unsigned-float" },
          ],
          endpoint: "currencies/create",
        }}
        {...this.props}
      />
    )
  }
}

export default CreateCurrency
