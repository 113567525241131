import React, { Component } from "react"

import LoadingSpinner from "../LoadingSpinner"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import VendorSearch from "../vendors/VendorSearch"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import { fireAxios, toFileDate, dateObjToInputDate, inputDateToDateObj, download } from "../helpers"

type props = {}

type warehouse =
  | {
      id: number
      name: string
    }
  | undefined

type state = {
  loading: boolean
  dialog: dialogProps
  warehouse: warehouse
  toWarehouseSearch: boolean
  productLine?: string
  toProductLineSearch: boolean
  date?: Date
  reportType: "Summary" | "Detailed"
}

const title = "Inventory Evaluation Report"

class InvEval extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)

    this.state = {
      loading: false,
      dialog: null,
      warehouse: undefined,
      toWarehouseSearch: false,
      productLine: undefined,
      toProductLineSearch: false,
      date: undefined,
      reportType: "Summary",
    }

    if (this.props.setAppTitle) {
      this.props.setAppTitle(title)
    }
  }

  warehouseResult = (result: warehouse) => {
    this.setState({
      warehouse: result,
      toWarehouseSearch: false,
    })
  }

  toWarehouseSearch = () => {
    this.setState({
      toWarehouseSearch: true,
    })
  }

  productLineResult = (result?: { product_line: string }) => {
    this.setState({
      productLine: result ? result.product_line : undefined,
      toProductLineSearch: false,
    })
  }

  toProductLineSearch = () => {
    this.setState({
      toProductLineSearch: true,
    })
  }

  backFromSearch = () => {
    this.setState({
      toProductLineSearch: false,
      toWarehouseSearch: false,
    })
  }

  updateDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      date: inputDateToDateObj(e.target.value),
    })
  }

  updateDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  submitRequest = () => {
    if (this.state.date) {
      this.setState({ loading: true }, () => {
        fireAxios(
          {
            method: "post",
            url: "reports/inventoryevaluation/",
            data: {
              date: this.state.date,
              warehouse_id: this.state.warehouse ? this.state.warehouse.id : undefined,
              product_line: this.state.productLine,
              report_type: this.state.reportType,
            },
          },
          (response) => {
            this.setState({ loading: false }, () => {
              download(
                "Excel",
                response.data,
                "InvEval_" +
                  this.state.reportType +
                  "_" +
                  (this.state.productLine || "") +
                  "_" +
                  (this.state.warehouse ? this.state.warehouse.name : "") +
                  "_" +
                  toFileDate(this.state.date || "")
              )
            })
          }
        )
      })
    } else {
      this.updateDialog({
        type: "alert",
        title: "Date required...",
        message: "A date must be entered for a valid report.",
        handleResponse: () => this.updateDialog(null),
      })
    }
  }

  handleReportType = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      reportType: e.target.name === "Detailed" ? "Detailed" : "Summary",
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toWarehouseSearch) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseResult} />
        </div>
      )
    }

    if (this.state.toProductLineSearch) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <VendorSearch returnResult={this.productLineResult} />
        </div>
      )
    }

    return (
      <div>
        <label htmlFor="start">Date (required): </label>
        <input type="date" name="date" onChange={this.updateDate} value={dateObjToInputDate(this.state.date || new Date()) || ""} />

        <br />
        <br />

        <label htmlFor="warehouse">
          {this.state.warehouse ? "Warehouse: " : "Select Warehouse (optional): "}
          <b>{this.state.warehouse ? this.state.warehouse.name : ""}</b>
        </label>
        <button name="warehouse" onClick={() => this.toWarehouseSearch()}>
          {this.state.warehouse ? "Edit" : "Add"}
        </button>
        {this.state.warehouse ? <button onClick={() => this.warehouseResult(undefined)}>Clear</button> : null}

        <br />
        <br />

        <label htmlFor="line">
          {this.state.productLine ? "Product Line: " : "Select Product Line (optional): "}
          <b>{this.state.productLine ? this.state.productLine : ""}</b>
        </label>
        <button name="line" onClick={() => this.toProductLineSearch()}>
          {this.state.productLine ? "Edit" : "Add"}
        </button>
        {this.state.productLine ? <button onClick={() => this.productLineResult(undefined)}>Clear</button> : null}

        <br />
        <br />

        <label htmlFor="summary">Summary</label>
        <input id="summary" type="radio" name="Summary" onChange={this.handleReportType} checked={this.state.reportType === "Summary"} />
        <br />
        <label htmlFor="detailed">Detailed</label>
        <input id="detailed" type="radio" name="Detailed" onChange={this.handleReportType} checked={this.state.reportType === "Detailed"} />

        <br />
        <br />
        <button onClick={this.submitRequest}>Generate</button>
        {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
      </div>
    )
  }
}
export default InvEval
