import React, { Component } from "react"

import LoadingSpinner from "../LoadingSpinner"
import SalesrepSearch from "../salesreps/SalesrepSearch"
import WarehouseSearch from "../warehouses/WarehouseSearch"

import { fireAxios, parseUnderscores } from "../helpers"
import { MENU_NESTING } from "../../menuStructure"

type props = {
  id?: number
}

type state = {
  loading: boolean
  toChooseSalesrep: boolean
  toChooseWarehouse: boolean
  userId?: number
  user?: user
}

const initialState: state = {
  loading: true,
  toChooseSalesrep: false,
  toChooseWarehouse: false,
  userId: undefined,
  user: undefined,
}

class User extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = initialState

    if (this.props.setAppTitle) {
      this.props.setAppTitle("User")
    }
  }

  componentDidMount() {
    this.setState(
      {
        userId: this.props.id,
      },
      () => {
        this.state.userId && this.getUserDetails(this.state.userId)
      }
    )
  }

  getUserDetails = (userId: number) => {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "users/details", data: { id: userId } }, (response) => {
        let user: user = response.data

        MENU_NESTING.forEach((main) => {
          main.subItems.forEach((sub) => {
            let matchedItem = user.access.find((x) => x.component_link === sub.link)
            // if the menu doesn't exist in the users current settings, add it to be updated
            // && don't allow change of access to globally allowed access points
            // && don't allow change of access to admin rights
            if (!matchedItem && !sub.access && !sub.admin) {
              user.access.push({
                component_link: sub.link,
                access: false,
                readonly: false,
              })
            }
          })
        })

        this.setState(
          {
            loading: false,
            user: user,
          },
          () => {
            this.props.setAppTitle && this.props.setAppTitle("User: " + (this.state.user ? this.state.user.email : "Error: Unknown"))
          }
        )
      })
    })
  }

  handleAccess = (e: React.ChangeEvent<HTMLInputElement>, componentLink: string) => {
    let newUser: user = JSON.parse(JSON.stringify(this.state.user))
    let foundAccess = newUser.access.find((x) => x.component_link === componentLink)
    let checked = e.target.checked

    if (!foundAccess) return

    if (e.target.name === "readonly" && checked) {
      foundAccess.access = true
      foundAccess.readonly = checked
    }

    if (e.target.name === "access" && !checked) {
      foundAccess.readonly = false
      foundAccess.access = checked
    }

    foundAccess[e.target.name] = checked

    this.setState({
      user: newUser,
    })
  }

  handlePermission = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const checked = e.target.checked

    this.setState((prevState) => {
      if (!prevState.user) {
        return prevState
      } else {
        return {
          ...prevState,
          user: { ...prevState.user, permissions: { ...prevState.user.permissions, [name]: checked } },
        }
      }
    })
  }

  chooseSalesrep = () => {
    this.setState({ toChooseSalesrep: true }, () => {
      this.props.handleSearchDepth && this.props.handleSearchDepth(1)
    })
  }

  chooseWarehouse = () => {
    this.setState({ toChooseWarehouse: true }, () => {
      this.props.handleSearchDepth && this.props.handleSearchDepth(1)
    })
  }

  backFromSearch = () => {
    this.setState(
      {
        toChooseSalesrep: false,
        toChooseWarehouse: false,
      },
      () => {
        this.props.handleSearchDepth && this.props.handleSearchDepth(0)
      }
    )
  }

  changeWarehouse = (result: warehouse) => {
    this.setState(
      (prevState) => {
        if (!prevState.user) {
          return prevState
        } else {
          return {
            ...prevState,
            user: {
              ...prevState.user,
              warehouse_id: result.id,
              warehouse: result,
            },
          }
        }
      },
      () => {
        this.backFromSearch()
      }
    )
  }

  changeSalesrep = (result: salesrep) => {
    this.setState(
      (prevState) => {
        if (!prevState.user) {
          return prevState
        } else {
          return {
            ...prevState,
            user: {
              ...prevState.user,
              salesrep_id: result.id,
              salesrep: result,
            },
          }
        }
      },
      () => {
        this.backFromSearch()
      }
    )
  }

  update = () => {
    let dataToSend = {
      user_id: this.state.userId,
      warehouse_id: this.state.user ? this.state.user.warehouse_id : null,
      salesrep_id: this.state.user ? this.state.user.salesrep_id : null,
      permissions: this.state.user ? this.state.user.permissions : null,
      access: this.state.user ? this.state.user.access : null,
    }

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "users/update", data: dataToSend }, () => {
        this.state.userId && this.getUserDetails(this.state.userId)
      })
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseWarehouse) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to User</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.changeWarehouse} />
        </div>
      )
    }

    if (this.state.toChooseSalesrep) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to User</button>
          <br />
          <br />
          <SalesrepSearch returnResult={this.changeSalesrep} />
        </div>
      )
    }

    if (this.state.user) {
      return (
        <div>
          <h2>Associated Salesrep</h2>
          <div>
            {this.state.user.salesrep.first_name + " " + this.state.user.salesrep.last_name}
            &nbsp;
            <button onClick={this.chooseSalesrep}>Edit</button>
          </div>
          <h2>Associated Warehouse</h2>
          <div>
            {this.state.user.warehouse.name}
            &nbsp;
            <button onClick={this.chooseWarehouse}>Edit</button>
          </div>
          <h2>User's Permissions</h2>
          <div>
            <div key={"admin"}>
              {parseUnderscores("admin")}{" "}
              <input name={"admin"} onChange={this.handlePermission} type={"checkbox"} checked={this.state.user.permissions["admin"] || false} />
            </div>
            {Object.keys(this.state.user.permissions).map((permKey) => {
              if (permKey === "admin" || this.state.user!.permissions["admin"]) {
                return null
              }
              return (
                <div key={permKey}>
                  {parseUnderscores(permKey)}{" "}
                  <input name={permKey} onChange={this.handlePermission} type={"checkbox"} checked={this.state.user!.permissions[permKey] || false} />
                </div>
              )
            })}
          </div>
          {this.state.user.permissions && this.state.user.permissions.admin ? null : (
            <div>
              <h2>User's Access</h2>
              <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
                {MENU_NESTING.map((mainMenu) => {
                  return (
                    <div key={mainMenu.mainItem} style={{ margin: "15px", padding: "15px", border: "1px solid black" }}>
                      <b>{mainMenu.mainItem}</b>
                      <hr />
                      <table>
                        <tbody>
                          <tr>
                            <th>Menu</th>
                            <th>Access</th>
                            <th>Read Only</th>
                          </tr>
                          {mainMenu.subItems.map((subMenu) => {
                            // don't allow change of access to globally allowed access points
                            // && don't allow change of access to admin rights
                            if (subMenu.access || subMenu.admin) {
                              return null
                            }
                            return (
                              <tr key={subMenu.link}>
                                <td>{subMenu.name}</td>
                                <td>
                                  <input
                                    name={"access"}
                                    onChange={(e) => this.handleAccess(e, subMenu.link)}
                                    checked={this.state.user!.access.find((x) => x.component_link === subMenu.link)!.access}
                                    type={"checkbox"}
                                  />
                                </td>
                                <td>
                                  <input
                                    name={"readonly"}
                                    onChange={(e) => this.handleAccess(e, subMenu.link)}
                                    checked={this.state.user!.access.find((x) => x.component_link === subMenu.link)!.readonly}
                                    type={"checkbox"}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                      <br />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          <br />
          <button onClick={this.update}>Update</button>
        </div>
      )
    }

    return <div>No User</div>
  }
}

export default User
