import React, { Component } from "react"

// import child child components / helpers
import VendorSearch from "../vendors/VendorSearch"
import ProductSearch from "../products/ProductSearch"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import LotPriceSearch from "./LotPriceSearch"
import OTBOrderSearch from "./OpenToBuy/OTBOrderSearch"
import LoadingSpinner from "../LoadingSpinner"
import NewTabText from "../newTabText/NewTabText"
import ControlledInput from "../edit/ControlledInput"
import ContextMenu from "../edit/ContextMenu"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import { fireAxios, inputDateToDateObj, toReadableDate } from "../helpers"

// import required libraries
import shortid from "shortid"
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"
import arrayMove from "array-move"

type props = {
  id?: number
  loadDetails?: {
    products: Array<product>
    vendor: vendor
  }
}

type vendor = {
  id: number
  name: string
  product_line: string
}

type whseOrder = {
  order_id: number
  order_product_id: number
  quantity: number
  qty_received?: number
}

type prodOrder = {
  id?: number
  warehouse_name: string
  warehouse_id: number | string
  order_id: number
  order_product_id: number
  quantity: number
  qty_received?: number
}

type prodWhse = {
  id: number | string
  name: string
  type?: string
  quantity?: number
  orders?: Array<whseOrder>
  qty_received?: number
  qty_error_style?: React.CSSProperties
  [index: string]: any
}

type product = {
  id: number
  unique_id?: string
  quantity: number
  cost: number
  product_line: string
  product_num: string
  description: string
  pur_mult: number
  vendor_cost: number
  warehouses: Array<prodWhse>
  orders?: Array<prodOrder>
  qty_error_style?: React.CSSProperties
  mult_error_style?: React.CSSProperties
  eta?: string
  [index: string]: any
}

type lotPrice = {
  unique_id?: string
  order_id: number
  title: string
  comment: string
  cost: number
  [index: string]: any
}

type warehouse = {
  id: number
  name: string
}

type address = {
  address_1: string
  address_2: string
  city: string
  province: string
  postal_code: string
  [index: string]: string
}

type state = {
  incomplete: boolean
  toChooseVendor: boolean
  vendor?: vendor
  toChooseProduct: boolean
  products: Array<product>
  toChooseWarehouse: boolean
  warehouse?: warehouse
  toChooseLotPrice: boolean
  lotPrices: Array<lotPrice>
  toChooseOTBOrder: boolean
  address?: address
  customAddress: boolean
  overallComment: string
  existingPOId?: number
  loading: boolean
  toChooseShipTo: boolean
  prodEditing?: product
  productInfo?: product
  dateCreated?: string
  eta?: string
  dialog: dialogProps
}

const initialState: state = {
  incomplete: false,
  toChooseVendor: false,
  vendor: undefined,
  toChooseProduct: false,
  products: [],
  toChooseWarehouse: false,
  warehouse: undefined,
  toChooseLotPrice: false,
  lotPrices: [],
  toChooseOTBOrder: false,
  address: undefined,
  customAddress: false,
  overallComment: "",
  existingPOId: undefined,
  loading: false,
  toChooseShipTo: false,
  prodEditing: undefined,
  dateCreated: undefined,
  dialog: null,
}

const DragHandle = SortableHandle(() => {
  const svgStyle = {
    cursor: "s-resize",
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" style={svgStyle}>
      <path d="M2 13.5h14V12H2v1.5zm0-4h14V8H2v1.5zM2 4v1.5h14V4H2z" />
    </svg>
  )
})

/*
██████  ██████   ██████  ██████  ██    ██  ██████ ████████ ███████     ██      ██ ███████ ████████
██   ██ ██   ██ ██    ██ ██   ██ ██    ██ ██         ██    ██          ██      ██ ██         ██
██████  ██████  ██    ██ ██   ██ ██    ██ ██         ██    ███████     ██      ██ ███████    ██
██      ██   ██ ██    ██ ██   ██ ██    ██ ██         ██         ██     ██      ██      ██    ██
██      ██   ██  ██████  ██████   ██████   ██████    ██    ███████     ███████ ██ ███████    ██
*/

const SortableItem = SortableElement(
  ({
    value,
    deleteProduct,
    deleteShipTo,
    addShipTo,
    handleProductInput,
    productInfo,
  }: {
    value: product
    deleteProduct: (prod: product) => void
    deleteShipTo: (prod: product, whse: prodWhse) => void
    addShipTo: (prod: product) => void
    handleProductInput: (name: string, value: string | number, prod: product, whse: prodWhse | null) => void
    productInfo: (prod: product) => void
  }) => {
    const splitDiv0 = { borderBottom: "1px solid #000", padding: "8px" }
    const splitDiv1 = { padding: "8px" }
    const noPadding = { padding: "0px" }

    const warehouses = value.warehouses
    let totalCst = 0
    let totalQty = 0

    warehouses.forEach((whse) => (totalQty += whse.quantity || 0))
    totalCst = totalQty * value.cost

    const totalReceived = warehouses.reduce((acc, curr) => (acc += curr.qty_received || 0), 0)
    const totalOrdered = warehouses.reduce((acc, curr) => (acc += curr.quantity || 0), 0)
    const completeBg = totalReceived >= totalOrdered && !!totalOrdered ? "#87dc9e" : undefined

    return (
      <ContextMenu key={value.unique_id} elementType="tbody" menuItems={[{ name: "Delete", onClick: () => deleteProduct(value) }]}>
        <tr className="row" onClick={() => productInfo(value)} style={{ backgroundColor: completeBg }}>
          <td onClick={(e) => e.stopPropagation()}>
            <DragHandle />
          </td>
          <td>
            <b>
              {value.product_line} - {value.product_num}
            </b>{" "}
            {value.description}
          </td>
          <td onClick={(e) => e.stopPropagation()} style={noPadding}>
            {warehouses.map((whse, i) => {
              return (
                <div style={i + 1 < warehouses.length ? splitDiv0 : splitDiv1} key={whse.id}>
                  {whse.name}
                  {whse.name === "direct" || whse.name === "" ? null : (
                    <div>
                      &nbsp; <button onClick={() => deleteShipTo(value, whse)}>-</button>
                      {i + 1 === warehouses.length ? <button onClick={() => addShipTo(value)}>+</button> : null}
                    </div>
                  )}
                </div>
              )
            })}
          </td>
          <td style={noPadding} onClick={(e) => e.stopPropagation()}>
            {warehouses.map((whse, i) => {
              return (
                <div style={i + 1 < warehouses.length ? splitDiv0 : splitDiv1} key={whse.id}>
                  {whse.type}
                </div>
              )
            })}
          </td>
          <td style={noPadding} onClick={(e) => e.stopPropagation()}>
            {warehouses.map((whse, i) => {
              return (
                <div style={i + 1 < warehouses.length ? splitDiv0 : splitDiv1} key={whse.id}>
                  <ControlledInput
                    placeholder="Quantity"
                    value={whse.quantity || ""}
                    name="quantity"
                    restriction={"unsigned-int"}
                    handleChange={(n, v) => handleProductInput(n, v, value, whse)}
                  />
                </div>
              )
            })}
          </td>
          <td onClick={(e) => e.stopPropagation()}>
            <ControlledInput
              placeholder="Cost"
              name={"cost"}
              value={value.cost || "0"}
              restriction={"unsigned-float"}
              handleChange={(n, v) => handleProductInput(n, v, value, null)}
            />
          </td>
          <td style={value.mult_error_style} onClick={(e) => e.stopPropagation()}>
            {value.pur_mult}
          </td>
          <td style={value.mult_error_style} onClick={(e) => e.stopPropagation()}>
            {totalQty} / ${totalCst.toFixed(2)}
          </td>
          <td onClick={(e) => e.stopPropagation()}>{totalReceived}</td>
        </tr>
      </ContextMenu>
    )
  }
)

const SortableList = SortableContainer(
  ({
    items,
    deleteProduct,
    deleteShipTo,
    addShipTo,
    handleProductInput,
    productInfo,
  }: {
    items: Array<product>
    deleteProduct: (prod: product) => void
    deleteShipTo: (prod: product, whse: prodWhse) => void
    addShipTo: (prod: product) => void
    handleProductInput: (name: string, value: string | number, prod: product, whse: prodWhse | null) => void
    productInfo: (prod: product) => void
  }) => {
    return (
      <table className="searchResults">
        <thead>
          <tr>
            <th></th>
            <th>Product</th>
            <th>Ship To</th>
            <th>Receiving</th>
            <th>Quantity</th>
            <th>Cost</th>
            <th>Pur. Mult.</th>
            <th>Total Qty/Cost</th>
            <th>Total Received</th>
          </tr>
        </thead>
        {items.map((value, index) => {
          // generate a unique id for each detail so React can manage the state properly
          if (!value.unique_id) {
            value.unique_id = shortid.generate()
          }

          return (
            <SortableItem
              key={value.unique_id}
              index={index}
              value={value}
              deleteProduct={deleteProduct}
              deleteShipTo={deleteShipTo}
              addShipTo={addShipTo}
              productInfo={productInfo}
              handleProductInput={handleProductInput}
            />
          )
        })}
      </table>
    )
  }
)

/*
██       ██████  ████████     ██████  ██████  ██  ██████ ███████     ██      ██ ███████ ████████
██      ██    ██    ██        ██   ██ ██   ██ ██ ██      ██          ██      ██ ██         ██
██      ██    ██    ██        ██████  ██████  ██ ██      █████       ██      ██ ███████    ██
██      ██    ██    ██        ██      ██   ██ ██ ██      ██          ██      ██      ██    ██
███████  ██████     ██        ██      ██   ██ ██  ██████ ███████     ███████ ██ ███████    ██
*/

const SortableLotPrice = SortableElement(
  ({
    lotPrice,
    deleteLotPrice,
    handleLotPriceInput,
  }: {
    lotPrice: lotPrice
    deleteLotPrice: (lot: lotPrice) => void
    handleLotPriceInput: (name: string, value: string | number, lot: lotPrice) => void
  }) => {
    return (
      <ContextMenu key={lotPrice.unique_id} elementType="tbody" menuItems={[{ name: "Delete", onClick: () => deleteLotPrice(lotPrice) }]}>
        <tr className="row" style={{ cursor: "default" }}>
          <td>
            <DragHandle />
          </td>
          <td>{lotPrice.order_id}</td>
          <td>{lotPrice.title}</td>
          <td>
            ${" "}
            <ControlledInput
              placeholder="Cost"
              defaultValue={lotPrice.cost}
              name="cost"
              restriction={"unsigned-float"}
              handleChange={(n, v) => handleLotPriceInput(n, v, lotPrice)}
            />
          </td>
          <td>
            <textarea
              placeholder="Comment"
              name={"comment"}
              defaultValue={lotPrice.comment}
              onChange={(e) => handleLotPriceInput(e.target.name, e.target.value, lotPrice)}
            />
          </td>
        </tr>
      </ContextMenu>
    )
  }
)

const SortableLotPrices = SortableContainer(
  ({
    lotPrices,
    deleteLotPrice,
    handleLotPriceInput,
  }: {
    lotPrices: Array<lotPrice>
    deleteLotPrice: (lot: lotPrice) => void
    handleLotPriceInput: (name: string, value: string | number, lot: lotPrice) => void
  }) => {
    return (
      <table className="searchResults">
        <thead>
          <tr>
            <th></th>
            <th>Order</th>
            <th>Title</th>
            <th>Cost</th>
            <th>Comment</th>
          </tr>
        </thead>
        {lotPrices.map((lotPrice, index) => {
          // generate a unique id for each detail so React can manage the state properly
          if (!lotPrice.unique_id) {
            lotPrice.unique_id = shortid.generate()
          }

          return (
            <SortableLotPrice
              key={lotPrice.unique_id}
              index={index}
              lotPrice={lotPrice}
              deleteLotPrice={deleteLotPrice}
              handleLotPriceInput={handleLotPriceInput}
            />
          )
        })}
      </table>
    )
  }
)

/*
 ██████  ██████  ███    ███ ██████   ██████  ███    ██ ███████ ███    ██ ████████
██      ██    ██ ████  ████ ██   ██ ██    ██ ████   ██ ██      ████   ██    ██
██      ██    ██ ██ ████ ██ ██████  ██    ██ ██ ██  ██ █████   ██ ██  ██    ██
██      ██    ██ ██  ██  ██ ██      ██    ██ ██  ██ ██ ██      ██  ██ ██    ██
 ██████  ██████  ██      ██ ██       ██████  ██   ████ ███████ ██   ████    ██
*/

class PurchaseOrder extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = initialState

    if (this.props.setAppTitle) {
      this.props.setAppTitle("Create P.O.")
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEscapeKey, false)

    if (this.props.id) {
      // from POSearch
      this.getPO(this.props.id)
    }

    if (this.props.loadDetails) {
      // from OpenToBuy
      let loadProducts: Array<product> = JSON.parse(JSON.stringify(this.props.loadDetails.products))

      // remove warehouses that are of 0 quantity
      for (let i = 0; i < loadProducts.length; i++) {
        loadProducts[i].warehouses = loadProducts[i].warehouses.filter((whse) => (whse.quantity || 0) !== 0)
      }

      // remove products that have no warehouse details
      loadProducts = loadProducts.filter((prod) => prod.warehouses.length > 0)

      // convert from OpenToBuyDetail format to PurchaseOrder format
      loadProducts.forEach((prod) => {
        prod.cost = prod.vendor_cost
        prod.orders = prod.warehouses.reduce((acc, curr) => {
          if (curr.orders) {
            return acc.concat(
              curr.orders.map((order) => {
                return {
                  warehouse_name: curr.name,
                  warehouse_id: curr.id,
                  order_product_id: order.order_product_id,
                  order_id: order.order_id,
                  quantity: order.quantity,
                  qty_received: order.qty_received,
                }
              })
            )
          }
          return acc
        }, [] as Array<prodOrder>)
      })

      this.setState({
        vendor: this.props.loadDetails.vendor,
        products: loadProducts,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEscapeKey, false)
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  /*
  ██       ██████   █████  ██████
  ██      ██    ██ ██   ██ ██   ██
  ██      ██    ██ ███████ ██   ██
  ██      ██    ██ ██   ██ ██   ██
  ███████  ██████  ██   ██ ██████
  */

  getPO = (poId: number) => {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "purchaseOrders/" + poId }, (response) => {
        this.setState(
          {
            loading: false,
            existingPOId: poId,
            ...response.data,
          },
          () => {
            this.props.setAppTitle && this.props.setAppTitle("Purchase #" + this.state.existingPOId)
          }
        )
      })
    })
  }

  /*
███████ ███████  █████  ██████   ██████ ██   ██
██      ██      ██   ██ ██   ██ ██      ██   ██
███████ █████   ███████ ██████  ██      ███████
     ██ ██      ██   ██ ██   ██ ██      ██   ██
███████ ███████ ██   ██ ██   ██  ██████ ██   ██
*/

  handleSearchDepth = (depth: number) => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(depth)
    }
  }

  backFromSearch = () => {
    this.setState(
      {
        toChooseVendor: false,
        toChooseProduct: false,
        toChooseWarehouse: false,
        toChooseShipTo: false,
        toChooseLotPrice: false,
        toChooseOTBOrder: false,
      },
      () => this.handleSearchDepth(0)
    )
  }

  chooseLotPrice = () => {
    this.setState(
      {
        toChooseLotPrice: true,
      },
      () => this.handleSearchDepth(1)
    )
  }

  lotPriceSearchResult = (lotPrice: lotPrice) => {
    let newArray: Array<lotPrice> = JSON.parse(JSON.stringify(this.state.lotPrices))
    newArray.push(lotPrice)

    this.setState(
      {
        lotPrices: newArray,
        toChooseLotPrice: false,
      },
      () => this.handleSearchDepth(0)
    )
  }

  chooseVendor = () => {
    this.setState(
      {
        toChooseVendor: true,
      },
      () => this.handleSearchDepth(1)
    )
  }

  vendorSearchResult = (vendor: vendor) => {
    if (vendor && vendor.product_line) {
      this.setState(
        {
          toChooseVendor: false,
          vendor: vendor,
        },
        () => this.handleSearchDepth(0)
      )
    } else {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "This vendor does not have a product line.",
        handleResponse: this.hideDialog,
      })
      return
    }
  }

  chooseWarehouse = () => {
    this.setState(
      {
        toChooseWarehouse: true,
      },
      () => this.handleSearchDepth(1)
    )
  }

  warehouseSearchResult = (warehouse: warehouse) => {
    if (warehouse) {
      let products: Array<product> = []

      if (this.state.products) {
        products = JSON.parse(JSON.stringify(this.state.products))
        products.forEach((prod) => {
          prod.warehouses.forEach((whse) => {
            if (whse.id === "") {
              whse.id = warehouse.id
              whse.name = warehouse.name
              whse.type = "Ship To"
            } else {
              whse.type = whse.id === warehouse.id ? "Ship To" : "Transfer"
            }
          })
        })
      }

      this.setState(
        {
          products: products,
          toChooseWarehouse: false,
          warehouse: warehouse,
        },
        () => this.handleSearchDepth(0)
      )
    }
  }

  addAddress = () => {
    let products: Array<product> = JSON.parse(JSON.stringify(this.state.products))

    if (products) {
      products.forEach((prod) => {
        prod.warehouses.forEach((whse) => {
          whse.id = "direct"
          whse.name = "direct"
          whse.type = "direct"
        })
      })
    }

    this.setState({
      products: products,
      customAddress: true,
    })
  }

  handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newAddress: address = JSON.parse(JSON.stringify(this.state.address))

    if (!newAddress) {
      newAddress = { address_1: "", address_2: "", city: "", province: "", postal_code: "" }
    }

    newAddress[e.target.name] = e.target.value

    this.setState(
      {
        address: newAddress,
      },
      () => {
        this.countErrors()
      }
    )
  }

  clearAddress = () => {
    let products: Array<product> = JSON.parse(JSON.stringify(this.state.products))

    products.forEach((prod) => {
      if (prod.warehouses.length > 1) {
        const sum = prod.warehouses.reduce((acc, curr) => (acc += curr.quantity || 0), 0)
        prod.warehouses = [{ id: "", name: "", type: "", quantity: sum, qty_received: 0 }]
      } else {
        // will have at least one warehouse
        prod.warehouses[0].id = ""
        prod.warehouses[0].name = ""
        prod.warehouses[0].type = ""
      }
    })

    this.setState({
      products: products,
      customAddress: false,
      address: undefined,
      warehouse: undefined,
    })
  }

  chooseProduct = () => {
    this.setState(
      {
        toChooseProduct: true,
      },
      () => this.handleSearchDepth(1)
    )
  }

  productSearchResult = (prod: product) => {
    if (prod) {
      let newArray: Array<product> = JSON.parse(JSON.stringify(this.state.products))

      if (newArray.find((x) => x.id === prod.id)) {
        this.showDialog({
          type: "alert",
          title: "Error, product exists on P.O. ",
          message: "This product is already on this P.O.",
          handleResponse: this.hideDialog,
        })
        return
      }

      newArray.push({
        id: prod.id,
        product_line: prod.product_line,
        product_num: prod.product_num,
        description: prod.description,
        cost: prod.vendor_cost,
        vendor_cost: prod.vendor_cost,
        quantity: 0,
        pur_mult: prod.pur_mult,
        warehouses: this.state.warehouse
          ? [
              {
                id: this.state.warehouse.id,
                name: this.state.warehouse.name,
                type: "Ship To",
                quantity: 0,
                qty_received: 0,
              },
            ]
          : [
              {
                id: this.state.address ? "direct" : "",
                name: this.state.address ? "direct" : "",
                type: this.state.address ? "direct" : "",
              },
            ],
      })

      this.setState(
        {
          toChooseProduct: false,
          products: newArray,
        },
        () => this.handleSearchDepth(0)
      )
    }
  }

  /*
██ ███    ██ ██████  ██    ██ ████████ ███████        ███████ ████████  ██████
██ ████   ██ ██   ██ ██    ██    ██    ██             ██         ██    ██
██ ██ ██  ██ ██████  ██    ██    ██    ███████        █████      ██    ██
██ ██  ██ ██ ██      ██    ██    ██         ██        ██         ██    ██
██ ██   ████ ██       ██████     ██    ███████ ▄█     ███████    ██     ██████
*/

  handleEta = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      eta: e.target.value,
    })
  }

  deleteProduct = (prod: product) => {
    const deleteIndex = this.state.products.findIndex((x) => x.unique_id === prod.unique_id)
    let newArray: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    newArray.splice(deleteIndex, 1)

    this.setState({
      products: newArray,
    })
  }

  addShipTo = (prod: product) => {
    this.setState(
      {
        prodEditing: prod,
        toChooseShipTo: true,
      },
      () => this.handleSearchDepth(1)
    )
  }

  handleShipTo = (whse: prodWhse, callback?: () => void) => {
    if (this.state.prodEditing) {
      let newArray: Array<product> = JSON.parse(JSON.stringify(this.state.products))
      const prodIndex = newArray.findIndex((prod) => prod.unique_id === (this.state.prodEditing ? this.state.prodEditing.unique_id : ""))
      const whseIndex = newArray[prodIndex].warehouses.findIndex((x) => x.id === whse.id)

      if (whseIndex === -1) {
        newArray[prodIndex].warehouses.push({
          id: whse.id,
          name: whse.name,
          type: (this.state.warehouse ? this.state.warehouse.name : "") === whse.name ? "Ship To" : "Transfer",
        })
      }

      this.setState(
        {
          products: newArray,
          prodEditing: undefined,
          toChooseShipTo: false,
        },
        () => {
          this.handleSearchDepth(0)
          if (callback) {
            callback()
          }
        }
      )
    }
  }

  deleteShipTo = (prod: product, whse: prodWhse) => {
    const prodIndex = this.state.products.findIndex((x) => x.unique_id === prod.unique_id)
    const whseIndex = this.state.products[prodIndex].warehouses.findIndex((x) => x.id === whse.id)
    let prodArray: Array<product> = JSON.parse(JSON.stringify(this.state.products))

    if (prodArray[prodIndex].warehouses.length > 1) {
      prodArray[prodIndex].warehouses.splice(whseIndex, 1)
    } else {
      prodArray.splice(prodIndex, 1)
    }

    this.setState({
      products: prodArray,
    })
  }

  countErrors = () => {
    let numErrors = 0
    this.state.products.forEach((prod) => {
      if (prod.qty_error_style || prod.mult_error_style) {
        ++numErrors
      }
      prod.warehouses.forEach((whse) => {
        if (whse.qty_error_style || !whse.quantity) {
          ++numErrors
        }
      })
    })

    if (!this.state.customAddress && !this.state.warehouse) {
      ++numErrors
    }

    if (this.state.customAddress) {
      if (!this.state.address) {
        ++numErrors
      }

      for (var prop in this.state.address) {
        if (prop !== "address_2" && !this.state.address[prop]) {
          ++numErrors
        }
      }
    }

    if (numErrors > 0) {
      this.setState({
        incomplete: true,
      })
    } else {
      this.setState({
        incomplete: false,
      })
    }

    return numErrors
  }

  handleProductInputChange = (name: string, value: string | number, prod: product, whse: prodWhse | null) => {
    let newArray: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let error = false
    const prodIndex = newArray.findIndex((x) => x.unique_id === prod.unique_id)

    if (name === "quantity" && !/^(\d)+$/.test(String(value))) {
      // value is not a positive integer
      error = true
    } else if (name === "cost" && !/^(\d)+\.?(\d)*$/.test(String(value))) {
      // value is not a float
      error = true
    }

    let whseIndex = -1

    if (!whse) {
      newArray[prodIndex][name] = value
      if (error && name === "cost") {
        newArray[prodIndex].qty_error_style = {
          borderStyle: "solid",
          borderColor: "red",
        }
      } else {
        newArray[prodIndex].qty_error_style = undefined
      }
    } else {
      whseIndex = newArray[prodIndex].warehouses.findIndex((x) => x.id === whse.id)
      newArray[prodIndex].warehouses[whseIndex][name] = value
      if (error && name === "quantity") {
        newArray[prodIndex].warehouses[whseIndex].qty_error_style = {
          borderStyle: "solid",
          borderColor: "red",
        }
      } else {
        newArray[prodIndex].warehouses[whseIndex].qty_error_style = undefined
      }
    }

    // find total order quantity accross warehouses to compare against purchase multiple
    let totalQtyToOrder = 0
    newArray[prodIndex].warehouses.forEach((x) => (totalQtyToOrder += x.quantity || 0))

    if (totalQtyToOrder % prod.pur_mult > 0) {
      newArray[prodIndex].mult_error_style = {
        border: "2px solid",
        borderColor: "red",
      }
    } else {
      newArray[prodIndex].mult_error_style = undefined
    }

    this.setState(
      {
        products: newArray,
        productInfo: this.state.productInfo ? newArray[prodIndex] : undefined,
      },
      () => {
        this.countErrors()
      }
    )
  }

  handlePOCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      overallComment: e.target.value,
    })
  }

  showProductInfo = (prod: product) => {
    this.setState({
      productInfo: prod,
    })
  }

  hideProductInfo = () => {
    this.setState({
      productInfo: undefined,
    })
  }

  handleEscapeKey = (e: any) => {
    if (e.key === "Escape") {
      this.setState({
        productInfo: undefined,
      })
    }
  }

  stopPropagation = (e: any) => {
    // prevents the overlay from closing when the body of the overlay is clicked
    e.stopPropagation()
  }

  /*
 █████  ████████ ████████  █████   ██████ ██   ██ ███████ ██████       ██████  ██████  ██████  ███████ ██████  ███████
██   ██    ██       ██    ██   ██ ██      ██   ██ ██      ██   ██     ██    ██ ██   ██ ██   ██ ██      ██   ██ ██
███████    ██       ██    ███████ ██      ███████ █████   ██   ██     ██    ██ ██████  ██   ██ █████   ██████  ███████
██   ██    ██       ██    ██   ██ ██      ██   ██ ██      ██   ██     ██    ██ ██   ██ ██   ██ ██      ██   ██      ██
██   ██    ██       ██    ██   ██  ██████ ██   ██ ███████ ██████       ██████  ██   ██ ██████  ███████ ██   ██ ███████
*/

  toAttachOrder = () => {
    this.setState(
      {
        toChooseOTBOrder: true,
      },
      () => this.handleSearchDepth(1)
    )
  }

  otbOrderSearchResult = (result: any) => {
    let productInQuestion: product = JSON.parse(JSON.stringify(this.state.productInfo))

    if (
      productInQuestion.orders &&
      productInQuestion.orders.find((x) => {
        return x.order_id === result.order_id && x.order_product_id === result.order_product_id
      })
    ) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "Order already fully attached to product.",
        handleResponse: this.hideDialog,
      })
      return
    }

    this.setState(
      {
        prodEditing: JSON.parse(JSON.stringify(this.state.productInfo)),
      },
      () => {
        // add warehouse to product if not already there
        this.handleShipTo(
          {
            id: result.warehouse_id,
            name: result.warehouse_name,
            type: "",
          },
          () => {
            let products: Array<product> = JSON.parse(JSON.stringify(this.state.products))
            let activeProd = products.find((x) => x.unique_id === (this.state.productInfo ? this.state.productInfo.unique_id : ""))

            if (!activeProd) return

            let activeWhse = activeProd.warehouses.find((x) => x.id === result.warehouse_id)
            let activeQty = activeWhse && activeWhse.quantity ? activeWhse.quantity : 0
            let existingOrder = activeProd.orders ? activeProd.orders.find((x) => x.order_id === result.order_id) : null

            if (activeProd.orders && existingOrder) {
              existingOrder.order_product_id = result.order_product_id
              existingOrder.quantity = existingOrder.quantity + parseInt(result.quantity, 10)
            } else if (activeProd.orders) {
              activeProd.orders.push(result)
            } else {
              activeProd.orders = [result]
            }

            if (activeWhse) {
              activeWhse.quantity = activeQty + result.quantity
            }

            this.setState(
              {
                toChooseOTBOrder: false,
                products: products,
                productInfo: activeProd,
              },
              () => this.handleSearchDepth(0)
            )
          }
        )
      }
    )
  }

  deleteAttachedOrder = (order: prodOrder) => {
    let products: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let activeProduct = products.find((x) => x.unique_id === (this.state.productInfo ? this.state.productInfo.unique_id : ""))

    if (!activeProduct) return

    if (activeProduct.orders) {
      let activeOrder = activeProduct.orders.find((x) => x.id === order.id)
      let orderIndex = activeProduct.orders.findIndex((x) => x.id === order.id)
      let activeWhse = activeProduct.warehouses.find((x) => x.id === order.warehouse_id)
      let activeQty = activeWhse && activeWhse.quantity ? activeWhse.quantity : 0

      if (activeWhse) {
        activeWhse.quantity = activeQty - (activeOrder ? activeOrder.quantity : 0)
        activeWhse.quantity = activeWhse.quantity < 0 ? undefined : activeWhse.quantity
      }

      activeProduct.orders.splice(orderIndex, 1)

      this.setState({
        products: products,
        productInfo: activeProduct,
      })
    }
  }

  /*
██       ██████  ████████     ██████  ██████  ██  ██████ ███████ ███████
██      ██    ██    ██        ██   ██ ██   ██ ██ ██      ██      ██
██      ██    ██    ██        ██████  ██████  ██ ██      █████   ███████
██      ██    ██    ██        ██      ██   ██ ██ ██      ██           ██
███████  ██████     ██        ██      ██   ██ ██  ██████ ███████ ███████
*/

  deleteLotPrice = (lotPrice: lotPrice) => {
    let newArray: Array<lotPrice> = JSON.parse(JSON.stringify(this.state.lotPrices))
    const deleteIndex = newArray.findIndex((x) => x.unique_id === lotPrice.unique_id)
    newArray.splice(deleteIndex, 1)

    this.setState({
      lotPrices: newArray,
    })
  }

  handleLotPriceInput = (name: string, value: string | number, lotPrice: lotPrice) => {
    let newArray: Array<lotPrice> = JSON.parse(JSON.stringify(this.state.lotPrices))
    let foundLot = newArray.find((x) => x.unique_id === lotPrice.unique_id)

    if (!foundLot) return

    foundLot[name] = value

    this.setState({
      lotPrices: newArray,
    })
  }

  onSortEndLotPrice = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.setState({
      lotPrices: arrayMove(this.state.lotPrices, oldIndex, newIndex),
    })
  }

  /*
  ███████ ██    ██ ██████  ███    ███ ██ ████████
  ██      ██    ██ ██   ██ ████  ████ ██    ██
  ███████ ██    ██ ██████  ██ ████ ██ ██    ██
       ██ ██    ██ ██   ██ ██  ██  ██ ██    ██
  ███████  ██████  ██████  ██      ██ ██    ██
  */

  submitSavePO = () => {
    let dataToSubmit = {
      id: this.state.existingPOId,
      vendor_id: this.state.vendor ? this.state.vendor.id : null,
      warehouse: this.state.warehouse,
      address: this.state.warehouse ? null : this.state.address,
      products: JSON.parse(JSON.stringify(this.state.products)),
      lot_prices: JSON.parse(JSON.stringify(this.state.lotPrices)),
      comment: this.state.overallComment,
    }

    if (this.countErrors() > 0) {
      return
    } else {
      console.log(dataToSubmit)
    }

    dataToSubmit.products.forEach((prod: any) => (prod.eta = inputDateToDateObj(prod.eta)))

    this.setState({ loading: true }, () => {
      fireAxios(
        { method: "post", url: this.state.existingPOId ? "purchaseOrders/update" : "purchaseOrders/create", data: dataToSubmit },
        (response) => {
          this.setState(initialState, () => {
            this.getPO(response.data)
          })
        }
      )
    })
  }

  /*
   ██████  ████████ ██   ██ ███████ ██████
  ██    ██    ██    ██   ██ ██      ██   ██
  ██    ██    ██    ███████ █████   ██████
  ██    ██    ██    ██   ██ ██      ██   ██
   ██████     ██    ██   ██ ███████ ██   ██
  */

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.setState({
      products: arrayMove(this.state.products, oldIndex, newIndex),
    })
  }

  /*
██████  ███████ ███    ██ ██████  ███████ ██████
██   ██ ██      ████   ██ ██   ██ ██      ██   ██
██████  █████   ██ ██  ██ ██   ██ █████   ██████
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
██   ██ ███████ ██   ████ ██████  ███████ ██   ██
*/

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseOTBOrder) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create P.O.</button>
          <br />
          <br />
          <OTBOrderSearch
            returnResult={this.otbOrderSearchResult}
            defaultedFields={{
              product_line: this.state.productInfo ? this.state.productInfo.product_line : "",
              product_num: this.state.productInfo ? this.state.productInfo.product_num : "",
            }}
          />
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    if (this.state.toChooseLotPrice) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create P.O.</button>
          <br />
          <br />
          <LotPriceSearch returnResult={this.lotPriceSearchResult} />
        </div>
      )
    }

    if (this.state.toChooseVendor) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create P.O.</button>
          <br />
          <br />
          <VendorSearch returnResult={this.vendorSearchResult} />
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    if (this.state.toChooseProduct) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create P.O.</button>
          <br />
          <br />
          <ProductSearch
            returnResult={this.productSearchResult}
            defaultedFields={{ line: this.state.vendor ? this.state.vendor.product_line : "" }}
          />
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    if (this.state.toChooseWarehouse) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create P.O.</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseSearchResult} />
        </div>
      )
    }

    if (this.state.toChooseShipTo) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create P.O.</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.handleShipTo} />
        </div>
      )
    }

    /*
  ███    ███  █████  ██ ███    ██     ██████  ███████ ███    ██ ██████  ███████ ██████
  ████  ████ ██   ██ ██ ████   ██     ██   ██ ██      ████   ██ ██   ██ ██      ██   ██
  ██ ████ ██ ███████ ██ ██ ██  ██     ██████  █████   ██ ██  ██ ██   ██ █████   ██████
  ██  ██  ██ ██   ██ ██ ██  ██ ██     ██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
  ██      ██ ██   ██ ██ ██   ████     ██   ██ ███████ ██   ████ ██████  ███████ ██   ██
  */

    const vend = this.state.vendor
    const whse = this.state.warehouse
    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    const overlay: React.CSSProperties = {
      position: "fixed" /* Sit on top of the page content */,
      display: "block" /* Hidden by default */,
      width: "100%" /* Full width (cover the whole page) */,
      height: "100%" /* Full height (cover the whole page) */,
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.5)" /* Black background with opacity */,
      zIndex: 99 /* Specify a stack order in case you're using a different order for other elements */,
      cursor: "pointer" /* Add a pointer on hover */,
    }

    const overlayBody: React.CSSProperties = {
      position: "absolute",
      top: "15%",
      left: "25%",
      width: "50%",
      height: "70%",
      zIndex: 100,
      boxShadow: "0px 0px 30px #000000",
      backgroundColor: "white",
      cursor: "default",
      padding: "20px",
      overflow: "auto",
    }

    let totalPOCost = 0

    this.state.products.forEach((prod) => {
      prod.warehouses.forEach((whse) => {
        totalPOCost += prod.cost * (whse.quantity ? whse.quantity : 0)
      })
    })

    this.state.lotPrices.forEach((lot) => {
      totalPOCost += lot.cost
    })

    if (vend) {
      return (
        <div>
          {this.state.productInfo ? (
            <div style={overlay} onClick={() => this.hideProductInfo()}>
              <div style={overlayBody} onClick={(e) => this.stopPropagation(e)}>
                <div style={headerStyle}>
                  <NewTabText
                    link="product"
                    id={this.state.productInfo.id}
                    text={this.state.productInfo.product_line + " - " + this.state.productInfo.product_num}
                  />
                  &nbsp;<span style={{ fontWeight: "normal" }}>{this.state.productInfo.description}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
                  <div>
                    ETA Date:
                    <br />
                    <input
                      type="date"
                      name={"eta"}
                      value={this.state.productInfo.eta || ""}
                      onChange={(e) => this.handleProductInputChange(e.target.name, e.target.value, this.state.productInfo!, null)}
                    />
                  </div>
                  <div>
                    Comment:
                    <br />
                    <textarea
                      name={"comment"}
                      value={this.state.productInfo.comment || ""}
                      onChange={(e) => this.handleProductInputChange(e.target.name, e.target.value, this.state.productInfo!, null)}
                    />
                  </div>
                </div>
                <div style={headerStyle}>
                  For Stock:{" "}
                  {this.state.productInfo.orders
                    ? this.state.productInfo.orders.reduce(
                        (acc, curr) => {
                          return acc - curr.quantity
                        },
                        this.state.productInfo.warehouses.reduce((acc, curr) => (acc += curr.quantity ? curr.quantity : 0), 0)
                      )
                    : this.state.productInfo.warehouses.reduce((acc, curr) => (acc += curr.quantity ? curr.quantity : 0), 0)}
                </div>
                <div style={headerStyle}>Attached Orders</div>
                <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
                  {this.state.productInfo.orders
                    ? this.state.productInfo.orders.map((order) => {
                        return (
                          <table key={order.order_product_id} style={{ border: "1px solid #000", padding: "10px" }}>
                            <tbody>
                              <tr>
                                <td>Order #:</td>
                                <td>
                                  <b>
                                    <NewTabText id={order.order_id} link="order" text={order.order_id.toString()} />
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td>Warehouse:</td>
                                <td>{order.warehouse_name}</td>
                              </tr>
                              <tr>
                                <td>Quantity:</td>
                                <td>{order.quantity}</td>
                              </tr>
                              <tr>
                                <td>Received:</td>
                                <td>{order.qty_received || "0"}</td>
                              </tr>
                              <tr>
                                <td>
                                  <button onClick={() => this.deleteAttachedOrder(order)}>Delete</button>
                                </td>
                                <td />
                              </tr>
                            </tbody>
                          </table>
                        )
                      })
                    : null}
                </div>
                <br />
                <br />
                <div>
                  <button onClick={() => this.toAttachOrder()}>Attach Order</button>
                </div>
              </div>
            </div>
          ) : null}

          <div>
            <div style={headerStyle}>Vendor:</div>
            <b>{vend.product_line}</b> - {vend.name}{" "}
            <button disabled={this.state.products.length > 0} onClick={this.chooseVendor}>
              Edit
            </button>
          </div>
          <div>
            <div style={headerStyle}>Ship to:</div>
            {whse ? (
              <div>
                {whse.name} <button onClick={this.chooseWarehouse}>Edit</button>
                &nbsp;<button onClick={this.clearAddress}>Clear</button>
              </div>
            ) : this.state.customAddress ? null : (
              <div>
                <button onClick={this.chooseWarehouse}>Warehouse</button>
                <button onClick={this.addAddress}>Ship Direct</button>
              </div>
            )}
            {this.state.customAddress ? (
              <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
                <div>
                  <input
                    onChange={(e) => this.handleAddressChange(e)}
                    defaultValue={this.state.address ? this.state.address.address_1 : undefined}
                    placeholder="Line 1"
                    name={"address_1"}
                  />
                  <br />
                  <input
                    onChange={(e) => this.handleAddressChange(e)}
                    defaultValue={this.state.address ? this.state.address.address_2 : undefined}
                    placeholder="Line 2"
                    name={"address_2"}
                  />
                  <br />
                  <input
                    onChange={(e) => this.handleAddressChange(e)}
                    defaultValue={this.state.address ? this.state.address.city : undefined}
                    placeholder="City"
                    name={"city"}
                  />
                  <br />
                  <input
                    onChange={(e) => this.handleAddressChange(e)}
                    defaultValue={this.state.address ? this.state.address.province : undefined}
                    placeholder="Province"
                    name={"province"}
                  />
                  <br />
                  <input
                    onChange={(e) => this.handleAddressChange(e)}
                    defaultValue={this.state.address ? this.state.address.postal_code : undefined}
                    placeholder="Postal Code"
                    name={"postal_code"}
                  />
                </div>
                <div>
                  <button onClick={this.clearAddress}>Clear</button>
                </div>
              </div>
            ) : null}
          </div>
          {!this.state.warehouse && !this.state.address ? null : (
            <div>
              <div style={headerStyle}>Products:</div>
              <SortableList
                items={this.state.products}
                onSortEnd={this.onSortEnd}
                deleteProduct={this.deleteProduct}
                deleteShipTo={this.deleteShipTo}
                addShipTo={this.addShipTo}
                handleProductInput={this.handleProductInputChange}
                productInfo={this.showProductInfo}
                useDragHandle={true}
              />
              <br />
              <button onClick={this.chooseProduct}>Add Product</button>
              <div style={headerStyle}>Lot Prices:</div>
              <SortableLotPrices
                lotPrices={this.state.lotPrices}
                onSortEnd={this.onSortEndLotPrice}
                deleteLotPrice={this.deleteLotPrice}
                handleLotPriceInput={this.handleLotPriceInput}
                useDragHandle={true}
              />
              <br />
              <button onClick={this.chooseLotPrice}>Attach Lot Price</button>
              <div style={headerStyle}>Details:</div>
              <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
                <div>Total Purchase Cost: ${totalPOCost.toFixed(2)}</div>
                <div>Created: {toReadableDate(this.state.dateCreated)}</div>
                <div>
                  Overall Comment:
                  <br />
                  <textarea defaultValue={this.state.overallComment} onChange={(e) => this.handlePOCommentChange(e)}></textarea>
                </div>
              </div>
              <br />
              <br />
              {this.state.incomplete ? <div style={{ color: "red" }}>Error: Some fields are incomplete or invalid!</div> : null}
              {this.state.products.length >= 1 ? (
                <div>
                  <button onClick={this.submitSavePO}>{this.state.existingPOId ? "Save" : "Submit"}</button>
                </div>
              ) : null}
            </div>
          )}
        </div>
      )
    }

    return (
      <div>
        To begin, choose the vendor: &nbsp;
        <button onClick={this.chooseVendor}>Choose Vendor</button>
      </div>
    )
  }
}

export default PurchaseOrder
