import React, { Component } from "react"

import BasicCreate from "../create/BasicCreate"
import User from "./User"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import SalesrepSearch from "../salesreps/SalesrepSearch"

class CreateUser extends Component {
  render() {
    return (
      <BasicCreate
        {...{
          title: "User",
          resultChild: <User />,
          inputs: [
            // required fields
            { value: "", required: true, name: "Email", databaseField: "email", inputType: "text", restriction: "email" },
            { value: "", required: true, name: "Password", databaseField: "password", inputType: "text" },
            {
              value: "",
              required: true,
              name: "Warehouse",
              databaseField: "warehouse_id",
              inputType: "search",
              searchName: "Warehouse",
              searchChild: <WarehouseSearch />,
              requiredSearchResult: "id",
              displaySearchResult: "name",
            },
            {
              value: "",
              required: true,
              name: "Salesrep",
              databaseField: "salesrep_id",
              inputType: "search",
              searchName: "Salesrep",
              searchChild: <SalesrepSearch />,
              requiredSearchResult: "id",
              displaySearchResult: "first_name",
            },
          ],
          endpoint: "users/create",
        }}
        {...this.props}
      />
    )
  }
}

export default CreateUser
