import React, { Component } from "react"

// import CSS
import "../../stylesheets/Product.css"

// import child components
import LoadingSpinner from "../LoadingSpinner"
import ImageUpload from "../files/ImageUpload"
import PDFUpload from "../files/PDFUpload"
import EditableTD from "../edit/EditableTD"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import NewTabText from "../newTabText/NewTabText"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
// import required libraries
import { fireAxios, toCurrency } from "../helpers"

type props = {
  id?: number
}

type warehouse = {
  id: number | string
  name: string
  qty_onhand?: number
  qty_backordered?: number
  qty_onorder_fororder?: number
  qty_onorder_forstock?: number
  bin_locations?: {
    location_1: string | null
    location_2: string | null
  }
  min_maxes?: {
    min: number
    max: number
  }
  [index: string]: any
}

type product = {
  id: number
  product_line: string
  product_num: string
  description: string
  imageURL: string
  pdfURL: string
  externalURL: string
  vendor_currency: string
  warehouses: Array<warehouse>
  closed: boolean
  [index: string]: any
}

type state = {
  productId?: number
  product?: product
  loading: boolean
  focusedInput?: string
  toChooseWarehouse: boolean
  loadingQtyInfo: boolean
  dialog: dialogProps
  qtyInfo: {
    title: string
    type?: string
    result: {
      columns: Array<{
        title: string
        key: string
      }>
      rows: Array<{
        id: string
        [index: string]: any
      }>
    }
  }
}

const initialState: state = {
  productId: undefined,
  product: undefined,
  loading: false,
  focusedInput: undefined,
  toChooseWarehouse: false,
  loadingQtyInfo: false,
  dialog: null,
  qtyInfo: {
    title: "",
    result: {
      columns: [],
      rows: [],
    },
  },
}

class Product extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEscapeInfo, false)

    this.setState(
      {
        productId: this.props.id,
      },
      () => {
        this.state.productId && this.getAllProductInfo(this.state.productId)
      }
    )
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEscapeInfo, false)
  }

  getAllProductInfo = (productId: number) => {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "products/all/" + productId }, (response) => {
        response.data.warehouses = response.data.warehouses.sort((a: warehouse, b: warehouse) => b.warehouse_id - a.warehouse_id)

        this.setState(
          {
            product: response.data,
            loading: false,
          },
          () => {
            this.props.setAppTitle &&
              this.state.product &&
              this.props.setAppTitle(this.state.product.product_line + " - " + this.state.product.product_num)
          }
        )
      })
    })
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  /*
 █████  ██████  ██████      ██     ██  █████  ██████  ███████ ██   ██  ██████  ██    ██ ███████ ███████
██   ██ ██   ██ ██   ██     ██     ██ ██   ██ ██   ██ ██      ██   ██ ██    ██ ██    ██ ██      ██
███████ ██   ██ ██   ██     ██  █  ██ ███████ ██████  █████   ███████ ██    ██ ██    ██ ███████ █████
██   ██ ██   ██ ██   ██     ██ ███ ██ ██   ██ ██   ██ ██      ██   ██ ██    ██ ██    ██      ██ ██
██   ██ ██████  ██████       ███ ███  ██   ██ ██   ██ ███████ ██   ██  ██████   ██████  ███████ ███████
*/

  addLocation = () => {
    var tempProduct: product = JSON.parse(JSON.stringify(this.state.product))

    if (tempProduct.warehouses.filter((i) => i.id === "").length > 0) {
      return // don't continue if there's already an empty location
    }

    tempProduct.warehouses.push({ id: "", name: "" })

    this.setState({
      product: tempProduct,
    })
  }

  chooseWarehouse = () => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(1)
    }
    this.setState({
      toChooseWarehouse: true,
    })
  }

  backFromSearch = () => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(0)
    }
    this.setState({
      toChooseWarehouse: false,
    })
  }

  warehouseResult = (warehouse: any) => {
    var tempProduct: product = JSON.parse(JSON.stringify(this.state.product))

    if (tempProduct.warehouses.filter((i) => i.id === warehouse.id).length > 0) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "The chosen warehouse: " + warehouse.name + ", is already in use",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(0)
    }

    // come back from search
    this.setState({ toChooseWarehouse: false }, () => {
      // add warehouse to empty warehouse created by "Add" button
      tempProduct.warehouses.filter((i) => i.id === "")[0].id = warehouse.id
      tempProduct.warehouses.filter((i) => i.id === warehouse.id)[0].name = warehouse.name

      this.setState({
        product: tempProduct,
      })
    })
  }

  /*
██    ██ ██████  ██████   █████  ████████ ███████     ██    ██  █████  ██      ██    ██ ███████ ███████
██    ██ ██   ██ ██   ██ ██   ██    ██    ██          ██    ██ ██   ██ ██      ██    ██ ██      ██
██    ██ ██████  ██   ██ ███████    ██    █████       ██    ██ ███████ ██      ██    ██ █████   ███████
██    ██ ██      ██   ██ ██   ██    ██    ██           ██  ██  ██   ██ ██      ██    ██ ██           ██
 ██████  ██      ██████  ██   ██    ██    ███████       ████   ██   ██ ███████  ██████  ███████ ███████
*/

  changeInputFocus = (position: string | number) => {
    this.setState({
      focusedInput: position.toString(),
    })
  }

  updateLocation = (source: product, context: string, value: any) => {
    let stateProd: product = JSON.parse(JSON.stringify(this.state.product)) // state that needs to be updated
    let whseIndex = stateProd.warehouses.findIndex((i) => i.id === source.id)
    let tempLocations = stateProd.warehouses[whseIndex].bin_locations

    if (!tempLocations) {
      stateProd.warehouses[whseIndex].bin_locations = { location_1: "", location_2: "" }
      tempLocations = stateProd.warehouses[whseIndex].bin_locations
    }

    if (context === "Bin 1" && tempLocations) {
      tempLocations.location_1 = value ? value : ""
    } else if (context === "Bin 2" && tempLocations) {
      tempLocations.location_2 = value ? value : ""
    } else {
      console.log("ERROR: no appropriate context")
      return
    }

    this.setState({ product: stateProd, loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "binLocations/update",
          data: {
            product_id: stateProd.id,
            warehouse_id: stateProd.warehouses[whseIndex].id,
            location_1: tempLocations ? tempLocations.location_1 : "",
            location_2: tempLocations ? tempLocations.location_2 : "",
          },
        },
        () => {
          this.getAllProductInfo(stateProd.id)
        }
      )
    })
  }

  updateMinMaxes = (source: warehouse, context: string, value: any) => {
    let stateProd: product = JSON.parse(JSON.stringify(this.state.product)) // state that needs to be updated
    let whseIndex = stateProd.warehouses.findIndex((i) => i.id === source.id)
    let tempMinMaxes = stateProd.warehouses[whseIndex].min_maxes

    if (!tempMinMaxes) {
      // for setting new min/maxes if no min/maxes exist for desired warehouse
      stateProd.warehouses[whseIndex].min_maxes = { min: 0, max: 0 }
      tempMinMaxes = stateProd.warehouses[whseIndex].min_maxes
    }

    if (context === "min" && tempMinMaxes) {
      tempMinMaxes.min = value ? value : 0
    } else if (context === "max" && tempMinMaxes) {
      tempMinMaxes.max = value ? value : 0
    } else {
      console.log("ERROR: no appropriate context")
      return
    }

    this.setState({ product: stateProd, loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "minMaxes/update",
          data: {
            product_id: stateProd.id,
            warehouse_id: stateProd.warehouses[whseIndex].id,
            min: tempMinMaxes ? tempMinMaxes.min : 0,
            max: tempMinMaxes ? tempMinMaxes.max : 0,
          },
        },
        () => {
          this.getAllProductInfo(stateProd.id)
        }
      )
    })
  }

  updateAvgCost = (source: product, _context: string, value: any) => {
    let stateProd: product = JSON.parse(JSON.stringify(this.state.product)) // state that needs to be updated
    let warehouse = stateProd.warehouses.find((i) => i.id === source.id)

    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "producthistory/update",
          data: {
            product_id: this.state.product ? this.state.product.id : null,
            warehouse_id: warehouse ? warehouse.id : null,
            new_cost: parseFloat(value),
            type: "Cost Adj.",
          },
        },
        () => {
          this.state.product && this.getAllProductInfo(this.state.product.id)
        }
      )
    })
  }

  closeProduct = () => {
    this.showDialog({
      type: "confirm",
      title: "Are you sure?",
      message: "Are you sure you want to close this product?",
      handleResponse: (response) => {
        if (response) {
          this.updateProduct(null, "closed", true)
        }
        this.hideDialog()
      },
    })
  }

  openProduct = () => {
    this.showDialog({
      type: "confirm",
      title: "Are you sure?",
      message: "Are you sure you want to open this product?",
      handleResponse: (response) => {
        if (response) {
          this.updateProduct(null, "closed", false)
        }
        this.hideDialog()
      },
    })
  }

  updateProduct = (_source: any, context: string, value: any) => {
    // make two copies of products state. One for updating columns in MySQL table 'products', one for updating state
    let submitProd: product = JSON.parse(JSON.stringify(this.state.product)) // product copy to submit to datebase
    let stateProd: product = JSON.parse(JSON.stringify(this.state.product)) // product copy to update state

    if (context) {
      submitProd[context] = value
      stateProd[context] = value
    } else {
      console.log("No context")
      return
    }

    this.setState({ product: stateProd, loading: true }, () => {
      fireAxios({ method: "post", url: "products/" + submitProd.id, data: submitProd }, () => {
        this.getAllProductInfo(stateProd.id)
      })
    })
  }

  /*
  ███████ ██ ██      ███████ ███████
  ██      ██ ██      ██      ██
  █████   ██ ██      █████   ███████
  ██      ██ ██      ██           ██
  ██      ██ ███████ ███████ ███████
  */

  deleteProductImage = () => {
    this.showDialog({
      type: "confirm",
      title: "Are you sure?",
      message: "Are you sure you want to delete this image?",
      handleResponse: (response) => {
        this.hideDialog()
        if (response) {
          this.setState({ loading: true }, () => {
            fireAxios(
              {
                method: "post",
                url: "filetransfers/delete/product/image",
                data: {
                  image_url: this.state.product ? this.state.product.imageURL : null,
                  product_id: this.state.product ? this.state.product.id : null,
                },
              },
              () => {
                this.state.product && this.getAllProductInfo(this.state.product.id)
              }
            )
          })
        } else {
          return
        }
      },
    })
  }

  deleteProductPDF = () => {
    this.showDialog({
      type: "confirm",
      title: "Are you sure?",
      message: "Are you sure you want to delete this pdf?",
      handleResponse: (response) => {
        this.hideDialog()
        if (response) {
          this.setState({ loading: true }, () => {
            fireAxios(
              {
                method: "post",
                url: "filetransfers/delete/product/pdf",
                data: {
                  pdf_url: this.state.product ? this.state.product.pdfURL : null,
                  product_id: this.state.product ? this.state.product.id : null,
                },
              },
              () => {
                this.state.product && this.getAllProductInfo(this.state.product.id)
              }
            )
          })
        } else {
          return
        }
      },
    })
  }

  /*
   ██████  ████████ ██    ██     ██ ███    ██ ███████  ██████
  ██    ██    ██     ██  ██      ██ ████   ██ ██      ██    ██
  ██    ██    ██      ████       ██ ██ ██  ██ █████   ██    ██
  ██ ▄▄ ██    ██       ██        ██ ██  ██ ██ ██      ██    ██
   ██████     ██       ██        ██ ██   ████ ██       ██████
      ▀▀
      */

  hideQtyInfo = () => {
    this.setState({
      qtyInfo: {
        title: "",
        result: {
          columns: [],
          rows: [],
        },
      },
    })
  }

  showQtyInfo = (warehouseId: number | string, type: string) => {
    let newQtyInfo = {
      title: "",
      type: "",
      result: {
        columns: [],
        rows: [],
      },
    }

    let newTitle: string, endPoint: string

    if (!this.state.product) return

    switch (type) {
      case "qty_committed":
        newTitle = "Orders with " + this.state.product.product_line + " - " + this.state.product.product_num + " in picking."
        endPoint = "committedDetails"
        break
      case "qty_in_transfer":
        newTitle = "Transfers with " + this.state.product.product_line + " - " + this.state.product.product_num + " attached."
        endPoint = "transferDetails"
        break
      case "qty_for_return":
        newTitle = "Return to vendor with " + this.state.product.product_line + " - " + this.state.product.product_num + " attached."
        endPoint = "returnDetails"
        break
      case "qty_backordered":
        newTitle = "Orders with outstanding " + this.state.product.product_line + " - " + this.state.product.product_num + " attached."
        endPoint = "backorderDetails"
        break
      case "qty_onorder_fororder":
        newTitle = "PO's with " + this.state.product.product_line + " - " + this.state.product.product_num + " attached for an Order."
        endPoint = "forOrderDetails"
        break
      case "qty_onorder_forstock":
        newTitle = "PO's with " + this.state.product.product_line + " - " + this.state.product.product_num + " attached for stock."
        endPoint = "forStockDetails"
        break
      default:
        newTitle = "Error"
    }

    newQtyInfo.title = newTitle
    newQtyInfo.type = type

    this.setState({ loadingQtyInfo: true, qtyInfo: newQtyInfo }, () => {
      fireAxios(
        {
          method: "post",
          url: "products/quantities/" + endPoint,
          data: {
            product_id: this.state.product ? this.state.product.id : null,
            warehouse_id: warehouseId,
          },
        },
        (response) => {
          newQtyInfo.result = response.data

          this.setState({
            loadingQtyInfo: false,
            qtyInfo: newQtyInfo,
          })
        }
      )
    })
  }

  /*
   ██████  ████████ ██   ██ ███████ ██████
  ██    ██    ██    ██   ██ ██      ██   ██
  ██    ██    ██    ███████ █████   ██████
  ██    ██    ██    ██   ██ ██      ██   ██
   ██████     ██    ██   ██ ███████ ██   ██
  */

  stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // prevents the overlay from closing when the body of the overlay is clicked
    e.stopPropagation()
  }

  handleEscapeInfo = (e: any) => {
    if (e.key === "Escape") {
      this.state.qtyInfo !== null && this.hideQtyInfo()
    }
  }

  getNewTabLink = (type: string | undefined) => {
    switch (type) {
      case "qty_committed":
      case "qty_backordered":
        return "order"
      case "qty_in_transfer":
        return "transfer"
      case "qty_for_return":
        return "return"
      case "qty_onorder_fororder":
      case "qty_onorder_forstock":
        return "purchase"
      default:
        return "error"
    }
  }

  isEligibleForClose = () => {
    if (this.state.product && this.state.product.closed) {
      return false
    }

    return (
      this.state.product &&
      (this.state.product.warehouses.length === 0 ||
        this.state.product.warehouses.every((whse) => {
          const quantities = [whse.qty_onhand, whse.qty_backordered, whse.qty_onorder_fororder, whse.qty_onorder_forstock]
          return quantities.every((x) => x && x <= 0)
        }))
    )
  }

  handleExternalURL = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newURL = e.target.value

    this.setState((prevState) => {
      if (!prevState.product) {
        return prevState
      } else {
        return {
          ...prevState,
          product: { ...prevState.product, externalURL: newURL },
        }
      }
    })
  }

  /*
██████  ███████ ███    ██ ██████  ███████ ██████
██   ██ ██      ████   ██ ██   ██ ██      ██   ██
██████  █████   ██ ██  ██ ██   ██ █████   ██████
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
██   ██ ███████ ██   ████ ██████  ███████ ██   ██
*/

  render() {
    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    const overlay: React.CSSProperties = {
      position: "fixed" /* Sit on top of the page content */,
      display: "block" /* Hidden by default */,
      width: "100%" /* Full width (cover the whole page) */,
      height: "100%" /* Full height (cover the whole page) */,
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.5)" /* Black background with opacity */,
      zIndex: 99 /* Specify a stack order in case you're using a different order for other elements */,
      cursor: "pointer" /* Add a pointer on hover */,
    }

    const overlayBody: React.CSSProperties = {
      position: "absolute",
      top: "15%",
      left: "25%",
      width: "50%",
      height: "70%",
      zIndex: 100,
      boxShadow: "0px 0px 30px #000000",
      backgroundColor: "white",
      cursor: "default",
      padding: "20px",
      overflow: "auto",
    }

    if (this.state.loading || !this.state.product) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseWarehouse) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Product</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseResult} />
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    return (
      <div>
        {this.state.qtyInfo.title ? (
          <div style={overlay} onClick={() => this.hideQtyInfo()}>
            <div style={overlayBody} onClick={(e) => this.stopPropagation(e)}>
              <div style={headerStyle}>
                {this.state.qtyInfo.title}
                <span style={{ fontWeight: "normal" }}>&nbsp;({this.state.qtyInfo.result.rows.length})</span>
              </div>
              {this.state.loadingQtyInfo ? (
                <LoadingSpinner />
              ) : (
                <table className="searchResults">
                  <tbody>
                    <tr>
                      {this.state.qtyInfo.result.columns.map((col) => (
                        <th key={col.key}>{col.title}</th>
                      ))}
                    </tr>
                    {this.state.qtyInfo.result.rows.map((row) => (
                      <tr key={row.id}>
                        {this.state.qtyInfo.result.columns.map((col) => {
                          let clickable = col.key === "id"
                          return (
                            <td key={row.id + col.key}>
                              {clickable ? (
                                <NewTabText link={this.getNewTabLink(this.state.qtyInfo.type)} id={row[col.key]} text={row[col.key]} />
                              ) : (
                                row[col.key]
                              )}
                            </td>
                          )
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        ) : null}

        <div className="product">
          <div className="productHeader">
            {this.state.product.closed ? (
              <span style={{ color: "red" }}>
                PRODUCT CLOSED
                <br />
                <br />
              </span>
            ) : null}
            <h2 className="productLineNum">
              {this.state.product.product_line} - <b>{this.state.product.product_num}</b>
            </h2>
            <table>
              <tbody>
                <tr>
                  <td>Description:</td>
                  <EditableTD
                    content={this.state.product.description}
                    placeholder="description"
                    disabled={this.state.product.closed}
                    source={this.state.product}
                    context="description"
                    focused={"description" === this.state.focusedInput}
                    focusedID={"description"}
                    changeFocus={this.changeInputFocus}
                    update={this.updateProduct}
                  />
                </tr>
              </tbody>
            </table>
          </div>

          <br />

          <div>
            Quantities: <br />
            <br />
            <table className="locations productDetailTable">
              <tbody>
                <tr>
                  <th>Warehouse</th>
                  <th>On Hand</th>
                  <th>Committed</th>
                  <th>Transfers</th>
                  <th>For Return</th>
                  <th>Available</th>
                  <th>Backorder</th>
                  <th>B/O On PO</th>
                  <th>Stock On PO</th>
                </tr>
                {this.state.product.warehouses.map((whse) => {
                  // only display warehouses with at least one quantity that is non-zero
                  if (whse.name && Object.keys(whse).filter((k) => k.includes("qty_") && whse[k]).length > 0) {
                    return (
                      <tr key={whse.id}>
                        <td>{whse.name ? whse.name : <button onClick={this.chooseWarehouse}>Choose Warehouse</button>}</td>
                        {[
                          "qty_onhand",
                          "qty_committed",
                          "qty_in_transfer",
                          "qty_for_return",
                          "qty_available",
                          "qty_backordered",
                          "qty_onorder_fororder",
                          "qty_onorder_forstock",
                        ].map((qtyType) => {
                          let clickable = whse[qtyType] > 0 && qtyType !== "qty_onhand" && qtyType !== "qty_available"
                          return (
                            <td
                              style={{
                                textAlign: "right",
                                cursor: clickable ? "pointer" : "default",
                                textDecoration: clickable ? "underline" : "inherit",
                              }}
                              onClick={() => clickable && this.showQtyInfo(whse.id, qtyType)}
                              key={whse.name + qtyType}
                            >
                              <span style={{ fontWeight: qtyType === "qty_available" ? "bold" : "normal" }}>{whse[qtyType]}</span>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  } else {
                    return null
                  }
                })}
              </tbody>
            </table>
          </div>

          <br />
          <br />

          <div>
            Locational Details: <br />
            <br />
            <table className="locations productDetailTable">
              <tbody>
                <tr>
                  <th>Warehouse</th>
                  <th>Avg Cost</th>
                  <th>Bin 1</th>
                  <th>Bin 2</th>
                  <th>Min</th>
                  <th>Max</th>
                </tr>
                {this.state.product.warehouses.map((whse) => {
                  return (
                    <tr key={whse.id}>
                      <td>{whse.name ? whse.name : <button onClick={this.chooseWarehouse}>Choose Warehouse</button>}</td>
                      {whse.id ? ( // no ability to edit if there's no chosen warehouse
                        <EditableTD
                          content={toCurrency(whse.avg_cost)}
                          placeholder="Avg Cost"
                          disabled={this.state.product!.closed}
                          source={whse}
                          context="avg_cost"
                          focused={whse.id + "avg_cost" === this.state.focusedInput}
                          focusedID={whse.id + "avg_cost"}
                          changeFocus={this.changeInputFocus}
                          restriction="currency"
                          update={this.updateAvgCost}
                        />
                      ) : (
                        <td>{whse.avg_cost ? whse.avg_cost : ""}</td>
                      )}
                      {whse.id ? ( // no ability to edit if there's no chosen warehouse
                        <EditableTD
                          content={whse.bin_locations ? whse.bin_locations.location_1 || "" : ""}
                          placeholder="Bin 1"
                          disabled={this.state.product!.closed}
                          source={whse}
                          context="Bin 1"
                          focused={whse.id + "Bin 1" === this.state.focusedInput}
                          focusedID={whse.id + "Bin 1"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateLocation}
                        />
                      ) : (
                        <td>{whse.bin_locations ? whse.bin_locations.location_1 : ""}</td>
                      )}
                      {whse.id ? ( // no ability to edit if there's no chosen warehouse
                        <EditableTD
                          content={whse.bin_locations ? whse.bin_locations.location_2 || "" : ""}
                          placeholder="Bin 2"
                          disabled={this.state.product!.closed}
                          source={whse}
                          context="Bin 2"
                          focused={whse.id + "Bin 2" === this.state.focusedInput}
                          focusedID={whse.id + "Bin 2"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateLocation}
                        />
                      ) : (
                        <td>{whse.bin_locations ? whse.bin_locations.location_2 : ""}</td>
                      )}
                      {whse.id ? ( // no ability to edit if there's no chosen warehouse
                        <EditableTD
                          content={whse.min_maxes ? whse.min_maxes.min : 0}
                          placeholder="min"
                          disabled={this.state.product!.closed}
                          source={whse}
                          context="min"
                          restriction="unsigned-int"
                          focused={whse.name + "min" === this.state.focusedInput}
                          focusedID={whse.name + "min"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateMinMaxes}
                        />
                      ) : (
                        <td>{whse.min_maxes ? whse.min_maxes.min : ""}</td>
                      )}
                      {whse.id ? ( // no ability to edit if there's no chosen warehouse
                        <EditableTD
                          content={whse.min_maxes ? whse.min_maxes.max : 0}
                          placeholder="max"
                          disabled={this.state.product!.closed}
                          source={whse}
                          context="max"
                          restriction="unsigned-int"
                          focused={whse.name + "max" === this.state.focusedInput}
                          focusedID={whse.name + "max"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateMinMaxes}
                        />
                      ) : (
                        <td>{whse.min_maxes ? whse.min_maxes.max : ""}</td>
                      )}
                    </tr>
                  )
                })}
                <tr>
                  {this.state.product.warehouses.find((x) => x.id === "") || this.state.product.closed ? null : (
                    <td className="addRow" onClick={this.addLocation}>
                      Add
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>

          <br />
          <br />

          <div style={{ display: "flex", justifyContent: "normal", flexWrap: "wrap" }}>
            <div style={{ paddingRight: "25px" }}>
              Costs: <br />
              <br />
              <table className="productDetailTable">
                <tbody>
                  <tr>
                    <th>Vendor Cost ({this.state.product.vendor_currency})</th>
                    <EditableTD
                      content={toCurrency(this.state.product.vendor_cost)}
                      placeholder="vendor"
                      disabled={this.state.product.closed}
                      source={this.state.product}
                      context="vendor_cost"
                      focused={"vendor" === this.state.focusedInput}
                      focusedID="vendor"
                      changeFocus={this.changeInputFocus}
                      restriction="currency"
                      update={this.updateProduct}
                    />
                  </tr>
                  <tr>
                    <th>Landed Cost (CD)</th>
                    <td>{toCurrency(this.state.product.landed_cost)}</td>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>

            <div style={{ paddingRight: "25px" }}>
              Pricing: <br />
              <br />
              <table className="productDetailTable">
                <tbody>
                  <tr>
                    <th>Target Margin</th>
                    <EditableTD
                      content={this.state.product.target_margin ? this.state.product.target_margin.toFixed(2) : ""}
                      placeholder="target"
                      disabled={this.state.product.closed}
                      source={this.state.product}
                      context="target_margin"
                      focused={"target" === this.state.focusedInput}
                      focusedID="target"
                      changeFocus={this.changeInputFocus}
                      restriction="unsigned-float"
                      update={this.updateProduct}
                    />
                  </tr>
                  <tr>
                    <th>Walk-in Margin</th>
                    <EditableTD
                      content={this.state.product.walkin_margin ? this.state.product.walkin_margin.toFixed(2) : ""}
                      placeholder="walkin"
                      disabled={this.state.product.closed}
                      source={this.state.product}
                      context="walkin_margin"
                      focused={"walkin" === this.state.focusedInput}
                      focusedID="walkin"
                      changeFocus={this.changeInputFocus}
                      restriction="unsigned-float"
                      update={this.updateProduct}
                    />
                  </tr>
                  <tr>
                    <th>Contractor Margin</th>
                    <EditableTD
                      content={this.state.product.contractor_margin ? this.state.product.contractor_margin.toFixed(2) : ""}
                      placeholder="contractor"
                      disabled={this.state.product.closed}
                      source={this.state.product}
                      context="contractor_margin"
                      focused={"contractor" === this.state.focusedInput}
                      focusedID="contractor"
                      changeFocus={this.changeInputFocus}
                      restriction="unsigned-float"
                      update={this.updateProduct}
                    />
                  </tr>
                </tbody>
              </table>
            </div>

            <div style={{ paddingRight: "25px" }}>
              Multiples: <br />
              <br />
              <table className="productDetailTable">
                <tbody>
                  <tr>
                    <th>Purchase Multiple</th>
                    <EditableTD
                      content={this.state.product.pur_mult}
                      placeholder="multiple"
                      disabled={this.state.product.closed}
                      source={this.state.product}
                      context="pur_mult"
                      focused={"pur_mult" === this.state.focusedInput}
                      focusedID="pur_mult"
                      changeFocus={this.changeInputFocus}
                      restriction="non-zero-unsigned-int"
                      update={this.updateProduct}
                    />
                  </tr>
                  <tr>
                    <th>Sell Multiple</th>
                    <EditableTD
                      content={this.state.product.sell_mult}
                      placeholder="multiple"
                      disabled={this.state.product.closed}
                      source={this.state.product}
                      context="sell_mult"
                      focused={"sell_mult" === this.state.focusedInput}
                      focusedID="sell_mult"
                      changeFocus={this.changeInputFocus}
                      restriction="non-zero-unsigned-int"
                      update={this.updateProduct}
                    />
                  </tr>
                  <tr>
                    <th>Price Per</th>
                    <EditableTD
                      content={this.state.product.price_per}
                      placeholder="multiple"
                      disabled={this.state.product.closed}
                      source={this.state.product}
                      context="price_per"
                      focused={"price_per" === this.state.focusedInput}
                      focusedID="price_per"
                      changeFocus={this.changeInputFocus}
                      restriction="non-zero-unsigned-int"
                      update={this.updateProduct}
                    />
                  </tr>
                  <tr>
                    <th>Unit of Measure</th>
                    <EditableTD
                      content={this.state.product.unit_of_measure}
                      placeholder="units"
                      disabled={this.state.product.closed}
                      source={this.state.product}
                      context="unit_of_measure"
                      focused={"unit_of_measure" === this.state.focusedInput}
                      focusedID="unit_of_measure"
                      changeFocus={this.changeInputFocus}
                      update={this.updateProduct}
                    />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <br />

          <div style={{ display: "flex", justifyContent: "normal", flexWrap: "wrap" }}>
            <div style={{ flex: "2 1 0", paddingRight: "25px" }}>
              <h3>Image</h3>
              {this.state.product.imageURL ? (
                <div>
                  <img alt="" style={{ width: "100%" }} src={this.state.product.imageURL} />
                  <br />
                  <br />
                </div>
              ) : null}
              <div style={this.state.product.imageURL ? { display: "flex" } : undefined}>
                <div style={this.state.product.imageURL ? { flex: "1 1 0", paddingRight: "25px" } : undefined}>
                  <ImageUpload
                    otherDataToSend={{ product_id: this.state.product.id }}
                    onUploadSuccess={() => this.getAllProductInfo(this.state.product!.id)}
                    apiRoute="filetransfers/upload/product/image"
                  />
                </div>
                {this.state.product.imageURL ? (
                  <div style={{ flex: "1 1 0" }}>
                    Delete image
                    <hr />
                    <button onClick={this.deleteProductImage}>Delete</button>
                    {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
                  </div>
                ) : null}
              </div>
            </div>

            <div style={{ flex: "1 1 0" }}>
              <h3>PDF</h3>
              {this.state.product.pdfURL ? (
                <div>
                  <a href={this.state.product.pdfURL} rel="noopener noreferrer" target={"_blank"}>
                    View PDF
                  </a>
                  <br />
                  <br />
                </div>
              ) : null}

              <div style={this.state.product.pdfURL ? { display: "flex" } : undefined}>
                <div style={this.state.product.pdfURL ? { flex: "1 1 0", paddingRight: "25px" } : undefined}>
                  <PDFUpload
                    otherDataToSend={{ product_id: this.state.product.id }}
                    onUploadSuccess={() => this.getAllProductInfo(this.state.product!.id)}
                    apiRoute="filetransfers/upload/product/pdf"
                  />
                </div>
                {this.state.product.pdfURL ? (
                  <div style={{ flex: "1 1 0" }}>
                    Delete PDF
                    <hr />
                    <button onClick={this.deleteProductPDF}>Delete</button>
                    {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
                  </div>
                ) : null}
              </div>

              <div>
                <h3>External Site</h3>
                {this.state.product.externalURL ? (
                  <div>
                    <a href={this.state.product.externalURL} rel="noopener noreferrer" target={"_blank"}>
                      {`${this.state.product.externalURL.toString().substring(0, 40)}...`}
                    </a>
                    <br />
                    <br />
                  </div>
                ) : null}
                Enter new site
                <hr />
                <input onChange={this.handleExternalURL} placeholder={"New site URL"} />
                <button onClick={(e) => this.updateProduct(e, "externalURL", this.state.product!.externalURL)}>Update</button>
              </div>
            </div>
          </div>

          <br />
          <br />

          {this.isEligibleForClose() || this.state.product.closed ? <h3>Other</h3> : null}

          {this.isEligibleForClose() ? <button onClick={this.closeProduct}>Close Product</button> : null}
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
          {this.state.product.closed ? (
            <div>
              This is product is closed. &nbsp; <button onClick={this.openProduct}>Open Product</button>
              {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
            </div>
          ) : null}

          <br />
          <br />
        </div>
      </div>
    )
  }
}

export default Product
