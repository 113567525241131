import React, { Component } from "react"
import logo_marchand from "./media/logo_marchand.png"
import server_error from "./media/server_error.svg"
import logo_montgomery from "./media/logo_montgomery.png"
import chrome_future from "./media/chrome_future.gif"
import "./App.css"

//     custom npm packages
// for MainMenu
import { Accordion, AccordionItem } from "react-sanfona"
// for login/authorization
import firebase from "firebase/app"
import "firebase/auth"

import * as Dialog from "./components/dialog/Dialog"
import merge from "lodash.merge"
import { fireAxios, setDebugMode } from "./components/helpers"
import { MENU_NESTING, DEFAULT, subItem } from "./menuStructure"

import ContextMenu from "./components/edit/ContextMenu"
import LoadingSpinner from "./components/LoadingSpinner"

type renderType = {
  [index: string]: {
    readonly: boolean
    component: React.ReactNode
    currentTitle: string
    [index: string]: any
  }
}

type initState = {
  title: string
  showMainMenu: boolean
  linkClicked: string
  childId: number
  authenticated: boolean
  quickbooksLogin?: string
  loading: boolean
  email: string
  password: string
  renderedComponents: renderType
  dialog: Dialog.props
  user: user
  preventNav: boolean
  currentTitle?: string
}

type props = {
  serverError: { error: any; timestamp?: Date }
}

class App extends Component<props, initState> {
  state: initState = {
    title: "Montgomery",
    showMainMenu: true,
    linkClicked: "",
    childId: 0,
    authenticated: false,
    loading: true,
    email: "",
    password: "",
    renderedComponents: DEFAULT,
    dialog: null,
    user: {} as user,
    preventNav: false,
    currentTitle: "",
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        // do nothing
      } else {
        return "Unsaved changes will be lost."
      }
    }

    let temp = localStorage.getItem("newTabState")
    let newTabData = temp ? JSON.parse(temp) : undefined

    if (newTabData) {
      this.setState(
        {
          linkClicked: newTabData.link,
          childId: newTabData.id,
          showMainMenu: !newTabData.link, // don't show main menu when navigating to a new link from a new tab
        },
        () =>
          this.authenticate(() => {
            this.switchComponents(this.state.linkClicked)
          })
      )

      localStorage.removeItem("newTabState")
    } else {
      this.authenticate()
    }
  }

  authenticate = (callback?: () => void) => {
    this.setState({ loading: true, authenticated: false }, () => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          fireAxios({ method: "post", url: "users/details", data: { email: user.email } }, (response: myAny) => {
            setDebugMode(response && response.data && response.data.debug_mode)
            this.setState(
              {
                authenticated: true,
                loading: false,
                user: response.data,
                linkClicked: this.state.linkClicked ? this.state.linkClicked : response.data.landing_page,
              },
              () => {
                this.authenticateQB(() => {
                  this.switchComponents(this.state.linkClicked)
                })
              }
            )
          })
        } else {
          this.setState({ authenticated: false, loading: false }, callback)
        }
      })
    })
  }

  authenticateQB = (callback: () => void) => {
    fireAxios(
      {
        url: "quickbooks/verify",
        method: "post",
        data: {
          url: window.location.href,
        },
      },
      (response) => {
        if (response.data) {
          // token was not verified, need to login to quickbooks
          this.setState(
            {
              loading: false,
              quickbooksLogin: response.data,
            },
            callback
          )
        } else {
          // token verified
          this.setState(
            {
              loading: false,
              quickbooksLogin: undefined,
            },
            () => {
              if (document.location.pathname !== "/") {
                document.location.href = "/"
              }
              callback()
            }
          )
        }
      }
    )
  }

  setTitle = (title: string) => {
    this.setState(
      (prevState) => {
        let prevComponent = prevState.renderedComponents ? prevState.renderedComponents[this.state.linkClicked] : null
        if (title) {
          return {
            ...prevState,
            title: title,
            renderedComponents: merge(prevState.renderedComponents, {
              [this.state.linkClicked]: {
                currentTitle: title,
              },
            }),
          }
        } else {
          return {
            ...prevState,
            title: prevComponent ? prevComponent.currentTitle : "",
          }
        }
      },
      () => {
        document.title = this.state.title || "Montgomery"
      }
    )
  }

  showDialog = (props: Dialog.props) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  handleMenuClick = () => {
    this.setState({
      showMainMenu: !this.state.showMainMenu,
    })
  }

  handleLinkClick = (e: myAny) => {
    let clickedLink = e.target.id

    if (clickedLink === this.state.linkClicked) {
      return
    }
    this.setState(
      {
        linkClicked: e.target.id,
        childId: 0,
      },
      () => this.switchComponents(this.state.linkClicked)
    )
  }

  getNavHandle = () => {
    return this.state.preventNav
  }

  handlePreventNav = (bool: boolean) => {
    this.setState({
      preventNav: bool,
    })
  }

  handleInputChange = (e: myAny) => {
    if (e.target.name === "email") {
      this.setState({ email: e.target.value })
    } else if (e.target.name === "password") {
      this.setState({ password: e.target.value })
    } else {
      console.log("Invalid input.")
    }
  }

  attemptLogin = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((_user) => {
        this.setState({ authenticated: true, password: "" })
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  handleEnterKey = (e: myAny) => {
    if (e.key === "Enter") {
      this.attemptLogin()
    }
  }

  resetRefresh = (subItem: subItem) => {
    this.resetComponent(subItem.link, this.hideDialog)
  }

  resetComponent = (link: string, callback: () => void) => {
    this.setState(
      (prevState) => {
        delete prevState.renderedComponents[link]
        return {
          ...prevState,
          renderedComponents: prevState.renderedComponents,
        }
      },
      () => {
        if (this.state.linkClicked === link) {
          this.switchComponents(this.state.linkClicked, () => {
            callback && callback()
          })
        }
        callback && callback()
      }
    )
  }

  switchComponents = (link: string | null, callback?: () => void) => {
    if (!link) {
      return null
    }

    let propsToSend: appProps = {
      user: this.state.user,
      setAppTitle: this.setTitle,
      disabled: false,
    }

    if (this.state.childId) {
      Object.assign(propsToSend, { id: this.state.childId })
    }

    const userAccess = this.state.user.access.find((x: myAny) => x.component_link === link)
    const userPermissions = this.state.user.permissions
    let linkConst: subItem | undefined

    for (var i = 0; i < MENU_NESTING.length; i++) {
      linkConst = MENU_NESTING[i].subItems.find((x) => x.link === link)
      if (linkConst) {
        break
      }
    }

    if (!linkConst) {
      return null
    }

    const accessType: boolean | string = this.confirmAccess(userAccess, userPermissions, linkConst, null)

    if (!accessType) {
      return null
    }

    const readonly = accessType === "readonly"

    if (readonly) {
      propsToSend.disabled = true
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        title:
          prevState.renderedComponents && prevState.renderedComponents[linkConst!.link]
            ? prevState.renderedComponents[linkConst!.link].currentTitle
            : "",
        renderedComponents: prevState.renderedComponents
          ? Object.keys(prevState.renderedComponents).find((x) => x === linkConst!.link)
            ? prevState.renderedComponents
            : merge(prevState.renderedComponents, {
                [linkConst!.link]: {
                  readonly: readonly,
                  component: React.cloneElement(linkConst!.component as React.ReactElement, { ...propsToSend, key: linkConst!.link }),
                },
              })
          : prevState.renderedComponents,
      }),
      () => {
        this.setTitle("")
        callback && callback()
      }
    )
  }

  confirmAccess = (access: myAny, permissions: myAny, subMenuConst: myAny, renderingMenu: myAny) => {
    // hide from menu rendering
    if (renderingMenu && subMenuConst.hidden) {
      return false
    }
    // if subMenu is set to 'access' everyone can access
    // or if admin, can access anything
    if ((subMenuConst && subMenuConst.access) || (permissions && permissions.admin)) {
      return true
      // otherwise, check if access is granted and/or is readonly
    } else if (access && subMenuConst && access.component_link === subMenuConst.link && access.access) {
      return access.readonly ? "readonly" : true
      // if viewing from new tab but no access is granted, readonly
    } else if (this.state.childId && !renderingMenu) {
      return "readonly"
    } else {
      return false
    }
  }

  isGoogleChrome = (): boolean => {
    const isChromium = window.chrome
    const winNav = window.navigator
    const vendorName = winNav.vendor
    // @ts-ignore
    const isOpera = typeof window.opr !== "undefined"
    const isIEedge = winNav.userAgent.indexOf("Edge") > -1
    const isIOSChrome = winNav.userAgent.match("CriOS")

    if (isIOSChrome) {
      // is Google Chrome on iOS
    }

    return isChromium !== null && typeof isChromium !== "undefined" && vendorName === "Google Inc." && !isOpera && !isIEedge
  }

  render() {
    if (!this.isGoogleChrome()) {
      return (
        <div style={{ padding: "50px", textAlign: "center" }}>
          <img style={{ width: "30vw", maxWidth: "400px" }} src={logo_montgomery} alt="logo" />
          <br />
          <br />
          <img src={chrome_future} style={{ width: "50%", maxWidth: "500px", boxShadow: "0px 0px 30px #000000" }} alt="gif" />
          <br />
          <br />
          <div style={{ color: "white" }}>
            Please run Montgomery on{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/intl/en_ca/chrome/" style={{ color: "white" }}>
              Google Chrome
            </a>
            .
          </div>
        </div>
      )
    }

    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.quickbooksLogin) {
      return (
        <div style={{ padding: "20px", textAlign: "center", color: "white" }}>
          <h1>Quickbooks</h1>
          <div>
            Quickbooks has disconnected... please have your accountant login to continue.
            <br />
            <br />
            <a style={{ color: "white" }} href={this.state.quickbooksLogin}>
              {this.state.quickbooksLogin.slice(0, 100)}...
            </a>
          </div>
        </div>
      )
    }

    if (!this.state.authenticated) {
      return (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <div>
            <img style={{ width: "20vw", maxWidth: "300px" }} src={logo_marchand} alt="logo" />
            <br />
            <img style={{ width: "30vw", maxWidth: "400px" }} src={logo_montgomery} alt="logo" />
          </div>
          <br />
          <div>
            <div style={{ color: "white" }}>You must login...</div>
            <br />
            <input onChange={(e) => this.handleInputChange(e)} onKeyPress={this.handleEnterKey} name="email" placeholder="email" type="email" />
            <br />
            <input
              onChange={(e) => this.handleInputChange(e)}
              onKeyPress={this.handleEnterKey}
              name="password"
              placeholder="password"
              type="password"
            />
            <br />
            <br />
            <button onClick={this.attemptLogin}>Login</button>
          </div>
        </div>
      )
    }

    if (!this.state.user.permissions) {
      return <LoadingSpinner />
    }

    return (
      <div className="App">
        <div className={this.state.showMainMenu ? "MainMenu-open MainMenu" : "MainMenu-close MainMenu"}>
          <Accordion className="content" allowMultiple={true}>
            {MENU_NESTING.map((item) => {
              if (
                this.state.user.permissions.admin ||
                item.subItems.find((sub) =>
                  this.confirmAccess(
                    this.state.user.access.find((x: myAny) => {
                      return x.component_link === sub.link
                    }),
                    this.state.user.permissions,
                    sub,
                    true
                  )
                )
              ) {
                return (
                  <AccordionItem className="menuMainItem" title={item.mainItem} key={item.mainItem}>
                    {item.subItems.map((subItem) => {
                      if (
                        this.state.user &&
                        this.confirmAccess(
                          this.state.user.access &&
                            this.state.user.access.find((x: myAny) => {
                              return x.component_link === subItem.link
                            }),
                          this.state.user.permissions,
                          subItem,
                          true
                        )
                      ) {
                        let selected = subItem.link === this.state.linkClicked
                        let loaded = !!this.state.renderedComponents[subItem.link]
                        return (
                          <ContextMenu
                            key={subItem.link}
                            menuItems={[{ name: "Reset " + subItem.name, onClick: () => this.resetRefresh(subItem) }]}
                            elementType="span"
                          >
                            <span
                              className="menuLink"
                              id={subItem.link}
                              onClick={this.handleLinkClick}
                              style={{ fontWeight: selected ? "bold" : undefined, color: selected ? "white" : undefined }}
                            >
                              {loaded ? "~ " : ""}
                              {subItem.name}
                            </span>
                          </ContextMenu>
                        )
                      }
                      return null
                    })}
                  </AccordionItem>
                )
              }
              return null
            })}
          </Accordion>
        </div>

        <div className={this.state.showMainMenu ? "main-content-open" : "main-content-close"}>
          <header className="App-header">
            <div onClick={this.handleMenuClick} className={this.state.showMainMenu ? "hamburger is-active" : "hamburger"} id="hamburger-10">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
            <img src={logo_marchand} className="App-logo" alt="Company Logo" />
            {this.props.serverError.error ? (
              <img
                src={server_error}
                onClick={() =>
                  this.showDialog({
                    title: "Internal Server Error Details",
                    type: "alert",
                    message: this.props.serverError.timestamp?.toString() + "\n\n" + JSON.stringify(this.props.serverError.error),
                    handleResponse: () => this.hideDialog(),
                    allowHideDialog: () => this.hideDialog(),
                  })
                }
                className="App-server-error"
                alt="Server Error"
              />
            ) : null}
            <div className="App-title">{this.state.title}</div>
            <div className="App-program-name">
              <img src={logo_montgomery} className="App-program-logo" alt="Monty Logo" />
            </div>
          </header>

          <div className="body-content">
            {this.state.dialog ? <Dialog.Dialog {...this.state.dialog} /> : null}
            {this.state.linkClicked && Object.keys(this.state.renderedComponents || {}).length > 0
              ? Object.keys(this.state.renderedComponents || {}).map((key) => (
                  <fieldset
                    disabled={this.state.renderedComponents ? this.state.renderedComponents[key].readonly : false}
                    style={{
                      border: "none",
                      margin: "0px",
                      padding: "0px",
                      display: this.state.linkClicked === key ? "block" : "none",
                    }}
                    key={key}
                  >
                    {this.state.renderedComponents ? this.state.renderedComponents[key].component : null}
                  </fieldset>
                ))
              : null}
          </div>
        </div>
      </div>
    )
  }
}

export default App
