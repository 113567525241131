import React, { Component } from "react"

// import child components / helpers
import LoadingSpinner from "../../LoadingSpinner"
import OpenToBuyDetail from "./OpenToBuyDetail"
import { fireAxios, toCurrency } from "../../helpers"

// import required libraries
import shortid from "shortid"

const title = "Open to Buy"

type props = {}

type order = {
  order_id: number
  order_product_id: number
  quantity: number
}

type warehouse = {
  id: number
  name: string
  qty_required: number
  orders: Array<order>
  quantity?: number
  qty_error_style?: React.CSSProperties
  [index: string]: any
}

type product = {
  unique_id?: string
  id: number
  os_id: number
  pur_mult: number
  quantity: number
  cost: number
  product_line: string
  product_num: string
  description: string
  vendor_cost: number
  warehouses: Array<warehouse>
  mult_error_style?: React.CSSProperties
}

type vendor = {
  id: number
  name: string
  total_order_value: number
  product_line: string
  min_order_val: number
  min_prepaid_val: number
  first_date: string
  products: Array<product>
}

type state = {
  loading: boolean
  openVendors: Array<vendor>
  toDetail: boolean
  selectedDetail?: vendor
  searchDepth: number
}

class OpenToBuy extends Component<props & appProps, state> {
  state: state = {
    loading: false,
    openVendors: [],
    toDetail: false,
    selectedDetail: undefined,
    searchDepth: 0,
  }

  componentDidMount() {
    // set app title if called, otherwise it's probably
    // being called as a child to a component other than App.js
    if (this.props.setAppTitle) {
      this.props.setAppTitle(title)
    }

    // get products that need to be purchased
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "purchaseOrders/opentobuy" }, (response) => {
        response.data.forEach((vendor: vendor) => {
          vendor.products.forEach((prod: product) => (prod.unique_id = shortid.generate()))
        })
        this.setState({
          openVendors: response.data,
          loading: false,
        })
      })
    })
  }

  toProducts = (detail: vendor) => {
    this.setState({
      toDetail: true,
      selectedDetail: detail,
    })
  }

  backToOpenToBuy = () => {
    this.setState(
      {
        toDetail: false,
        selectedDetail: undefined,
      },
      () => {
        this.props.setAppTitle && this.props.setAppTitle(title)
      }
    )
  }

  handleSearchDepth = (depth: number) => {
    this.setState({
      searchDepth: depth,
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toDetail) {
      return (
        <div>
          {this.state.searchDepth > 0 ? null : (
            <div>
              <button onClick={this.backToOpenToBuy}>Back to {title}</button>
              <br />
              <br />
            </div>
          )}
          <OpenToBuyDetail {...this.props} detail={this.state.selectedDetail as vendor} />
        </div>
      )
    }

    var openVendors: Array<JSX.Element> = []
    var totalCost = 0
    this.state.openVendors.forEach((vend, i) => {
      var cost = vend.total_order_value
      totalCost += cost
      openVendors.push(
        <tr className="row" onClick={() => this.toProducts(vend)} key={i}>
          <td>{vend.product_line}</td>
          <td>{vend.name}</td>
          <td>{toCurrency(cost)}</td>
          <td>{toCurrency(vend.min_order_val)}</td>
          <td>{toCurrency(vend.min_prepaid_val)}</td>
          <td>{vend.first_date ? vend.first_date.substring(0, 10) : ""}</td>
        </tr>
      )
    })

    return (
      <div>
        <table className="searchResults">
          <tbody>
            <tr>
              <th>Line</th>
              <th>Vendor</th>
              <th>Open Value</th>
              <th>Min Order</th>
              <th>Min Prepaid</th>
              <th>First Date</th>
            </tr>
            {openVendors}
          </tbody>
        </table>
        <br />
        Total Open Value: {toCurrency(totalCost)}
      </div>
    )
  }
}
export default OpenToBuy
