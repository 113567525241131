import React, { Component } from "react"

// import child components / helpers
import Search from "../search/Search"
import Vendor from "./Vendor"

class VendorSearch extends Component<appProps> {
  render() {
    return (
      <Search
        title={"Vendor"}
        closable={true}
        preventClosedUse={true}
        queries={[
          { name: "Vendor Id", value: "", databaseSearch: "id", restriction: "non-zero-unsigned-int" },
          { name: "Product Line", value: "", databaseSearch: "product_line", restriction: "product-line" },
          { name: "Name", value: "", databaseSearch: "name" },
          { name: "Phone Number", value: "", databaseSearch: "phone_num", restriction: "phone-number" },
        ]}
        resultColumns={[
          { name: "Vendor Id", databaseField: "id" },
          { name: "Product Line", databaseField: "product_line" },
          { name: "Name", databaseField: "name" },
          { name: "Phone Number", databaseField: "phone_num" },
        ]}
        apiEndpoint={"vendors/search"}
        children={<Vendor />}
        {...this.props}
      />
    )
  }
}

export default VendorSearch
