import React, { Component } from "react"

// import child components / helpers
import ProductSearch from "../products/ProductSearch"
import LoadingSpinner from "../LoadingSpinner"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import ControlledInput from "../edit/ControlledInput"

import shortid from "shortid"
import { fireAxios } from "../helpers"

type props = {}

type warehouse = {
  id: number
  name: string
}

type salesrep = {
  id: number
  first_name: string
  last_name: string
}

type product = {
  unique_id: string
  qty_to_return: number
  qty_eligible: number
  selling_price: number
  invoice_id: number
  validating: boolean
  valid: boolean
  return_type: string
  [index: string]: any
}

type state = {
  title: string
  toChooseWarehouse: boolean
  warehouse?: warehouse
  takenBy?: salesrep
  toChooseProduct: boolean
  products: Array<product>
  print: boolean
  download: boolean
  submitted: boolean
  newInvoiceNum?: number
  [index: string]: any
}

const initialState: state = {
  title: "Order Return",
  toChooseWarehouse: false,
  warehouse: undefined,
  takenBy: undefined,
  toChooseProduct: false,
  products: [],
  print: true,
  download: false,
  submitted: false,
  newInvoiceNum: undefined,
}

class OrderReturn extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = {
      ...initialState,
      warehouse: this.props.user ? this.props.user.warehouse : undefined,
      takenBy: this.props.user ? this.props.user.salesrep : undefined,
    }

    if (this.props.setAppTitle) {
      this.props.setAppTitle(this.state.title)
    }
  }

  /*
  ███████ ███████  █████  ██████   ██████ ██   ██
  ██      ██      ██   ██ ██   ██ ██      ██   ██
  ███████ █████   ███████ ██████  ██      ███████
       ██ ██      ██   ██ ██   ██ ██      ██   ██
  ███████ ███████ ██   ██ ██   ██  ██████ ██   ██
  */

  handleSearchDepth = (depth: number) => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(depth)
    }
  }

  backFromSearch = () => {
    this.handleSearchDepth(0)
    this.setState({
      toChooseProduct: false,
      toChooseWarehouse: false,
    })
  }

  chooseWarehouse = () => {
    this.handleSearchDepth(1)
    this.setState({
      toChooseWarehouse: true,
    })
  }

  warehouseSearchResult = (result: warehouse) => {
    this.handleSearchDepth(0)
    this.setState({
      toChooseWarehouse: false,
      warehouse: result,
    })
  }

  chooseProduct = () => {
    this.handleSearchDepth(1)
    this.setState({
      toChooseProduct: true,
    })
  }

  productSearchResult = (result: product) => {
    this.handleSearchDepth(0)

    let newProducts = JSON.parse(JSON.stringify(this.state.products))

    // setup product
    result.unique_id = shortid.generate()
    result.qty_to_return = 1
    result.qty_eligible = 0
    result.selling_price = 0
    result.invoice_id = 0
    result.validating = false
    result.valid = false
    result.return_type = "for_stock"

    newProducts.push(result)

    this.setState({
      toChooseProduct: false,
      products: newProducts,
    })
  }

  /*
  ██ ███    ██ ██████  ██    ██ ████████ ███████
  ██ ████   ██ ██   ██ ██    ██    ██    ██
  ██ ██ ██  ██ ██████  ██    ██    ██    ███████
  ██ ██  ██ ██ ██      ██    ██    ██         ██
  ██ ██   ████ ██       ██████     ██    ███████
  */

  deleteProduct = (uniqueId: string) => {
    let newProducts: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let foundIndex = newProducts.findIndex((x) => x.unique_id === uniqueId)

    newProducts.splice(foundIndex, 1)

    this.setState({
      products: newProducts,
    })
  }

  handleProductInput = (name: string, value: string | number, uniqueId: string) => {
    let newProducts: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let foundProd = newProducts.find((x) => x.unique_id === uniqueId)

    if (foundProd) {
      foundProd[name] = value
    }

    this.setState({
      products: newProducts,
    })
  }

  handlePrintCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newState: { [index: string]: any } = {}
    newState[e.target.name] = e.target.checked
    this.setState(newState)
  }

  /*
  ██████  ███████  ██████  ██    ██ ███████ ███████ ████████ ███████
  ██   ██ ██      ██    ██ ██    ██ ██      ██         ██    ██
  ██████  █████   ██    ██ ██    ██ █████   ███████    ██    ███████
  ██   ██ ██      ██ ▄▄ ██ ██    ██ ██           ██    ██         ██
  ██   ██ ███████  ██████   ██████  ███████ ███████    ██    ███████
                      ▀▀
  */

  validate = (uniqueId: string) => {
    let newProducts: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let foundProduct = newProducts.find((x) => x.unique_id === uniqueId)

    if (foundProduct) {
      foundProduct.validating = true
    } else {
      return
    }

    let dataToSubmit = {
      invoice_id: foundProduct.invoice_id,
      product_id: foundProduct.id,
    }

    this.setState(
      {
        products: newProducts,
      },
      () => {
        fireAxios({ method: "post", url: "returnorders/validate", data: dataToSubmit }, (response) => {
          foundProduct!.validating = false
          foundProduct!.qty_eligible = response.data.qty_eligible
          foundProduct!.selling_price = response.data.selling_price

          this.setState({
            products: newProducts,
          })
        })
      }
    )
  }

  invoice = () => {
    let dataToSubmit = {
      warehouse_id: this.state.warehouse ? this.state.warehouse.id : null,
      taken_by_id: this.state.takenBy ? this.state.takenBy.id : null,
      products: this.state.products,
      print: this.state.print,
      download: this.state.download,
    }

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "returnorders/invoice", data: dataToSubmit }, (response) => {
        if (response.data.error) {
          this.setState({
            loading: false,
            error: response.data.error,
          })
        } else {
          this.setState({
            loading: false,
            submitted: true,
            error: false,
            newInvoiceNum: response.data,
          })
        }
      })
    })
  }

  /*
  ██████  ███████ ███    ██ ██████  ███████ ██████
  ██   ██ ██      ████   ██ ██   ██ ██      ██   ██
  ██████  █████   ██ ██  ██ ██   ██ █████   ██████
  ██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
  ██   ██ ███████ ██   ████ ██████  ███████ ██   ██
  */

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseWarehouse) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create Order</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseSearchResult} />
        </div>
      )
    }

    if (this.state.toChooseProduct) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create Order</button>
          <br />
          <br />
          <ProductSearch returnResult={this.productSearchResult} />
        </div>
      )
    }

    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    return (
      <div>
        <div style={{ textAlign: "center" }}>
          Taking return as <b>{this.state.takenBy ? `${this.state.takenBy.first_name} ${this.state.takenBy.last_name}` : `No Salesrep`}</b>
        </div>
        {this.state.submitted ? (
          <div>
            <span style={{ color: "#87dc9e" }}>Successfully invoiced.</span> New invoice: #{this.state.newInvoiceNum}
          </div>
        ) : null}
        {this.state.error ? (
          <div>
            <span style={{ color: "#ff8180" }}>Error invoicing.</span> {this.state.error}
          </div>
        ) : null}
        <fieldset style={{ borderLeft: "none", borderBottom: "none", borderRight: "none" }} disabled={this.state.submitted}>
          <div>
            <div style={headerStyle}>Sales branch:</div>
            {this.state.warehouse ? this.state.warehouse.name : ""}&nbsp;
            <button onClick={this.chooseWarehouse}>Edit</button>
          </div>
          <div>
            <div style={headerStyle}>Products:</div>
            {this.state.products.length > 0 ? (
              <table className="searchResults">
                <tbody>
                  <tr>
                    <th>Product</th>
                    <th>Quantity To Return</th>
                    <th>Matching Invoice #</th>
                    <th>Eligible Quantity</th>
                    <th>Original Sell Price</th>
                    <th>Return Type</th>
                    <th></th>
                  </tr>
                  {this.state.products.map((product) => {
                    let color = product.qty_to_return <= product.qty_eligible && product.qty_to_return > 0 ? "#87dc9e" : "#ff8180"

                    return (
                      <tr className="row" key={product.unique_id} style={{ cursor: "default", backgroundColor: color }}>
                        <td>
                          {product.product_line} - <b>{product.product_num}</b>
                        </td>
                        <td>
                          <ControlledInput
                            placeholder={"Qty"}
                            name={"qty_to_return"}
                            value={product.qty_to_return}
                            style={{ width: "130px" }}
                            restriction={"non-zero-unsigned-int"}
                            handleChange={(n, v) => this.handleProductInput(n, v, product.unique_id)}
                          />
                        </td>
                        <td>
                          <ControlledInput
                            placeholder={"Invoice #"}
                            name={"invoice_id"}
                            value={product.invoice_id}
                            style={{ width: "80px" }}
                            restriction={"non-zero-unsigned-int"}
                            handleChange={(n, v) => this.handleProductInput(n, v, product.unique_id)}
                          />
                          {product.validating ? <LoadingSpinner /> : <button onClick={() => this.validate(product.unique_id)}>Validate</button>}
                        </td>
                        <td>{product.qty_eligible}</td>
                        <td>{product.selling_price}</td>
                        <td>
                          <select
                            onChange={(e) => this.handleProductInput(e.target.name, e.target.value, product.unique_id)}
                            name="return_type"
                            value={product.return_type}
                          >
                            <option value="for_stock">For Stock</option>
                            <option value="defective">Defective</option>
                          </select>
                        </td>
                        <td>{product.validating ? null : <button onClick={() => this.deleteProduct(product.unique_id)}>Delete</button>}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ) : null}
            <br />
            <button onClick={this.chooseProduct}>Add Product</button>
          </div>
          <div>
            <div style={headerStyle}>Invoice</div>
            <table>
              <tbody>
                <tr>
                  <td style={{ paddingRight: "15px" }}>
                    <button onClick={() => this.invoice()}>Invoice</button>
                  </td>
                  <td style={{ paddingLeft: "15px", borderLeft: "1px solid black" }}>
                    Print? <input type="checkbox" onChange={(e) => this.handlePrintCheck(e)} name={"print"} checked={this.state.print} />
                    <br />
                    Download? <input type="checkbox" onChange={(e) => this.handlePrintCheck(e)} name={"download"} checked={this.state.download} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </fieldset>
      </div>
    )
  }
}

export default OrderReturn
