import React, { Component } from "react"

import LoadingSpinner from "../LoadingSpinner"
import CustomerSearch from "./CustomerSearch"
import ControlledInput from "../edit/ControlledInput"

import { fireAxios } from "../helpers"

type props = {
  counter: boolean
}

type state = {
  loading: boolean
  customer?: customer
  payment_kinds?: Array<{
    id: number
    kind: string
  }>
  selectedPaymentKind?: number
  requireChequeNum?: boolean
  cheque_num?: string
  toChooseCustomer?: boolean
  amount?: number
  submitted: boolean
  journal_id?: string
  reverted?: boolean
  comment?: string
}

type customer = {
  id: number
  customer_num: string
  company_name: string
}

const initialState: state = {
  loading: false,
  submitted: false,
  customer: undefined,
  toChooseCustomer: undefined,
  amount: undefined,
  journal_id: undefined,
  reverted: false,
  cheque_num: undefined,
  selectedPaymentKind: undefined,
  comment: undefined,
}

const title = "Deposit"

class Deposit extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState
    this.props.setAppTitle && this.props.setAppTitle((this.props.counter ? "Counter " : "") + title)
  }

  componentDidMount() {
    this.getPaymentKinds()
  }

  getPaymentKinds = () => {
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "get",
          url: "paymentkinds/all",
        },
        (response) => {
          this.setState({
            payment_kinds: response.data,
            selectedPaymentKind: response.data[0].id,
            loading: false,
          })
        }
      )
    })
  }

  backFromSearch = () => {
    this.setState({
      toChooseCustomer: false,
    })
  }

  toCustomerSearch = () => {
    this.setState({
      toChooseCustomer: true,
    })
  }

  customerSearchResult = (customer: customer) => {
    this.setState({
      customer: customer,
      toChooseCustomer: false,
    })
  }

  handleAmount = (value: number | string) => {
    this.setState({
      amount: parseFloat(value.toString()),
    })
  }

  handleChequeNum = (value: string) => {
    this.setState({
      cheque_num: value,
    })
  }

  handleComment = (value: string) => {
    this.setState({
      comment: value,
    })
  }

  handlePaymentKind = (value: number) => {
    this.setState({
      selectedPaymentKind: value,
      requireChequeNum: this.state.payment_kinds!.find((x) => x.id === value)!.kind === "Cheque",
    })
  }

  reset = () => {
    this.setState(initialState)
  }

  revert = () => {
    this.setState({ loading: false }, () => {
      fireAxios(
        {
          method: "post",
          url: "deposits/revert",
          data: {
            journal_id: this.state.journal_id,
          },
        },
        () => {
          this.setState({ loading: false, reverted: true })
        }
      )
    })
  }

  submit = () => {
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "deposits/create",
          data: {
            customer: this.state.customer,
            warehouse_id: this.props.user?.warehouse_id,
            payment_kind_id: this.state.selectedPaymentKind,
            amount: this.state.amount,
            comment: this.state.comment,
            counter_deposit: this.props.counter,
            cheque_num: this.state.cheque_num,
          },
        },
        (response) => {
          this.setState({ loading: false, submitted: true, journal_id: response.data })
        }
      )
    })
  }

  render() {
    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseCustomer) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <CustomerSearch returnResult={this.customerSearchResult} />
        </div>
      )
    }

    if (!this.state.customer) {
      return (
        <div>
          To begin, choose a customer: <button onClick={this.toCustomerSearch}>Choose Customer</button>
        </div>
      )
    }

    if (this.state.reverted) {
      return (
        <div>
          Sucessfully reverted <b>${this.state.amount}</b> from customer <b>{this.state.customer.customer_num}</b>
          <br />
          <br />
          <button onClick={this.reset}>New Deposit</button>
        </div>
      )
    }

    if (this.state.submitted) {
      return (
        <div>
          Sucessfully deposited <b>${this.state.amount}</b> to customer <b>{this.state.customer.customer_num}</b>
          <br />
          <br />
          <button onClick={this.reset}>New Deposit</button>
          <br />
          <br />
          <button onClick={this.revert}>Revert</button>
        </div>
      )
    }

    return (
      <div>
        <div style={headerStyle}>
          Deposit Towards: {this.state.customer.customer_num} - {this.state.customer.company_name}
          &nbsp;<button onClick={this.toCustomerSearch}>Edit</button>
        </div>
        <br />
        <br />
        <div>
          Deposit amount:{" "}
          <ControlledInput restriction={"unsigned-float"} handleChange={(_n, v) => this.handleAmount(v)} value={this.state.amount || ""} />
        </div>
        <br />
        <br />
        <div>
          Deposit Kind:
          <br />
          {this.state.payment_kinds ? (
            <select onChange={(e) => this.handlePaymentKind(parseInt(e.currentTarget.value, 10))}>
              {this.state.payment_kinds.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.kind}
                </option>
              ))}
            </select>
          ) : null}
        </div>
        <br />
        {this.state.requireChequeNum ? (
          <div>
            Cheque Number: <input onChange={(e) => this.handleChequeNum(e.currentTarget.value)} value={this.state.cheque_num} />
          </div>
        ) : null}
        <br />
        <div>
          Comment:
          <br />
          <textarea name="comment" onChange={(e) => this.handleComment(e.currentTarget.value)} value={this.state.comment || ""} />
        </div>
        <br />
        <br />
        <button onClick={this.submit}>Submit</button>
      </div>
    )
  }
}

export default Deposit
