import React, { Component } from "react"

import { regexStandards, restrictions, round2 } from "../helpers"

import shortid from "shortid"

type props = {
  restriction: restrictions
  handleChange: (name: string, value: string | number) => void
  updateValidity?: (unique_id: string, isValid: boolean, callback: () => void) => void
  required?: boolean
  isSearchInput?: boolean
} & React.HTMLProps<HTMLInputElement>

type state = {
  unique_id: string // for ability to track which inputs are/aren't valid
  stringEqv: string // without this, parseFloat will remove a trailing "." and the user can't seemlessly type decimals
}

class ControlledInput extends Component<props, state> {
  constructor(props: props) {
    super(props)
    this.state = {
      unique_id: shortid.generate(),
      stringEqv: "",
    }
  }

  componentDidUpdate(prevProps: props, prevState: state) {
    // manages display of input when value changes because of an external source to inputing directly in input
    if (this.props.value !== prevProps.value && this.props.value !== this.postParse(this.preParse(this.state.stringEqv))) {
      let displayValue = ""

      if (this.props.value) {
        if (this.props.restriction === "unsigned-float") {
          displayValue = round2(this.props.value.toString()).toString()
        } else {
          displayValue = this.preParse(this.props.value.toString())
        }
      } else {
        displayValue = prevState.stringEqv
      }

      this.setState({
        stringEqv: displayValue,
      })
    }
  }

  // converts as user is inputing
  preParse = (value: string) => {
    if (
      this.props.restriction === "province" ||
      this.props.restriction === "currency-code" ||
      this.props.restriction === "country-code" ||
      this.props.restriction === "product-line" ||
      this.props.restriction === "customer-num"
    ) {
      value = value.toUpperCase()
    } else if ((this.props.restriction === "unsigned-int" || this.props.restriction === "non-zero-unsigned-int") && !this.props.isSearchInput) {
      const tempNum = parseInt(value, 10)
      if (isNaN(tempNum)) {
        value = "0"
      } else {
        value = tempNum.toString()
      }
    }
    return value
  }

  // converts once user is done inputing, for the appropriate value to send to state of parent component
  postParse = (value: string) => {
    if (this.props.isSearchInput || !this.isValid()) return value

    let valueToPass: string | number

    if (this.props.restriction === "unsigned-int" || this.props.restriction === "non-zero-unsigned-int") {
      valueToPass = parseInt(value, 10)
    } else if (this.props.restriction === "unsigned-float") {
      valueToPass = parseFloat(parseFloat(value).toFixed(2))
    } else {
      valueToPass = value
    }

    if (typeof valueToPass === "number" && (value === undefined || value === null || isNaN(valueToPass))) {
      valueToPass = 0
    } else if (valueToPass === undefined || valueToPass === null) {
      valueToPass = ""
    }

    return valueToPass
  }

  isValid = () => {
    const isEmpty = this.props && (this.props.value === undefined || this.props.value === null || this.props.value.toString() === "")
    const value: string = this.state.stringEqv || (!isEmpty && this.props.value!.toString()) || ""

    if (this.props.required && isEmpty) {
      return false
    }

    if (this.props.isSearchInput && (value === "" || value === "%")) {
      return true
    }

    return regexStandards(this.props.restriction).test(value)
  }

  handleFocus = (e: React.FormEvent<HTMLInputElement>) => {
    e.currentTarget.select()
  }

  handleInput = (parentEvent: React.FormEvent<HTMLInputElement>, parentHandler: (name: string, value: string | number) => void) => {
    const name = parentEvent.currentTarget.name
    const value = parentEvent.currentTarget.value

    this.setState(
      {
        stringEqv: this.preParse(value),
      },
      () => {
        if (this.props.updateValidity) {
          this.props.updateValidity(this.state.unique_id, this.isValid(), () => {
            this.processInput(name, this.state.stringEqv, parentHandler)
          })
        } else {
          this.processInput(name, this.state.stringEqv, parentHandler)
        }
      }
    )
  }

  processInput = (name: string, value: string, parentHandler: (name: string, value: string | number) => void) => {
    if (value === "" || value === "0" || value === undefined || value === null || this.isValid() || this.props.isSearchInput) {
      parentHandler && parentHandler(name, this.postParse(value))
    }
  }

  render() {
    // destructure custom props from props that are for HTMLLInputs
    let { restriction, updateValidity, required, isSearchInput, handleChange, ...inputProps } = this.props

    let title = ""

    if (restriction === "phone-number") {
      title = "123-123-1234x123"
    } else if (restriction === "customer-num") {
      title = "ABC123"
    } else if (restriction === "province" || restriction === "currency-code") {
      title = "XY"
    } else if (restriction === "product-line") {
      title = "AB1"
    } else if (restriction === "country-code") {
      title = "ABC"
    }

    return (
      <input
        {...inputProps}
        title={title}
        onFocus={this.handleFocus}
        value={this.state.stringEqv || this.props.value}
        style={{ ...this.props.style, border: !this.isValid() ? "2px solid red" : undefined }}
        onChange={(e) => this.handleInput(e, this.props.handleChange)}
      />
    )
  }
}
export default ControlledInput
