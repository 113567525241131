import React, { Component } from "react"

// import child components / helpers
import LoadingSpinner from "../LoadingSpinner"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import ProductSearch from "./ProductSearch"
import ControlledInput from "../edit/ControlledInput"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import { fireAxios } from "../helpers"

// import required libraries
import shortid from "shortid"
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"
import arrayMove from "array-move"

type props = {}

type product = {
  id: number
  unique_id?: string
  submitted: boolean
  product_line: string
  product_num: string
  description: string
  actual_qty_available?: number
  qty_available: number
  qty_onhand: number
}

type state = {
  loading: boolean
  toChooseWarehouse: boolean
  warehouse?: {
    id: number
    name: string
  }
  toChooseProduct: boolean
  products: Array<product>
  dialog: dialogProps
}

const initialState: state = {
  loading: false,
  toChooseWarehouse: false,
  warehouse: undefined,
  toChooseProduct: false,
  products: [],
  dialog: null,
}

const title = "Quantity Adjustments"

const DragHandle = SortableHandle(() => {
  const svgStyle = {
    cursor: "s-resize",
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" style={svgStyle}>
      <path d="M2 13.5h14V12H2v1.5zm0-4h14V8H2v1.5zM2 4v1.5h14V4H2z" />
    </svg>
  )
})

const SortableProduct = SortableElement(
  ({
    prod,
    handleActualQty,
    deleteProduct,
  }: {
    prod: product
    handleActualQty: (name: string, value: string | number, prod: product) => void
    deleteProduct: (prod: product) => void
  }) => {
    const difference = (prod.actual_qty_available || 0) - prod.qty_available
    const bgColor = prod.submitted ? "#87dc9e" : undefined

    return (
      <tr className="row" style={{ cursor: "default", background: bgColor }} key={prod.unique_id}>
        <td>
          <DragHandle />
        </td>
        <td>
          <b>
            {prod.product_line} - {prod.product_num}
          </b>{" "}
          {prod.description}
        </td>
        <td>{prod.qty_onhand}</td>
        <td>{prod.qty_available}</td>
        <td>
          <ControlledInput
            handleChange={(n, v) => handleActualQty(n, v, prod)}
            value={prod.actual_qty_available}
            restriction={"unsigned-int"}
            disabled={prod.submitted}
          />
        </td>
        <td>{difference}</td>
        <td>{prod.submitted ? null : <button onClick={() => deleteProduct(prod)}>Delete</button>}</td>
      </tr>
    )
  }
)

const SortableProductList = SortableContainer(
  ({
    products,
    handleActualQty,
    deleteProduct,
  }: {
    products: Array<product>
    handleActualQty: (name: string, value: string | number, prod: product) => void
    deleteProduct: (prod: product) => void
  }) => {
    return (
      <table className="searchResults">
        <tbody>
          <tr>
            <th></th>
            <th>Product</th>
            <th>Qty On Hand</th>
            <th>Qty Available</th>
            <th>Actual Qty Available</th>
            <th>Difference</th>
            <th></th>
          </tr>
          {products.map((prod, index) => {
            // generate a unique id for each detail so React can manage the state properly
            if (!prod.hasOwnProperty("unique_id")) {
              prod.unique_id = shortid.generate()
            }

            return <SortableProduct key={prod.unique_id} index={index} prod={prod} deleteProduct={deleteProduct} handleActualQty={handleActualQty} />
          })}
        </tbody>
      </table>
    )
  }
)

class QuantityAdj extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState
    this.props.setAppTitle && this.props.setAppTitle(title)
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.setState({
      products: arrayMove(this.state.products, oldIndex, newIndex),
    })
  }

  chooseWarehouse = () => {
    this.setState({
      toChooseWarehouse: true,
    })
  }

  warehouseSearchResult = (warehouse: any) => {
    this.setState({
      warehouse: warehouse,
      toChooseWarehouse: false,
    })
  }

  chooseProduct = () => {
    this.setState({
      toChooseProduct: true,
    })
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  productSearchResult = (product: any) => {
    let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let foundProd = newProds.find((x) => x.id === product.id)

    if (foundProd) {
      this.showDialog({
        type: "alert",
        title: "Can not add product",
        message: "You've already added this product.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (!this.state.warehouse) return

    // get additional information needed for the product
    this.setState({ loading: true }, () => {
      fireAxios(
        { method: "post", url: "products/adjDetails/", data: { product_id: product.id, warehouse_id: this.state.warehouse!.id } },
        (response) => {
          product.qty_onhand = response.data.qty_onhand
          product.qty_available = response.data.qty_available
          product.actual_qty_available = response.data.qty_available
          newProds.push(product)
          this.setState({
            loading: false,
            products: newProds,
            toChooseProduct: false,
          })
        }
      )
    })
  }

  deleteProduct = (product: product) => {
    const deleteIndex = this.state.products.findIndex((x) => x.unique_id === product.unique_id)
    let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    newProds.splice(deleteIndex, 1)

    this.setState({
      products: newProds,
    })
  }

  handleActualQty = (_name: string, value: string | number, product: product) => {
    let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let prodToChange = newProds.find((x) => x.unique_id === product.unique_id)

    if (!prodToChange) return

    prodToChange.actual_qty_available = parseInt(value ? value.toString() : "0", 10)

    this.setState({
      products: newProds,
    })
  }

  backFromSearch = () => {
    this.setState({
      toChooseProduct: false,
      toChooseWarehouse: false,
    })
  }

  reset = () => {
    this.setState(initialState)
  }

  submit = () => {
    let dataToSubmit: {
      comment: string
      warehouse_id: number
      products: Array<{
        product_id: number
        qty_diff: number
      }>
    } = {
      comment: "",
      warehouse_id: this.state.warehouse!.id,
      products: [],
    }

    if (
      this.state.products.some((prod) => {
        const actualQty = prod.actual_qty_available
        const qtyAvailable = prod.qty_available
        const difference = (actualQty || 0) - qtyAvailable

        if (prod.actual_qty_available === null || prod.actual_qty_available === undefined) {
          this.showDialog({
            type: "alert",
            title: "Quantity Error",
            message: "No new quantity entered in product: " + prod.product_num,
            handleResponse: this.hideDialog,
          })
          return true
        }

        if (qtyAvailable + difference < 0) {
          this.showDialog({
            type: "alert",
            title: "Error, adjusted stock exceeds current stock!",
            message: "Cannot adjust to less than quantity available in product: " + prod.product_num,
            handleResponse: this.hideDialog,
          })
          return true
        }

        if (difference === 0) {
          this.showDialog({
            type: "alert",
            title: "Quantity Error",
            message: "Product: " + prod.product_num + " has no difference.",
            handleResponse: this.hideDialog,
          })
          return true
        }

        if (!prod.submitted && this.state.warehouse) {
          dataToSubmit.products.push({
            product_id: prod.id,
            qty_diff: difference,
          })
        }
        return false
      })
    ) {
      return
    }

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "qtyadjustments/submit", data: dataToSubmit }, () => {
        let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))
        newProds.forEach((prod) => (prod.submitted = true))
        this.setState({
          products: newProds,
          loading: false,
        })
      })
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseProduct) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <ProductSearch returnResult={this.productSearchResult} />
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    if (this.state.toChooseWarehouse) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseSearchResult} />
        </div>
      )
    }

    if (!this.state.warehouse) {
      return (
        <div>
          To begin, choose a warehouse: <button onClick={this.chooseWarehouse}>Choose Warehouse</button>
        </div>
      )
    }

    return (
      <div>
        Warehouse: <b>{this.state.warehouse.name}</b>
        &nbsp;
        <button disabled={this.state.products.length > 0} onClick={this.chooseWarehouse}>
          Edit
        </button>
        <br />
        <br />
        <SortableProductList
          products={this.state.products}
          onSortEnd={this.onSortEnd}
          deleteProduct={this.deleteProduct}
          handleActualQty={this.handleActualQty}
          useDragHandle={true}
        />
        <br />
        <div>
          <button onClick={this.chooseProduct}>Add Product</button>
        </div>
        <br />
        <br />
        {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        {this.state.products.length > 0 && this.state.products.every((prod) => prod.submitted) ? (
          <div>
            <button onClick={this.reset}>New Adjustments</button>
          </div>
        ) : (
          <div>
            <button onClick={this.submit}>Submit Adjustments</button>
          </div>
        )}
      </div>
    )
  }
}

export default QuantityAdj
