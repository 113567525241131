import React, { Component } from "react"

// import child components / helpers
import Search from "../search/Search"

const propsToSend = {
  title: "Bin Location",
  queries: [{ name: "Location", value: "", databaseSearch: "location" }],
  resultColumns: [{ name: "Location", databaseField: "location" }],
  apiEndpoint: "binLocations/search",
}

class BinLocationSearch extends Component<appProps> {
  render() {
    return <Search {...propsToSend} {...this.props} />
  }
}

export default BinLocationSearch
