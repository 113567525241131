import React, { Component } from "react"

import BasicCreate from "../create/BasicCreate"
import Warehouse from "./Warehouse"
import WarehouseSearch from "./WarehouseSearch"

class CreateWarehouse extends Component {
  render() {
    return (
      <BasicCreate
        {...{
          title: "Warehouse",
          resultChild: <Warehouse />,
          inputs: [
            // required fields
            { value: "", required: true, name: "Name", databaseField: "name", inputType: "text" },
            { value: "", required: true, name: "Phone Number", databaseField: "phone_num", inputType: "text", restriction: "phone-number" },
            { value: "", required: true, name: "Address 1", databaseField: "address_1", inputType: "text" },
            { value: "", required: true, name: "City", databaseField: "city", inputType: "text" },
            { value: "", required: true, name: "Postal Code", databaseField: "postal_code", inputType: "text" },
            { value: "", required: true, name: "Province", databaseField: "province", inputType: "text", restriction: "province" },
            { value: "", required: true, name: "Country", databaseField: "country", inputType: "text" },
            // optional fields
            {
              value: "",
              required: false,
              name: "Defective Relation",
              databaseField: "defective_id",
              inputType: "search",
              searchName: "Warehouse",
              searchChild: <WarehouseSearch />,
              requiredSearchResult: "id",
              displaySearchResult: "name",
            },
            { value: "", required: false, name: "Address 2", databaseField: "address_2", inputType: "text" },
          ],
          endpoint: "warehouses/create",
        }}
        {...this.props}
      />
    )
  }
}

export default CreateWarehouse
