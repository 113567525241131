import React, { Component } from "react"

import BasicCreate from "../create/BasicCreate"

class CreateCustomerContact extends Component<appProps> {
  render() {
    return (
      <BasicCreate
        {...{
          title: "Contact",
          resultChild: false,
          inputs: [
            // required fields
            { value: "", required: true, name: "Name", databaseField: "name", inputType: "text" },
            { value: "", required: true, name: "Title", databaseField: "title", inputType: "text" },
            { value: "", required: true, name: "Email", databaseField: "email", inputType: "text", restriction: "email" },
            { value: "", required: true, name: "Phone Number", databaseField: "phone_num", inputType: "text", restriction: "phone-number" },
            // optional fields
            { value: "", required: false, name: "Fax Number", databaseField: "fax_num", inputType: "text", restriction: "phone-number" },
            { value: "", required: false, name: "Cell Number", databaseField: "cell_num", inputType: "text", restriction: "phone-number" },
            {
              value: "0",
              required: true,
              name: "Send Invoice",
              databaseField: "send_invoice",
              inputType: "select",
              options: [
                { value: "1", name: "Yes" },
                { value: "0", name: "No" },
              ],
            },
          ],
          endpoint: "customers/create/contact",
        }}
        {...this.props}
      />
    )
  }
}

export default CreateCustomerContact
