import React, { Component } from "react"

// import child child components / helpers
import LoadingSpinner from "../LoadingSpinner"
import SalesRepSearch from "../salesreps/SalesrepSearch"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import { fireAxios, toFileDate, dateObjToInputDate, inputDateToDateObj, download } from "../helpers"

type props = {}

type salesrep =
  | {
      first_name?: string
      last_name?: string
    }
  | false
  | undefined

type state = {
  loading: boolean
  dialog: dialogProps
  salesRep: salesrep
  beginDate?: Date
  endDate?: Date
  reportType: string
}

const initialState = {
  loading: false,
  dialog: null,
  salesRep: undefined,
  beginDate: undefined,
  endDate: new Date(),
  reportType: "Summary",
}

const title = "Sales Reports"

class SalesReport extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState

    if (this.props.setAppTitle) {
      this.props.setAppTitle(title)
    }
  }

  searchSalesRep = (result?: salesrep) => {
    this.setState({
      salesRep: result,
    })
  }

  updateDate = (type: string, e: myAny) => {
    let newDate = inputDateToDateObj(e.target.value)

    if (type === "end") {
      this.setState({
        endDate: newDate,
      })
    } else {
      this.setState({
        beginDate: newDate,
      })
    }
  }

  updateDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  submitRequest = () => {
    if (this.state.beginDate && this.state.endDate) {
      this.setState({ loading: true }, () => {
        fireAxios(
          {
            method: "post",
            url: "reports/salesreport/",
            data: {
              salesRep: this.state.salesRep,
              beginDate: this.state.beginDate,
              endDate: this.state.endDate,
              reportType: this.state.reportType,
            },
          },
          (response) => {
            this.setState({ loading: false }, () => {
              download(
                "Excel",
                response.data,
                "Sales_" + this.state.reportType + "_" + toFileDate(this.state.beginDate || "") + "-" + toFileDate(this.state.endDate || "")
              )
            })
          }
        )
      })
    } else {
      this.updateDialog({
        type: "alert",
        title: "Error!",
        message: "Both dates must be filled out",
        handleResponse: () => this.updateDialog(null),
      })
    }
  }

  handleReportType = (e: myAny) => {
    this.setState({
      reportType: e.target.name,
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.salesRep === false) {
      return (
        <div>
          <button onClick={() => this.searchSalesRep()}>Back to {title}</button>
          <br />
          <br />
          <SalesRepSearch returnResult={this.searchSalesRep} />
        </div>
      )
    }

    return (
      <div>
        <label htmlFor="start">Start Date: </label>
        <input
          type="date"
          name="start"
          onChange={(e) => this.updateDate("begin", e)}
          value={dateObjToInputDate(this.state.beginDate || new Date()) || ""}
        />
        <label htmlFor="end"> End Date: </label>
        <input
          type="date"
          name="end"
          onChange={(e) => this.updateDate("end", e)}
          value={dateObjToInputDate(this.state.endDate || new Date()) || ""}
        />
        <br />
        <br />
        <label htmlFor="salesrep">
          {this.state.salesRep ? "Current Salesrep: " : "Select Salesrep: "}{" "}
          <b>{this.state.salesRep ? this.state.salesRep.first_name + " " + this.state.salesRep.last_name : ""}</b>{" "}
        </label>
        <button name="salesrep" onClick={() => this.searchSalesRep(false)}>
          {this.state.salesRep ? "Edit" : "Add"}
        </button>

        {this.state.salesRep ? <button onClick={() => this.searchSalesRep()}>Clear Salesrep</button> : null}

        <br />
        <br />
        <label htmlFor="Summary">Summary</label>
        <input id="Summary" type="radio" name="Summary" onChange={this.handleReportType} checked={this.state.reportType === "Summary"} />
        <br />
        <label htmlFor="byCust">By Customer</label>
        <input id="byCust" type="radio" name="Customer" onChange={this.handleReportType} checked={this.state.reportType === "Customer"} />
        <br />
        <label htmlFor="byProd">By Product</label>
        <input id="byProd" type="radio" name="Product" onChange={this.handleReportType} checked={this.state.reportType === "Product"} />

        <br />
        <br />
        <button onClick={this.submitRequest}>Generate</button>
        {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
      </div>
    )
  }
}
export default SalesReport
