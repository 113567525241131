import React, { Component } from "react"

import { fireAxios } from "../helpers"

import BinLocationSearch from "./BinLocationSearch"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import LoadingSpinner from "../LoadingSpinner"
import { Dialog, props as dialogProps } from "../dialog/Dialog"

type props = {}

type state = {
  batchChange: boolean
  swapBins: boolean
  chooseBinLocation: boolean
  choosingBin?: string
  firstBin?: string
  secondBin?: string
  newBinName: string
  chooseWarehouse: boolean
  warehouse?: {
    id: number
    name: string
  }
  submitted: boolean
  loading: boolean
  dialog: dialogProps
}

const initialState: state = {
  batchChange: false,
  swapBins: false,
  chooseBinLocation: false,
  choosingBin: undefined,
  firstBin: undefined,
  secondBin: undefined,
  newBinName: "",
  chooseWarehouse: false,
  warehouse: undefined,
  submitted: false,
  loading: false,
  dialog: null,
}

const title = "Batch Bin Change"

class BatchBinChange extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = initialState
    this.props.setAppTitle && this.props.setAppTitle(title)
  }

  batchChange = () => {
    this.setState({
      batchChange: true,
    })
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  swapBins = () => {
    this.setState({
      swapBins: true,
    })
  }

  binLocationResult = (bin: { location: string }) => {
    if (this.state.choosingBin === "first") {
      this.setState({
        firstBin: bin.location,
        chooseBinLocation: false,
      })
    } else if (this.state.choosingBin === "second") {
      this.setState({
        secondBin: bin.location,
        chooseBinLocation: false,
      })
    } else {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "No choosing bin processed. You should not see this. Report to developers.",
        handleResponse: this.hideDialog,
      })
    }
  }

  toChooseBinLocation = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      choosingBin: e.currentTarget.name,
      chooseBinLocation: true,
    })
  }

  toChooseWarehouse = () => {
    this.setState({
      chooseWarehouse: true,
    })
  }

  warehouseSearchResult = (warehouse: any) => {
    this.setState({
      warehouse: warehouse,
      chooseWarehouse: false,
    })
  }

  backFromSearch = () => {
    this.setState({
      chooseBinLocation: false,
      chooseWarehouse: false,
    })
  }

  binNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      newBinName: e.target.value,
    })
  }

  submitSwap = () => {
    const dataToSubmit = {
      warehouse_id: this.state.warehouse ? this.state.warehouse.id : null,
      first_bin: this.state.firstBin,
      second_bin: this.state.secondBin,
    }

    this.showDialog({
      type: "confirm",
      title: "Swaping bins?",
      message: "Swap bins " + this.state.firstBin + " and " + this.state.secondBin + "?",
      handleResponse: (response) => {
        if (response) {
          this.setState({ loading: true }, () => {
            fireAxios({ method: "post", url: "binlocations/swapbins", data: dataToSubmit }, () => {
              this.setState({
                submitted: true,
                loading: false,
              })
            })
          })
        } else {
          this.hideDialog()
        }
      },
    })
  }

  submitBatchChange = () => {
    const dataToSubmit = {
      warehouse_id: this.state.warehouse ? this.state.warehouse.id : null,
      first_bin: this.state.firstBin,
      new_name: this.state.newBinName,
    }

    this.showDialog({
      type: "confirm",
      title: "Changing bins?",
      message: "Change bin " + this.state.firstBin + " to " + this.state.newBinName + "?",
      handleResponse: (response) => {
        if (response) {
          this.setState({ loading: true }, () => {
            fireAxios({ method: "post", url: "binlocations/batchchange", data: dataToSubmit }, () => {
              this.setState({
                submitted: true,
                loading: false,
              })
            })
          })
        }
      },
    })
  }

  reset = () => {
    this.setState(initialState)
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.submitted) {
      return (
        <div>
          {this.state.batchChange ? (
            <div>
              Changed bin <b>{this.state.firstBin}</b> to <b>{this.state.newBinName}</b>.
            </div>
          ) : (
            <div>
              Swaped bin <b>{this.state.firstBin}</b> with <b>{this.state.secondBin}</b>.
            </div>
          )}
          <br />
          <div>
            Make another change? <button onClick={this.reset}>New Change</button>
          </div>
        </div>
      )
    }

    if (this.state.chooseWarehouse) {
      return (
        <div>
          <div>
            <button onClick={this.backFromSearch}>Back to {title}</button>
          </div>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseSearchResult} />
        </div>
      )
    }

    if (this.state.chooseBinLocation) {
      return (
        <div>
          <div>
            <button onClick={this.backFromSearch}>Back to {title}</button>
          </div>
          <br />
          <br />
          <BinLocationSearch returnResult={this.binLocationResult} />
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    if (!this.state.warehouse) {
      return (
        <div>
          To begin, choose a warehouse: <button onClick={this.toChooseWarehouse}>Choose Warehouse</button>
        </div>
      )
    }

    return (
      <div>
        <div>
          Chosen Warehouse: <b>{this.state.warehouse.name}</b> <button onClick={this.toChooseWarehouse}>Edit</button>
          <br />
          <br />
        </div>
        {this.state.swapBins ? (
          <div>
            Swap bins:
            <br />
            <br />
            {!this.state.firstBin ? (
              <div>
                Choose first bin:{" "}
                <button name={"first"} onClick={this.toChooseBinLocation}>
                  Choose Bin
                </button>
              </div>
            ) : (
              <div>
                First bin: <b>{this.state.firstBin}</b>{" "}
                <button name={"first"} onClick={this.toChooseBinLocation}>
                  Edit
                </button>
                <br />
                <br />
                {!this.state.secondBin ? (
                  <div>
                    Choose second bin:{" "}
                    <button name={"second"} onClick={this.toChooseBinLocation}>
                      Choose Bin
                    </button>
                  </div>
                ) : (
                  <div>
                    Second bin: <b>{this.state.secondBin}</b>{" "}
                    <button name={"second"} onClick={this.toChooseBinLocation}>
                      Edit
                    </button>
                    <br />
                    <br />
                    <button onClick={this.submitSwap}>Submit Swap</button>
                    {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : this.state.batchChange ? (
          <div>
            Batch change:
            <br />
            <br />
            {!this.state.firstBin ? (
              <div>
                Choose bin to change:{" "}
                <button name={"first"} onClick={this.toChooseBinLocation}>
                  Choose Bin
                </button>
              </div>
            ) : (
              <div>
                Chosen Bin: <b>{this.state.firstBin}</b>{" "}
                <button name={"first"} onClick={this.toChooseBinLocation}>
                  Edit
                </button>
                <br />
                <br />
                Change to bin: <input name={"new_bin"} value={this.state.newBinName} onChange={this.binNameChange} />
                <br />
                <br />
                <button onClick={this.submitBatchChange}>Submit Changes</button>
                {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
              </div>
            )}
          </div>
        ) : (
          <div>
            <button onClick={this.batchChange}>Batch Change</button> or <button onClick={this.swapBins}>Swap Bins</button>
          </div>
        )}
      </div>
    )
  }
}

export default BatchBinChange
