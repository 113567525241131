import React, { Component } from "react"

// import child child components / helpers
import CustomerSearch from "../customers/CustomerSearch"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import ProductSearch from "../products/ProductSearch"
import SalesrepSearch from "../salesreps/SalesrepSearch"
import VendorSearch from "../vendors/VendorSearch"
import LoadingSpinner from "../LoadingSpinner"
import ControlledInput from "../edit/ControlledInput"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import { AccessDialog, props as accessDialogProps } from "../dialog/AccessDialog"
import NewTabText from "../newTabText/NewTabText"
import ContextMenu from "../edit/ContextMenu"
import { fireAxios, toCurrency, toReadableDate, calculateMargin, calculateSell, download } from "../helpers"

// import required libraries
import shortid from "shortid"
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"
import arrayMove from "array-move"

type props = {
  id?: number
}

type state = {
  loading: boolean
  dialog: dialogProps
  accessDialog: accessDialogProps
  accessSupplySources: boolean
  accessChangeSalesrep: boolean
  dateCreated?: Date
  customer?: customer
  toChooseCustomer: boolean
  pricing: Array<pricing>
  salesrep?: salesrep
  toChooseSalesrep: boolean
  warehouse?: warehouse
  toChooseWarehouse: boolean
  products: Array<product>
  productInfo?: product
  toChooseProduct: boolean
  toChooseVendor: boolean
  attachmentInfo?: {
    title: string
    type: string
    result: {
      columns: Array<{ title: string; key: string }>
      rows: Array<{ id: number; [index: string]: any }>
    }
  }
  loadingAttachments: boolean
  lotPriceChecked: boolean
  lotPrices: Array<lotPrice>
  lotPriceInfo?: lotPrice
  payments: Array<payment>
  paymentInfo?: payment
  invoices: Array<invoice>
  invoiceInfo?: invoice
  isQuote: boolean
  closed: boolean
  cancelled: boolean
  existingOrderId?: number
  revision: number
  showCosts: boolean
  takenBy?: salesrep
  shipTo: {
    address_1?: string
    address_2?: string
    address_3?: string
    address_4?: string
    address_5?: string
    [index: string]: any
  }
  details: {
    ship_via: string
    po_number?: string
    discount?: number
    instruction?: string
    comment?: string
  }
  shipTerm: string
  salesTaxes: Array<salestax>
  selectedSalesTax?: string
  print: boolean
  download: boolean
  stateChanged: boolean
  inputValidities: Array<{
    unique_id: string
    valid: boolean
  }>
  [index: string]: any
}

type product = {
  // changeable / inputs
  qty_to_order: number
  selling_price: number
  discount: number
  margin: number
  qty_to_invoice: number
  description: string
  header_value?: string
  header: boolean
  order_specific: boolean
  ship_n_debit: boolean
  cost: number
  line?: string
  number?: string
  supply_details: Array<supplyDetail>
  // unchangeable
  id?: number
  order_product_id?: number
  product_line?: string
  product_num?: string
  qty_invoiced: number
  price_per: number
  landed_cost: number
  sell_mult: number
  pur_mult: number
  // other
  unique_id?: string
  autoFocus?: string
  [index: string]: any
}

type supplyDetail = {
  qty_to_supply: number
  warehouse_id?: number
  warehouse_name?: string
  supply_source: string
  type: string
  qty_available?: number
  avg_cost?: number
  landed_cost?: number
  cost?: number
  checked: boolean
}

type customer = {
  id: number
  customer_num: string
  company_name: string
  province: string
  cod_status: boolean
}

type pricing = {
  product_id: number
  sell_price: number
  ship_n_debit: boolean
  cost: number
}

type invoice = {
  id: number
  discount: number
  tax_rate: number
  date: Date
  products: Array<invoiceProduct>
  lot_prices: Array<invoiceLotPrice>
}

type invoiceProduct = {
  id?: number
  os_product_id?: number
  line?: string
  number?: string
  description?: string
  product_line?: string
  product_num?: string
  selling_price: number
  cost: number
  quantity: number
  price_per: number
  discount: number
}

type invoiceLotPrice = {
  id: number
  title: string
  cost: number
  selling_price: number
}

type lotPrice = {
  // changeable / inputs
  selling_price: number
  cost: number
  margin: number
  title: string
  comment: string
  // unchangeable
  amount_invoiced: number
  // other
  unique_id: string
  [index: string]: any
}

type payment = {
  line_index: number
  date: string | Date
  total: number
  deposit: number
  applied_deposit: number
  change_given: number
  comment: string
  types: Array<paymentType>
  saved: boolean
  unique_id: string
  [index: string]: any
}

type paymentType = {
  name: "Visa" | "Debit" | "Cash" | "Mastercard" | "Cheque" | "EFT"
  amount: number
  detail: string
  expiry: string
  [index: string]: any
}

type salesrep = {
  id: number
  first_name: string
  last_name: string
}

type warehouse = {
  id: number
  name: string
}

type salestax = {
  province_code: string
  province_name: string
  types: Array<{
    name: string
    percent: number
  }>
}

const initialState: state = {
  loading: false,
  dialog: null,
  accessDialog: null,
  accessSupplySources: false,
  accessChangeSalesrep: false,
  dateCreated: undefined,
  customer: undefined,
  toChooseCustomer: false,
  pricing: [],
  salesrep: undefined,
  toChooseSalesrep: false,
  warehouse: undefined,
  toChooseWarehouse: false,
  products: [],
  toChooseProduct: false,
  toChooseVendor: false,
  productInfo: undefined,
  attachmentInfo: undefined,
  loadingAttachments: false,
  lotPriceChecked: false,
  lotPrices: [],
  lotPriceInfo: undefined,
  payments: [],
  paymentInfo: undefined,
  invoices: [],
  invoiceInfo: undefined,
  isQuote: false,
  closed: false,
  cancelled: false,
  existingOrderId: undefined,
  revision: 0,
  showCosts: true,
  takenBy: undefined,
  shipTo: {},
  details: {
    ship_via: "no_selection",
  },
  shipTerm: "no_selection",
  salesTaxes: [],
  selectedSalesTax: undefined,
  stateChanged: false,
  inputValidities: [],
  print: false,
  download: false,
}

const DragHandle = SortableHandle(() => {
  const svgStyle = {
    cursor: "s-resize",
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" style={svgStyle}>
      <path d="M2 13.5h14V12H2v1.5zm0-4h14V8H2v1.5zM2 4v1.5h14V4H2z" />
    </svg>
  )
})

/*
██████  ██████   ██████  ██████  ██    ██  ██████ ████████     ██      ██ ███████ ████████
██   ██ ██   ██ ██    ██ ██   ██ ██    ██ ██         ██        ██      ██ ██         ██
██████  ██████  ██    ██ ██   ██ ██    ██ ██         ██        ██      ██ ███████    ██
██      ██   ██ ██    ██ ██   ██ ██    ██ ██         ██        ██      ██      ██    ██
██      ██   ██  ██████  ██████   ██████   ██████    ██        ███████ ██ ███████    ██
*/

const SortableProduct = SortableElement(
  ({
    value,
    i,
    deleteProduct,
    showProductInfo,
    handleProductInput,
    stopPropagation,
  }: {
    value: product
    i: number
    deleteProduct: (prod: product) => void
    showProductInfo: (prod: product) => void
    handleProductInput: (name: string, value: string | number, prod: product) => void
    stopPropagation: (e: any) => void
  }) => {
    const qtyToOrder = value.hasOwnProperty("qty_to_order") ? value.qty_to_order : 0
    const qtyInvoiced = value.hasOwnProperty("qty_invoiced") ? value.qty_invoiced : 0
    const complete = qtyInvoiced >= qtyToOrder && qtyToOrder > 0
    const bgColor = complete ? "#87dc9e" : undefined
    const activePrice = value.hasOwnProperty("selling_price") ? value.selling_price : 0
    const discount = value.hasOwnProperty("discount") ? value.discount : 0
    const pricePer = value.hasOwnProperty("price_per") ? value.price_per : 0
    const totalPrc = (qtyToOrder * ((activePrice / pricePer) * (1 - discount / 100))).toFixed(2)

    return value.header ? (
      <ContextMenu key={value.unique_id} elementType="tbody" menuItems={[{ name: "Delete", onClick: () => deleteProduct(value) }]}>
        <tr className="row" style={{ cursor: "default" }}>
          <td style={{ border: "none", background: "white" }}>{i}</td>
          <td>
            <DragHandle />
          </td>
          <td colSpan={6} style={{ textAlign: "center" }}>
            <input
              style={{ textAlign: "center", width: "50%", fontSize: "20px" }}
              name={"header_value"}
              defaultValue={value.header_value}
              onClick={(e) => stopPropagation(e)}
              onChange={(e) => handleProductInput("header_value", e.target.value, value)}
            />
          </td>
        </tr>
      </ContextMenu>
    ) : (
      <ContextMenu
        key={value.unique_id}
        elementType="tbody"
        menuItems={[...((qtyInvoiced === 0 && [{ name: "Delete", onClick: () => deleteProduct(value) }]) || [])]}
      >
        <tr className="row columnHover" style={{ background: bgColor }} onClick={() => showProductInfo(value)}>
          <td onClick={(e) => stopPropagation(e)} style={{ border: "none", background: "white" }}>
            {i}
          </td>
          <td onClick={(e) => stopPropagation(e)}>
            <DragHandle />
          </td>
          {value.order_specific ? (
            <td style={{ width: "1em" }}>
              <span style={{ color: "red" }}>
                <b>OS</b>&nbsp;
              </span>
            </td>
          ) : null}
          <td onClick={() => (value.autoFocus = value.order_specific ? "product_line" : undefined)} colSpan={value.order_specific ? 1 : 2}>
            <b>
              {value[value.order_specific ? "line" : "product_line"]} - {value[value.order_specific ? "number" : "product_num"]}
            </b>{" "}
            {value.description}
          </td>
          <td onClick={() => (value.autoFocus = "qty_to_order")}>{qtyToOrder.toString()}</td>
          <td onClick={() => (value.autoFocus = "qty_to_invoice")}>{qtyInvoiced.toString()}</td>
          <td onClick={() => (value.autoFocus = "selling_price")}>{toCurrency((activePrice / pricePer) * (1 - discount / 100))}</td>
          <td onClick={() => (value.autoFocus = "selling_price")}>{toCurrency(totalPrc)}</td>
        </tr>
      </ContextMenu>
    )
  }
)

const SortableProductList = SortableContainer(
  ({
    items,
    deleteProduct,
    showProductInfo,
    handleProductInput,
    stopPropagation,
  }: {
    items: Array<product>
    deleteProduct: (prod: product) => void
    showProductInfo: (prod: product) => void
    handleProductInput: (name: string, value: string | number, prod: product) => void
    stopPropagation: (e: any) => void
  }) => {
    const borderlessCell = {
      border: "0",
    }

    let grandTotal = 0

    return (
      <table className="searchResults">
        <thead>
          <tr>
            <th style={{ border: "none", background: "white" }}></th>
            <th></th>
            <th colSpan={2}>Product</th>
            <th>Quantity</th>
            <th>Invoiced</th>
            <th>Selling Price</th>
            <th>Total</th>
          </tr>
        </thead>
        {items.map((value, index) => {
          // generate a unique id for each detail so React can manage the state properly
          if (!value.hasOwnProperty("unique_id")) {
            value.unique_id = shortid.generate()
          }

          // calculate grand total
          let qtyToOrder = value.hasOwnProperty("qty_to_order") ? value.qty_to_order : 0
          let activePrc = value.hasOwnProperty("selling_price") ? value.selling_price : 0
          let discount = value.hasOwnProperty("discount") ? value.discount : 0
          let pricePer = value.hasOwnProperty("price_per") ? value.price_per : 0
          let individualPrc = qtyToOrder * ((activePrc / pricePer) * (1 - discount / 100))
          grandTotal += individualPrc

          return (
            <SortableProduct
              key={value.unique_id}
              index={index}
              value={value}
              i={index + 1}
              deleteProduct={deleteProduct}
              showProductInfo={showProductInfo}
              stopPropagation={stopPropagation}
              handleProductInput={handleProductInput}
            />
          )
        })}
        <tfoot>
          <tr>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
            <th>{toCurrency(grandTotal)}</th>
          </tr>
        </tfoot>
      </table>
    )
  }
)

/*
██       ██████  ████████     ██████  ██████  ██  ██████ ███████     ██      ██ ███████ ████████
██      ██    ██    ██        ██   ██ ██   ██ ██ ██      ██          ██      ██ ██         ██
██      ██    ██    ██        ██████  ██████  ██ ██      █████       ██      ██ ███████    ██
██      ██    ██    ██        ██      ██   ██ ██ ██      ██          ██      ██      ██    ██
███████  ██████     ██        ██      ██   ██ ██  ██████ ███████     ███████ ██ ███████    ██
*/

const SortableLotPrice = SortableElement(
  ({
    value,
    i,
    deleteLotPrice,
    showLotPriceInfo,
    handleLotPriceInput,
  }: {
    value: lotPrice
    i: number
    deleteLotPrice: (lot: lotPrice) => void
    showLotPriceInfo: (lot: lotPrice) => void
    handleLotPriceInput: (name: string, value: string | number, lot: lotPrice) => void
  }) => {
    let amountInvoiced = value.amount_invoiced ? value.amount_invoiced : 0
    let sellingPrice = value.selling_price ? value.selling_price : 0
    let complete = amountInvoiced >= sellingPrice && sellingPrice !== 0
    let bgColor = complete ? "#87dc9e" : undefined

    return (
      <ContextMenu
        key={value.unique_id}
        elementType="tbody"
        menuItems={[...(amountInvoiced <= 0 ? [{ name: "Delete", onClick: () => deleteLotPrice(value) }] : [])]}
      >
        <tr className="row" style={{ cursor: "default", background: bgColor }}>
          <td style={{ border: "none", background: "white" }}>{i}</td>
          <td>
            <DragHandle />
          </td>
          <td>
            <input
              placeholder="Title"
              defaultValue={value.title}
              disabled={amountInvoiced > 0}
              onChange={(e) => handleLotPriceInput("title", e.target.value, value)}
            />
          </td>
          <td onClick={() => showLotPriceInfo(value)} style={{ cursor: "pointer" }}>
            {toCurrency(sellingPrice)}
          </td>
          <td onClick={() => showLotPriceInfo(value)} style={{ cursor: "pointer" }}>
            {toCurrency(amountInvoiced)}
          </td>
          <td>
            <textarea placeholder="Comment" defaultValue={value.comment} onChange={(e) => handleLotPriceInput("comment", e.target.value, value)} />
          </td>
        </tr>
      </ContextMenu>
    )
  }
)

const SortableLotPriceList = SortableContainer(
  ({
    items,
    deleteLotPrice,
    showLotPriceInfo,
    handleLotPriceInput,
  }: {
    items: Array<lotPrice>
    deleteLotPrice: (lot: lotPrice) => void
    showLotPriceInfo: (lot: lotPrice) => void
    handleLotPriceInput: (name: string, value: string | number, lot: lotPrice) => void
  }) => {
    const borderlessCell = {
      border: "0",
    }

    let grandTotal = 0

    return (
      <table className="searchResults">
        <thead>
          <tr>
            <th style={{ border: "none", background: "white" }}></th>
            <th></th>
            <th>Title</th>
            <th>Selling Price</th>
            <th>Amount Invoiced</th>
            <th>Comment</th>
          </tr>
        </thead>
        {items.map((value, index) => {
          // generate a unique id for each item so React can manage the state properly
          if (!value.hasOwnProperty("unique_id")) {
            value.unique_id = shortid.generate()
          }

          grandTotal += value.hasOwnProperty("selling_price") ? value.selling_price : 0

          return (
            <SortableLotPrice
              key={value.unique_id}
              index={index}
              value={value}
              i={index + 1}
              deleteLotPrice={deleteLotPrice}
              showLotPriceInfo={showLotPriceInfo}
              handleLotPriceInput={handleLotPriceInput}
            />
          )
        })}
        <tfoot>
          <tr>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
            <th>{toCurrency(grandTotal)}</th>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
          </tr>
        </tfoot>
      </table>
    )
  }
)

/*
 ██████  ██████  ███    ███ ██████   ██████  ███    ██ ███████ ███    ██ ████████
██      ██    ██ ████  ████ ██   ██ ██    ██ ████   ██ ██      ████   ██    ██
██      ██    ██ ██ ████ ██ ██████  ██    ██ ██ ██  ██ █████   ██ ██  ██    ██
██      ██    ██ ██  ██  ██ ██      ██    ██ ██  ██ ██ ██      ██  ██ ██    ██
 ██████  ██████  ██      ██ ██       ██████  ██   ████ ███████ ██   ████    ██
*/

class Order extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)
    this.state = {
      ...initialState,
      warehouse: this.props.user ? this.props.user.warehouse : undefined,
      takenBy: this.props.user ? this.props.user.salesrep : undefined,
    }

    if (this.props.setAppTitle) {
      if (this.props.id || this.state.existingOrderId) {
        this.props.setAppTitle("Order #" + (this.props.id ? this.props.id : this.state.existingOrderId))
      } else {
        this.props.setAppTitle("Create Order")
      }
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEscapeInfo, false)

    if (this.props.id) {
      this.getAllOrderDetails(this.props.id)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEscapeInfo, false)
  }

  /*
  ██       ██████   █████  ██████       ██████  ██████  ██████  ███████ ██████
  ██      ██    ██ ██   ██ ██   ██     ██    ██ ██   ██ ██   ██ ██      ██   ██
  ██      ██    ██ ███████ ██   ██     ██    ██ ██████  ██   ██ █████   ██████
  ██      ██    ██ ██   ██ ██   ██     ██    ██ ██   ██ ██   ██ ██      ██   ██
  ███████  ██████  ██   ██ ██████       ██████  ██   ██ ██████  ███████ ██   ██
  */

  getAllOrderDetails = (orderId: number, callback?: () => void) => {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "orders/" + orderId }, (response) => {
        this.setState(
          {
            loading: false,
            ...response.data,
          },
          () => {
            this.setState(
              {
                lotPriceChecked: this.state.lotPrices.length > 0,
                products: response.data.products.map((prod: product) => this.initializeProd(prod)),
                stateChanged: false,
                existingOrderId: orderId,
              },
              () => {
                this.props.setAppTitle && this.props.setAppTitle("Order #" + this.state.existingOrderId + " - " + this.state.revision)

                if (callback) {
                  callback()
                }
              }
            )
          }
        )
      })
    })
  }

  /*
███████ ███████  █████  ██████   ██████ ██   ██     ███████ ███    ██ ███████
██      ██      ██   ██ ██   ██ ██      ██   ██     ██      ████   ██ ██
███████ █████   ███████ ██████  ██      ███████     █████   ██ ██  ██ ███████
     ██ ██      ██   ██ ██   ██ ██      ██   ██     ██      ██  ██ ██      ██
███████ ███████ ██   ██ ██   ██  ██████ ██   ██     ██      ██   ████ ███████
*/

  handleSearchDepth = (depth: number) => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(depth)
    }
  }

  backFromSearch = () => {
    this.handleSearchDepth(0)
    this.setState({
      toChooseCustomer: false,
      toChooseSalesrep: false,
      toChooseWarehouse: false,
      toChooseProduct: false,
      toChooseVendor: false,
    })
  }

  chooseCustomer = () => {
    this.handleSearchDepth(1)
    this.setState({
      toChooseCustomer: true,
    })
  }

  customerSearchResult = (customer: customer) => {
    if (!this.state.customer) {
      this.handleSearchDepth(0)
      this.getCustomerDetails(customer)
      return
    }

    if (this.state.products.length > 0) {
      this.showDialog({
        type: "confirm",
        title: "Pricing Change Warning",
        message: "Pricing may be affected. How would you like to continue?",
        options: ["Reprice All", "Keep Current Pricing"],
        handleResponse: (response) => {
          this.handleSearchDepth(0)
          if (!response) {
            this.setState({
              toChooseCustomer: false,
            })
          } else {
            this.getCustomerDetails(customer, response === "Reprice All")
          }
          this.hideDialog()
        },
      })
    } else {
      this.handleSearchDepth(0)
      this.getCustomerDetails(customer)
    }
  }

  getCustomerDetails = (customer: customer, reprice?: boolean) => {
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "orders/customer/info",
          data: {
            customer_id: customer.id,
            date_created: this.state.dateCreated,
          },
        },
        (response) => {
          const salesTaxes = response.data.sales_taxes
          const pricing = response.data.pricing
          const salesrep = response.data.salesrep

          let selectedSalesTax = salesTaxes.find((x: salestax) => customer.province === x.province_code)
          selectedSalesTax = selectedSalesTax ? selectedSalesTax.province_code : "EX"

          this.setState(
            {
              loading: false,
              selectedSalesTax: selectedSalesTax,
              salesTaxes: salesTaxes,
              pricing: pricing,
              customer: customer,
              salesrep: salesrep,
              shipTo: {},
              toChooseCustomer: false,
            },
            () => {
              let products = JSON.parse(JSON.stringify(this.state.products))

              if (reprice) {
                products.map((prod: product) => this.reprice(prod))
                this.setState({
                  products: products,
                })
              } else {
                products.map((prod: product) => this.initializeProd(prod))
                this.setState({
                  products: products,
                })
              }
            }
          )
        }
      )
    })
  }

  chooseSalesrep = () => {
    if (this.accessChangeSalesrep()) {
      this.handleSearchDepth(1)
      this.setState({
        toChooseSalesrep: true,
      })
    }
  }

  salesrepSearchResult = (salesrep: salesrep) => {
    this.handleSearchDepth(0)
    if (salesrep) {
      this.setState({
        toChooseSalesrep: false,
        salesrep: salesrep,
      })
    }
  }

  chooseWarehouse = () => {
    if (this.state.products.length > 0) {
      this.showDialog({
        type: "confirm",
        title: "Warehouse Change Warning",
        message: "Supply sources and pricing may change. Would you like to continue?",
        handleResponse: (response) => {
          if (response) {
            this.handleSearchDepth(1)
            this.setState({
              toChooseWarehouse: true,
            })
          }
          this.hideDialog()
        },
      })
    } else {
      this.handleSearchDepth(1)
      this.setState({
        toChooseWarehouse: true,
      })
    }
  }

  warehouseSearchResult = (whse: warehouse) => {
    this.handleSearchDepth(0)
    if (whse) {
      this.setState(
        {
          toChooseWarehouse: false,
          warehouse: whse,
        },
        () => {
          // use callback for setState because this.determineSources uses this.state.warehouse
          this.setState({
            products: this.state.products.map((prod) => this.determineSources(prod)),
          })
        }
      )
    }
  }

  chooseProduct = () => {
    this.handleSearchDepth(1)
    this.setState({
      toChooseProduct: true,
    })
  }

  productSearchResult = (prod: product) => {
    this.handleSearchDepth(0)
    if (prod) {
      this.setState({ toChooseProduct: false }, () => {
        let newArray = JSON.parse(JSON.stringify(this.state.products))

        // get additional information needed for the product
        // (latest avg. cost and quantities available)
        this.setState({ loading: true }, () => {
          if (this.state.existingOrderId) {
            fireAxios(
              {
                method: "post",
                url: "products/orderDetails/",
                data: {
                  order_id: this.state.existingOrderId,
                  product_id: prod.id,
                },
              },
              (response) => {
                if (response.data) {
                  prod.supply_details = response.data
                }
                prod = this.initializeProd(prod)
                newArray.push(prod)
                this.setState({
                  loading: false,
                  products: newArray.map((prod: product) => this.determineCost(prod)),
                })
              }
            )
          } else {
            fireAxios({ method: "get", url: "products/orderDetails/" + prod.id }, (response) => {
              if (response.data) {
                prod.supply_details = response.data
              }
              prod = this.initializeProd(prod)
              newArray.push(prod)
              this.setState({
                loading: false,
                products: newArray.map((prod: product) => this.determineCost(prod)),
              })
            })
          }
        })
      })
    }
  }

  /*
██████  ██████   ██████  ██████  ██    ██  ██████ ████████
██   ██ ██   ██ ██    ██ ██   ██ ██    ██ ██         ██
██████  ██████  ██    ██ ██   ██ ██    ██ ██         ██
██      ██   ██ ██    ██ ██   ██ ██    ██ ██         ██
██      ██   ██  ██████  ██████   ██████   ██████    ██
*/

  initializeProd = (prod: product) => {
    prod.unique_id = prod.unique_id ? prod.unique_id : shortid.generate()

    if (prod.header) {
      prod.header = true
      prod.header_value = prod.header_value ? prod.header_value : ""
      prod.cost = 0
      prod.margin = 0
      prod.discount = 0
      prod.selling_price = 0
      prod.qty_to_invoice = 0
      prod.qty_to_order = 0
      prod.price_per = 1
      prod.supply_details = []
    } else if (prod.order_specific) {
      prod.order_specific = true
      prod.line = prod.line ? prod.line : ""
      prod.number = prod.number ? prod.number : ""
      prod.description = prod.description ? prod.description : ""
      prod.cost = prod.cost ? prod.cost : 0
      prod.discount = prod.discount ? prod.discount : 0
      prod.selling_price = prod.selling_price ? prod.selling_price : 0
      prod.margin = prod.selling_price && prod.cost ? calculateMargin(prod.selling_price, prod.cost) : 0
      prod.qty_to_invoice = prod.qty_to_invoice ? prod.qty_to_invoice : 0
      prod.qty_to_order = prod.qty_to_order ? prod.qty_to_order : 0
      prod.supply_details = prod.supply_details ? prod.supply_details : []
      prod.price_per = 1
    } else {
      // an actual product
      const foundPricing = this.state.pricing.find((x) => x.product_id === prod.id)

      if (foundPricing && !prod.selling_price) {
        prod.selling_price = foundPricing.sell_price
      }

      if (foundPricing && foundPricing.ship_n_debit) {
        prod.ship_n_debit = true
        prod.cost = foundPricing.cost
      } else {
        prod.ship_n_debit = false
      }

      prod.qty_to_order = prod.qty_to_order ? prod.qty_to_order : 0
      prod.qty_to_invoice = prod.qty_to_invoice ? prod.qty_to_invoice : 0
      prod.selling_price = prod.selling_price ? prod.selling_price : 0
      prod.discount = prod.discount ? prod.discount : 0
      prod = this.determineSources(prod)
    }

    return prod
  }

  reprice = (prod: product) => {
    const foundPricing = this.state.pricing.find((x) => x.product_id === prod.id)

    if (foundPricing) {
      prod.selling_price = foundPricing.sell_price
    }

    prod = this.initializeProd(prod)

    return prod
  }

  // determine the cost for the product
  // INITIALLY:
  // the cost is the avg_cost of the origin warehouse if inventory is non-zero
  // the cost is the landed_cost if inventory is <= 0
  // OTHERWISE:
  // the cost is determined by taking new average costs depending on what is selected as the supply sources
  determineCost = (prod: product) => {
    if (prod.header) {
      return prod
    }

    const sellingPrice = prod.selling_price

    if (prod.ship_n_debit) {
      prod.margin = calculateMargin(sellingPrice, prod.cost)
      return prod
    }

    const originWhse = prod.supply_details.find((x) => x.warehouse_id === (this.state.warehouse ? this.state.warehouse.id : 0))
    const purchase = prod.supply_details.find((x) => x.supply_source === "Purchase")
    const holdAll = prod.supply_details.find((x) => x.supply_source === "Hold All")
    const otherWhses = prod.supply_details.filter(
      (x) => x.supply_source === "Warehouse" && x.warehouse_id !== (this.state.warehouse ? this.state.warehouse.id : 0)
    )
    const discount = prod.discount

    // set the purchase cost to the manually entered cost for order specific products
    if (prod.order_specific && purchase) {
      purchase.cost = prod.cost
      return prod
    }

    if (!prod.hasOwnProperty("cost") || !prod.qty_to_order) {
      // determine the cost initially with no quantity sources chosen
      let cost =
        originWhse && originWhse.qty_available && originWhse.qty_available > 0 ? originWhse.avg_cost : prod.landed_cost ? prod.landed_cost : 0
      prod.cost = cost || 0
      prod.selling_price = sellingPrice ? sellingPrice : 0
      prod.discount = discount ? discount : 0
      prod.margin = cost && sellingPrice ? calculateMargin(sellingPrice, cost) : 0
    } else {
      // product already has a cost, but quantities have probably changed which could change the cost
      let cost = 0
      let qty = 0
      prod.discount = discount ? discount : 0

      if (holdAll && holdAll.landed_cost) {
        let qtyRemaining = prod.qty_to_order ? prod.qty_to_order : 0

        if (originWhse) {
          qtyRemaining -= originWhse.qty_available || 0
          cost = originWhse.avg_cost || 0
          qty = originWhse.qty_available || 0
        }

        otherWhses &&
          otherWhses.forEach((whse) => {
            if (qtyRemaining <= 0) {
              prod.cost = cost
              prod.margin = calculateMargin(sellingPrice, cost)
              return prod
            }
            if (whse.qty_available && whse.qty_available > 0) {
              qtyRemaining -= whse.qty_available
              cost = this.determineAvgCost(cost, qty, whse.avg_cost || 0, whse.qty_available)
              qty += whse.qty_available
            }
          })

        if (qtyRemaining <= 0) {
          prod.cost = cost
          prod.margin = calculateMargin(sellingPrice, cost)
          return prod
        }

        if (purchase) {
          cost = this.determineAvgCost(cost, qty, prod.landed_cost, qtyRemaining)
        }

        prod.cost = cost
        prod.margin = calculateMargin(sellingPrice, cost)
        return prod
      }

      if (originWhse && originWhse.qty_to_supply > 0 && originWhse.qty_available && originWhse.qty_available > 0) {
        cost = originWhse.avg_cost || 0
        qty = originWhse.qty_to_supply
      }

      otherWhses.forEach((whse) => {
        if (whse.qty_to_supply > 0 && whse.qty_available && whse.qty_available > 0) {
          cost = this.determineAvgCost(cost, qty, whse.avg_cost || 0, whse.qty_to_supply)
          qty += whse.qty_to_supply
        }
      })

      if (purchase && purchase.qty_to_supply > 0) {
        cost = this.determineAvgCost(cost, qty, prod.landed_cost, purchase.qty_to_supply)
      }

      prod.cost = cost
      prod.margin = calculateMargin(sellingPrice, cost)
    }

    return prod
  }

  determineAvgCost = (initialCost: number, initialQty: number, newCost: number, newQty: number) => {
    if (initialQty + newQty === 0) {
      return 0
    }
    return (initialCost * initialQty + newCost * newQty) / (initialQty + newQty)
  }

  // prods is optional
  determineSources = (prod: product, prods?: Array<product>) => {
    let qtyToOrder = prod.qty_to_order

    qtyToOrder -= prod.qty_invoiced ? prod.qty_invoiced : 0

    if (!prod.supply_details.some((x) => x.supply_source === "Purchase")) {
      // supply sources have not been setup for this product
      // so, setup the locational supply sources
      prod.supply_details.forEach((x) => {
        x.supply_source = "Warehouse"
        x.type = ""
        x.qty_to_supply = 0
        x.checked = false
      })
      prod.supply_details.push({ supply_source: "Purchase", type: "", qty_to_supply: 0, checked: false })
      prod.supply_details.push({ supply_source: "Hold All", type: "", qty_to_supply: 0, checked: false })
    }

    if (!qtyToOrder) {
      // product quantity is 0 or not a number, reset locational details
      prod.supply_details.forEach((x) => {
        x.qty_to_supply = 0
        x.type = ""
        x.checked = false
      })

      return this.determineCost(prod)
    }

    if (this.state.isQuote) {
      prod.supply_details.forEach((x) => {
        if (x.supply_source === "Hold All") {
          x.qty_to_supply = qtyToOrder
          x.checked = true
        } else {
          x.qty_to_supply = 0
          x.checked = false
          x.type = ""
        }
      })

      return this.determineCost(prod)
    }

    if (prod.supply_details.length > 0) {
      let originWhse = prod.supply_details.find((x) => x.warehouse_name === (this.state.warehouse ? this.state.warehouse.name : ""))

      if (originWhse) {
        let originWhseQty = this.qtyAvailableRemaining(originWhse, prod.id || 0, prod.unique_id || "", true, prods ? prods : this.state.products)
        if (!originWhseQty || qtyToOrder === 0) {
          originWhse.qty_to_supply = 0
          originWhse.type = ""
          originWhse.checked = false
        } else if (qtyToOrder <= originWhseQty) {
          originWhse.qty_to_supply = qtyToOrder
          originWhse.type = "Pick"
          originWhse.checked = true
          qtyToOrder = 0
        } else {
          originWhse.qty_to_supply = originWhseQty
          originWhse.type = "Pick"
          originWhse.checked = true
          qtyToOrder -= originWhseQty
        }
      }

      let remainingWhses = prod.supply_details.filter((x) => {
        return x.warehouse_name ? x.warehouse_name !== (this.state.warehouse ? this.state.warehouse.name : "") : false
      })

      remainingWhses.forEach((whse) => {
        let whseQty = this.qtyAvailableRemaining(whse, prod.id || 0, prod.unique_id || "", true, prods ? prods : this.state.products)
        if (!whseQty || qtyToOrder === 0) {
          whse.qty_to_supply = 0
          whse.type = ""
          whse.checked = false
        } else if (qtyToOrder <= whseQty) {
          whse.qty_to_supply = qtyToOrder
          whse.type = "Transfer"
          whse.checked = true
          qtyToOrder = 0
        } else {
          whse.qty_to_supply = whseQty
          whse.type = "Transfer"
          whse.checked = true
          qtyToOrder -= whseQty
        }
      })

      let toPurchase = prod.supply_details.find((x) => x.supply_source === "Purchase")
      if (toPurchase && qtyToOrder > 0) {
        toPurchase.qty_to_supply = qtyToOrder
        toPurchase.checked = true
      } else if (toPurchase) {
        toPurchase.qty_to_supply = 0
        toPurchase.checked = false
      }

      let toBackOrder = prod.supply_details.find((x) => x.supply_source === "Hold All")

      if (toBackOrder) {
        toBackOrder.qty_to_supply = 0
        toBackOrder.checked = false
      }
    }

    return this.determineCost(prod)
  }

  handleSupplyCheckbox = (e: any) => {
    if (!this.accessSupplySources()) {
      return
    }

    if (!this.state.productInfo === undefined) return

    let newProducts: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let prodIndex = newProducts.findIndex((x) => x.unique_id === this.state.productInfo!.unique_id) || 0

    if (this.state.productInfo && !this.state.productInfo.qty_to_order) {
      return
    }

    if (e.target.name === "Hold All") {
      let detailToChange = newProducts[prodIndex].supply_details.find((x) => x.supply_source === e.target.name)

      if (!detailToChange) return

      detailToChange.qty_to_supply = e.target.checked ? this.state.productInfo!.qty_to_order : 0
      detailToChange.checked = e.target.checked

      if (e.target.checked) {
        newProducts[prodIndex].supply_details.forEach((detail) => {
          if (detail.supply_source === e.target.name) {
            return
          }
          detail.qty_to_supply = 0
          detail.type = ""
          detail.checked = false
        })
      } else {
        newProducts[prodIndex] = this.determineSources(newProducts[prodIndex])
      }
    } else {
      let whseToChange = newProducts[prodIndex].supply_details.find((x) => x.warehouse_name === e.target.name)

      let whseToChangeAvail = 0

      if (whseToChange) {
        whseToChangeAvail = this.qtyAvailableRemaining(
          whseToChange,
          newProducts[prodIndex].id || 0,
          newProducts[prodIndex].unique_id || "",
          true,
          newProducts
        )
      }

      if (whseToChange) {
        let purchase = newProducts[prodIndex].supply_details.find((x) => x.supply_source === "Purchase")
        const holdAll = newProducts[prodIndex].supply_details.find((x) => x.supply_source === "Hold All")
        const otherWhses = newProducts[prodIndex].supply_details.filter((x) => x.supply_source === "Warehouse" && x.warehouse_name !== e.target.name)
        const originWhse = newProducts[prodIndex].supply_details.find(
          (x) => x.warehouse_name === (this.state.warehouse ? this.state.warehouse.name : "")
        )
        const qtyToOrder = this.state.productInfo!.qty_to_order
        let sumOtherWhses = 0
        otherWhses.forEach((x) => (sumOtherWhses += x.qty_to_supply))

        if (holdAll && (holdAll.checked || (e.target.checked && sumOtherWhses === qtyToOrder))) {
          return
        }

        whseToChange.checked = e.target.checked

        if (e.target.checked && purchase) {
          let qtyToMove = purchase.qty_to_supply > whseToChangeAvail ? whseToChangeAvail : purchase.qty_to_supply
          whseToChange.qty_to_supply += qtyToMove
          purchase.qty_to_supply = purchase.qty_to_supply - qtyToMove
          purchase.checked = purchase.qty_to_supply > 0
          whseToChange.type = whseToChange.warehouse_name === (originWhse ? originWhse.warehouse_name : "") ? "Pick" : "Transfer"
        } else if (purchase) {
          purchase.qty_to_supply = purchase.qty_to_supply + whseToChange.qty_to_supply
          purchase.checked = purchase.qty_to_supply > 0
          whseToChange.qty_to_supply = 0
          whseToChange.type = ""
        }
      }
    }

    this.setState({
      products: newProducts.map((prod) => this.determineCost(prod)),
      productInfo: this.determineCost(newProducts[prodIndex]),
    })
  }

  onKeyPressSupplyInput = (e: any) => {
    if (this.state.productInfo && !this.state.productInfo.qty_to_order) {
      e.preventDefault()
    }
  }

  handleSupplyInput = (name: string, value: number) => {
    if (!this.accessSupplySources()) {
      return
    }

    if (this.state.isQuote || !this.state.productInfo || !this.state.warehouse) {
      return
    }

    let newProducts: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let prodIndex = newProducts.findIndex((x) => x.unique_id === this.state.productInfo!.unique_id)

    const otherWhses = newProducts[prodIndex].supply_details.filter((x) => x.supply_source === "Warehouse" && x.warehouse_name !== name)
    const originWhse = newProducts[prodIndex].supply_details.find((x) => x.warehouse_name === this.state.warehouse!.name)
    const valueChangeTo = value
    let purchase = newProducts[prodIndex].supply_details.find((x) => x.supply_source === "Purchase")
    let holdAll = newProducts[prodIndex].supply_details.find((x) => x.supply_source === "Hold All")
    let whseToChange = newProducts[prodIndex].supply_details.find((x) => x.warehouse_name === name)
    let sumOtherWhses = 0
    otherWhses.forEach((x) => (sumOtherWhses += x.qty_to_supply))

    if (whseToChange) {
      whseToChange.qty_to_supply = valueChangeTo

      if (holdAll && holdAll.checked) {
        holdAll.checked = false
        holdAll.qty_to_supply = 0
      }

      if (purchase) {
        purchase.qty_to_supply = this.state.productInfo.qty_to_order - sumOtherWhses - valueChangeTo
        purchase.checked = purchase.qty_to_supply > 0
      }

      whseToChange.checked = whseToChange.qty_to_supply > 0
      whseToChange.type = whseToChange.checked
        ? whseToChange.warehouse_name === (originWhse ? originWhse.warehouse_name : "")
          ? "Pick"
          : "Transfer"
        : ""
    }

    this.setState({
      products: newProducts.map((prod) => this.determineCost(prod)),
      productInfo: this.determineCost(newProducts[prodIndex]),
    })
  }

  // remove from qty available across duplicate products
  //    detail:   the supply detail in question
  //    prodId:   INT, the id of the product in question
  //    uniqueId: STRING, the unique id of the product in question
  //    exclude:  BOOL, if true do not remove from asking detail's quantities
  //    prods:    products to compare against
  qtyAvailableRemaining = (detail: supplyDetail, prodId: number, uniqueId: string, exclude: boolean, prods: Array<product>) => {
    return detail.qty_available || detail.qty_available === 0
      ? detail.qty_available -
          prods
            .filter((prod) => prod.id === prodId && (exclude ? prod.unique_id !== uniqueId : true))
            .map((prod) => {
              return prod.supply_details
                .filter((x) => x.warehouse_name === detail.warehouse_name)
                .map((detail) => {
                  return detail.qty_to_supply ? detail.qty_to_supply : 0
                })
                .reduce((acc, i) => acc + i, 0)
            })
            .reduce((acc, i) => acc + i, 0)
      : 0
  }

  deleteProduct = (prod: product) => {
    const deleteIndex = this.state.products.findIndex((x) => x.unique_id === prod.unique_id)
    let newArray: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    newArray.splice(deleteIndex, 1)

    this.setState({
      products: newArray,
    })
  }

  onProductSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.setState({
      products: arrayMove(this.state.products, oldIndex, newIndex),
    })
  }

  showProductInfo = (prod: product) => {
    this.setState({
      productInfo: prod,
    })
  }

  hideProductInfo = () => {
    // check for errors in quantities before closing productInfo
    if (!this.state.productInfo) return

    const qtyInvoiced = this.state.productInfo.qty_invoiced ? this.state.productInfo.qty_invoiced : 0
    const qtyToOrder = this.state.productInfo.qty_to_order ? this.state.productInfo.qty_to_order : 0
    const qtyToInvoice = this.state.productInfo.qty_to_invoice ? this.state.productInfo.qty_to_invoice : 0

    if (qtyToInvoice > qtyToOrder) {
      this.showDialog({
        type: "alert",
        title: "Product Quantity Error",
        message: "You cannot invoice more than ordered.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (qtyInvoiced > qtyToOrder && qtyToOrder >= 0) {
      this.showDialog({
        type: "alert",
        title: "Product Quantity Error",
        message: "You cannot order less than are already invoiced.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (qtyToOrder > 0 && qtyToOrder % this.state.productInfo.sell_mult > 0) {
      this.showDialog({
        type: "alert",
        title: "Sell Multiple Error",
        message: "You cannot sell outside of the sell multiple.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (this.state.productInfo.supply_details.find((x) => x.supply_source === "Purchase")) {
      // will be true even for empty purchase quantities
      const purchaseMultiple = this.state.productInfo.pur_mult
      const purchaseWhse = this.state.productInfo.supply_details.find((x) => x.supply_source === "Purchase")
      const purchaseQty = purchaseWhse ? purchaseWhse.qty_to_supply : 0
      const whses = this.state.productInfo.supply_details.filter((x) => x.supply_source === "Warehouse")

      if (purchaseQty < 0) {
        this.showDialog({
          type: "alert",
          title: "Purchase Quantity Error",
          message: "Your supply sources do not add up. Change your total quantity to order, or your supply sources.",
          handleResponse: this.hideDialog,
        })
        return
      }

      let breakFunction = false

      whses.forEach((whse) => {
        if (whse.qty_to_supply > (whse.qty_available || 0) && (whse.qty_available || 0) >= 0) {
          this.showDialog({
            type: "alert",
            title: "Warehouse Quantity Error",
            message: "You do not have enough stock in " + whse.warehouse_name + " to fulfill your asking quantity.",
            handleResponse: this.hideDialog,
          })
          breakFunction = true
          return
        }
      })

      if (breakFunction) {
        return
      }

      if (purchaseQty > 0 && purchaseQty % purchaseMultiple > 0) {
        this.showDialog({
          type: "confirm",
          title: "Purchase Warning",
          message: "You are purchasing outside the purchase multiple. Would you like to continue?",
          handleResponse: (response) => {
            if (response) {
              this.setState({
                productInfo: undefined,
              })
            }
            this.hideDialog()
          },
        })
      } else {
        // all good, hide product info
        this.setState({
          productInfo: undefined,
        })
      }
    } else {
      // all good, hide product info
      this.setState({
        productInfo: undefined,
      })
    }
  }

  handleProductInputChange = (name: string, value: string | number, prod: product) => {
    let newArray: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    const i = newArray.findIndex((x) => x.unique_id === prod.unique_id)
    const inputName = name

    if (prod.header) {
      newArray[i][inputName] = value
      this.setState({
        products: newArray,
      })
      return
    }

    if (inputName === "margin") {
      // margin changes the selling price
      newArray[i].selling_price = calculateSell(parseFloat(value.toString()), prod.cost)
    } else if (inputName === "selling_price") {
      // selling price changes the margin
      newArray[i].margin = calculateMargin(value, prod.cost, prod.discount)
    } else if (inputName === "discount") {
      // discount changes the margin
      newArray[i].margin = calculateMargin(prod.selling_price, prod.cost, value)
    }

    if (inputName === "qty_to_order") {
      newArray[i].qty_to_order = parseInt(value.toString(), 10)
      newArray[i].qty_to_invoice = parseInt(value.toString(), 10)
      // quantity change updates the supply sources and the cost
      newArray[i] = this.determineSources(newArray[i])
    } else {
      // all other inputs need to update
      newArray[i][inputName] = value
    }

    this.setState({
      products: newArray,
      productInfo: this.state.productInfo ? newArray[i] : undefined,
    })
  }

  /*
   █████  ████████ ████████  █████   ██████ ██   ██ ███    ███ ███████ ███    ██ ████████ ███████
  ██   ██    ██       ██    ██   ██ ██      ██   ██ ████  ████ ██      ████   ██    ██    ██
  ███████    ██       ██    ███████ ██      ███████ ██ ████ ██ █████   ██ ██  ██    ██    ███████
  ██   ██    ██       ██    ██   ██ ██      ██   ██ ██  ██  ██ ██      ██  ██ ██    ██         ██
  ██   ██    ██       ██    ██   ██  ██████ ██   ██ ██      ██ ███████ ██   ████    ██    ███████
  */

  hideAttachmentInfo = () => {
    this.setState({
      attachmentInfo: undefined,
    })
  }

  showAttachmentInfo = (type: string) => {
    let newAttachmentInfo = {
      title: type + " attachments:",
      type: type,
      result: {
        columns: [],
        rows: [],
      },
    }

    this.setState({ loadingAttachments: true, attachmentInfo: newAttachmentInfo }, () => {
      fireAxios(
        {
          method: "post",
          url: "orders/attachments/" + type,
          data: {
            product_id: this.state.productInfo ? this.state.productInfo.id : 0,
            order_id: this.state.existingOrderId,
            order_product_id: this.state.productInfo ? this.state.productInfo.order_product_id : 0,
          },
        },
        (response) => {
          newAttachmentInfo.result = response.data

          this.setState({
            loadingAttachments: false,
            attachmentInfo: newAttachmentInfo,
          })
        }
      )
    })
  }

  /*
  ██████  ██  █████  ██       ██████   ██████
  ██   ██ ██ ██   ██ ██      ██    ██ ██
  ██   ██ ██ ███████ ██      ██    ██ ██   ███
  ██   ██ ██ ██   ██ ██      ██    ██ ██    ██
  ██████  ██ ██   ██ ███████  ██████   ██████
  */

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  showAccessDialog = (props: accessDialogProps) => {
    this.setState({
      accessDialog: props,
    })
  }

  hideAccessDialog = () => {
    this.setState({
      accessDialog: null,
    })
  }

  accessSupplySources = () => {
    if (
      !this.state.accessSupplySources &&
      this.props.user &&
      this.props.user.permissions &&
      !this.props.user.permissions.admin &&
      !this.props.user.permissions.change_supply_sources
    ) {
      this.showAccessDialog({
        message: "Cannot manually change supply sources.",
        userId: this.props.user.user_id,
        permissionType: "change_supply_sources",
        messageForQueue: `For Order #: ${this.state.existingOrderId || "New Order"}`,
        handleResponse: (response) => {
          if (!response) {
            this.hideAccessDialog()
          } else {
            this.setState(
              {
                accessSupplySources: true,
              },
              () => this.hideAccessDialog()
            )
          }
        },
      })
      return false
    } else {
      return true
    }
  }

  accessChangeSalesrep = () => {
    if (
      !this.state.accessChangeSalesrep &&
      this.props.user &&
      this.props.user.permissions &&
      !this.props.user.permissions.admin &&
      !this.props.user.permissions.change_salesrep_orders
    ) {
      this.showAccessDialog({
        message: "Cannot change salesrep.",
        userId: this.props.user.user_id,
        permissionType: "change_salesrep_orders",
        messageForQueue: `For Order #: ${this.state.existingOrderId || "New Order"}`,
        handleResponse: (response) => {
          if (!response) {
            this.hideAccessDialog()
          } else {
            this.setState(
              {
                accessChangeSalesrep: true,
              },
              () => this.hideAccessDialog()
            )
          }
        },
      })
      return false
    } else {
      return true
    }
  }

  /*
 ██████  ██████  ██████  ███████ ██████      ███████ ██████  ███████  ██████ ██ ███████ ██  ██████
██    ██ ██   ██ ██   ██ ██      ██   ██     ██      ██   ██ ██      ██      ██ ██      ██ ██
██    ██ ██████  ██   ██ █████   ██████      ███████ ██████  █████   ██      ██ █████   ██ ██
██    ██ ██   ██ ██   ██ ██      ██   ██          ██ ██      ██      ██      ██ ██      ██ ██
 ██████  ██   ██ ██████  ███████ ██   ██     ███████ ██      ███████  ██████ ██ ██      ██  ██████
*/

  addOrderSpecificProduct = () => {
    let prodsCopy: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let newOrderSpecificProd = this.initializeProd({ order_specific: true } as product)

    prodsCopy.push(newOrderSpecificProd)

    this.setState({
      products: prodsCopy,
    })
  }

  handleOSInput = (name: string, value: string | number, prod: product) => {
    let prodsCopy: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let prodCopy = prodsCopy.find((x) => x.unique_id === prod.unique_id)

    if (!prodCopy) return

    prodCopy[name] = value

    if (name === "cost" && prodCopy.selling_price !== 0) {
      prodCopy.selling_price = calculateSell(prodCopy.margin, parseFloat(value.toString()))
    }

    this.setState({
      products: prodsCopy,
      productInfo: prodCopy,
    })
  }

  chooseVendorForOS = () => {
    this.handleSearchDepth(1)
    this.setState({
      toChooseVendor: true,
    })
  }

  vendorSearchResult = (vendor: any) => {
    if (vendor && vendor.product_line) {
      this.setState(
        {
          toChooseVendor: false,
        },
        () => {
          this.handleSearchDepth(0)
          let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))
          let newInfo: product = JSON.parse(JSON.stringify(this.state.productInfo))
          let newProd = newProds.find((x) => x.unique_id === newInfo.unique_id)

          if (!newProd) return

          newProd.line = vendor.product_line
          newInfo.line = vendor.product_line

          this.setState({
            products: newProds,
            productInfo: newInfo,
          })
        }
      )
    } else {
      this.showDialog({
        title: "Vendor not valid.",
        message: "This vendor does not have a product line.",
        type: "alert",
        handleResponse: this.hideDialog,
      })
      return
    }
  }

  /*
  ██████  ██████   ██████  ██████  ██    ██  ██████ ████████     ██   ██ ███████  █████  ██████  ███████ ██████  ███████
  ██   ██ ██   ██ ██    ██ ██   ██ ██    ██ ██         ██        ██   ██ ██      ██   ██ ██   ██ ██      ██   ██ ██
  ██████  ██████  ██    ██ ██   ██ ██    ██ ██         ██        ███████ █████   ███████ ██   ██ █████   ██████  ███████
  ██      ██   ██ ██    ██ ██   ██ ██    ██ ██         ██        ██   ██ ██      ██   ██ ██   ██ ██      ██   ██      ██
  ██      ██   ██  ██████  ██████   ██████   ██████    ██        ██   ██ ███████ ██   ██ ██████  ███████ ██   ██ ███████
  */

  addProductHeader = () => {
    let prodsCopy: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let newHeader = this.initializeProd({ header: true } as product)

    prodsCopy.push(newHeader)

    this.setState({
      products: prodsCopy,
    })
  }

  /*
   ██████  ██    ██  ██████  ████████ ███████
  ██    ██ ██    ██ ██    ██    ██    ██
  ██    ██ ██    ██ ██    ██    ██    █████
  ██ ▄▄ ██ ██    ██ ██    ██    ██    ██
   ██████   ██████   ██████     ██    ███████
      ▀▀
  */

  convertOrderQuote = () => {
    let isQuote = !this.state.isQuote
    let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))

    this.setState({ isQuote: isQuote }, () => {
      newProds = newProds.map((prod, i, array) => {
        return (array[i] = this.determineSources(prod, array))
      })
      this.setState({
        products: newProds,
      })
    })
  }

  /*
  ██       ██████  ████████     ██████  ██████  ██  ██████ ███████
  ██      ██    ██    ██        ██   ██ ██   ██ ██ ██      ██
  ██      ██    ██    ██        ██████  ██████  ██ ██      █████
  ██      ██    ██    ██        ██      ██   ██ ██ ██      ██
  ███████  ██████     ██        ██      ██   ██ ██  ██████ ███████
  */

  addLotPrice = () => {
    let newArray = JSON.parse(JSON.stringify(this.state.lotPrices))

    newArray.push({
      title: "",
      selling_price: 0,
      cost: 0,
      comment: "",
    })

    this.setState({
      lotPrices: newArray,
    })
  }

  deleteLotPrice = (lotPrice: lotPrice) => {
    const deleteIndex = this.state.lotPrices.findIndex((x) => x.unique_id === lotPrice.unique_id)
    let newArray: Array<lotPrice> = JSON.parse(JSON.stringify(this.state.lotPrices))
    newArray.splice(deleteIndex, 1)

    this.setState({
      lotPrices: newArray,
    })
  }

  handleLotPriceCheck = () => {
    if (this.state.lotPriceChecked && this.state.lotPrices.length > 0) {
      if (
        this.state.lotPrices.length === 1 &&
        this.state.lotPrices[0].selling_price === 0 &&
        this.state.lotPrices[0].comment === "" &&
        this.state.lotPrices[0].title === "" &&
        this.state.lotPrices[0].cost === 0
      ) {
        this.setState({
          lotPrices: [],
          lotPriceChecked: false,
        })
      } else {
        this.showDialog({
          type: "confirm",
          title: "Lot Price Change Warning",
          message: "Pricing will be cleared. Are you sure you want to remove lot pricing?",
          handleResponse: (response) => {
            if (response) {
              this.setState({
                lotPrices: [],
                lotPriceChecked: false,
              })
            }
            this.hideDialog()
          },
        })
      }
    } else if (!this.state.lotPriceChecked) {
      this.addLotPrice()
      this.setState({
        lotPriceChecked: true,
      })
    } else {
      this.setState({
        lotPriceChecked: false,
      })
    }
  }

  handleLotPriceInputChange = (name: string, value: string | number, lotPrice: lotPrice) => {
    let lotPrices: Array<lotPrice> = JSON.parse(JSON.stringify(this.state.lotPrices))
    let foundLot = lotPrices.find((x) => x.unique_id === lotPrice.unique_id)

    if (!foundLot) return

    switch (name) {
      case "cost":
        foundLot.margin = calculateMargin(foundLot.selling_price, value)
        break
      case "margin":
        foundLot.selling_price = calculateSell(parseFloat(value.toString()), foundLot.cost)
        break
      case "selling_price":
        foundLot.margin = calculateMargin(value, foundLot.cost)
        break
      default:
      // do nothing extra
    }

    foundLot[name] = value

    this.setState({
      lotPrices: lotPrices,
      lotPriceInfo: this.state.lotPriceInfo ? foundLot : undefined,
    })
  }

  onLotPriceSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.setState({
      lotPrices: arrayMove(this.state.lotPrices, oldIndex, newIndex),
    })
  }

  showLotPriceInfo = (lotPrice: lotPrice) => {
    this.setState({
      lotPriceInfo: lotPrice,
    })
  }

  hideLotPriceInfo = () => {
    this.setState({
      lotPriceInfo: undefined,
    })
  }

  /*
  ██████   █████  ██    ██ ███    ███ ███████ ███    ██ ████████ ███████
  ██   ██ ██   ██  ██  ██  ████  ████ ██      ████   ██    ██    ██
  ██████  ███████   ████   ██ ████ ██ █████   ██ ██  ██    ██    ███████
  ██      ██   ██    ██    ██  ██  ██ ██      ██  ██ ██    ██         ██
  ██      ██   ██    ██    ██      ██ ███████ ██   ████    ██    ███████
  */

  newPayment = () => {
    let newArray: Array<payment> = JSON.parse(JSON.stringify(this.state.payments))

    newArray.push({
      line_index: newArray.length + 1,
      date: "Not Saved",
      total: 0,
      deposit: 0,
      applied_deposit: 0,
      change_given: 0,
      comment: "",
      types: [
        { name: "Visa", amount: 0, detail: "", expiry: "" },
        { name: "Debit", amount: 0, detail: "", expiry: "" },
        { name: "Cash", amount: 0, detail: "", expiry: "" },
        { name: "Mastercard", amount: 0, detail: "", expiry: "" },
        { name: "Cheque", amount: 0, detail: "", expiry: "" },
        { name: "EFT", amount: 0, detail: "", expiry: "" },
      ],
      saved: false,
      unique_id: shortid.generate(),
    })

    this.setState({
      payments: newArray,
    })
  }

  deletePayment = (payment: payment) => {
    const deleteIndex = this.state.payments.findIndex((x) => x.unique_id === payment.unique_id)
    let newArray: Array<payment> = JSON.parse(JSON.stringify(this.state.payments))
    newArray.splice(deleteIndex, 1)

    // rename all unsaved payments to numerical order
    newArray.forEach((x, i) => {
      if (!x.saved) {
        x.line_index = i + 1
      }
    })

    this.setState({
      payments: newArray,
    })
  }

  showPaymentInfo = (payment: payment) => {
    this.setState({
      paymentInfo: payment,
    })
  }

  hidePaymentInfo = () => {
    this.setState({
      paymentInfo: undefined,
    })
  }

  handlePaymentComment = (payment: payment, e: any) => {
    let newArray: Array<payment> = JSON.parse(JSON.stringify(this.state.payments))
    const index = newArray.findIndex((x) => x.unique_id === payment.unique_id)

    newArray[index][e.target.name] = e.target.value

    this.setState({
      payments: newArray,
    })
  }

  handlePaymentInfo = (name: string, value: string | number) => {
    let newArray: Array<payment> = JSON.parse(JSON.stringify(this.state.payments))
    let payment = newArray.find((x) => x.unique_id === (this.state.paymentInfo ? this.state.paymentInfo.unique_id : ""))

    if (payment) {
      payment[name] = value
    }

    this.setState({
      payments: newArray,
      paymentInfo: payment,
    })
  }

  handlePaymentType = (name: string, value: string | number, type: paymentType) => {
    let newArray: Array<payment> = JSON.parse(JSON.stringify(this.state.payments))
    let newPayment = newArray.find((x) => x.unique_id === (this.state.paymentInfo ? this.state.paymentInfo.unique_id : ""))

    if (!newPayment) return

    let newType = newPayment.types.find((x) => x.name === type.name)

    if (!newType) return

    newType[name] = value

    if (newType.name === "Cash") {
      newPayment.change_given = this.changeDue(newPayment)
    }

    this.setState({
      payments: newArray,
      paymentInfo: newPayment,
    })
  }

  // handleEnterKey = (type:"applied_deposit" | "deposit" | paymentType, payment:payment, e:any) => {
  //   if (e.key === "Enter") {
  //     let newArray:Array<payment> = JSON.parse(JSON.stringify(this.state.payments))
  //     let newPayment = newArray.find(x => x.unique_id === payment.unique_id)
  //
  //     if (!newPayment) return
  //
  //     if (type === "deposit") {
  //       newPayment.deposit = this.totalEstimatedBO()
  //     } else if (type === "applied_deposit") {
  //       newPayment.applied_deposit = this.totalInvoiceAmount()
  //     } else {
  //       newPayment.types.forEach(x => {
  //         if (x.name === type.name) {
  //           let previousValue = x.amount ? x.amount : 0
  //           x.amount = previousValue + this.amountDue(payment)
  //         }
  //
  //         if (x.name === "Cash") {
  //           newPayment!.change_given = this.changeDue(newPayment as payment)
  //         }
  //       })
  //     }
  //
  //     this.setState({
  //       payments: newArray,
  //       paymentInfo: newPayment
  //     })
  //   }
  // }

  /*
  ██ ███    ██ ██    ██  ██████  ██  ██████ ███████ ███████
  ██ ████   ██ ██    ██ ██    ██ ██ ██      ██      ██
  ██ ██ ██  ██ ██    ██ ██    ██ ██ ██      █████   ███████
  ██ ██  ██ ██  ██  ██  ██    ██ ██ ██      ██           ██
  ██ ██   ████   ████    ██████  ██  ██████ ███████ ███████
  */

  hideInvoiceInfo = () => {
    this.setState({
      invoiceInfo: undefined,
    })
  }

  showInvoiceInfo = (invoice: invoice) => {
    this.setState({
      invoiceInfo: invoice,
    })
  }

  /*
  ███████ ██    ██ ██████  ███    ███ ██ ████████         ██     ███████  █████  ██    ██ ███████
  ██      ██    ██ ██   ██ ████  ████ ██    ██           ██      ██      ██   ██ ██    ██ ██
  ███████ ██    ██ ██████  ██ ████ ██ ██    ██          ██       ███████ ███████ ██    ██ █████
       ██ ██    ██ ██   ██ ██  ██  ██ ██    ██         ██             ██ ██   ██  ██  ██  ██
  ███████  ██████  ██████  ██      ██ ██    ██        ██         ███████ ██   ██   ████   ███████
  */

  submitSaveOrder = (invoicePack?: "invoice" | "pack") => {
    if (!this.state.isQuote && (this.state.shipTerm === "no_selection" || this.state.details.ship_via === "no_selection")) {
      this.showDialog({
        type: "alert",
        title: "Ship Details Empty",
        message: "Ship term and ship via must be clarified.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (!this.state.isQuote && !this.state.details.po_number) {
      this.showDialog({
        type: "alert",
        title: "PO Number Empty",
        message: "The PO number cannot be empty for an order.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (invoicePack && this.totalInvoiceAmount() === 0) {
      this.showDialog({
        type: "alert",
        title: "Invoice Error",
        message: "Nothing to invoice.",
        handleResponse: this.hideDialog,
      })
      return
    }

    const unsavedPayment = this.state.payments.find((x) => !x.saved)
    const amountDue = parseFloat(this.amountDue(unsavedPayment).toFixed(2))

    if (this.state.customer && this.state.customer.cod_status && unsavedPayment && this.totalDeposits(unsavedPayment) < this.totalEstimatedBO()) {
      this.showDialog({
        type: "alert",
        title: "Deposit Error",
        message: "Cannot invoice or pick. Deposit amount is less than backordered amount.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (this.state.customer && this.state.customer.cod_status && this.amountDue(unsavedPayment ? unsavedPayment : undefined) > 0) {
      this.showDialog({
        type: "alert",
        title: "Payment Error",
        message: "Cannot invoice or pick. This cash account's order has insufficient payments.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (unsavedPayment && !invoicePack && unsavedPayment.applied_deposit && unsavedPayment.applied_deposit > 0) {
      this.showDialog({
        type: "alert",
        title: "Deposit Error",
        message: "Cannot pick. There is a deposit being applied.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (unsavedPayment && amountDue > 0 && this.tenderEntered(unsavedPayment) === 0) {
      this.showDialog({
        type: "alert",
        title: "Amount Due Error",
        message: "Cannot invoice or pick. Amount due is greater than 0 and there is no tender entered.",
        handleResponse: this.hideDialog,
      })
      return
    }

    const changeDue = parseFloat(this.changeDue(unsavedPayment).toFixed(2))
    const changeDif = amountDue + changeDue

    if (this.state.customer && this.state.customer.cod_status && (changeDif > 0.05 || changeDif < -0.05) && amountDue < 0) {
      this.showDialog({
        type: "alert",
        title: "Save/Invoice Error",
        message: "Cannot save/invoice. Amount due is owing.",
        handleResponse: this.hideDialog,
      })
      return
    }

    let dataToSubmit = {
      id: this.state.existingOrderId,
      customer_id: this.state.customer ? this.state.customer.id : undefined,
      warehouse_id: this.state.warehouse ? this.state.warehouse.id : undefined,
      is_quote: this.state.isQuote,
      products: this.state.products,
      lot_prices: this.state.lotPrices,
      payments: this.state.payments,
      tax_prov_code: this.state.selectedSalesTax,
      details: this.state.details,
      ship_to: this.state.shipTo,
      ship_term: this.state.shipTerm,
      download: this.state.download,
      print: this.state.print,
      pack: invoicePack === "pack",
      invoice: !!invoicePack,
      revision: this.state.revision,
      takenBy: this.state.takenBy,
      salesrep_id: this.state.salesrep ? this.state.salesrep.id : undefined,
      cancelled: this.state.cancelled,
    }

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: this.state.existingOrderId ? "orders/update" : "orders/create", data: dataToSubmit }, (response) => {
        const orderId = this.state.existingOrderId ? JSON.parse(JSON.stringify(this.state.existingOrderId)) : response.data
        this.setState(initialState, () => {
          this.getAllOrderDetails(orderId, () => {
            let latestInvoiceId = 0
            this.state.invoices.forEach((x) => (latestInvoiceId = x.id > latestInvoiceId ? x.id : latestInvoiceId))

            if (dataToSubmit.download) {
              if (invoicePack === "invoice") {
                this.downloadPDF("invoice", latestInvoiceId)
              } else if (invoicePack === "pack") {
                this.downloadPDF("packing", latestInvoiceId)
              } else if (dataToSubmit.is_quote) {
                this.downloadPDF("quote", orderId)
              } else {
                this.downloadPDF("picking", orderId)
              }
            }
          })
        })
      })
    })
  }

  cancelOrder = () => {
    this.showDialog({
      type: "confirm",
      title: "Cancellation Warning",
      message: `Change cannot be reverted. Are you sure you want to cancel this ${this.state.isQuote ? "quote" : "order"}`,
      handleResponse: (response) => {
        if (response) {
          this.setState({ loading: true }, () => {
            fireAxios({ method: "post", url: "orders/cancel", data: { id: this.state.existingOrderId } }, (response) => {
              const orderId = this.state.existingOrderId ? JSON.parse(JSON.stringify(this.state.existingOrderId)) : response.data
              this.setState(initialState, () => {
                this.getAllOrderDetails(orderId)
              })
            })
          })
        }
        this.hideDialog()
      },
    })
  }

  /*
 ██████  █████  ██       ██████ ██    ██ ██       █████  ████████ ██  ██████  ███    ██ ███████
██      ██   ██ ██      ██      ██    ██ ██      ██   ██    ██    ██ ██    ██ ████   ██ ██
██      ███████ ██      ██      ██    ██ ██      ███████    ██    ██ ██    ██ ██ ██  ██ ███████
██      ██   ██ ██      ██      ██    ██ ██      ██   ██    ██    ██ ██    ██ ██  ██ ██      ██
 ██████ ██   ██ ███████  ██████  ██████  ███████ ██   ██    ██    ██  ██████  ██   ████ ███████
*/

  totalCost = () => {
    let totalCost = 0

    this.state.products.forEach((prod) => (totalCost += ((prod.cost || 0) / prod.price_per) * (prod.qty_to_order - (prod.qty_invoiced || 0))))
    this.state.lotPrices.forEach((lot) => (totalCost += (lot.cost || 0) - ((lot.amount_invoiced || 0) / (lot.selling_price || 0)) * (lot.cost || 0)))
    this.state.invoices.forEach((invoice) => {
      invoice.products.forEach((prod) => (totalCost += (prod.cost * prod.quantity) / prod.price_per))
      invoice.lot_prices.forEach((lot) => (totalCost += lot.cost))
    })

    return totalCost
  }

  sellPrice = () => {
    let sellPrice = 0

    this.state.products.forEach((prod) => {
      sellPrice += ((prod.selling_price || 0) * ((prod.qty_to_order || 0) - (prod.qty_invoiced || 0))) / prod.price_per
    })

    this.state.lotPrices.forEach((lot) => {
      sellPrice += (lot.selling_price || 0) - (lot.amount_invoiced || 0)
    })

    this.state.invoices.forEach((invoice) => {
      invoice.products.forEach((prod) => (sellPrice += (prod.selling_price * prod.quantity) / prod.price_per))
      invoice.lot_prices.forEach((lot) => (sellPrice += lot.selling_price))
    })

    return sellPrice
  }

  sellPriceDiscounted = () => {
    let sellPrice = 0

    this.state.products.forEach((prod) => {
      sellPrice +=
        (parseFloat(((prod.selling_price || 0) - ((prod.selling_price || 0) * (prod.discount || 0)) / 100).toFixed(2)) *
          ((prod.qty_to_order || 0) - (prod.qty_invoiced || 0))) /
        prod.price_per
    })

    this.state.lotPrices.forEach((lot) => {
      sellPrice += (lot.selling_price || 0) - (lot.amount_invoiced || 0)
    })

    // apply overall discount before invoices, as invoices can have their own overall discount
    sellPrice *= this.state.details.discount ? 1 - this.state.details.discount / 100 : 1

    this.state.invoices.forEach((invoice) => {
      invoice.products.forEach((prod) => (sellPrice += (prod.selling_price * prod.quantity * (1 - (invoice.discount || 0) / 100)) / prod.price_per))
      invoice.lot_prices.forEach((lot) => (sellPrice += lot.selling_price * (1 - (invoice.discount || 0) / 100)))
    })

    return sellPrice
  }

  discount = () => {
    return (this.sellPrice() - this.productDiscountTotal()) * (this.state.details.discount ? this.state.details.discount / 100 : 0)
  }

  productDiscountTotal = () => {
    let totalDisc = 0

    this.state.products.forEach((prod) => {
      totalDisc +=
        (parseFloat(((prod.selling_price || 0) - (prod.selling_price || 0) * (1 - (prod.discount || 0) / 100)).toFixed(2)) *
          (prod.qty_to_order || 0)) /
        prod.price_per
    })

    return totalDisc
  }

  totalMargin = () => {
    if (this.totalCost() === 0 && this.sellPriceDiscounted() === 0) {
      return 0
    } else if (this.totalCost() === 0) {
      return 0
    } else if (this.sellPriceDiscounted() === 0) {
      return 100
    }
    return (1 - this.totalCost() / this.sellPriceDiscounted()) * 100
  }

  totalSellPrice = () => {
    return this.sellPriceDiscounted() * this.combinedTaxRate()
  }

  combinedTaxRate = () => {
    let taxRate = 0
    let foundTax = this.state.salesTaxes.find((tax) => this.state.selectedSalesTax === tax.province_code)
    foundTax && foundTax.types.forEach((type) => (taxRate += type.percent / 100))
    return 1 + taxRate
  }

  totalEstimatedBO = () => {
    let estimatedBO = 0

    this.state.products.forEach((prod) => {
      prod.supply_details.forEach((detail) => {
        let sellingPrice = prod.selling_price ? prod.selling_price : 0
        let qtyToInvoice = prod.qty_to_invoice ? prod.qty_to_invoice : 0
        let qtyToSupply = detail.qty_to_supply ? detail.qty_to_supply : 0
        qtyToInvoice = qtyToInvoice > qtyToSupply ? qtyToSupply : qtyToInvoice
        let prodDiscount = 1 - (prod.discount || 0) / 100
        let pricePer = prod.price_per
        if (detail.type !== "Pick") {
          // Transfers and Purchases
          estimatedBO += (sellingPrice * qtyToSupply * prodDiscount) / pricePer
        } else if (detail.type === "Pick") {
          // partially invoiced picking warehouse
          estimatedBO += (sellingPrice * (qtyToSupply - qtyToInvoice) * prodDiscount) / pricePer
        }
      })
    })

    this.state.lotPrices.forEach((lotPrice) => {
      let sellingPrice = lotPrice.selling_price ? lotPrice.selling_price : 0
      let amountToInvoice = lotPrice.amount_to_invoice ? lotPrice.amount_to_invoice : 0
      let amountInvoiced = lotPrice.amount_invoiced ? lotPrice.amount_invoiced : 0
      estimatedBO += sellingPrice - amountToInvoice - amountInvoiced
    })

    let overallDiscount = this.state.details.discount ? 1 - this.state.details.discount / 100 : 1
    estimatedBO *= overallDiscount
    estimatedBO *= this.combinedTaxRate()

    return estimatedBO
  }

  totalInvoiceAmount = () => {
    let invoiceAmount = 0

    this.state.products.forEach((prod) => {
      prod.supply_details.forEach((detail) => {
        if (detail.type === "Pick") {
          let sellingPrice = prod.selling_price ? prod.selling_price : 0
          let qtyToInvoice = prod.qty_to_invoice ? prod.qty_to_invoice : 0
          let qtyToSupply = detail.qty_to_supply ? detail.qty_to_supply : 0
          qtyToInvoice = qtyToInvoice > qtyToSupply ? qtyToSupply : qtyToInvoice
          let prodDiscount = prod.discount ? 1 - prod.discount / 100 : 1
          let pricePer = prod.price_per ? prod.price_per : 1
          invoiceAmount += (sellingPrice * qtyToInvoice * prodDiscount) / pricePer
        }
      })
    })

    this.state.lotPrices.forEach((lotPrice) => {
      let amountToInvoice = lotPrice.amount_to_invoice ? lotPrice.amount_to_invoice : 0
      invoiceAmount += amountToInvoice
    })

    let overallDiscount = this.state.details.discount ? 1 - this.state.details.discount / 100 : 1
    invoiceAmount *= overallDiscount
    invoiceAmount *= this.combinedTaxRate()

    return invoiceAmount
  }

  totalInvoicedAmount = () => {
    let invoicedTotal = 0
    this.state.invoices.forEach((invoice) => {
      let invoiceSubTotal = 0
      invoice.products.forEach((prod) => (invoiceSubTotal += (prod.quantity * prod.selling_price) / prod.price_per))
      invoice.lot_prices.forEach((lot) => (invoiceSubTotal += lot.selling_price))
      invoiceSubTotal *= 1 + (invoice.tax_rate || 0) / 100
      invoiceSubTotal *= 1 - (invoice.discount || 0) / 100
      invoicedTotal += invoiceSubTotal
    })
    return invoicedTotal
  }

  tenderEntered = (payment?: payment) => {
    if (payment) {
      return payment.types.reduce((acc, curr) => {
        return acc + (curr.amount ? curr.amount : 0)
      }, 0)
    }

    return 0
  }

  otherTenderEntered = () => {
    let total = 0
    this.state.payments.forEach((pay) => {
      if (pay.saved) {
        total += pay.types.reduce((acc, curr) => {
          return acc + (curr.amount ? curr.amount : 0)
        }, 0)

        total -= pay.change_given ? pay.change_given : 0
      }
    })
    return total
  }

  otherDeposits = () => {
    let total = 0
    this.state.payments.forEach((pay) => {
      total += pay.saved ? (pay.deposit ? pay.deposit : 0) : 0
      total -= pay.saved ? (pay.applied_deposit ? pay.applied_deposit : 0) : 0
    })
    return total
  }

  currentDeposits = (payment?: payment) => {
    let deposit = payment && payment.deposit ? payment.deposit : 0
    let applyDeposit = payment && payment.applied_deposit ? payment.applied_deposit : 0
    return deposit - applyDeposit
  }

  totalDeposits = (payment?: payment) => {
    return this.otherDeposits() + this.currentDeposits(payment)
  }

  amountDue = (payment?: payment) => {
    if (payment && payment.saved) {
      return 0
    } else {
      let due =
        this.totalInvoiceAmount() + this.totalInvoicedAmount() + this.totalDeposits(payment) - this.tenderEntered(payment) - this.otherTenderEntered()
      due = due > -0.05 && due < 0.05 ? 0 : due
      return due
    }
  }

  changeDue = (payment?: payment) => {
    if (!payment) {
      return 0
    }

    let cashType = payment.types.find((x) => x.name === "Cash")
    if (cashType) {
      const cashTender = cashType.amount ? cashType.amount : 0
      const amountDue = parseFloat(this.amountDue(payment).toFixed(2))

      if (cashTender > 0 && cashTender > amountDue) {
        return -Math.round(amountDue * 20) / 20
      }
    }

    return 0
  }

  /*
 ██████  ████████ ██   ██ ███████ ██████
██    ██    ██    ██   ██ ██      ██   ██
██    ██    ██    ███████ █████   ██████
██    ██    ██    ██   ██ ██      ██   ██
 ██████     ██    ██   ██ ███████ ██   ██
*/

  stopPropagation = (e: any) => {
    // prevents the overlay from closing when the body of the overlay is clicked
    e.stopPropagation()
  }

  handleFocusSelect = (e: any) => {
    e.target.select()
  }

  handleShowCosts = (e: any) => {
    this.setState({
      showCosts: !e.target.checked,
    })
  }

  handlePrintCheck = (e: any) => {
    let newState = {} as state
    newState[e.target.name] = e.target.checked
    this.setState(newState)
  }

  handleShipToChange = (e: any) => {
    let newShipTo = JSON.parse(JSON.stringify(this.state.shipTo))
    newShipTo[e.target.name] = e.target.value

    this.setState({
      shipTo: newShipTo,
    })
  }

  clearShipTo = () => {
    this.setState({
      shipTo: {
        address_1: "",
        address_2: "",
        address_3: "",
        address_4: "",
        address_5: "",
      },
    })
  }

  useBillingAddress = () => {
    let customer = JSON.parse(JSON.stringify(this.state.customer))
    this.setState({
      shipTo: {
        address_1: customer.address_1 ? customer.address_1 : "",
        address_2: customer.address_2 ? customer.address_2 : "",
        address_3: customer.city ? customer.city : "",
        address_4: customer.postal_code ? customer.postal_code : "",
        address_5: customer.country ? customer.country : "",
      },
    })
  }

  handleOrderDetailChange = (name: string, value: string | number) => {
    let newDetails = JSON.parse(JSON.stringify(this.state.details))
    newDetails[name] = value

    this.setState({
      details: newDetails,
    })
  }

  handleShipTerms = (e: any) => {
    this.setState({
      shipTerm: e.target.value,
    })
  }

  handleShipVia = (e: any) => {
    this.setState({
      details: { ...this.state.details, ship_via: e.target.value },
    })
  }

  handleSalesTax = (e: any) => {
    this.setState({
      selectedSalesTax: e.target.value,
    })
  }

  handleEscapeInfo = (e: any) => {
    if (e.key === "Escape") {
      if (this.state.attachmentInfo) {
        this.hideAttachmentInfo()
        return
      }

      // dont hide when searching for a vendor (productInfo would be hidden)
      if (this.state.productInfo && this.state.toChooseVendor) {
        return
      }

      this.state.productInfo !== null && this.hideProductInfo()
      this.state.lotPriceInfo !== null && this.hideLotPriceInfo()
      this.state.paymentInfo !== null && this.hidePaymentInfo()
      this.state.invoiceInfo !== null && this.hideInvoiceInfo()
    }
  }

  downloadPDF = (endpoint: string, id: number, callback?: () => void) => {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", responseType: "arraybuffer", url: "generatePDF/" + endpoint + "/" + id }, (response) => {
        if (response.data && response.data.byteLength > 0) {
          download("PDF", response.data, endpoint + "-" + id)
        }
        this.setState(
          {
            loading: false,
          },
          () => {
            if (callback) {
              callback()
            }
          }
        )
      })
    })
  }

  /*
██████  ███████ ███    ██ ██████  ███████ ██████
██   ██ ██      ████   ██ ██   ██ ██      ██   ██
██████  █████   ██ ██  ██ ██   ██ █████   ██████
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
██   ██ ███████ ██   ████ ██████  ███████ ██   ██
*/

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseCustomer) {
      return (
        <div>
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
          <button onClick={this.backFromSearch}>Back to Create Order</button>
          <br />
          <br />
          <CustomerSearch returnResult={this.customerSearchResult} />
        </div>
      )
    }

    if (this.state.toChooseSalesrep) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create Order</button>
          <br />
          <br />
          <SalesrepSearch returnResult={this.salesrepSearchResult} />
        </div>
      )
    }

    if (this.state.toChooseWarehouse) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create Order</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseSearchResult} />
        </div>
      )
    }

    if (this.state.toChooseProduct) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create Order</button>
          <br />
          <br />
          <ProductSearch returnResult={this.productSearchResult} />
        </div>
      )
    }

    if (this.state.toChooseVendor) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create Order</button>
          <br />
          <br />
          <VendorSearch returnResult={this.vendorSearchResult} />
        </div>
      )
    }

    /*
███████ ████████ ██    ██ ██      ███████ ███████
██         ██     ██  ██  ██      ██      ██
███████    ██      ████   ██      █████   ███████
     ██    ██       ██    ██      ██           ██
███████    ██       ██    ███████ ███████ ███████
*/

    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    const overlay: React.CSSProperties = {
      position: "fixed" /* Sit on top of the page content */,
      display: "block" /* Hidden by default */,
      width: "100%" /* Full width (cover the whole page) */,
      height: "100%" /* Full height (cover the whole page) */,
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.5)" /* Black background with opacity */,
      zIndex: 99 /* Specify a stack order in case you're using a different order for other elements */,
      cursor: "pointer" /* Add a pointer on hover */,
    }

    const overlayBody: React.CSSProperties = {
      position: "absolute",
      top: "15%",
      left: "25%",
      width: "50%",
      height: "70%",
      zIndex: 100,
      boxShadow: "0px 0px 30px #000000",
      backgroundColor: "white",
      cursor: "default",
      padding: "20px",
      overflow: "auto",
    }

    const pricingStyle: React.CSSProperties = {
      fontFamily: '"Lucida Console", Monaco, monospace',
      float: "right",
    }

    const borderlessCell: React.CSSProperties = {
      border: "0",
    }

    /*
    ███    ███  █████  ██ ███    ██     ██████  ███████ ███    ██ ██████  ███████ ██████
    ████  ████ ██   ██ ██ ████   ██     ██   ██ ██      ████   ██ ██   ██ ██      ██   ██
    ██ ████ ██ ███████ ██ ██ ██  ██     ██████  █████   ██ ██  ██ ██   ██ █████   ██████
    ██  ██  ██ ██   ██ ██ ██  ██ ██     ██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
    ██      ██ ██   ██ ██ ██   ████     ██   ██ ███████ ██   ████ ██████  ███████ ██   ██
    */

    const customer = this.state.customer
    const whse = this.state.warehouse
    let paymentTotal = 0
    let depositTotal = 0
    let invoiceTotal = 0
    const savedPayments = this.state.payments.reduce((acc, curr) => (acc += curr.saved ? 1 : 0), 0) > 0
    const unSavedPayments = this.state.payments.reduce((acc, curr) => (acc += !curr.saved ? 1 : 0), 0) > 0

    if (customer) {
      return (
        <div>
          {this.state.closed ? (
            <div style={{ color: "green", textAlign: "center" }}>
              <b>{this.state.isQuote ? "QUOTE" : "ORDER"} CLOSED</b>
              <br />
              <br />
            </div>
          ) : null}
          {this.state.cancelled ? (
            <div style={{ color: "red", textAlign: "center" }}>
              <b>{this.state.isQuote ? "QUOTE" : "ORDER"} CANCELLED</b>
              <br />
              <br />
            </div>
          ) : null}
          {this.state.existingOrderId ? (
            <div style={{ textAlign: "center" }}>
              Taken by <b>{this.state.takenBy ? `${this.state.takenBy.first_name} ${this.state.takenBy.last_name}` : `No Salesrep`}</b>
              &nbsp;on {toReadableDate(this.state.dateCreated)}
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              Taking order as <b>{this.state.takenBy ? `${this.state.takenBy.first_name} ${this.state.takenBy.last_name}` : `No Salesrep`}</b>
            </div>
          )}

          <fieldset style={{ borderLeft: "none", borderBottom: "none", borderRight: "none" }} disabled={this.state.closed || this.state.cancelled}>
            <legend style={{ textAlign: "right" }}>
              <input type="checkbox" onChange={(e) => this.handleShowCosts(e)} defaultChecked={!this.state.showCosts} />
            </legend>

            {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
            {this.state.accessDialog ? <AccessDialog {...this.state.accessDialog} /> : null}

            {/*
              ██ ███    ██ ██    ██  ██████  ██  ██████ ███████     ██ ███    ██ ███████  ██████
              ██ ████   ██ ██    ██ ██    ██ ██ ██      ██          ██ ████   ██ ██      ██    ██
              ██ ██ ██  ██ ██    ██ ██    ██ ██ ██      █████       ██ ██ ██  ██ █████   ██    ██
              ██ ██  ██ ██  ██  ██  ██    ██ ██ ██      ██          ██ ██  ██ ██ ██      ██    ██
              ██ ██   ████   ████    ██████  ██  ██████ ███████     ██ ██   ████ ██       ██████
            */}

            {this.state.invoiceInfo ? (
              <div style={overlay} onClick={() => this.hideInvoiceInfo()}>
                <div style={overlayBody} onClick={(e) => this.stopPropagation(e)}>
                  <div style={headerStyle}>
                    Invoice #{this.state.invoiceInfo.id}
                    <span style={{ fontWeight: "normal" }}>&nbsp;{this.state.invoiceInfo.date}</span>
                  </div>
                  <div>
                    <table className="searchResults">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Quantity</th>
                          {this.state.showCosts ? <th>Cost</th> : null}
                          <th>Selling Price</th>
                          <th>Discount</th>
                          {this.state.showCosts ? <th>Margin</th> : null}
                        </tr>
                      </thead>
                      {this.state.invoiceInfo.products
                        ? this.state.invoiceInfo.products.map((prod) => (
                            <tbody key={prod.id}>
                              <tr style={{ textAlign: "right", cursor: "default" }}>
                                <td style={{ textAlign: "left" }}>
                                  {prod.os_product_id ? (
                                    <span>
                                      <span style={{ color: "red" }}>
                                        <b>OS</b>&nbsp;
                                      </span>
                                      <b>
                                        {prod.line} - {prod.number}
                                      </b>{" "}
                                      - {prod.description}
                                    </span>
                                  ) : (
                                    <span>
                                      <b>
                                        <NewTabText id={prod.id || 0} link="product" text={prod.product_line + "-" + prod.product_num} />
                                      </b>{" "}
                                      {prod.description}
                                    </span>
                                  )}
                                </td>
                                <td>{prod.quantity}</td>
                                {this.state.showCosts ? <td>{toCurrency(prod.cost / prod.price_per)}</td> : null}
                                <td>{toCurrency(prod.selling_price / prod.price_per)}</td>
                                <td>% {prod.discount ? prod.discount.toFixed(2) : "0"}</td>
                                {this.state.showCosts ? <td>% {calculateMargin(prod.selling_price, prod.cost, prod.discount).toFixed(2)}</td> : null}
                              </tr>
                            </tbody>
                          ))
                        : null}
                    </table>
                    <br />
                    <br />
                    {this.state.invoiceInfo.lot_prices.length > 0 ? (
                      <table className="searchResults">
                        <thead>
                          <tr>
                            <th>Lot Price</th>
                            {this.state.showCosts ? <th>Cost</th> : null}
                            <th>Selling Price</th>
                          </tr>
                        </thead>
                        {this.state.invoiceInfo.lot_prices
                          ? this.state.invoiceInfo.lot_prices.map((lot) => (
                              <tbody key={lot.id}>
                                <tr style={{ textAlign: "right", cursor: "default" }}>
                                  <td style={{ textAlign: "left" }}>{lot.title}</td>
                                  {this.state.showCosts ? <td>{toCurrency(lot.cost)}</td> : null}
                                  <td>{toCurrency(lot.selling_price)}</td>
                                </tr>
                              </tbody>
                            ))
                          : null}
                      </table>
                    ) : null}
                    <br />
                    <br />
                    <div>
                      {this.state.invoiceInfo.discount > 0 ? <div>Overall Discount: % {this.state.invoiceInfo.discount.toFixed(2)}</div> : null}
                      <br />
                      Applied taxes: % {this.state.invoiceInfo.tax_rate ? this.state.invoiceInfo.tax_rate.toFixed(2) : "0.00"}
                    </div>
                    <br />
                    <br />
                    <div>
                      <div
                        onClick={() => this.downloadPDF("packing", this.state.invoiceInfo ? this.state.invoiceInfo.id : 0)}
                        style={{ cursor: "pointer", textDecoration: "underline" }}
                      >
                        Download Packing
                      </div>
                      <br />
                      <div
                        onClick={() => this.downloadPDF("invoice", this.state.invoiceInfo ? this.state.invoiceInfo.id : 0)}
                        style={{ cursor: "pointer", textDecoration: "underline" }}
                      >
                        Download Invoice
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/*
            ██       ██████  ████████     ██████  ██████  ██  ██████ ███████     ██ ███    ██ ███████  ██████
            ██      ██    ██    ██        ██   ██ ██   ██ ██ ██      ██          ██ ████   ██ ██      ██    ██
            ██      ██    ██    ██        ██████  ██████  ██ ██      █████       ██ ██ ██  ██ █████   ██    ██
            ██      ██    ██    ██        ██      ██   ██ ██ ██      ██          ██ ██  ██ ██ ██      ██    ██
            ███████  ██████     ██        ██      ██   ██ ██  ██████ ███████     ██ ██   ████ ██       ██████
          */}

            {this.state.lotPriceInfo ? (
              <div style={overlay} onClick={() => this.hideLotPriceInfo()}>
                <div style={overlayBody} onClick={(e) => this.stopPropagation(e)}>
                  <div style={headerStyle}>{this.state.lotPriceInfo.title ? this.state.lotPriceInfo.title : "Untitled"}</div>
                  <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>Selling Price:</td>
                          <td>
                            <ControlledInput
                              placeholder="Price"
                              name="selling_price"
                              value={this.state.lotPriceInfo.selling_price}
                              restriction={"unsigned-float"}
                              handleChange={(n, v) => this.handleLotPriceInputChange(n, v, this.state.lotPriceInfo!)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Amount to Invoice:</td>
                          <td>
                            <ControlledInput
                              placeholder="Amount"
                              name="amount_to_invoice"
                              value={this.state.lotPriceInfo.amount_to_invoice}
                              restriction={"unsigned-float"}
                              handleChange={(n, v) => this.handleLotPriceInputChange(n, v, this.state.lotPriceInfo!)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Amount Invoiced:</td>
                          <td>{this.state.lotPriceInfo.amount_invoiced}</td>
                        </tr>
                      </tbody>
                    </table>
                    {this.state.showCosts ? (
                      <table>
                        <tbody>
                          <tr>
                            <td>Cost:</td>
                            <td>
                              <ControlledInput
                                placeholder="Cost"
                                name="cost"
                                value={this.state.lotPriceInfo.cost}
                                restriction={"unsigned-float"}
                                handleChange={(n, v) => this.handleLotPriceInputChange(n, v, this.state.lotPriceInfo!)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Margin:</td>
                            <td>
                              <ControlledInput
                                placeholder="Margin"
                                name="margin"
                                value={
                                  this.state.lotPriceInfo.margin ||
                                  calculateMargin(this.state.lotPriceInfo.selling_price, this.state.lotPriceInfo.cost).toString()
                                }
                                restriction={"unsigned-float"}
                                handleChange={(n, v) => this.handleLotPriceInputChange(n, v, this.state.lotPriceInfo!)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}

            {/*
          ██████   █████  ██    ██ ███    ███ ███████ ███    ██ ████████     ██ ███    ██ ███████  ██████
          ██   ██ ██   ██  ██  ██  ████  ████ ██      ████   ██    ██        ██ ████   ██ ██      ██    ██
          ██████  ███████   ████   ██ ████ ██ █████   ██ ██  ██    ██        ██ ██ ██  ██ █████   ██    ██
          ██      ██   ██    ██    ██  ██  ██ ██      ██  ██ ██    ██        ██ ██  ██ ██ ██      ██    ██
          ██      ██   ██    ██    ██      ██ ███████ ██   ████    ██        ██ ██   ████ ██       ██████
          */}

            {this.state.paymentInfo ? (
              <div style={overlay} onClick={() => this.hidePaymentInfo()}>
                <div style={overlayBody} onClick={(e) => this.stopPropagation(e)}>
                  <div style={headerStyle}>
                    {"Payment " + this.state.paymentInfo.line_index}
                    &nbsp;<span style={{ fontWeight: "normal" }}>{this.state.paymentInfo.date}</span>
                  </div>
                  <div style={{ display: "flex", justifyContent: this.state.paymentInfo.saved ? "flex-start" : "space-around", flexWrap: "wrap" }}>
                    {this.state.paymentInfo.saved ? null : (
                      <table>
                        <tbody>
                          <tr>
                            <td>Total Order:</td>
                            <td>$</td>
                            <td style={pricingStyle}>{toCurrency(this.totalSellPrice(), true)}</td>
                          </tr>
                          <tr>
                            <td>Invoice Amount:</td>
                            <td>$</td>
                            <td style={pricingStyle}>{toCurrency(this.totalInvoiceAmount(), true)}</td>
                          </tr>
                          <tr>
                            <td>Estimated B/O:</td>
                            <td>$</td>
                            <td style={pricingStyle}>{toCurrency(this.totalEstimatedBO(), true)}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {this.state.paymentInfo.saved ? null : (
                      <table>
                        <tbody>
                          <tr>
                            <td>Other Payments:</td>
                            <td>$</td>
                            <td style={pricingStyle}>{toCurrency(this.otherTenderEntered(), true)}</td>
                          </tr>
                          <tr>
                            <td>Tender Entered:</td>
                            <td>$</td>
                            <td style={pricingStyle}>{toCurrency(this.tenderEntered(this.state.paymentInfo), true)}</td>
                          </tr>
                          <tr>
                            <td>Amount Due:</td>
                            <td>$</td>
                            <td style={pricingStyle}>{toCurrency(this.amountDue(this.state.paymentInfo), true)}</td>
                          </tr>
                          <tr>
                            <td>Change Due:</td>
                            <td>$</td>
                            <td style={pricingStyle}>{toCurrency(this.state.paymentInfo.change_given, true)}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    <table>
                      <tbody>
                        {this.state.paymentInfo.saved ? (
                          <tr>
                            <td>Change Given:</td>
                            <td>$</td>
                            <td style={pricingStyle}>{toCurrency(this.state.paymentInfo.change_given, true)}</td>
                          </tr>
                        ) : null}
                        <tr>
                          <td>Deposit:</td>
                          <td>$</td>
                          <td>
                            <ControlledInput
                              name="deposit"
                              handleChange={(n, v) => this.handlePaymentInfo(n, v)}
                              disabled={this.state.paymentInfo.saved}
                              restriction={"unsigned-float"}
                              // onKeyPress={e => this.handleEnterKey("deposit", this.state.paymentInfo!, e)}
                              value={this.state.paymentInfo.deposit}
                            />
                          </td>
                        </tr>
                        {this.state.paymentInfo.saved ? null : (
                          <tr>
                            <td>Total Deposits:</td>
                            <td>$</td>
                            <td>{toCurrency(this.totalDeposits(this.state.paymentInfo), true)}</td>
                          </tr>
                        )}
                        <tr>
                          <td>Apply to Invoice:</td>
                          <td>$</td>
                          <td>
                            <ControlledInput
                              name="applied_deposit"
                              handleChange={(n, v) => this.handlePaymentInfo(n, v)}
                              restriction={"unsigned-float"}
                              disabled={this.state.paymentInfo.saved}
                              // onKeyPress={e => this.handleEnterKey("applied_deposit", this.state.paymentInfo!, e)}
                              value={this.state.paymentInfo.applied_deposit}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <br />
                  <table>
                    <tbody>
                      <tr style={{ fontWeight: "bold" }}>
                        <td>Payment Type</td>
                        <td>Tender Amount</td>
                        <td>Detail</td>
                        <td>Expiry</td>
                      </tr>
                      {this.state.paymentInfo.types.map((type) => (
                        <tr key={type.name}>
                          <td>{type.name}</td>
                          <td>
                            ${" "}
                            <ControlledInput
                              handleChange={(n, v) => this.handlePaymentType(n, v, type)}
                              restriction={"unsigned-float"}
                              // onKeyPress={e => this.handleEnterKey(type, this.state.paymentInfo!, e)}
                              disabled={this.state.paymentInfo!.saved}
                              name={"amount"}
                              value={type.amount}
                            />
                          </td>
                          <td>
                            <input
                              onChange={(e) => this.handlePaymentType("detail", e.target.value, type)}
                              value={type.detail}
                              name={"detail"}
                              disabled={this.state.paymentInfo!.saved}
                            />
                          </td>
                          <td>
                            <input
                              onChange={(e) => this.handlePaymentType("expiry", e.target.value, type)}
                              type="month"
                              value={type.expiry}
                              name={"expiry"}
                              disabled={this.state.paymentInfo!.saved}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}

            {/*
            ██████  ██████   ██████  ██████  ██    ██  ██████ ████████     ██ ███    ██ ███████  ██████
            ██   ██ ██   ██ ██    ██ ██   ██ ██    ██ ██         ██        ██ ████   ██ ██      ██    ██
            ██████  ██████  ██    ██ ██   ██ ██    ██ ██         ██        ██ ██ ██  ██ █████   ██    ██
            ██      ██   ██ ██    ██ ██   ██ ██    ██ ██         ██        ██ ██  ██ ██ ██      ██    ██
            ██      ██   ██  ██████  ██████   ██████   ██████    ██        ██ ██   ████ ██       ██████
          */}

            {this.state.productInfo ? (
              <div style={overlay} onClick={() => this.hideProductInfo()}>
                <div style={overlayBody} onClick={(e) => this.stopPropagation(e)}>
                  <div style={headerStyle}>
                    {this.state.productInfo.order_specific ? (
                      <div>
                        <span style={{ color: "red" }}>OS</span>
                        &nbsp;
                        <button onClick={this.chooseVendorForOS} disabled={this.state.productInfo.qty_invoiced > 0}>
                          {this.state.productInfo.line ? "Edit" : "Choose Line"}
                        </button>
                        &nbsp;
                        {this.state.productInfo.line}
                        &nbsp; -{" "}
                        <input
                          name={"number"}
                          placeholder={"Product Number"}
                          value={this.state.productInfo.number}
                          disabled={this.state.productInfo.qty_invoiced > 0}
                          onChange={(e) => this.handleOSInput("number", e.target.value, this.state.productInfo!)}
                        />
                        &nbsp;
                        <input
                          name={"description"}
                          placeholder={"Description"}
                          value={this.state.productInfo.description}
                          disabled={this.state.productInfo.qty_invoiced > 0}
                          onChange={(e) => this.handleOSInput("description", e.target.value, this.state.productInfo!)}
                        />
                      </div>
                    ) : (
                      <div>
                        <NewTabText
                          link="product"
                          id={this.state.productInfo.id || 0}
                          text={this.state.productInfo.product_line + " - " + this.state.productInfo.product_num}
                        />
                        &nbsp;
                        <input
                          onChange={(e) => this.handleProductInputChange("description", e.target.value, this.state.productInfo!)}
                          value={this.state.productInfo.description}
                          name={"description"}
                          placeholder={"New Description"}
                          style={{ width: "50%", backgroundColor: "#eee" }}
                        />
                        &nbsp; {this.state.productInfo.unit_of_measure ? "Measure: " + this.state.productInfo.unit_of_measure : null}
                      </div>
                    )}
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <td>Total Quantity:</td>
                            <td>
                              <ControlledInput
                                placeholder="Quantity"
                                name="qty_to_order"
                                autoFocus={this.state.productInfo.autoFocus === "qty_to_order"}
                                onFocus={this.handleFocusSelect}
                                restriction={"non-zero-unsigned-int"}
                                value={this.state.productInfo.qty_to_order}
                                handleChange={(n, v) => this.handleProductInputChange(n, v, this.state.productInfo!)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Sell/Pur Mult:</td>
                            <td>
                              {this.state.productInfo.sell_mult} / {this.state.productInfo.pur_mult}
                            </td>
                          </tr>
                          <tr>
                            <td>Qty To Invoice:</td>
                            <td>
                              <ControlledInput
                                placeholder="Quantity"
                                name="qty_to_invoice"
                                autoFocus={this.state.productInfo.autoFocus === "qty_to_invoice"}
                                onFocus={this.handleFocusSelect}
                                restriction={"unsigned-int"}
                                value={this.state.productInfo.qty_to_invoice}
                                handleChange={(n, v) => this.handleProductInputChange(n, v, this.state.productInfo!)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Qty Picking:</td>
                            <td>{parseInt(this.state.productInfo.qty_picked || 0, 10) + parseInt(this.state.productInfo.qty_to_pick || 0, 10)}</td>
                          </tr>
                          <tr>
                            <td>Qty Invoiced:</td>
                            <td>{this.state.productInfo.qty_invoiced || "0"}</td>
                          </tr>
                          {this.state.productInfo.qty_returned > 0 ? (
                            <tr>
                              <td>Qty Returned:</td>
                              <td>{this.state.productInfo.qty_returned}</td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <td>Selling price:</td>
                            <td>$</td>
                            <td>
                              <ControlledInput
                                placeholder="Price"
                                name={"selling_price"}
                                value={this.state.productInfo.selling_price ? this.state.productInfo.selling_price : ""}
                                autoFocus={this.state.productInfo.autoFocus === "selling_price"}
                                onFocus={this.handleFocusSelect}
                                handleChange={(n, v) => this.handleProductInputChange(n, v, this.state.productInfo!)}
                                restriction={"unsigned-float"}
                              />
                            </td>
                          </tr>
                          {this.state.showCosts ? (
                            <tr>
                              <td>Margin:</td>
                              <td>%</td>
                              <td>
                                <ControlledInput
                                  placeholder="Margin"
                                  name={"margin"}
                                  value={this.state.productInfo.margin ? this.state.productInfo.margin : ""}
                                  handleChange={(n, v) => this.handleProductInputChange(n, v, this.state.productInfo!)}
                                  restriction={"unsigned-float"}
                                />
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>Discount:</td>
                            <td>%</td>
                            <td>
                              <ControlledInput
                                placeholder="Discount"
                                name={"discount"}
                                value={this.state.productInfo.discount ? this.state.productInfo.discount : ""}
                                handleChange={(n, v) => this.handleProductInputChange(n, v, this.state.productInfo!)}
                                restriction={"unsigned-float"}
                              />
                            </td>
                          </tr>
                          {this.state.showCosts ? (
                            <tr>
                              <td>
                                {this.state.productInfo.ship_n_debit ? "Ship & Debit " : this.state.productInfo.order_specific ? null : "Potential "}
                                Cost:
                              </td>
                              <td></td>
                              <td>
                                <b>
                                  {this.state.productInfo.order_specific ? (
                                    <ControlledInput
                                      value={this.state.productInfo.cost}
                                      name={"cost"}
                                      restriction={"unsigned-float"}
                                      handleChange={(n, v) => this.handleOSInput(n, v, this.state.productInfo!)}
                                    />
                                  ) : (
                                    toCurrency(this.state.productInfo.cost)
                                  )}
                                </b>
                                {this.state.productInfo.price_per && this.state.productInfo.price_per > 1 ? (
                                  <span> / {this.state.productInfo.price_per}</span>
                                ) : null}
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                    {this.state.showCosts ? (
                      <div>
                        {this.state.productInfo.order_specific ? null : (
                          <table>
                            <tbody>
                              <tr>
                                <td>Target Price:</td>
                                <td>{toCurrency(calculateSell(this.state.productInfo.target_margin, this.state.productInfo.cost))}</td>
                              </tr>
                              <tr>
                                <td>Walk-in Price:</td>
                                <td>{toCurrency(calculateSell(this.state.productInfo.walkin_margin, this.state.productInfo.cost))}</td>
                              </tr>
                              <tr>
                                <td>Contractor Price:</td>
                                <td>{toCurrency(calculateSell(this.state.productInfo.contractor_margin, this.state.productInfo.cost))}</td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </div>
                    ) : null}
                    <div>
                      Comment:
                      <br />
                      <textarea
                        placeholder="Comment"
                        name={"comment"}
                        defaultValue={this.state.productInfo.comment}
                        style={{ height: "60px", width: "400px" }}
                        onChange={(e) => this.handleProductInputChange("comment", e.target.value, this.state.productInfo!)}
                      />
                    </div>
                  </div>
                  <br />
                  <div>
                    <div style={headerStyle}>Supply source:</div>
                    <table className="searchResults" style={{ width: "auto" }}>
                      <thead>
                        <tr>
                          <th>Origin</th>
                          <th>Available</th>
                          <th></th>
                          <th>Supply</th>
                          <th>Type</th>
                          {this.state.showCosts && !this.state.productInfo.order_specific ? <th>Cost</th> : null}
                        </tr>
                      </thead>
                      {this.state.productInfo.supply_details
                        ? this.state.productInfo.supply_details.map((detail) => {
                            let clickable =
                              this.state.existingOrderId &&
                              this.state.productInfo &&
                              this.state.productInfo.order_product_id &&
                              (detail.supply_source === "Purchase" || (detail.warehouse_id && detail.type === "Transfer"))
                            return (
                              <tbody key={detail.supply_source + (detail.warehouse_id ? detail.warehouse_id.toString() : "")}>
                                <tr>
                                  <td
                                    style={{ textDecoration: clickable ? "underline" : "inherit", cursor: clickable ? "pointer" : "default" }}
                                    onClick={() => clickable && this.showAttachmentInfo(detail.warehouse_id ? detail.type : detail.supply_source)}
                                  >
                                    {detail.warehouse_name ? detail.warehouse_name : detail.supply_source}
                                  </td>
                                  <td>
                                    {this.qtyAvailableRemaining(
                                      detail,
                                      this.state.productInfo!.id || 0,
                                      this.state.productInfo!.unique_id!,
                                      false,
                                      this.state.products
                                    )}
                                  </td>
                                  {detail.supply_source !== "Purchase" ? (
                                    <td>
                                      <input
                                        type="checkbox"
                                        name={detail.warehouse_name ? detail.warehouse_name : detail.supply_source}
                                        onChange={(e) => this.handleSupplyCheckbox(e)}
                                        checked={detail.checked}
                                      />
                                    </td>
                                  ) : (
                                    <td />
                                  )}
                                  {detail.supply_source !== "Purchase" && detail.supply_source !== "Hold All" ? (
                                    <td>
                                      <ControlledInput
                                        name={detail.warehouse_name ? detail.warehouse_name : detail.supply_source}
                                        handleChange={(n, v) => this.handleSupplyInput(n, parseInt(v.toString(), 10))}
                                        restriction={"unsigned-int"}
                                        onKeyPress={(e) => this.onKeyPressSupplyInput(e)}
                                        value={detail.qty_to_supply}
                                        style={{ width: "50px" }}
                                      />
                                    </td>
                                  ) : (
                                    <td>{detail.qty_to_supply ? detail.qty_to_supply.toString() : ""}</td>
                                  )}
                                  <td>{detail.type}</td>
                                  {this.state.showCosts && !this.state.productInfo!.order_specific ? (
                                    <td>
                                      {detail.supply_source === "Warehouse"
                                        ? toCurrency(detail.avg_cost)
                                        : detail.supply_source === "Purchase"
                                        ? toCurrency(this.state.productInfo!.landed_cost)
                                        : null}
                                    </td>
                                  ) : null}
                                </tr>
                              </tbody>
                            )
                          })
                        : null}
                    </table>
                  </div>
                </div>
              </div>
            ) : null}

            {/*
           █████  ████████ ████████  █████   ██████ ██   ██ ███    ███ ███████ ███    ██ ████████     ██ ███    ██ ███████  ██████
          ██   ██    ██       ██    ██   ██ ██      ██   ██ ████  ████ ██      ████   ██    ██        ██ ████   ██ ██      ██    ██
          ███████    ██       ██    ███████ ██      ███████ ██ ████ ██ █████   ██ ██  ██    ██        ██ ██ ██  ██ █████   ██    ██
          ██   ██    ██       ██    ██   ██ ██      ██   ██ ██  ██  ██ ██      ██  ██ ██    ██        ██ ██  ██ ██ ██      ██    ██
          ██   ██    ██       ██    ██   ██  ██████ ██   ██ ██      ██ ███████ ██   ████    ██        ██ ██   ████ ██       ██████
          */}

            {this.state.attachmentInfo ? (
              <div style={{ ...overlay, zIndex: 101 }} onClick={() => this.hideAttachmentInfo()}>
                <div style={{ ...overlayBody, zIndex: 102 }} onClick={(e) => this.stopPropagation(e)}>
                  <div style={headerStyle}>
                    {this.state.attachmentInfo!.title}
                    <span style={{ fontWeight: "normal" }}>&nbsp;({this.state.attachmentInfo!.result.rows.length})</span>
                  </div>
                  {this.state.loadingAttachments ? (
                    <LoadingSpinner />
                  ) : (
                    <table className="searchResults">
                      <thead>
                        <tr>
                          {this.state.attachmentInfo!.result.columns.map((col) => (
                            <th key={col.key}>{col.title}</th>
                          ))}
                        </tr>
                      </thead>
                      {this.state.attachmentInfo!.result.rows.map((row) => (
                        <tbody key={row.id}>
                          <tr>
                            {this.state.attachmentInfo!.result.columns.map((col) => {
                              let clickable = col.key === "id"
                              return (
                                <td key={row.id + col.key}>
                                  {clickable ? (
                                    <NewTabText link={this.state.attachmentInfo!.type.toLowerCase()} id={row[col.key]} text={row[col.key]} />
                                  ) : (
                                    row[col.key]
                                  )}
                                </td>
                              )
                            })}
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  )}
                </div>
              </div>
            ) : null}

            {/*
             ██████  ██████  ██████  ███████ ██████      ███████ ███    ██ ████████ ██████  ██    ██
            ██    ██ ██   ██ ██   ██ ██      ██   ██     ██      ████   ██    ██    ██   ██  ██  ██
            ██    ██ ██████  ██   ██ █████   ██████      █████   ██ ██  ██    ██    ██████    ████
            ██    ██ ██   ██ ██   ██ ██      ██   ██     ██      ██  ██ ██    ██    ██   ██    ██
             ██████  ██   ██ ██████  ███████ ██   ██     ███████ ██   ████    ██    ██   ██    ██
          */}

            <div>
              <div style={headerStyle}>
                <div style={{ display: "inline-block" }}>Customer:</div>
              </div>
              <b>{customer.customer_num}</b> - {customer.company_name}
              &nbsp;
              {this.state.customer && this.state.customer.cod_status ? (
                <span style={{ color: "red", fontWeight: "bold" }}>[ Cash Account ]</span>
              ) : null}
              &nbsp;
              <button disabled={this.state.invoices.length > 0 || savedPayments} onClick={this.chooseCustomer}>
                Edit
              </button>
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div style={{ flex: "1" }}>
                  <div style={headerStyle}>Salesrep:</div>
                  {(this.state.salesrep ? this.state.salesrep.first_name : "") + " " + (this.state.salesrep ? this.state.salesrep.last_name : "")}
                  &nbsp;
                  <button disabled={this.state.invoices.length > 0 || savedPayments} onClick={this.chooseSalesrep}>
                    Edit
                  </button>
                </div>
                <div style={{ flex: "1", paddingLeft: "5px" }}>
                  <div style={headerStyle}>Sales branch:</div>
                  {whse ? whse.name : ""}&nbsp;
                  <button disabled={this.state.invoices.length > 0 || savedPayments} onClick={this.chooseWarehouse}>
                    Edit
                  </button>
                </div>
                {this.state.invoices.length <= 0 ? (
                  <div style={{ flex: "1", paddingLeft: "5px" }}>
                    <div style={headerStyle}>{this.state.isQuote ? "Quote" : "Order"}</div>
                    <button onClick={this.convertOrderQuote}>Convert to {this.state.isQuote ? "Order" : "Quote"}</button>
                  </div>
                ) : null}
              </div>
              <div style={headerStyle}>Products:</div>
              <SortableProductList
                items={this.state.products}
                onSortEnd={this.onProductSortEnd}
                deleteProduct={this.deleteProduct}
                showProductInfo={this.showProductInfo}
                stopPropagation={this.stopPropagation}
                handleProductInput={this.handleProductInputChange}
                useDragHandle={true}
              />
              <button onClick={this.chooseProduct}>Add Product</button>
              &nbsp;
              <button onClick={this.addOrderSpecificProduct}>Add Order Specific</button>
              &nbsp;
              <button onClick={this.addProductHeader}>Add Header</button>
            </div>
            <div style={headerStyle}>
              Lot Pricing:&nbsp;
              <input type="checkbox" onChange={() => this.handleLotPriceCheck()} checked={this.state.lotPriceChecked} />
            </div>
            {this.state.lotPriceChecked ? (
              <div>
                <SortableLotPriceList
                  items={this.state.lotPrices}
                  onSortEnd={this.onLotPriceSortEnd}
                  deleteLotPrice={this.deleteLotPrice}
                  showLotPriceInfo={this.showLotPriceInfo}
                  handleLotPriceInput={this.handleLotPriceInputChange}
                  useDragHandle={true}
                />
                <br />
                <button onClick={this.addLotPrice}>Add Lot Price</button>
              </div>
            ) : null}
            <br />
            <div style={headerStyle}>Details:</div>
            <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>Ship to:</td>
                      <td style={{ textAlign: "right" }}>
                        {Object.values(this.state.shipTo).every((v) => !v) ? (
                          <button onClick={this.useBillingAddress}>Use Billing Address</button>
                        ) : (
                          <button onClick={this.clearShipTo}>Clear</button>
                        )}
                      </td>
                    </tr>
                    {Array.from({ length: 5 }, (_v, k) => k + 1).map((
                      x // map over an array of 1-N integers (in this case, 5)
                    ) => (
                      <tr key={x}>
                        <td colSpan={2}>
                          <input
                            placeholder={"Line " + x}
                            name={"address_" + x}
                            value={this.state.shipTo["address_" + x] || ""}
                            maxLength={30}
                            size={31}
                            onChange={(e) => this.handleShipToChange(e)}
                            style={{ fontFamily: "monospace" }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>Ship Via</td>
                      <td>
                        <select onChange={this.handleShipVia} value={this.state.details.ship_via}>
                          <option value="no_selection" disabled={true}>
                            No Selection
                          </option>
                          <option value="pickup">Pickup</option>
                          <option value="truck">Truck</option>
                          <option value="courier">Courier</option>
                          <option value="other">Other</option>
                        </select>
                        {this.state.isQuote || this.state.details.ship_via !== "no_selection" ? null : <span style={{ color: "red" }}> *</span>}
                      </td>
                    </tr>
                    <tr>
                      <td>Ship Terms</td>
                      <td>
                        <select onChange={this.handleShipTerms} value={this.state.shipTerm}>
                          <option value="no_selection" disabled={true}>
                            No Selection
                          </option>
                          <option value="ship_backorder">Ship & Backorder</option>
                          <option value="one_shipment">One Shipment Only</option>
                          <option value="hold_till_advise">Hold Till Advise</option>
                        </select>
                        {this.state.isQuote || this.state.shipTerm !== "no_selection" ? null : <span style={{ color: "red" }}> *</span>}
                      </td>
                    </tr>
                    <tr>
                      <td>P.O. Number</td>
                      <td>
                        <input
                          name="po_number"
                          defaultValue={this.state.details.po_number}
                          maxLength={30}
                          size={31}
                          onChange={(e) => this.handleOrderDetailChange("po_number", e.target.value)}
                          style={{ fontFamily: "monospace" }}
                        />
                        {this.state.isQuote || this.state.details.po_number ? null : <span style={{ color: "red" }}> *</span>}
                      </td>
                    </tr>
                    <tr>
                      <td>Instruction</td>
                      <td>
                        <input
                          name="instruction"
                          defaultValue={this.state.details.instruction}
                          maxLength={60}
                          size={31}
                          onChange={(e) => this.handleOrderDetailChange("instruction", e.target.value)}
                          style={{ fontFamily: "monospace" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>Sales Tax</td>
                      <td>
                        <select
                          onChange={this.handleSalesTax}
                          defaultValue={this.state.selectedSalesTax}
                          disabled={this.state.invoices.length > 0 || savedPayments}
                          style={{ width: "170px" }}
                        >
                          {this.state.salesTaxes.map((tax) => (
                            <option key={tax.province_code} value={tax.province_code}>
                              {tax.province_name} ({tax.types.map((x) => x.name).join(", ")})
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>Discount</td>
                      <td>
                        %{" "}
                        <ControlledInput
                          name="discount"
                          defaultValue={this.state.details.discount}
                          maxLength={30}
                          size={20}
                          handleChange={(n, v) => this.handleOrderDetailChange(n, v)}
                          restriction={"unsigned-float"}
                          style={{ fontFamily: "monospace" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table>
                <tbody>
                  {this.state.showCosts ? (
                    <tr>
                      <td>Margin:</td>
                      <td>%</td>
                      <td style={pricingStyle}>{this.totalMargin().toFixed(2).toString()}</td>
                    </tr>
                  ) : null}
                  {this.state.showCosts ? (
                    <tr>
                      <td>Cost:</td>
                      <td>$</td>
                      <td style={pricingStyle}>{toCurrency(this.totalCost(), true)}</td>
                    </tr>
                  ) : null}
                  {this.state.showCosts ? (
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <br />
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>Sell Price:</td>
                    <td>$</td>
                    <td style={pricingStyle}>{toCurrency(this.sellPrice(), true)}</td>
                  </tr>
                  {this.productDiscountTotal() > 0 ? (
                    <tr>
                      <td>Savings:</td>
                      <td>$</td>
                      <td style={pricingStyle}>{toCurrency(this.productDiscountTotal(), true)}</td>
                    </tr>
                  ) : null}
                  {this.discount() > 0 ? (
                    <tr>
                      <td>Discount:</td>
                      <td>$</td>
                      <td style={pricingStyle}>{toCurrency(this.discount(), true)}</td>
                    </tr>
                  ) : null}
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>Sub Total:</td>
                    <td>$</td>
                    <td style={pricingStyle}>{toCurrency(this.sellPriceDiscounted(), true)}</td>
                  </tr>
                  {this.state.selectedSalesTax
                    ? this.state.salesTaxes
                        .find((tax) => this.state.selectedSalesTax === tax.province_code)!
                        .types.map((type) => (
                          <tr key={type.name}>
                            <td>{type.name}</td>
                            <td>$</td>
                            <td style={pricingStyle}>{toCurrency(this.sellPriceDiscounted() * (type.percent / 100), true)}</td>
                          </tr>
                        ))
                    : null}
                  <tr>
                    <td>Total:</td>
                    <td>$</td>
                    <td style={pricingStyle}>
                      <b>{toCurrency(this.totalSellPrice(), true)}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <div>
              Overall Comment:
              <br />
              <textarea
                placeholder="Comment"
                name={"comment"}
                defaultValue={this.state.details.comment}
                onChange={(e) => this.handleOrderDetailChange("comment", e.target.value)}
              />
            </div>
            {this.state.invoices.length > 0 ? (
              <div>
                <div style={headerStyle}>Invoices:</div>
                <table className="searchResults">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  {this.state.invoices.map((invoice) => {
                    let invoiceSubTotal = 0
                    invoice.products.forEach((prod) => (invoiceSubTotal += (prod.quantity * prod.selling_price) / prod.price_per))
                    invoice.lot_prices.forEach((lot) => (invoiceSubTotal += lot.selling_price))
                    invoiceSubTotal *= 1 + (invoice.tax_rate || 0) / 100
                    invoiceSubTotal *= 1 - (invoice.discount || 0) / 100
                    invoiceTotal += invoiceSubTotal
                    return (
                      <tbody key={invoice.id}>
                        <tr className="row" onClick={() => this.showInvoiceInfo(invoice)}>
                          <td>
                            <span style={{ fontWeight: "bold" }}>{"Invoice #" + invoice.id}</span>
                            &nbsp;{toReadableDate(invoice.date)}
                          </td>
                          <td>{toCurrency(invoiceSubTotal)}</td>
                        </tr>
                      </tbody>
                    )
                  })}
                  <tfoot>
                    <tr>
                      <th style={borderlessCell}></th>
                      <th>{toCurrency(invoiceTotal)}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            ) : null}
            {!this.state.isQuote ? (
              <div>
                <div style={headerStyle}>Payments:</div>
                <table className="searchResults">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Amount</th>
                      <th>Deposit</th>
                      <th>Comment</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  {this.state.payments.map((payment) => {
                    let typeTotalValue = 0
                    payment.types.forEach((type) => (typeTotalValue += type.amount ? type.amount : 0))
                    const deposit = payment.deposit ? payment.deposit : 0
                    const appliedDeposit = payment.applied_deposit ? payment.applied_deposit : 0
                    const changeGiven = payment.change_given ? payment.change_given : 0
                    const paymentSubTotal = typeTotalValue - deposit + appliedDeposit - changeGiven
                    const depositSubTotal = deposit - appliedDeposit
                    paymentTotal += paymentSubTotal
                    depositTotal += depositSubTotal
                    return (
                      <tbody key={payment.unique_id}>
                        <tr className="row">
                          <td onClick={() => this.showPaymentInfo(payment)} style={{ cursor: "pointer" }}>
                            <span style={{ fontWeight: "bold" }}>{"Payment " + payment.line_index}</span>
                            &nbsp;{payment.date !== "Not Saved" ? toReadableDate(payment.date) : payment.date}
                          </td>
                          <td>{toCurrency(paymentSubTotal)}</td>
                          <td>{toCurrency(depositSubTotal)}</td>
                          <td>
                            <textarea
                              placeholder="Comment"
                              name={"comment"}
                              defaultValue={payment.comment}
                              onChange={(e) => this.handlePaymentComment(payment, e)}
                            />
                          </td>
                          <td>{payment.saved ? null : <button onClick={() => this.deletePayment(payment)}>Delete</button>}</td>
                        </tr>
                      </tbody>
                    )
                  })}
                  <tfoot>
                    <tr>
                      <th style={borderlessCell}></th>
                      <th>{toCurrency(paymentTotal)}</th>
                      <th>{toCurrency(depositTotal)}</th>
                      <th style={borderlessCell}></th>
                      <th style={borderlessCell}></th>
                      <th style={borderlessCell}></th>
                    </tr>
                  </tfoot>
                </table>
                <button disabled={unSavedPayments} onClick={this.newPayment}>
                  New Payment
                </button>
              </div>
            ) : null}
            <br />
            <br />
            {this.state.cancelled || this.state.closed ? null : (
              <div>
                <div style={headerStyle}>Save</div>
                <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ paddingRight: "15px" }}>
                          <button onClick={() => this.submitSaveOrder()}>
                            {this.state.isQuote ? "Save" : "Save" + (this.state.print ? " & Pick" : "")}
                          </button>
                          {!this.state.isQuote ? (
                            <div>
                              <br />
                              <button onClick={() => this.submitSaveOrder("invoice")}>Invoice</button>
                              <br />
                              <br />
                              <button onClick={() => this.submitSaveOrder("pack")}>Invoice (Pack)</button>
                            </div>
                          ) : null}
                        </td>
                        <td style={{ paddingLeft: "15px", borderLeft: "1px solid black" }}>
                          Print? <input type="checkbox" onChange={(e) => this.handlePrintCheck(e)} name={"print"} checked={this.state.print} />
                          <br />
                          Download?{" "}
                          <input type="checkbox" onChange={(e) => this.handlePrintCheck(e)} name={"download"} checked={this.state.download} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {!this.state.existingOrderId || this.state.isQuote ? null : (
                    <div>
                      Download:
                      <br />
                      <br />
                      <button onClick={() => this.downloadPDF("picking", this.state.existingOrderId || 0)}>Last Picking Slip</button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {this.state.existingOrderId && !this.state.cancelled && !this.state.closed ? (
              <div>
                <div style={headerStyle}>Cancel {this.state.isQuote ? "Quote" : "Order"}</div>
                <div>
                  <button onClick={this.cancelOrder}>Cancel</button>
                </div>
              </div>
            ) : null}
          </fieldset>
        </div>
      )
    }

    return (
      <div>
        To begin, choose the customer: &nbsp;
        <button onClick={this.chooseCustomer}>Choose Customer</button>
      </div>
    )
  }
}

export default Order
