import React, { Component } from "react"

// import child components / helpers
import LoadingSpinner from "../LoadingSpinner"
import EditableTD from "../edit/EditableTD"
import WarehouseSearch from "./WarehouseSearch"
import { Dialog, props as dialogProps } from "../dialog/Dialog"

// import required libraries
import { fireAxios } from "../helpers"

type props = {
  id?: number
  title?: string
}

type state = {
  warehouseId?: number
  warehouse: {
    id?: number
    name: string
    closed: boolean
    address_1?: string
    address_2?: string
    city?: string
    province?: string
    postal_code?: string
    country?: string
    phone_num?: string
    fax_num?: string
    defective: {
      name: string
    }
  }
  loading: boolean
  focusedInput: string
  toSearch: boolean
  searchChild: React.ReactNode
  searchContext?: string
  dialog: dialogProps
}

const initialState: state = {
  warehouseId: undefined,
  warehouse: {
    id: undefined,
    name: "",
    closed: true,
    defective: {
      name: "",
    },
  },
  loading: false,
  focusedInput: "",
  toSearch: false,
  searchChild: null,
  searchContext: undefined,
  dialog: null,
}

class Warehouse extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    this.setState(
      {
        warehouseId: this.props.id,
      },
      () => {
        this.state.warehouseId && this.getWarehouseDetails(this.state.warehouseId)
      }
    )
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  getWarehouseDetails = (id: number) => {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "warehouses/all/" + id }, (response) => {
        this.setState(
          {
            warehouse: response.data,
            loading: false,
          },
          () => {
            this.props.setAppTitle && this.props.setAppTitle(this.state.warehouse.name)
          }
        )
      })
    })
  }

  updateWarehouse = (_source: any, context: string, value: any) => {
    let newWarehouse = JSON.parse(JSON.stringify(this.state.warehouse)) // product copy to submit to datebase
    // remove data that isn't a column in the `customers` table
    delete newWarehouse.defective

    if (context) {
      newWarehouse[context] = value
    } else {
      console.log("No context")
      return
    }
    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "warehouses/update", data: newWarehouse }, () => {
        this.state.warehouse.id && this.getWarehouseDetails(this.state.warehouse.id)
      })
    })
  }

  openCloseWarehouse = () => {
    const isClosed = this.state.warehouse.closed

    this.showDialog({
      type: "confirm",
      title: "Are you sure?",
      message: "Are you sure you want to " + (isClosed ? "open" : "close") + " this warehouse?",
      handleResponse: (response) => {
        if (response) {
          this.updateWarehouse(null, "closed", !isClosed)
        }
        this.hideDialog()
      },
    })
  }

  changeInputFocus = (position: string | number) => {
    this.setState({
      focusedInput: position.toString(),
    })
  }

  returnSearchResult = (result: { [index: string]: any }) => {
    this.setState(
      {
        toSearch: false,
        focusedInput: "",
      },
      () => {
        if (this.props.handleSearchDepth) {
          this.props.handleSearchDepth(0)
        }
        this.state.searchContext && this.updateWarehouse(null, this.state.searchContext, result.id)
      }
    )
  }

  toSearch = (searchChild: React.ReactNode, searchContext: string) => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(1)
    }

    this.setState({
      toSearch: true,
      searchChild: searchChild,
      searchContext: searchContext,
    })
  }

  backFromSearch = () => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(0)
    }

    this.setState({
      toSearch: false,
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toSearch) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {this.props.title}</button>
          <br />
          <br />
          {React.cloneElement(this.state.searchChild as React.ReactElement, { returnResult: this.returnSearchResult })}
        </div>
      )
    }

    return (
      <div className="customer">
        <div className="header">
          {this.state.warehouse.closed ? (
            <span style={{ color: "red" }}>
              WAREHOUSE CLOSED
              <br />
            </span>
          ) : null}
          <h2>
            <b>{this.state.warehouse.name}</b>
          </h2>
        </div>

        <div style={{ display: "flex", justifyContent: "normal", flexWrap: "wrap" }}>
          <div style={{ padding: "12px" }}>
            Address:
            <br />
            <br />
            <table className="detailTable">
              <tbody>
                <tr>
                  <th>Line 1</th>
                  <EditableTD
                    content={this.state.warehouse.address_1 || ""}
                    placeholder={"Line 1"}
                    context={"address_1"}
                    focused={"Address Line 1" === this.state.focusedInput}
                    focusedID={"Address Line 1"}
                    source={this.state.warehouse}
                    changeFocus={this.changeInputFocus}
                    update={this.updateWarehouse}
                  />
                </tr>
                <tr>
                  <th>Line 2</th>
                  <EditableTD
                    content={this.state.warehouse.address_2 || ""}
                    placeholder={"Line 2"}
                    context={"address_2"}
                    focused={"Address Line 2" === this.state.focusedInput}
                    focusedID={"Address Line 2"}
                    source={this.state.warehouse}
                    changeFocus={this.changeInputFocus}
                    update={this.updateWarehouse}
                  />
                </tr>
                <tr>
                  <th>City</th>
                  <EditableTD
                    content={this.state.warehouse.city || ""}
                    placeholder={"City"}
                    context={"city"}
                    focused={"city" === this.state.focusedInput}
                    focusedID={"city"}
                    source={this.state.warehouse}
                    changeFocus={this.changeInputFocus}
                    update={this.updateWarehouse}
                  />
                </tr>
                <tr>
                  <th>Province</th>
                  <EditableTD
                    content={this.state.warehouse.province || ""}
                    placeholder={"Province"}
                    restriction={"province"}
                    context={"province"}
                    focused={"province" === this.state.focusedInput}
                    focusedID={"province"}
                    source={this.state.warehouse}
                    changeFocus={this.changeInputFocus}
                    update={this.updateWarehouse}
                  />
                </tr>
                <tr>
                  <th>Postal Code</th>
                  <EditableTD
                    content={this.state.warehouse.postal_code || ""}
                    placeholder={"Postal Code"}
                    context={"postal_code"}
                    focused={"postal_code" === this.state.focusedInput}
                    focusedID={"postal_code"}
                    source={this.state.warehouse}
                    changeFocus={this.changeInputFocus}
                    update={this.updateWarehouse}
                  />
                </tr>
                <tr>
                  <th>Country</th>
                  <EditableTD
                    content={this.state.warehouse.country || ""}
                    placeholder={"Country"}
                    context={"country"}
                    focused={"country" === this.state.focusedInput}
                    focusedID={"country"}
                    source={this.state.warehouse}
                    changeFocus={this.changeInputFocus}
                    update={this.updateWarehouse}
                  />
                </tr>
              </tbody>
            </table>
          </div>

          <div style={{ padding: "12px" }}>
            Contact:
            <br />
            <br />
            <table className="detailTable">
              <tbody>
                <tr>
                  <th>Phone</th>
                  <EditableTD
                    content={this.state.warehouse.phone_num || ""}
                    placeholder={"Phone Number"}
                    restriction={"phone-number"}
                    context={"phone_num"}
                    focused={"phone_num" === this.state.focusedInput}
                    focusedID={"phone_num"}
                    source={this.state.warehouse}
                    changeFocus={this.changeInputFocus}
                    update={this.updateWarehouse}
                  />
                </tr>
                <tr>
                  <th>Fax</th>
                  <EditableTD
                    content={this.state.warehouse.fax_num || ""}
                    placeholder={"Fax"}
                    restriction={"phone-number"}
                    context={"fax_num"}
                    focused={"fax_num" === this.state.focusedInput}
                    focusedID={"fax_num"}
                    source={this.state.warehouse}
                    changeFocus={this.changeInputFocus}
                    update={this.updateWarehouse}
                  />
                </tr>
              </tbody>
            </table>
          </div>

          <div style={{ padding: "12px" }}>
            Defective Relation:
            <br />
            <br />
            <table className="detailTable">
              <tbody>
                <tr>
                  <EditableTD
                    content={this.state.warehouse.defective.name}
                    source={this.state.warehouse}
                    context={"defective_id"}
                    focused={"defective_id" === this.state.focusedInput}
                    focusedID={"defective_id"}
                    changeFocus={this.changeInputFocus}
                    inputType={"search"}
                    toSearch={this.toSearch}
                    searchChild={<WarehouseSearch />}
                  />
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <br />

        <div>
          <h2>Other:</h2>
          <div>
            This is warehouse is {this.state.warehouse.closed ? "closed" : "open"}. &nbsp;{" "}
            <button onClick={this.openCloseWarehouse}>{this.state.warehouse.closed ? "Open" : "Close"} warehouse</button>
            {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
          </div>
        </div>
      </div>
    )
  }
}

export default Warehouse
