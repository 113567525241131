import React, { Component } from "react"

// import child components / helpers
import LoadingSpinner from "../LoadingSpinner"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import ProductSearch from "../products/ProductSearch"
import VendorSearch from "../vendors/VendorSearch"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import NewTabText from "../newTabText/NewTabText"
import ControlledInput from "../edit/ControlledInput"
import { fireAxios, toCurrency } from "../helpers"

// import required libraries
import shortid from "shortid"
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"
import arrayMove from "array-move"

type props = {
  id?: number
}

type product = {
  id: number
  unique_id: string
  product_line: string
  product_num: string
  description: string
  qty_for_return: number
  qty_returned: number
  qty_onhand: number
  qty_available: number
  qty_to_adjust: number
  return_value: number
  original_po: string
  return_type: string
  landed_cost: number
  [index: string]: any
}

type warehouse = {
  id: number
  name: string
}

type vendor = {
  id: number
  name: string
  product_line: string
}

type state = {
  loading: boolean
  vendorReturnId: string
  isClosed: boolean
  toChooseWarehouse: boolean
  warehouse?: warehouse
  toChooseVendor: boolean
  vendor?: vendor
  toChooseProduct: boolean
  products: Array<product>
  productInfo?: product
  existingReturnId?: number
  dialog: dialogProps
}

const initialState: state = {
  loading: false,
  vendorReturnId: "",
  isClosed: false,
  toChooseWarehouse: false,
  warehouse: undefined,
  toChooseVendor: false,
  vendor: undefined,
  toChooseProduct: false,
  products: [],
  productInfo: undefined,
  existingReturnId: undefined,
  dialog: null,
}

const title = "Return to Vendor"

const DragHandle = SortableHandle(() => {
  const svgStyle = {
    cursor: "s-resize",
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" style={svgStyle}>
      <path d="M2 13.5h14V12H2v1.5zm0-4h14V8H2v1.5zM2 4v1.5h14V4H2z" />
    </svg>
  )
})

const SortableProduct = SortableElement(
  ({
    i,
    prod,
    showProductInfo,
    deleteProduct,
  }: {
    i: number
    prod: product
    showProductInfo: (prod: product) => void
    deleteProduct: (prod: product) => void
  }) => {
    const returnValue = prod.hasOwnProperty("return_value") ? prod.return_value : 0
    const qtyForReturn = prod.hasOwnProperty("qty_for_return") ? prod.qty_for_return : 0
    const qtyReturned = prod.hasOwnProperty("qty_returned") ? prod.qty_returned : 0

    const bgColor = qtyForReturn > 0 && qtyReturned === qtyForReturn ? "#87dc9e" : undefined

    return (
      <tr className="row" style={{ cursor: "default", background: bgColor }} key={prod.unique_id}>
        <td style={{ border: "none", background: "white" }}>{i}</td>
        <td>
          <DragHandle />
        </td>
        <td onClick={() => showProductInfo(prod)} style={{ cursor: "pointer" }}>
          <b>
            {prod.product_line} - {prod.product_num}
          </b>{" "}
          {prod.description}
        </td>
        <td>{prod.qty_for_return}</td>
        <td>{toCurrency(returnValue * qtyForReturn)}</td>
        <td>{prod.qty_returned}</td>
        <td>{qtyReturned > 0 ? null : <button onClick={() => deleteProduct(prod)}>Delete</button>}</td>
      </tr>
    )
  }
)

const SortableProductList = SortableContainer(
  ({
    products,
    showProductInfo,
    deleteProduct,
  }: {
    products: Array<product>
    showProductInfo: (prod: product) => void
    deleteProduct: (prod: product) => void
  }) => {
    const borderlessCell = {
      border: "0",
    }

    let forReturnValue = 0

    return (
      <table className="searchResults">
        <tbody>
          <tr>
            <th style={{ border: "none", background: "white" }}></th>
            <th></th>
            <th>Product</th>
            <th>Qty For Return</th>
            <th>Value For Return</th>
            <th>Qty Returned</th>
            <th></th>
          </tr>
          {products.map((prod, index) => {
            // generate a unique id for each detail so React can manage the state properly
            if (!prod.hasOwnProperty("unique_id")) {
              prod.unique_id = shortid.generate()
            }

            // calculate total values
            const returnValue = prod.hasOwnProperty("return_value") ? prod.return_value : 0
            const qtyForReturn = prod.hasOwnProperty("qty_for_return") ? prod.qty_for_return : 0

            forReturnValue += returnValue * qtyForReturn

            return (
              <SortableProduct
                key={prod.unique_id}
                index={index}
                prod={prod}
                i={index + 1}
                deleteProduct={deleteProduct}
                showProductInfo={showProductInfo}
              />
            )
          })}
          <tr>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
            <th>{toCurrency(forReturnValue)}</th>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
            <th style={borderlessCell}></th>
          </tr>
        </tbody>
      </table>
    )
  }
)

class ReturnToVendor extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = initialState

    if (this.props.setAppTitle) {
      if (this.props.id || this.state.existingReturnId) {
        this.props.setAppTitle(title + " #" + (this.props.id ? this.props.id : this.state.existingReturnId))
      } else {
        this.props.setAppTitle(title)
      }
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEscapeKey, false)

    if (this.props.id) {
      this.getAllReturnDetails(this.props.id)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEscapeKey, false)
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.setState({
      products: arrayMove(this.state.products, oldIndex, newIndex),
    })
  }

  handleSearchDepth = (depth: number) => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(depth)
    }
  }

  getAllReturnDetails = (returnId: number) => {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "returnToVendor/" + returnId }, (response) => {
        this.setState({
          loading: false,
          ...response.data,
        })
      })
    })
  }

  chooseWarehouse = () => {
    this.handleSearchDepth(1)
    this.setState({
      toChooseWarehouse: true,
    })
  }

  warehouseSearchResult = (warehouse: warehouse) => {
    this.handleSearchDepth(0)
    this.setState({
      warehouse: warehouse,
      toChooseWarehouse: false,
    })
  }

  chooseVendor = () => {
    this.handleSearchDepth(1)
    this.setState({
      toChooseVendor: true,
    })
  }

  vendorSearchResult = (vendor: vendor) => {
    this.handleSearchDepth(0)
    this.setState({
      vendor: vendor,
      toChooseVendor: false,
    })
  }

  chooseProduct = () => {
    this.handleSearchDepth(1)
    this.setState({
      toChooseProduct: true,
    })
  }

  productSearchResult = (product: product) => {
    this.handleSearchDepth(0)

    let newProds = JSON.parse(JSON.stringify(this.state.products))

    // get additional information needed for the product
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "products/adjDetails/",
          data: {
            product_id: product.id,
            warehouse_id: this.state.warehouse ? this.state.warehouse.id : null,
          },
        },
        (response) => {
          product.qty_onhand = response.data.qty_onhand
          product.qty_available = response.data.qty_available
          product.qty_for_return = 0
          product.qty_to_adjust = 0
          product.qty_returned = 0
          product.return_value = product.landed_cost
          product.original_po = ""
          product.return_type = "defective"

          newProds.push(product)

          this.setState({
            loading: false,
            products: newProds,
            toChooseProduct: false,
          })
        }
      )
    })
  }

  backFromSearch = () => {
    this.handleSearchDepth(0)
    this.setState({
      toChooseProduct: false,
      toChooseWarehouse: false,
      toChooseVendor: false,
    })
  }

  deleteProduct = (product: product) => {
    const deleteIndex = this.state.products.findIndex((x) => x.unique_id === product.unique_id)
    let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))

    newProds.splice(deleteIndex, 1)

    this.setState({
      products: newProds,
    })
  }

  handleVendorReturnId = (e: any) => {
    this.setState({
      vendorReturnId: e.target.value,
    })
  }

  showProductInfo = (prod: product) => {
    this.setState({
      productInfo: prod,
    })
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    console.log("hiding dialog")
    this.setState({
      dialog: null,
    })
  }

  hideProductInfo = () => {
    if (!this.state.productInfo) {
      return
    }

    const prods: Array<product> = JSON.parse(JSON.stringify(this.state.products))

    const totalForReturn = prods.reduce((acc, curr) => {
      return (acc += curr.id === this.state.productInfo!.id ? curr.qty_for_return - curr.qty_returned : 0)
    }, 0)

    const qtyAvailable = this.state.productInfo.qty_available
    const qtyForReturn = this.state.productInfo.qty_for_return
    const qtyReturned = this.state.productInfo.qty_returned
    const qtyToAdjust = this.state.productInfo.qty_to_adjust
    const qtyOutstanding = qtyForReturn - qtyReturned

    if (qtyOutstanding > qtyAvailable) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "Cannot return more than available.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (totalForReturn > qtyAvailable) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "Between other duplicate products you are trying to return more than available.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (qtyToAdjust > qtyOutstanding) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "Cannot adjust more than for return.",
        handleResponse: this.hideDialog,
      })
      return
    }

    this.setState({
      productInfo: undefined,
    })
  }

  handleEscapeKey = (e: any) => {
    if (e.key === "Escape") {
      this.state.productInfo !== null && this.hideProductInfo()
    }
  }

  stopPropagation = (e: any) => {
    // prevents the overlay from closing when the body of the overlay is clicked
    e.stopPropagation()
  }

  handleProductInfoInput = (name: string, value: string | number) => {
    if (!this.state.productInfo) {
      return
    }

    let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let newProd = newProds.find((x) => x.unique_id === this.state.productInfo!.unique_id)

    if (!newProd) return

    newProd[name] = value

    this.setState({
      productInfo: newProd,
      products: newProds,
    })
  }

  handleReturnType = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (!this.state.productInfo) {
      return
    }

    let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let newProd = newProds.find((x) => x.unique_id === this.state.productInfo!.unique_id)

    if (!newProd) return

    newProd.return_type = e.target.value

    this.setState({
      products: newProds,
      productInfo: newProd,
    })
  }

  submitSave = () => {
    let dataToSubmit = {
      return_id: this.state.existingReturnId,
      vendor_id: this.state.vendor ? this.state.vendor.id : null,
      warehouse_id: this.state.warehouse ? this.state.warehouse.id : null,
      vendor_return_id: this.state.vendorReturnId,
      products: this.state.products,
    }

    this.setState({ loading: true }, () => {
      fireAxios(
        { method: "post", url: this.state.existingReturnId ? "returnToVendor/update" : "returnToVendor/create", data: dataToSubmit },
        (response) => {
          const returnId = this.state.existingReturnId ? this.state.existingReturnId : response.data
          this.setState(initialState, () => {
            this.getAllReturnDetails(returnId)
          })
        }
      )
    })
  }

  render() {
    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    const overlay: React.CSSProperties = {
      position: "fixed" /* Sit on top of the page content */,
      display: "block" /* Hidden by default */,
      width: "100%" /* Full width (cover the whole page) */,
      height: "100%" /* Full height (cover the whole page) */,
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.5)" /* Black background with opacity */,
      zIndex: 99 /* Specify a stack order in case you're using a different order for other elements */,
      cursor: "pointer" /* Add a pointer on hover */,
    }

    const overlayBody: React.CSSProperties = {
      position: "absolute",
      top: "15%",
      left: "15%",
      width: "70%",
      height: "70%",
      zIndex: 100,
      boxShadow: "0px 0px 30px #000000",
      backgroundColor: "white",
      cursor: "default",
      padding: "20px",
      overflow: "auto",
    }

    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseProduct) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <ProductSearch
            returnResult={this.productSearchResult}
            defaultedFields={{ line: this.state.vendor ? this.state.vendor.product_line : "" }}
          />
        </div>
      )
    }

    if (this.state.toChooseWarehouse) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseSearchResult} />
        </div>
      )
    }

    if (this.state.toChooseVendor) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <VendorSearch returnResult={this.vendorSearchResult} />
        </div>
      )
    }

    if (!this.state.warehouse) {
      return (
        <div>
          To begin, choose a warehouse: <button onClick={this.chooseWarehouse}>Choose Warehouse</button>
        </div>
      )
    }

    if (this.state.warehouse && !this.state.vendor) {
      return (
        <div>
          Warehouse: <b>{this.state.warehouse ? this.state.warehouse.name : ""}</b> <button onClick={this.chooseWarehouse}>Edit</button>
          <br />
          <br />
          Next, choose the vendor: <button onClick={this.chooseVendor}>Choose Vendor</button>
        </div>
      )
    }

    return (
      <div>
        {this.state.productInfo ? (
          <div style={overlay} onClick={() => this.hideProductInfo()}>
            {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
            <div style={overlayBody} onClick={(e) => this.stopPropagation(e)}>
              <div style={headerStyle}>
                <NewTabText
                  id={this.state.productInfo.id}
                  link="product"
                  text={this.state.productInfo.product_line + " - " + this.state.productInfo.product_num}
                />
                &nbsp;<span style={{ fontWeight: "normal" }}>{this.state.productInfo.description}</span>
              </div>
              <div style={{ display: "flex", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                <table>
                  <tbody>
                    <tr>
                      <td>On Hand:</td>
                      <td>{this.state.productInfo.qty_onhand}</td>
                    </tr>
                    <tr>
                      <td>Available:</td>
                      <td>{this.state.productInfo.qty_available}</td>
                    </tr>
                    <tr>
                      <td>for Return:</td>
                      <td>
                        <ControlledInput
                          name={"qty_for_return"}
                          restriction={"unsigned-int"}
                          handleChange={(n, v) => this.handleProductInfoInput(n, v)}
                          value={this.state.productInfo.qty_for_return}
                          disabled={this.state.isClosed}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>to Adjust:</td>
                      <td>
                        <ControlledInput
                          name={"qty_to_adjust"}
                          restriction={"unsigned-int"}
                          handleChange={(n, v) => this.handleProductInfoInput(n, v)}
                          value={this.state.productInfo.qty_to_adjust}
                          disabled={this.state.isClosed}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Returned:</td>
                      <td>
                        <b>{this.state.productInfo.qty_returned}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr>
                      <td>Original PO #:</td>
                      <td>
                        <input
                          name={"original_po"}
                          onChange={(e) => this.handleProductInfoInput(e.target.name, e.target.value)}
                          value={this.state.productInfo.original_po}
                          disabled={this.state.isClosed}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Return Value ($):</td>
                      <td>
                        <ControlledInput
                          name={"return_value"}
                          restriction={"unsigned-float"}
                          handleChange={(n, v) => this.handleProductInfoInput(n, v)}
                          value={this.state.productInfo.return_value}
                          disabled={this.state.isClosed}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  Reason:&nbsp;
                  <select
                    onChange={this.handleReturnType}
                    value={this.state.productInfo.return_type}
                    style={{ height: "25px" }}
                    disabled={this.state.isClosed}
                  >
                    <option value="defective">Defective</option>
                    <option value="slow_moving">Slow Moving</option>
                    <option value="too_much">Shipped Too Much</option>
                    <option value="wrong_product">Shipped Wrong Product</option>
                    <option value="other">Other</option>
                  </select>
                  <br />
                  <br />
                  <textarea
                    name={"comment"}
                    onChange={(e) => this.handleProductInfoInput(e.target.name, e.target.value)}
                    placeholder={"Comment"}
                    value={this.state.productInfo.comment}
                    style={{ height: "70px", width: "300px" }}
                    disabled={this.state.isClosed}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.isClosed ? <div style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>Closed</div> : null}
        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
          <div>
            Warehouse: <b>{this.state.warehouse.name}</b> &nbsp;
            {this.state.existingReturnId ? null : <button onClick={this.chooseWarehouse}>Edit</button>}
            <br />
            <br />
            Vendor: <b>{this.state.vendor ? this.state.vendor.name : ""}</b> &nbsp;
            {this.state.existingReturnId || this.state.products.length > 0 ? null : <button onClick={this.chooseVendor}>Edit</button>}
          </div>
          <div>
            Our Return Id: <b>{this.state.existingReturnId ? this.state.existingReturnId : "Not yet generated"}</b>
            <br />
            <br />
            Vendors Return Id: <input onChange={this.handleVendorReturnId} value={this.state.vendorReturnId} disabled={this.state.isClosed} />
          </div>
        </div>
        <br />
        <br />
        <SortableProductList
          products={this.state.products}
          onSortEnd={this.onSortEnd}
          deleteProduct={this.deleteProduct}
          showProductInfo={this.showProductInfo}
          useDragHandle={true}
        />
        <br />
        <div>{this.state.isClosed ? null : <button onClick={this.chooseProduct}>Add Product</button>}</div>
        <br />
        <br />
        {this.state.products.length > 0 ? <div>{this.state.isClosed ? null : <button onClick={this.submitSave}>Save & Submit</button>}</div> : null}
      </div>
    )
  }
}

export default ReturnToVendor
