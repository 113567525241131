import React, { Component } from "react"

import LoadingSpinner from "../LoadingSpinner"
import { CreateInput, inputTypeProps } from "./CreateInput"
import { Dialog, props as dialogProps } from "../dialog/Dialog"

import { fireAxios } from "../helpers"

type props = {
  title: string
  inputs: Array<inputTypeProps>
  resultChild: React.ReactNode
  endpoint: string
}

type state = {
  loading: boolean
  toSearch: boolean
  searchChild: React.ReactNode
  searchInputName: string | null
  errorMessage: string | null
  resultId: number | null
  searchDepth: number
  inputs: Array<inputTypeProps>
  dialog: dialogProps
}

const initialState: state = {
  loading: false,
  toSearch: false,
  searchChild: null,
  searchInputName: null,
  errorMessage: null,
  resultId: null,
  searchDepth: 0,
  inputs: [],
  dialog: null,
}

class BasicCreate extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = initialState

    if (this.props.setAppTitle) {
      this.props.setAppTitle("Create " + this.props.title)
    }
  }

  componentDidMount() {
    this.setState({
      inputs: this.props.inputs,
    })
  }

  handleCreateInput = (name: string, value: string | number, isValid: boolean) => {
    this.setState((prevState) => {
      let newInput = prevState.inputs.find((x) => x.name === name)

      if (!newInput) {
        return prevState
      } else {
        newInput.isValid = isValid
        newInput.value = value.toString()

        return {
          ...prevState,
          inputs: prevState.inputs.map((el) => (el.name === newInput!.name ? { ...el, value: newInput!.value, isValid: newInput!.isValid } : el)),
        }
      }
    })
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  toSearch = (searchChild: myAny, searchInputName: string) => {
    this.setState({
      toSearch: true,
      searchChild: searchChild,
      searchInputName: searchInputName,
    })
  }

  backFromSearch = () => {
    this.setState({
      toSearch: false,
    })
  }

  backFromResult = () => {
    if (this.props.setAppTitle) {
      this.props.setAppTitle("Create " + this.props.title)
    }

    this.setState({
      resultId: null,
    })
  }

  handleSearchDepth = (depth: number) => {
    this.setState({
      searchDepth: depth,
    })
  }

  returnSearchResult = (result: { [index: string]: any }) => {
    this.setState((prevState) => {
      let inputInQuestion = prevState.inputs.find((x) => x.name === this.state.searchInputName)

      if (inputInQuestion) {
        // check if there are falsey values for the required search results
        if (!result[inputInQuestion.requiredSearchResult as string]) {
          this.showDialog({
            type: "alert",
            title: "Error!",
            message: "This choice does not have " + inputInQuestion.requiredSearchResult,
            handleResponse: this.hideDialog,
          })
          return prevState
        } else {
          inputInQuestion.value = result[inputInQuestion.requiredSearchResult as string]
          inputInQuestion.display = result[inputInQuestion.displaySearchResult as string]

          return {
            ...prevState,
            toSearch: false,
            inputs: prevState.inputs.map((el) =>
              el.name === prevState.searchInputName ? { ...el, display: inputInQuestion!.display, value: inputInQuestion!.value } : el
            ),
          }
        }
      }

      return prevState
    })
  }

  submitSave = () => {
    // find if there are any invalid inputs
    let invalidInput = this.state.inputs.find((x) => (x.hasOwnProperty("isValid") && !x.isValid) || (x.required && x.value.length === 0))

    if (invalidInput) {
      // an invalid input exists
      if (invalidInput.required && invalidInput.value.length === 0) {
        // a required input is not filled out
        this.showDialog({
          title: "Error!",
          message: this.props.title + " " + invalidInput.name + " is required.",
          type: "alert",
          handleResponse: this.hideDialog,
        })
        return
      }
      this.showDialog({
        title: "Error!",
        message: this.props.title + " " + invalidInput.name + " is not valid.",
        type: "alert",
        handleResponse: this.hideDialog,
      })
      return
    }

    let dataToSubmit = Object.assign({}, ...this.state.inputs.map((input: any) => (input = { [input.databaseField]: input.value })))

    if (this.props.parentId) {
      Object.assign(dataToSubmit, this.props.parentId)
    }

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: this.props.endpoint, data: dataToSubmit }, (response) => {
        if (response.data.error) {
          this.setState({
            loading: false,
            errorMessage: response.data.errorMessage,
          })
        } else {
          this.setState(
            {
              ...initialState,
              resultId: response.data.insertId,
              inputs: this.props.inputs.map((x) => {
                return { ...x, value: "" } // reset values
              }),
            },
            () => {
              if (this.props.returnResult) {
                this.props.returnResult(response.data)
              }
            }
          )
        }
      })
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.resultId && this.props.resultChild) {
      return (
        <div>
          {this.state.searchDepth === 0 ? (
            <div>
              <button onClick={this.backFromResult}>Back to Create {this.props.title}</button>
              <br />
              <br />
            </div>
          ) : null}
          {React.cloneElement(this.props.resultChild as React.ReactElement, {
            id: this.state.resultId,
            handleSearchDepth: this.handleSearchDepth,
            ...this.props,
          })}
        </div>
      )
    }

    if (this.state.toSearch) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create {this.props.title}</button>
          <br />
          <br />
          {React.cloneElement(this.state.searchChild as React.ReactElement, { returnResult: this.returnSearchResult })}
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    const inputFunctions = {
      onChangeValue: this.handleCreateInput,
      toSearch: this.toSearch,
    }

    return (
      <div>
        <b>Required {this.props.title} Fields:</b>
        <br />
        <br />
        <table>
          <tbody>
            {this.state.inputs.map((inputProps: myAny) =>
              inputProps.required ? <CreateInput key={inputProps.name} {...inputFunctions} {...inputProps} /> : null
            )}
          </tbody>
        </table>
        {this.state.inputs.some((x: { required: myAny }) => !x.required) ? (
          <div>
            <br />
            <br />
            <b>Optional {this.props.title} Fields:</b>
            <br />
            <br />
            <table>
              <tbody>
                {this.state.inputs.map((inputProps) =>
                  !inputProps.required ? <CreateInput key={inputProps.name} {...inputFunctions} {...inputProps} /> : null
                )}
              </tbody>
            </table>
          </div>
        ) : null}
        <br />
        {this.state.errorMessage ? (
          <div>
            <span style={{ color: "red" }}>{this.state.errorMessage}</span>
            <br />
            <br />
          </div>
        ) : null}
        <button onClick={this.submitSave}>Create {this.props.title}</button>
        {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
      </div>
    )
  }
}

export default BasicCreate
