import React, { Component } from "react"

import LoadingSpinner from "../LoadingSpinner"

import { fireAxios } from "../helpers"

type props = {}

type state = {
  loading: boolean
  rules: Array<rule>
  qbAccounts: Array<qbAccount>
}

type qbAccount = {
  AcctNum: string
  Name: string
  Id: string
}

type rule = {
  id: number
  rule: string
  accounts: Array<account>
}

type account = {
  id: number
  kind: string
  debit_qb_number?: string
  debit_qb_name?: string
  debit_qb_id?: string
  credit_qb_number?: string
  credit_qb_name?: string
  credit_qb_id?: string
}

const initialState = {
  loading: true,
  rules: [],
  qbAccounts: [],
}

class QuickbooksSettings extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState
    this.props.setAppTitle && this.props.setAppTitle("Quickbooks Settings")
  }

  componentDidMount() {
    this.getAllInfo()
  }

  getAllInfo = () => {
    fireAxios(
      {
        method: "get",
        url: "quickbooks/rules/all",
      },
      (response) => {
        this.setState(
          {
            rules: response.data,
          },
          () => {
            fireAxios(
              {
                method: "get",
                url: "quickbooks/accounts/all",
              },
              (response) => {
                this.setState({
                  loading: false,
                  qbAccounts: response.data,
                })
              }
            )
          }
        )
      }
    )
  }

  changeAccount = (debit: boolean, rule: rule, ruleAccountId: number, newQBAccountId: string) => {
    let foundAccount = rule.accounts.find((x) => x.id === ruleAccountId)
    let foundQBAccount = this.state.qbAccounts.find((x) => x.Id === newQBAccountId)

    if (foundAccount && foundQBAccount) {
      if (debit) {
        foundAccount.debit_qb_id = foundQBAccount.Id
        foundAccount.debit_qb_name = foundQBAccount.Name
        foundAccount.debit_qb_number = foundQBAccount.AcctNum
      } else {
        foundAccount.credit_qb_id = foundQBAccount.Id
        foundAccount.credit_qb_name = foundQBAccount.Name
        foundAccount.credit_qb_number = foundQBAccount.AcctNum
      }
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        rules: Object.assign([], prevState.rules, rule),
      }
    })
  }

  save = () => {
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "quickbooks/rules/update",
          data: this.state.rules,
        },
        () => {
          this.getAllInfo()
        }
      )
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    return (
      <div>
        {this.state.rules.map((rule) => (
          <div key={rule.id}>
            <h3>{rule.rule}</h3>
            <hr />
            <button onClick={this.save}>Save</button>
            <hr />
            <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
              {rule.accounts.map((account) => (
                <div key={account.id} style={{ border: "1px solid black", padding: "5px", margin: "5px" }}>
                  <b>{account.kind}</b>
                  <hr />
                  {["Debit", "Credit"].map((x) => (
                    <div key={x} style={{ padding: "5px" }}>
                      {x}:
                      <select
                        onChange={(e) => this.changeAccount(x === "Debit", rule, account.id, e.currentTarget.value)}
                        value={x === "Debit" ? account.debit_qb_id || "" : account.credit_qb_id || ""}
                      >
                        <option disabled value={""}>
                          Choose an Account (none set)
                        </option>
                        {this.state.qbAccounts.map((qbAcc) => {
                          return (
                            <option key={qbAcc.Id} value={qbAcc.Id}>
                              {qbAcc.AcctNum} - {qbAcc.Name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <br />
            <br />
          </div>
        ))}
      </div>
    )
  }
}

export default QuickbooksSettings
