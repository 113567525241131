import React, { Component } from "react"

// import child components
import LoadingSpinner from "../LoadingSpinner"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import { fireAxios } from "../helpers"

type props = {
  extensions: Array<string>
  fileType: string
  otherDataToSend: {
    [index: string]: any
  }
  apiRoute: string
  onUploadSuccess: () => void
}

type state = {
  loading: boolean
  file: File | null
  hoverFilePicker: boolean
  dialog: dialogProps
}

const initialState: state = {
  loading: false,
  file: null,
  hoverFilePicker: false,
  dialog: null,
}

class Upload extends Component<props, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState
  }

  toggleHover = () => {
    this.setState({
      hoverFilePicker: !this.state.hoverFilePicker,
    })
  }

  handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      file: e.target.files ? e.target.files[0] : null,
    })
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let extentions: Array<string> = this.state.file && this.state.file.name.includes(".") ? this.state.file.name.split(".") : []
    let extension: string = extentions.pop() ? (extentions.pop() as string).toLowerCase() : ""

    if (!extension || !this.props.extensions.find((x: string) => x === extension)) {
      this.showDialog({
        title: "Error!",
        message: `File is not a valid ${this.props.fileType}. Supported file extensions are: ${this.props.extensions.join(", ")}`,
        type: "alert",
        options: [],
        handleResponse: this.hideDialog,
      })
    }

    const formData = new FormData()

    Object.keys(this.props.otherDataToSend).forEach((key) => {
      formData.append(key, this.props.otherDataToSend[key])
    })

    formData.append(this.props.fileType, this.state.file as Blob)

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: this.props.apiRoute, headers: { "content-type": "multipart/form-data" }, data: formData }, (_response) => {
        this.setState(
          {
            loading: false,
            file: null,
          },
          () => {
            if (this.props.onUploadSuccess) {
              this.props.onUploadSuccess()
            }
          }
        )
      })
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    let hoverStyle = {
      border: "1px dotted black",
    }

    const extensionsToAccept = this.props.extensions.reduce((acc: string, curr: string) => (acc += `.${curr},`), "")

    return (
      <form onSubmit={this.handleSubmit}>
        {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        Upload new {this.props.fileType}
        <hr />
        <input
          style={this.state.hoverFilePicker ? hoverStyle : undefined}
          type="file"
          accept={extensionsToAccept}
          name={this.props.fileType}
          onChange={this.handleFile}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
        />
        <br />
        <br />
        <button disabled={!this.state.file} type="submit">
          Upload
        </button>
      </form>
    )
  }
}

export default Upload
