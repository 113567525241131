import React, { Component } from "react"

import BasicCreate from "../create/BasicCreate"
import Salesrep from "./Salesrep"

class CreateSalesrep extends Component {
  render() {
    return (
      <BasicCreate
        {...{
          title: "Salesrep",
          resultChild: <Salesrep />,
          inputs: [
            // required fields
            { value: "", required: true, name: "First Name", databaseField: "first_name", inputType: "text" },
            { value: "", required: true, name: "Last Name", databaseField: "last_name", inputType: "text" },
          ],
          endpoint: "salesreps/create",
        }}
        {...this.props}
      />
    )
  }
}

export default CreateSalesrep
