import React from "react"

import { openNewTab } from "../helpers"

type props = {
  id: number
  link: string
  text: string
}

class NewTabText extends React.Component<props> {
  render() {
    return (
      <span
        onClick={() => {
          openNewTab(this.props.link, this.props.id)
        }}
        style={{ cursor: "pointer", textDecoration: "underline" }}
      >
        {this.props.text}
      </span>
    )
  }
}
export default NewTabText
