import React, { Component } from "react"

// import child child components / helpers
import LoadingSpinner from "../LoadingSpinner"
import BinLocationSearch from "../products/BinLocationSearch"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import { fireAxios, download } from "../helpers"

type props = {}

type warehouse = {
  id?: number
  name?: string
}

type state = {
  loading: boolean
  toChooseBinLocation: boolean
  binLocation: string
  toChooseWarehouse: boolean
  warehouse?: warehouse
  includeZeroQuantities: boolean
}

const initialState = {
  loading: false,
  toChooseBinLocation: false,
  binLocation: "",
  toChooseWarehouse: false,
  warehouse: undefined,
  includeZeroQuantities: false,
}

const title = "Quantity by Bin Location"

class QtyByBin extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState
    if (this.props.setAppTitle) {
      this.props.setAppTitle(title)
    }
  }

  backFromSearch = () => {
    this.setState({
      toChooseBinLocation: false,
      toChooseWarehouse: false,
    })
  }

  chooseBinLocation = () => {
    this.setState({
      toChooseBinLocation: true,
    })
  }

  binLocationSearchResult = (location: { [index: string]: any }) => {
    this.setState({
      binLocation: location.location,
      toChooseBinLocation: false,
    })
  }

  chooseWarehouse = () => {
    this.setState({
      toChooseWarehouse: true,
    })
  }

  warehouseSearchResult = (warehouse: warehouse) => {
    this.setState({
      warehouse: warehouse,
      toChooseWarehouse: false,
    })
  }

  handleZeroQuantities = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      includeZeroQuantities: e.target.checked,
    })
  }

  generateReport = () => {
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "reports/qtybybin",
          data: {
            warehouse: this.state.warehouse,
            location: this.state.binLocation,
            include_zeros: this.state.includeZeroQuantities,
            title: this.state.binLocation + "_quantity_report",
          },
        },
        (response) => {
          download("Excel", response.data, this.state.binLocation + "_quantity_report")
          this.setState({
            loading: false,
          })
        }
      )
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseBinLocation) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <BinLocationSearch returnResult={this.binLocationSearchResult} />
        </div>
      )
    }

    if (this.state.toChooseWarehouse) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseSearchResult} />
        </div>
      )
    }

    if (!this.state.warehouse) {
      return (
        <div>
          To begin, choose desired warehouse: <button onClick={this.chooseWarehouse}>Choose Warehouse</button>
        </div>
      )
    }

    if (!this.state.binLocation) {
      return (
        <div>
          Warehouse: <b>{this.state.warehouse.name}</b>
          &nbsp;<button onClick={this.chooseWarehouse}>Edit</button>
          <br />
          <br />
          Next, choose a primary bin location: <button onClick={this.chooseBinLocation}>Choose Bin Location</button>
        </div>
      )
    }

    return (
      <div>
        <div>
          Warehouse: <b>{this.state.warehouse.name}</b>
          &nbsp;<button onClick={this.chooseWarehouse}>Edit</button>
          <br />
          <br />
          Primary Bin: <b>{this.state.binLocation}</b>
          &nbsp;<button onClick={this.chooseBinLocation}>Edit</button>
          <br />
          <br />
          Include 0 quantities: <input type="checkbox" onChange={this.handleZeroQuantities} checked={this.state.includeZeroQuantities} />
        </div>
        <br />
        <button onClick={this.generateReport}>Generate Report</button>
      </div>
    )
  }
}

export default QtyByBin
