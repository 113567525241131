import React, { Component } from "react"

// import child components
import Search from "../search/Search"
import Pricing from "./Pricing"

class PricingSearch extends Component<appProps> {
  render() {
    return (
      <Search
        title={"Pricing"}
        queries={[
          { name: "Customer Number", value: "", databaseSearch: "customer_num", restriction: "customer-num" },
          { name: "Pricing Title", value: "", databaseSearch: "title" },
        ]}
        resultColumns={[
          { name: "Customer Number", databaseField: "customer_num" },
          { name: "Pricing Title", databaseField: "title" },
        ]}
        apiEndpoint={"pricing/search"}
        children={<Pricing />}
        {...this.props}
      />
    )
  }
}

export default PricingSearch
