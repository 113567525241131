import React, { Component } from "react"

// import child components / helpers
import LoadingSpinner from "../LoadingSpinner"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import { fireAxios } from "../helpers"

type props = {
  id?: number
}

type state = {
  loading: boolean
  salesrepId?: number
  dialog: dialogProps
  salesrep: {
    id: number
    first_name: string
    last_name: string
    closed: boolean
  }
}

class Salesrep extends Component<props & appProps, state> {
  state: state = {
    salesrepId: 0,
    dialog: null,
    salesrep: {
      id: -1,
      first_name: "",
      last_name: "",
      closed: true,
    },
    loading: false,
  }

  componentDidMount() {
    this.setState(
      {
        salesrepId: this.props.id,
      },
      () => {
        this.getSalesrepsDetails(this.state.salesrepId ? this.state.salesrepId : -1)
      }
    )
  }

  getSalesrepsDetails = (id: number) => {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "salesreps/" + id }, (response) => {
        this.setState(
          {
            salesrep: response.data,
            loading: false,
          },
          () => {
            this.props.setAppTitle && this.props.setAppTitle(this.state.salesrep.first_name + " " + this.state.salesrep.last_name)
            this.hideDialog()
          }
        )
      })
    })
  }

  updateSalesrep = (_source: any, context: string, value: any) => {
    let newSalesrep = JSON.parse(JSON.stringify(this.state.salesrep)) // product copy to submit to datebase

    if (context) {
      newSalesrep[context] = value
    } else {
      console.log("No context")
      return
    }

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "salesreps/update", data: newSalesrep }, () => {
        this.getSalesrepsDetails(this.state.salesrep.id)
      })
    })
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  openCloseSalesrep = () => {
    const isClosed = this.state.salesrep.closed

    this.showDialog({
      type: "confirm",
      title: "Are you sure?",
      message: "Are you sure you want to " + (isClosed ? "open" : "close") + " this salesrep?",
      handleResponse: (response) => {
        if (response) {
          this.updateSalesrep(null, "closed", !isClosed)
        } else {
          this.hideDialog()
        }
      },
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    return (
      <div className="customer">
        {this.state.salesrep.closed ? (
          <div className="header">
            <span style={{ color: "red" }}>SALESREP CLOSED</span>
          </div>
        ) : null}

        {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}

        <div style={{ display: "flex", justifyContent: "normal", flexWrap: "wrap" }}>
          <div style={{ padding: "12px" }}>
            <table className="detailTable">
              <tbody>
                <tr>
                  <th>First Name</th>
                  <td>{this.state.salesrep.first_name}</td>
                </tr>
                <tr>
                  <th>Last Name</th>
                  <td>{this.state.salesrep.last_name}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <br />

        <div>
          <h2>Other:</h2>
          <div>
            This is salesrep is {this.state.salesrep.closed ? "closed" : "open"}: &nbsp;{" "}
            <button onClick={this.openCloseSalesrep}>{this.state.salesrep.closed ? "Open" : "Close"} salesrep</button>
          </div>
        </div>
      </div>
    )
  }
}

export default Salesrep
