import React, { Component } from "react"

// import child components / helpers
import POSearch from "../purchasing/POSearch"
import LoadingSpinner from "../LoadingSpinner"
import ControlledInput from "../edit/ControlledInput"
import NewTabText from "../newTabText/NewTabText"
import { fireAxios } from "../helpers"

type props = {}

type reception = {
  qty_received: number
}

type destination = {
  type: string
  quantity: number
  receptions: Array<reception>
}

type product = {
  id: number
  po_product_id: number
  product_id: number
  line: string
  num: string
  description: string
  qty_to_receive: number
  destinations: Array<destination>
  [index: string]: any
}

type details = {
  id: number
  vendor: {
    id: number
    name: string
  }
  products: Array<product>
}

type state = {
  loading: boolean
  toChoosePO: boolean
  receivingDetails?: details
  submitted: boolean
  vendorSlip: string
  [index: string]: any
}

const initialState: state = {
  loading: false,
  toChoosePO: false,
  receivingDetails: undefined,
  submitted: false,
  vendorSlip: "",
}

class ReceivePO extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = initialState
    this.props.setAppTitle && this.props.setAppTitle("Receive Purchase")
  }

  poSearchResult = (po: any) => {
    // get the po receiving details
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "receiving/" + po.id }, (response) => {
        this.setState({
          receivingDetails: response.data,
          toChoosePO: false,
          loading: false,
        })
      })
    })
  }

  handleToReceiveQty = (name: string, value: string | number, prod: product) => {
    let newArray: details = JSON.parse(JSON.stringify(this.state.receivingDetails))
    let foundProd = newArray.products.find((x) => x.po_product_id === prod.po_product_id)

    if (!foundProd) return

    foundProd[name] = value

    this.setState({
      receivingDetails: newArray,
    })
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  backFromSearch = () => {
    this.setState({
      toChoosePO: false,
    })
  }

  choosePO = () => {
    this.setState({
      toChoosePO: true,
    })
  }

  resetReceiving = () => {
    this.setState(initialState)
  }

  submitReceived = () => {
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "receiving",
          data: {
            ...this.state.receivingDetails,
            vendor_slip_num: this.state.vendorSlip,
          },
        },
        () => {
          this.setState({
            loading: false,
            submitted: true,
          })
        }
      )
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.submitted) {
      return (
        <div>
          <div>Reception Complete</div>
          <br />
          {/* <button onClick={this.printSummary}>Print Summary</button> */}
          <button onClick={this.resetReceiving}>Receive Another</button>
        </div>
      )
    }

    if (this.state.toChoosePO) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Receive Purchase</button>
          <br />
          <br />
          <POSearch returnResult={this.poSearchResult} />
        </div>
      )
    }

    var recvDetails = this.state.receivingDetails
    if (recvDetails) {
      return (
        <div>
          <div>
            Selected PO: <b>{recvDetails.id}</b> <button onClick={this.choosePO}>Edit</button>
          </div>
          <br />
          <div>
            Vendor: <b>{recvDetails.vendor.id}</b> {recvDetails.vendor.name}
          </div>
          <br />
          <br />
          <br />
          Vendor Packing Slip #:
          <br />
          <input name="vendorSlip" onChange={this.handleInputChange} />
          <br />
          <br />
          <table>
            <tbody>
              <tr>
                <th>Product</th>
                <th>Description</th>
                <th>Ordered</th>
                <th>Received</th>
                <th>To Receive</th>
              </tr>
              {recvDetails.products.map((prod) => {
                let qtyOrdered = 0
                let qtyReceived = 0
                prod.destinations.forEach((dest) => {
                  qtyOrdered += dest.type !== "Over Received" ? dest.quantity : 0
                  dest.receptions.forEach((reception) => (qtyReceived += reception.qty_received))
                })
                return (
                  <tr key={prod.po_product_id}>
                    <td>
                      <NewTabText id={prod.product_id} link="product" text={prod.line + " - " + prod.num} />
                    </td>
                    <td>{prod.description}</td>
                    <td>{qtyOrdered}</td>
                    <td>{qtyReceived}</td>
                    <td>
                      <ControlledInput
                        placeholder="Qty"
                        name={"qty_to_receive"}
                        restriction={"unsigned-int"}
                        value={prod.qty_to_receive || 0}
                        handleChange={(n, v) => this.handleToReceiveQty(n, v, prod)}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <br />
          <button onClick={this.submitReceived}>Submit Receiving</button>
        </div>
      )
    }

    return (
      <div>
        To begin, choose the purchase: &nbsp;
        <button onClick={this.choosePO}>Choose Purchase</button>
      </div>
    )
  }
}

export default ReceivePO
