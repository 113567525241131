import React, { Component } from "react"

import LoadingSpinner from "../LoadingSpinner"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import { fireAxios, toFileDate, dateObjToInputDate, inputDateToDateObj, download } from "../helpers"

type props = {}

type warehouse =
  | {
      id: number
      name: string
    }
  | undefined

type state = {
  loading: boolean
  warehouse: warehouse
  toWarehouseSearch: boolean
  beginDate?: Date
  endDate?: Date
  [index: string]: any
}

const title = "Open Orders Report"

class OpenOrders extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)

    this.state = {
      loading: false,
      warehouse: undefined,
      toWarehouseSearch: false,
    }

    if (this.props.setAppTitle) {
      this.props.setAppTitle(title)
    }
  }

  warehouseResult = (result: warehouse) => {
    this.setState({
      warehouse: result,
      toWarehouseSearch: false,
    })
  }

  toWarehouseSearch = () => {
    this.setState({
      toWarehouseSearch: true,
    })
  }

  backFromSearch = () => {
    this.setState({
      toWarehouseSearch: false,
    })
  }

  updateDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [e.target.name]: inputDateToDateObj(e.target.value),
    })
  }

  clearDate = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      [e.currentTarget.name]: undefined,
    })
  }

  submitRequest = () => {
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "reports/openorders/",
          data: {
            begin_date: this.state.beginDate,
            end_date: this.state.endDate,
            warehouse_id: this.state.warehouse ? this.state.warehouse.id : undefined,
          },
        },
        (response) => {
          this.setState({ loading: false }, () => {
            download(
              "Excel",
              response.data,
              "OpenOrders" +
                "_" +
                (this.state.warehouse ? this.state.warehouse.name : "") +
                (this.state.beginDate ? "_" + toFileDate(this.state.beginDate || "") : "") +
                (this.state.endDate ? "_" + toFileDate(this.state.endDate || "") : "")
            )
          })
        }
      )
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toWarehouseSearch) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseResult} />
        </div>
      )
    }

    return (
      <div>
        <label htmlFor="beginDate">Begin Date (optional): </label>
        <input
          type="date"
          name="beginDate"
          onChange={this.updateDate}
          value={this.state.beginDate ? dateObjToInputDate(this.state.beginDate) || "" : ""}
        />
        <button name="beginDate" onClick={this.clearDate}>
          Clear
        </button>

        <br />
        <br />

        <label htmlFor="endDate">End Date (optional): </label>
        <input type="date" name="endDate" onChange={this.updateDate} value={this.state.endDate ? dateObjToInputDate(this.state.endDate) || "" : ""} />
        <button name="endDate" onClick={this.clearDate}>
          Clear
        </button>

        <br />
        <br />

        <label htmlFor="warehouse">
          {this.state.warehouse ? "Warehouse: " : "Select Warehouse (optional): "}
          <b>{this.state.warehouse ? this.state.warehouse.name + " " : " "}</b>
        </label>
        <button name="warehouse" onClick={() => this.toWarehouseSearch()}>
          {this.state.warehouse ? "Edit" : "Add"}
        </button>
        {this.state.warehouse ? <button onClick={() => this.warehouseResult(undefined)}>Clear</button> : null}

        <br />
        <br />
        <button onClick={this.submitRequest}>Generate</button>
      </div>
    )
  }
}
export default OpenOrders
