import React from "react"

import SignOut from "./components/home/SignOut"
import Testing from "./components/Testing"
import Settings from "./components/home/Settings"
import UserSearch from "./components/home/UserSearch"
import CreateUser from "./components/home/CreateUser"
import ProductSearch from "./components/products/ProductSearch"
import Product from "./components/products/Product"
import QuantityAdj from "./components/products/QuantityAdj"
import VendorSearch from "./components/vendors/VendorSearch"
import CustomerSearch from "./components/customers/CustomerSearch"
import PurchaseOrder from "./components/purchasing/PurchaseOrder"
import ProdHistSearch from "./components/products/ProdHistSearch"
import ReceivePO from "./components/receiving/ReceivePO"
import ReceiveTransfer from "./components/receiving/ReceiveTransfer"
import POSearch from "./components/purchasing/POSearch"
import Payables from "./components/purchasing/Payables"
import WarehouseSearch from "./components/warehouses/WarehouseSearch"
import OpenToBuy from "./components/purchasing/OpenToBuy/OpenToBuy"
import Order from "./components/orders/Order"
import OrderSearch from "./components/orders/OrderSearch"
import OrderReturn from "./components/orders/OrderReturn"
import Transfer from "./components/transfers/Transfer"
import TransferSearch from "./components/transfers/TransferSearch"
import SalesReport from "./components/reports/SalesReport"
import Adjustments from "./components/reports/Adjustments"
import InvEval from "./components/reports/InvEval"
import OpenOrders from "./components/reports/OpenOrders"
import OpenPOs from "./components/reports/OpenPOs"
import QtyByBin from "./components/reports/QtyByBin"
import BinChange from "./components/products/BinChange"
import BatchBinChange from "./components/products/BatchBinChange"
import ReturnToVendor from "./components/returns/ReturnToVendor"
import ReturnVendorSearch from "./components/returns/ReturnVendorSearch"
import CreateProduct from "./components/products/CreateProduct"
import CurrencySearch from "./components/currencies/CurrencySearch"
import CreateCurrency from "./components/currencies/CreateCurrency"
import UpdateCurrency from "./components/currencies/UpdateCurrency"
import CreateCustomer from "./components/customers/CreateCustomer"
import CreateSalesrep from "./components/salesreps/CreateSalesrep"
import SalesrepSearch from "./components/salesreps/SalesrepSearch"
import CreateVendor from "./components/vendors/CreateVendor"
import CreateWarehouse from "./components/warehouses/CreateWarehouse"
import PricingSearch from "./components/pricing/PricingSearch"
import Pricing from "./components/pricing/Pricing"
import Permissions from "./components/home/Permissions"
import QuickbooksSettings from "./components/home/QuickbooksSettings"
import Deposit from "./components/customers/Deposit"
import ApplyDeposit from "./components/customers/ApplyDeposit"

type menuNesting = Array<mainItems>

type mainItems = {
  mainItem: string
  subItems: Array<subItem>
}

export type subItem = {
  name: string
  link: string
  access?: boolean
  admin?: boolean
  hidden?: boolean
  component: React.ReactNode
}

const defaultPage = "signout"

export const DEFAULT = {
  [defaultPage]: {
    readonly: false,
    component: <SignOut />,
    currentTitle: "Sign Out",
  },
}

export const MENU_NESTING: menuNesting = [
  {
    mainItem: "Home",
    subItems: [
      {
        name: "Settings",
        link: "settings",
        access: true,
        component: <Settings />,
      },
      {
        name: "Quickbooks",
        link: "qbsettings",
        admin: true,
        component: <QuickbooksSettings />,
      },
      {
        name: "User Search",
        link: "usersearch",
        admin: true,
        component: <UserSearch />,
      },
      {
        name: "Create User",
        link: "createuser",
        admin: true,
        component: <CreateUser />,
      },
      {
        name: "Permissions Queue",
        link: "permissions",
        component: <Permissions />,
      },
      {
        name: "Testing",
        link: "testing",
        component: <Testing />,
      },
      {
        name: "Sign Out",
        link: defaultPage,
        access: true,
        component: <SignOut />,
      },
    ],
  },
  {
    mainItem: "Warehouses",
    subItems: [
      {
        name: "Search",
        link: "searchwarehouses",
        component: <WarehouseSearch />,
      },
      {
        name: "Create",
        link: "createwarehouse",
        component: <CreateWarehouse />,
      },
    ],
  },
  {
    mainItem: "Salesreps",
    subItems: [
      {
        name: "Search",
        link: "searchsalesreps",
        component: <SalesrepSearch />,
      },
      {
        name: "Create",
        link: "createsalesrep",
        component: <CreateSalesrep />,
      },
    ],
  },
  {
    mainItem: "Currencies",
    subItems: [
      {
        name: "Search",
        link: "searchcurrencies",
        component: <CurrencySearch />,
      },
      {
        name: "Create",
        link: "createcurrency",
        component: <CreateCurrency />,
      },
      {
        name: "Update",
        link: "updatecurrency",
        component: <UpdateCurrency />,
      },
    ],
  },
  {
    mainItem: "Vendors",
    subItems: [
      {
        name: "Search",
        link: "searchvendors",
        component: <VendorSearch />,
      },
      {
        name: "Create",
        link: "createvendor",
        component: <CreateVendor />,
      },
    ],
  },
  {
    mainItem: "Customers",
    subItems: [
      {
        name: "Search",
        link: "searchcustomers",
        component: <CustomerSearch />,
      },
      {
        name: "Create",
        link: "createcustomer",
        component: <CreateCustomer />,
      },
      {
        name: "Counter Dep.",
        link: "counterdeposit",
        component: <Deposit counter={true} />,
      },
      {
        name: "Deposit",
        link: "deposit",
        component: <Deposit counter={false} />,
      },
      {
        name: "Apply Deposit",
        link: "applydeposit",
        component: <ApplyDeposit />,
      },
    ],
  },
  {
    mainItem: "Products",
    subItems: [
      {
        name: "Search",
        link: "searchproducts",
        component: <ProductSearch />,
      },
      {
        name: "Product",
        link: "product",
        component: <Product />,
        hidden: true,
      },
      {
        name: "Create",
        link: "createproduct",
        component: <CreateProduct />,
      },
      {
        name: "History",
        link: "producthistory",
        component: <ProdHistSearch />,
      },
      {
        name: "Bin Changes",
        link: "binchange",
        component: <BinChange />,
      },
      {
        name: "Batch Bin Changes",
        link: "batchbinchange",
        component: <BatchBinChange />,
      },
      {
        name: "Quantity Adjustments",
        link: "quantityadj",
        component: <QuantityAdj />,
      },
    ],
  },

  {
    mainItem: "Orders",
    subItems: [
      {
        name: "Search",
        link: "searchOrders",
        component: <OrderSearch />,
      },
      {
        name: "Create",
        link: "order",
        component: <Order />,
      },
      {
        name: "Return",
        link: "returnOrders",
        component: <OrderReturn />,
      },
    ],
  },
  {
    mainItem: "Pricing",
    subItems: [
      {
        name: "Search",
        link: "searchpricing",
        component: <PricingSearch />,
      },
      {
        name: "Create",
        link: "createpricing",
        component: <Pricing />,
      },
    ],
  },
  {
    mainItem: "Transfers",
    subItems: [
      {
        name: "Search",
        link: "transferSearch",
        component: <TransferSearch />,
      },
      {
        name: "Create",
        link: "transfer",
        component: <Transfer />,
      },
    ],
  },
  {
    mainItem: "Purchasing",
    subItems: [
      {
        name: "Search",
        link: "searchPOs",
        component: <POSearch />,
      },
      {
        name: "Create",
        link: "purchase",
        component: <PurchaseOrder />,
      },
      {
        name: "Open to Buy",
        link: "openToBuy",
        component: <OpenToBuy />,
      },
      {
        name: "Payables",
        link: "payables",
        component: <Payables />,
      },
    ],
  },
  {
    mainItem: "Receiving",
    subItems: [
      {
        name: "Purchase",
        link: "receivePurchase",
        component: <ReceivePO />,
      },
      {
        name: "Transfer",
        link: "receiveTransfer",
        component: <ReceiveTransfer />,
      },
    ],
  },
  {
    mainItem: "Returns",
    subItems: [
      {
        name: "Search",
        link: "searchreturn",
        component: <ReturnVendorSearch />,
      },
      {
        name: "Create",
        link: "return",
        component: <ReturnToVendor />,
      },
    ],
  },

  {
    mainItem: "Reports",
    subItems: [
      {
        name: "Quantities by Bin",
        link: "qtybybin",
        component: <QtyByBin />,
      },
      {
        name: "Inventory Evaluation",
        link: "invevalreport",
        component: <InvEval />,
      },
      {
        name: "Adjustments",
        link: "adjustmentreport",
        component: <Adjustments />,
      },
      {
        name: "Open Orders",
        link: "openorders",
        component: <OpenOrders />,
      },
      {
        name: "Open POs",
        link: "openpos",
        component: <OpenPOs />,
      },
      {
        name: "Sales",
        link: "salesreport",
        component: <SalesReport />,
      },
    ],
  },
]
