import "./index.css"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import firebase from "firebase/app"

// initialize firebase
var config: object = {
  apiKey: "AIzaSyBF1l5yektBp9wNB7aAUlcPee6keLgxU2U",
  authDomain: "montgomery-sandbox-308017.firebaseapp.com",
  databaseURL: "https://montgomery-sandbox-308017-default-rtdb.firebaseio.com",
  projectId: "montgomery-sandbox-308017",
  storageBucket: "montgomery-sandbox-308017.appspot.com",
  messagingSenderId: "82218409168",
  appId: "1:82218409168:web:f8133978f0ad6f2f9bd2d5",
} // TODO: move into private folder

firebase.initializeApp(config)

let SERVERERR: { error: any; timestamp?: Date } = { error: false }

export const setServerError = (errData: any) => {
  SERVERERR = {
    error: errData,
    timestamp: errData ? new Date() : undefined,
  }
  renderApp()
}

const renderApp = () => {
  ReactDOM.render(<App serverError={SERVERERR} />, document.getElementById("root"))
}

renderApp()
