import React, { Component } from "react"

// import child components
import ProductSearch from "./ProductSearch"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import LoadingSpinner from "../LoadingSpinner"
import Search from "../search/Search"

type props = {}

type warehouse =
  | {
      id?: number
      name?: string
    }
  | undefined

type product =
  | {
      id?: number
      product_line?: string
      product_num?: string
      description?: string
    }
  | undefined

type state = {
  toChooseProduct: boolean
  product: product
  toChooseWarehouse: boolean
  warehouse: warehouse
  loading: boolean
}

class ProdHistSearch extends Component<props & appProps, state> {
  state: state = {
    toChooseProduct: false,
    product: undefined,
    toChooseWarehouse: false,
    warehouse: undefined,
    loading: false,
  }

  constructor(props: props & appProps) {
    super(props)
    this.props.setAppTitle && this.props.setAppTitle("Product History")
  }

  backFromSearch = () => {
    this.setState({
      toChooseProduct: false,
      toChooseWarehouse: false,
    })
  }

  chooseProduct = () => {
    this.setState({
      toChooseProduct: true,
    })
  }

  chooseWarehouse = () => {
    this.setState({
      toChooseWarehouse: true,
    })
  }

  warehouseSearchResult = (warehouse: warehouse) => {
    this.setState({ toChooseWarehouse: false, warehouse: warehouse })
  }

  productSearchResult = (product: product) => {
    this.setState({ toChooseProduct: false, product: product })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseProduct) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Product History</button>
          <br />
          <br />
          <ProductSearch returnResult={this.productSearchResult} />
        </div>
      )
    }

    if (this.state.toChooseWarehouse) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Product History</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseSearchResult} />
        </div>
      )
    }

    if (!this.state.warehouse) {
      return (
        <div>
          To begin, choose a warehouse: <button onClick={this.chooseWarehouse}>Choose Warehouse</button>
        </div>
      )
    }

    if (!this.state.product) {
      return (
        <div>
          Warehouse: <b>{this.state.warehouse.name}</b>
          &nbsp;<button onClick={this.chooseWarehouse}>Edit</button>
          <br />
          <br />
          <div>
            Next, choose a product: <button onClick={this.chooseProduct}>Choose Product</button>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
          <div>
            Warehouse: <b>{this.state.warehouse.name}</b>
            &nbsp;<button onClick={this.chooseWarehouse}>Edit</button>
            <br />
            <br />
            Product:{" "}
            <b>
              {this.state.product.product_line} - {this.state.product.product_num}
            </b>{" "}
            {this.state.product.description}
            &nbsp;<button onClick={this.chooseProduct}>Edit</button>
          </div>
        </div>
        <br />
        <Search
          title={"Product History"}
          hideHeaders={true}
          newTabResult={true}
          defaultedFields={{
            warehouse_id: this.state.warehouse.id,
            product_id: this.state.product.id,
          }}
          queries={[
            { name: "Warehouse", value: "", databaseSearch: "warehouse_id" },
            { name: "Product", value: "", databaseSearch: "product_id" },
          ]}
          resultColumns={[
            { name: "Date", databaseField: "entry_date", type: "date" },
            { name: "Type", databaseField: "history_type" },
            { name: "NEWTABLINK", databaseField: "new_tab_link", newTabLink: true, hidden: true },
            { name: "Reference #", databaseField: "reference_id" },
            { name: "NEW_TAB_ID", databaseField: "new_tab_id", newTabId: true, hidden: true },
            { name: "Qty Difference", databaseField: "qty_difference" },
            { name: "Per", databaseField: "price_per" },
            { name: "Transacted Cost", databaseField: "cost_difference", type: "currency" },
            { name: "On Hand", databaseField: "qty_onhand" },
            { name: "Avg. Cost", databaseField: "avg_cost", type: "currency" },
          ]}
          apiEndpoint={"producthistory/search"}
        />
      </div>
    )
  }
}

export default ProdHistSearch
