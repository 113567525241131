import React, { Component } from "react"
import "../../stylesheets/SearchResults.css"

import { toCurrency, toReadableDate, openNewTab } from "../helpers"

type props = {
  returnResult: (result: any) => void
  newTabResult: boolean
  title: string
  resultColumns: Array<{
    name: string
    type?: string
    newTabId?: boolean
    newTabLink?: boolean
    hidden?: boolean
    databaseField: string
  }>
  results: Array<{
    [index: string]: any
  }>
}

type state = {
  clickedResult: {
    [index: string]: any
  } | null
}

class SearchResults extends Component<props, state> {
  state: state = {
    clickedResult: null,
  }

  handleRowClick = (result: myAny) => {
    this.setState(
      {
        clickedResult: result,
      },
      () => {
        if (this.props.newTabResult) {
          const newTabLinkValue = result[(this.props.resultColumns.find((x) => x.newTabLink) || { databaseField: "" }).databaseField]
          const newTabIdValue = result[(this.props.resultColumns.find((x) => x.newTabId) || { databaseField: "" }).databaseField]
          if (newTabLinkValue) {
            openNewTab(newTabLinkValue, newTabIdValue)
          }
        } else {
          this.props.returnResult && this.props.returnResult(this.state.clickedResult)
        }
      }
    )
  }

  render() {
    return (
      <div>
        <p>{this.props.title} Search Results</p>
        <table className="searchResults">
          <thead>
            <tr>
              {this.props.resultColumns.map((column) => {
                if (column.hidden) {
                  return null
                }
                return <th key={column.name}>{column.name}</th>
              })}
            </tr>
          </thead>
          {this.props.results.map((result) => {
            return (
              <tbody key={result.id}>
                <tr className="row" onClick={() => this.handleRowClick(result)}>
                  {this.props.resultColumns.map((column) => {
                    let value = result[column.databaseField]

                    if (column.type === "date") {
                      value = toReadableDate(value)
                    } else if (column.type === "currency") {
                      value = toCurrency(value)
                    }

                    if (column.hidden) {
                      return null
                    }
                    return <td key={column.name}>{value}</td>
                  })}
                </tr>
              </tbody>
            )
          })}
        </table>
      </div>
    )
  }
}

export default SearchResults
