import React, { Component } from "react"

type response = boolean | string | null

export type props = {
  title: string
  message: string
  type: "alert" | "confirm" | "options"
  options?: Array<string>
  allowHideDialog?: () => void
  handleResponse: (response: response) => void
} | null

type state = {
  type: string
  message: string
  options: Array<string>
  response: response
}

const initialState: state = {
  type: "",
  message: "",
  options: [],
  response: null,
}

export class Dialog extends Component<props, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEscape, false)

    this.setState({
      type: this.props.type,
      message: this.props.message,
      options: this.props.options || [],
    })
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEscape, false)
  }

  handleEscape = () => {
    this.props.allowHideDialog && this.props.allowHideDialog()
  }

  stopPropagation = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // prevents the overlay from closing when the body of the overlay is clicked
    e.stopPropagation()
  }

  handleOption = (e: myAny) => {
    this.setState({
      response: e.target.name,
    })
  }

  render() {
    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      color: "#d03a29",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    const overlay: React.CSSProperties = {
      position: "fixed" /* Sit on top of the page content */,
      display: "block" /* Hidden by default */,
      width: "100%" /* Full width (cover the whole page) */,
      height: "100%" /* Full height (cover the whole page) */,
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.5)" /* Black background with opacity */,
      zIndex: 200 /* Specify a stack order in case you're using a different order for other elements */,
    }

    const overlayBody: React.CSSProperties = {
      position: "absolute",
      top: "30%",
      left: "30%",
      width: "40%",
      height: "40%",
      zIndex: 201,
      boxShadow: "0px 0px 30px #000000",
      backgroundColor: "white",
      cursor: "default",
      padding: "20px",
      overflow: "auto",
    }

    return (
      <div style={overlay} onClick={this.handleEscape}>
        <div style={overlayBody} onClick={(e) => this.stopPropagation(e)}>
          <div style={headerStyle}>{this.props.title}</div>

          <br />

          {this.props.message}

          <br />
          <br />

          {this.props.type === "alert" ? <button onClick={() => this.props.handleResponse(true)}>Okay</button> : null}

          {this.props.type === "confirm" ? (
            <div style={{ textAlign: "center" }}>
              <button style={{ marginRight: "10%" }} onClick={() => this.props.handleResponse(true)}>
                Yes
              </button>
              &nbsp;
              <button onClick={() => this.props.handleResponse(false)}>No</button>
            </div>
          ) : null}

          {this.props.type === "options" ? (
            <div>
              {this.props.options
                ? this.props.options.map((option: string) => (
                    <div key={option}>
                      <input type="radio" name={option} id={option} onClick={this.handleOption} checked={this.state.response === option} />
                      <label htmlFor={option}>{option}</label>
                      <br />
                    </div>
                  ))
                : null}
              <br />
              <button onClick={() => this.props.handleResponse(this.state.response)} disabled={!this.state.response}>
                Continue
              </button>
              &nbsp;
              <button onClick={() => this.props.handleResponse(false)}>Cancel</button>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default Dialog
