import React, { Component } from "react"

// import child components / helpers
import LoadingSpinner from "./LoadingSpinner"
import { fireAxios } from "./helpers"

type props = {}

type state = {
  loading: boolean
  value: number
}

const initialState: state = {
  loading: false,
  value: 0,
}

class Testing extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = initialState
    this.props.setAppTitle && this.props.setAppTitle("Testing")
  }

  test = (error: boolean) => {
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "qa",
          data: {
            forceError: error,
          },
        },
        (_response: myAny) => {
          this.setState({ loading: false })
        }
      )
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    return (
      <div>
        <button onClick={() => this.test(false)}>QA Result</button>
        <button onClick={() => this.test(true)}>QA Error</button>
      </div>
    )
  }
}
export default Testing
