import React, { Component } from "react"

// import child child components / helpers
import LoadingSpinner from "../LoadingSpinner"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import ProductSearch from "../products/ProductSearch"
import NewTabText from "../newTabText/NewTabText"
import ControlledInput from "../edit/ControlledInput"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import { fireAxios, toReadableDate } from "../helpers"

// import required libraries
import shortid from "shortid"
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"
import arrayMove from "array-move"

type props = {
  id?: number
}

type product = {
  id: number
  unique_id: string
  quantity: number
  qty_shipped: number
  qty_transfered: number
  cost: number
  product_line: string
  product_num: string
  description: string
  comment: string
  quantities: Array<{
    warehouse_id: number
    avg_cost: number
    qty_available: number
  }>
  [index: string]: any
}

type warehouse = {
  id: number
  name: string
}

type state = {
  loading: boolean
  transferId?: number
  toChooseWarehouse: boolean
  warehouseTarget?: string
  fromWarehouse?: warehouse
  toWarehouse?: warehouse
  products: Array<product>
  toChooseProduct: boolean
  comment: string
  date_created?: string
  submitted: boolean
  canceled: boolean
  closed: boolean
  dialog: dialogProps
  inputValidities: Array<{
    unique_id: string
    valid: boolean
  }>
}

const initialState: state = {
  loading: false,
  toChooseWarehouse: false,
  warehouseTarget: undefined,
  fromWarehouse: undefined,
  toWarehouse: undefined,
  products: [],
  toChooseProduct: false,
  comment: "",
  date_created: undefined,
  submitted: false,
  transferId: undefined,
  canceled: false,
  closed: false,
  dialog: null,
  inputValidities: [],
}

const DragHandle = SortableHandle(() => {
  const svgStyle = {
    cursor: "s-resize",
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" style={svgStyle}>
      <path d="M2 13.5h14V12H2v1.5zm0-4h14V8H2v1.5zM2 4v1.5h14V4H2z" />
    </svg>
  )
})

const SortableItem = SortableElement(
  ({
    value,
    transferId,
    fromWarehouse,
    toWarehouse,
    deleteProduct,
    handleProductInput,
    updateValidity,
  }: {
    value: product
    transferId?: number
    fromWarehouse: warehouse
    toWarehouse: warehouse
    deleteProduct: (prod: product) => void
    handleProductInput: (name: string, value: string | number, prod: product) => void
    updateValidity: (unique_id: string, isValid: boolean, callback: () => void) => void
  }) => {
    let fromQtyIndex = value.quantities.findIndex((qty) => qty.warehouse_id === fromWarehouse.id)
    let fromQty = fromQtyIndex > -1 ? value.quantities[fromQtyIndex].qty_available : 0
    let toQtyIndex = value.quantities.findIndex((qty) => qty.warehouse_id === toWarehouse.id)
    let toQty = toQtyIndex > -1 ? value.quantities[toQtyIndex].qty_available : 0

    const errorStyle = { background: "#ffc5c5" }

    return (
      <tr className="row" style={{ cursor: "default" }} key={value.unique_id}>
        <td>{transferId ? null : <DragHandle />}</td>
        <td>
          <b>
            <NewTabText id={value.id} link="product" text={value.product_line + "-" + value.product_num} />
          </b>{" "}
          {value.description}
        </td>
        <td style={fromQty - (value.quantity ? value.quantity : 0) < 0 ? errorStyle : undefined}>{fromQty}</td>
        <td>{toQty}</td>
        <td>
          <ControlledInput
            placeholder="Requested"
            name="quantity"
            value={(value.quantity === 0 ? "0" : value.quantity) || ""}
            restriction="non-zero-unsigned-int"
            handleChange={(n, v) => handleProductInput(n, v, value)}
            disabled={!!transferId}
            updateValidity={updateValidity}
          />
        </td>
        {transferId ? (
          <td>
            <ControlledInput
              placeholder="Shipped"
              name="qty_shipped"
              value={(value.qty_shipped === 0 ? "0" : value.qty_shipped) || ""}
              restriction="unsigned-int"
              handleChange={(n, v) => handleProductInput(n, v, value)}
              updateValidity={updateValidity}
            />
          </td>
        ) : null}
        {transferId ? <td style={value.qty_transfered > value.qty_shipped ? errorStyle : undefined}>{value.qty_transfered}</td> : null}
        <td>
          <textarea
            placeholder="Comment"
            name={"comment"}
            defaultValue={value.comment}
            disabled={!!transferId}
            onChange={(e) => handleProductInput("comment", e.currentTarget.value, value)}
          />
        </td>
        <td>{transferId ? null : <button onClick={() => deleteProduct(value)}>Delete</button>}</td>
      </tr>
    )
  }
)

const SortableList = SortableContainer(
  ({
    items,
    transferId,
    fromWarehouse,
    toWarehouse,
    deleteProduct,
    handleProductInput,
    updateValidity,
  }: {
    items: Array<product>
    transferId?: number
    fromWarehouse: warehouse
    toWarehouse: warehouse
    deleteProduct: (prod: product) => void
    handleProductInput: (name: string, value: string | number, prod: product) => void
    updateValidity: (unique_id: string, isValid: boolean, callback: () => void) => void
  }) => {
    return (
      <table className="searchResults">
        <tbody>
          <tr>
            <th></th>
            <th>Product</th>
            <th>from Qty</th>
            <th>to Qty</th>
            <th>Quantity Requested</th>
            {transferId ? <th>Quantity Shipped</th> : null}
            {transferId ? <th>Quantity Received</th> : null}
            <th>Comment</th>
            <th></th>
          </tr>
          {items.map((value, index) => {
            // generate a unique id for each detail so React can manage the state properly
            if (!value.hasOwnProperty("unique_id")) {
              value.unique_id = shortid.generate()
            }

            return (
              <SortableItem
                key={value.unique_id}
                index={index}
                value={value}
                transferId={transferId}
                fromWarehouse={fromWarehouse}
                toWarehouse={toWarehouse}
                deleteProduct={deleteProduct}
                updateValidity={updateValidity}
                handleProductInput={handleProductInput}
              />
            )
          })}
        </tbody>
      </table>
    )
  }
)

class Transfer extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = initialState
    if (this.props.setAppTitle) {
      this.props.setAppTitle("Create Transfer")
    }
  }

  componentDidMount() {
    if (this.props.id) {
      this.getAllTransferDetails(this.props.id)
    }
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  getAllTransferDetails = (transferId: number) => {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "transfers/" + transferId }, (response) => {
        this.setState(
          {
            loading: false,
            ...response.data,
          },
          () => {
            this.setState(
              {
                transferId: transferId,
              },
              () => {
                this.props.setAppTitle && this.props.setAppTitle("Transfer #" + this.state.transferId)
              }
            )
          }
        )
      })
    })
  }

  /*
  ███████ ███████  █████  ██████   ██████ ██   ██
  ██      ██      ██   ██ ██   ██ ██      ██   ██
  ███████ █████   ███████ ██████  ██      ███████
       ██ ██      ██   ██ ██   ██ ██      ██   ██
  ███████ ███████ ██   ██ ██   ██  ██████ ██   ██
  */

  chooseProduct = () => {
    this.setState({
      toChooseProduct: true,
    })
  }

  productSearchResult = (prod: product) => {
    if (this.state.products.find((x) => x.id === prod.id)) {
      this.showDialog({
        type: "alert",
        title: "Error!",
        message: "You've already added this product.",
        handleResponse: this.hideDialog,
      })
      return
    }

    this.setState(
      {
        toChooseProduct: false,
      },
      () => {
        let newArray: Array<product> = JSON.parse(JSON.stringify(this.state.products))

        this.setState({ loading: true }, () => {
          fireAxios({ method: "post", url: "products/transferDetails/", data: { product_id: prod.id } }, (response) => {
            if (response.data) {
              prod.quantities = response.data
            }
            newArray.push(prod)
            this.setState({
              loading: false,
              products: newArray,
            })
          })
        })
      }
    )
  }

  chooseWarehouse = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({
      toChooseWarehouse: true,
      warehouseTarget: e.currentTarget.name,
    })
  }

  warehouseSearchResult = (whse: warehouse) => {
    if (this.state.warehouseTarget === "from") {
      if (this.state.toWarehouse && this.state.toWarehouse.id === whse.id) {
        this.showDialog({
          type: "alert",
          title: "Error!",
          message: "You're already shipping to this warehouse.",
          handleResponse: this.hideDialog,
        })
        return
      }
      this.setState({
        fromWarehouse: whse,
        toChooseWarehouse: false,
      })
    } else if (this.state.warehouseTarget === "to") {
      if (this.state.fromWarehouse && this.state.fromWarehouse.id === whse.id) {
        this.showDialog({
          type: "alert",
          title: "Error!",
          message: "You're already shipping from this warehouse.",
          handleResponse: this.hideDialog,
        })
        return
      }
      this.setState({
        toWarehouse: whse,
        toChooseWarehouse: false,
      })
    } else {
      console.log("no warehouse target")
    }
  }

  backFromSearch = () => {
    this.setState({
      toChooseWarehouse: false,
      toChooseProduct: false,
    })
  }

  /*
  ██ ███    ██ ██████  ██    ██ ████████ ███████
  ██ ████   ██ ██   ██ ██    ██    ██    ██
  ██ ██ ██  ██ ██████  ██    ██    ██    ███████
  ██ ██  ██ ██ ██      ██    ██    ██         ██
  ██ ██   ████ ██       ██████     ██    ███████
  */

  handleProductInputChange = (name: string, value: string | number, prod: product) => {
    let newArray: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    const prodIndex = newArray.findIndex((x) => x.unique_id === prod.unique_id)

    newArray[prodIndex][name] = value

    this.setState({
      products: newArray,
    })
  }

  updateValidity = (unique_id: string, isValid: boolean, callback: () => void) => {
    this.setState((prevState) => {
      if (prevState.inputValidities.find((x) => x.unique_id === unique_id)) {
        return {
          ...prevState,
          inputValidities: prevState.inputValidities.map((x) => {
            if (x.unique_id === unique_id) {
              x.valid = isValid
            }
            return x
          }),
        }
      } else {
        prevState.inputValidities.push({
          unique_id: unique_id,
          valid: isValid,
        })
        return prevState
      }
    }, callback)
  }

  deleteProduct = (prod: product) => {
    const deleteIndex = this.state.products.findIndex((x) => x.unique_id === prod.unique_id)
    let newArray = JSON.parse(JSON.stringify(this.state.products))
    newArray.splice(deleteIndex, 1)

    this.setState({
      products: newArray,
    })
  }

  handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      comment: e.target.value,
    })
  }

  /*
  ███████ ██    ██ ██████  ███    ███ ██ ████████
  ██      ██    ██ ██   ██ ████  ████ ██    ██
  ███████ ██    ██ ██████  ██ ████ ██ ██    ██
       ██ ██    ██ ██   ██ ██  ██  ██ ██    ██
  ███████  ██████  ██████  ██      ██ ██    ██
  */

  submitTransfer = () => {
    if (
      this.state.products.some((prod) => {
        let fromWhse = prod.quantities.find((x) => x.warehouse_id === (this.state.fromWarehouse ? this.state.fromWarehouse.id : null))
        let fromQty = fromWhse && fromWhse.qty_available ? fromWhse.qty_available : 0
        let qtyToTransfer = prod.quantity ? prod.quantity : 0
        if (fromQty < qtyToTransfer) {
          this.showDialog({
            type: "alert",
            title: "Insufficient Quantity...",
            message:
              (this.state.fromWarehouse ? this.state.fromWarehouse.name : "") +
              " doesn't have enough quantity available to fufill your request for product: " +
              prod.product_line +
              " - " +
              prod.product_num,
            handleResponse: this.hideDialog,
          })
          return true
        } else if (qtyToTransfer === 0) {
          this.showDialog({
            type: "alert",
            title: "No quantity requested...",
            message: prod.product_line + " - " + prod.product_num + ": doesn't have any quantity requested to transfer.",
            handleResponse: this.hideDialog,
          })
          return true
        }
        return false
      })
    ) {
      return
    }

    if (this.state.inputValidities.some((x) => !x.valid)) {
      this.showDialog({
        type: "alert",
        title: "Input Error!",
        message: "One or more inputs is invalid. Fix before submitting.",
        handleResponse: this.hideDialog,
      })
      return
    }

    let dataToSubmit = {
      from_warehouse_id: this.state.fromWarehouse ? this.state.fromWarehouse.id : null,
      to_warehouse_id: this.state.toWarehouse ? this.state.toWarehouse.id : null,
      products: this.state.products.map((x) => {
        return { ...x, product_id: x.id }
      }),
      comment: this.state.comment,
    }

    dataToSubmit = JSON.parse(JSON.stringify(dataToSubmit)) // make a copy so we don't affect state
    dataToSubmit.products.forEach((prod) => {
      // carry over latest avg cost from origin warehouse
      const foundProd = prod.quantities.find((qty) => qty.warehouse_id === dataToSubmit.from_warehouse_id)
      prod.cost = foundProd ? foundProd.avg_cost : 0
    })

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "transfers/create", data: dataToSubmit }, (response) => {
        this.setState(
          {
            loading: false,
            submitted: true,
            transferId: response.data,
          },
          () => {
            this.props.setAppTitle && this.props.setAppTitle("Transfer #" + this.state.transferId)
          }
        )
      })
    })
  }

  updateTransfer = () => {
    let dataToSubmit = {
      transfer_id: this.state.transferId,
      products: this.state.products,
    }

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "transfers/update", data: dataToSubmit }, () => {
        this.setState({
          loading: false,
          submitted: true,
        })
      })
    })
  }

  cancelTransfer = () => {
    this.showDialog({
      type: "confirm",
      title: "Are you sure?",
      message: `Are you sure you want to close this transfer? Cannot be undone. Outstanding stock will remain in origin warehouse. Attached orders will be removed.`,
      handleResponse: (response) => {
        if (response) {
          let dataToSubmit = {
            transfer_id: this.state.transferId,
          }

          this.setState({ loading: true }, () => {
            fireAxios({ method: "post", url: "transfers/cancel", data: dataToSubmit }, () => {
              this.setState({
                loading: false,
                submitted: true,
              })
            })
          })
        }
        this.hideDialog()
      },
    })
  }

  /*
  ███████  ██████  ██████  ████████  █████  ██████  ██      ███████
  ██      ██    ██ ██   ██    ██    ██   ██ ██   ██ ██      ██
  ███████ ██    ██ ██████     ██    ███████ ██████  ██      █████
       ██ ██    ██ ██   ██    ██    ██   ██ ██   ██ ██      ██
  ███████  ██████  ██   ██    ██    ██   ██ ██████  ███████ ███████
  */

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.setState({
      products: arrayMove(this.state.products, oldIndex, newIndex),
    })
  }

  /*
  ██████  ███████ ███    ██ ██████  ███████ ██████
  ██   ██ ██      ████   ██ ██   ██ ██      ██   ██
  ██████  █████   ██ ██  ██ ██   ██ █████   ██████
  ██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
  ██   ██ ███████ ██   ████ ██████  ███████ ██   ██
  */

  render() {
    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseWarehouse) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create Transfer</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseSearchResult} />
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    if (this.state.toChooseProduct) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Create Transfer</button>
          <br />
          <br />
          <ProductSearch returnResult={this.productSearchResult} />
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    if (this.state.submitted) {
      return (
        <div>
          Transfer submitted successfully. Transfer #: <b>{this.state.transferId}</b>
        </div>
      )
    }

    if (this.state.fromWarehouse && this.state.toWarehouse) {
      return (
        <div>
          {this.state.closed ? (
            <div>
              This transfer is <span style={{ color: "green" }}>closed</span>
              <br />
              <br />
            </div>
          ) : null}
          {this.state.canceled ? (
            <div>
              This transfer was <span style={{ color: "red" }}>canceled</span>
              <br />
              <br />
            </div>
          ) : null}
          <fieldset
            disabled={this.state.closed || this.state.canceled}
            style={{ border: this.state.closed || this.state.canceled ? "1px solid black" : "none" }}
          >
            {this.state.date_created ? <div style={headerStyle}>Date Created: {toReadableDate(this.state.date_created)}</div> : null}
            <div style={headerStyle}>From warehouse:</div>
            <div>
              {this.state.fromWarehouse.name} &nbsp;
              <button name="from" onClick={(e) => this.chooseWarehouse(e)} disabled={this.state.products.length > 0}>
                Edit
              </button>
            </div>
            <div style={headerStyle}>To warehouse:</div>
            <div>
              {this.state.toWarehouse.name} &nbsp;
              <button name="to" onClick={(e) => this.chooseWarehouse(e)} disabled={this.state.products.length > 0}>
                Edit
              </button>
            </div>
            <div style={headerStyle}>Products to transfer:</div>
            <SortableList
              items={this.state.products}
              onSortEnd={this.onSortEnd}
              transferId={this.state.transferId}
              fromWarehouse={this.state.fromWarehouse}
              toWarehouse={this.state.toWarehouse}
              deleteProduct={this.deleteProduct}
              updateValidity={this.updateValidity}
              handleProductInput={this.handleProductInputChange}
              useDragHandle={true}
            />
            <br />
            {this.state.transferId ? null : <button onClick={this.chooseProduct}>Add Product</button>}
            <br />
            <br />
            Overall comment:
            <br />
            <textarea onChange={this.handleComment} value={this.state.comment || ""} disabled={!!this.state.transferId} />
            <br />
            <br />
            {this.state.transferId ? <button onClick={this.updateTransfer}>Update</button> : <button onClick={this.submitTransfer}>Submit</button>}
            {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
            <br />
            <br />
            {this.state.transferId ? (
              <div>
                <div style={headerStyle}>Cancel Transfer</div>
                <button onClick={this.cancelTransfer}>Cancel</button>
              </div>
            ) : null}
            {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
          </fieldset>
        </div>
      )
    }

    if (this.state.fromWarehouse && !this.state.toWarehouse) {
      return (
        <div>
          <div style={headerStyle}>From warehouse:</div>
          <div>
            {this.state.fromWarehouse.name} &nbsp;
            <button name="from" onClick={(e) => this.chooseWarehouse(e)}>
              Edit
            </button>
          </div>
          <div>
            <div style={headerStyle}>To warehouse:</div>
            Next, choose the <b>to</b> warehouse: &nbsp;
            <button name="to" onClick={(e) => this.chooseWarehouse(e)}>
              Choose Warehouse
            </button>
          </div>
        </div>
      )
    }

    return (
      <div>
        To begin, choose the <b>from</b> warehouse: &nbsp;
        <button name="from" onClick={(e) => this.chooseWarehouse(e)}>
          Choose Warehouse
        </button>
      </div>
    )
  }
}

export default Transfer
