import React, { Component } from "react"

// import child components / helpers
import LoadingSpinner from "../LoadingSpinner"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import ProductSearch from "./ProductSearch"
import ContextMenu from "../edit/ContextMenu"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import { fireAxios } from "../helpers"
import shortid from "shortid"

type props = {}

type product = {
  id: number
  new_location_1: string
  new_location_2: string
  submitted?: boolean
  [index: string]: any
}

type state = {
  loading: boolean
  toChooseWarehouse: boolean
  warehouse?: {
    id: number
    name: string
  }
  toChooseProduct: boolean
  products: Array<product>
  dialog: dialogProps
}

const initialState: state = {
  loading: false,
  toChooseWarehouse: false,
  warehouse: undefined,
  toChooseProduct: false,
  products: [],
  dialog: null,
}

const title = "Bin Changes"

class QuantityAdj extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = initialState
    this.props.setAppTitle && this.props.setAppTitle(title)
  }

  chooseWarehouse = () => {
    this.setState({
      toChooseWarehouse: true,
    })
  }

  warehouseSearchResult = (warehouse: any) => {
    this.setState({
      warehouse: warehouse,
      toChooseWarehouse: false,
    })
  }

  chooseProduct = () => {
    this.setState({
      toChooseProduct: true,
    })
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  productSearchResult = (product: { [index: string]: any }) => {
    let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let foundProd = newProds.find((x) => x.id === product.id)

    if (foundProd) {
      this.showDialog({
        type: "alert",
        title: "Can not add product",
        message: "You've already added this product.",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (!this.state.warehouse) return

    // get additional information needed for the product
    this.setState({ loading: true }, () => {
      fireAxios(
        { method: "post", url: "binlocations/prodwhse/", data: { product_id: product.id, warehouse_id: this.state.warehouse!.id } },
        (response) => {
          product.unique_id = shortid.generate()
          product.location_1 = response.data ? response.data.location_1 : ""
          product.location_2 = response.data ? response.data.location_2 : ""
          product.new_location_1 = response.data ? response.data.location_1 : ""
          product.new_location_2 = response.data ? response.data.location_2 : ""

          newProds.push(product as product)

          this.setState({
            loading: false,
            products: newProds,
            toChooseProduct: false,
          })
        }
      )
    })
  }

  deleteProduct = (product: product) => {
    const deleteIndex = this.state.products.findIndex((x) => x.unique_id === product.unique_id)
    let newProds = JSON.parse(JSON.stringify(this.state.products))
    newProds.splice(deleteIndex, 1)

    this.setState({
      products: newProds,
    })
  }

  handleNewLocation = (e: React.ChangeEvent<HTMLInputElement>, product: product) => {
    let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let prodToChange = newProds.find((x) => x.id === product.id)

    if (!prodToChange) return

    prodToChange[e.target.name] = e.target.value

    this.setState({
      products: newProds,
    })
  }

  backFromSearch = () => {
    this.setState({
      toChooseProduct: false,
      toChooseWarehouse: false,
    })
  }

  reset = () => {
    this.setState(initialState)
  }

  submit = () => {
    let dataToSubmit: {
      warehouse_id?: number
      products: Array<product>
    } = {
      warehouse_id: this.state.warehouse ? this.state.warehouse.id : undefined,
      products: [],
    }

    dataToSubmit.products = this.state.products.map((prod) => {
      return {
        id: prod.id,
        new_location_1: prod.new_location_1,
        new_location_2: prod.new_location_2,
      }
    })

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "binlocations/update/multiple", data: dataToSubmit }, () => {
        let newProds: Array<product> = JSON.parse(JSON.stringify(this.state.products))
        newProds.forEach((prod) => (prod.submitted = true))
        this.setState({
          products: newProds,
          loading: false,
        })
      })
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseProduct) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <ProductSearch returnResult={this.productSearchResult} />
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    if (this.state.toChooseWarehouse) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <WarehouseSearch returnResult={this.warehouseSearchResult} />
        </div>
      )
    }

    if (!this.state.warehouse) {
      return (
        <div>
          To begin, choose a warehouse: <button onClick={this.chooseWarehouse}>Choose Warehouse</button>
        </div>
      )
    }

    return (
      <div>
        Warehouse: <b>{this.state.warehouse.name}</b>
        &nbsp;
        <button disabled={this.state.products.length > 0} onClick={this.chooseWarehouse}>
          Edit
        </button>
        <br />
        <br />
        <table className="searchResults">
          <thead>
            <tr>
              <th>Product</th>
              <th>Bin 1</th>
              <th>Bin 2</th>
              <th>New Bin 1</th>
              <th>New Bin 2</th>
            </tr>
          </thead>
          {this.state.products.map((prod) => {
            return (
              <ContextMenu
                key={prod.id}
                className="row"
                style={{ cursor: "default", background: prod.submitted ? "#87dc9e" : undefined }}
                elementType="tbody"
                menuItems={[{ name: "Delete", onClick: () => this.deleteProduct(prod) }]}
              >
                <tr>
                  <td>
                    <b>
                      {prod.product_line} - {prod.product_num}
                    </b>{" "}
                    {prod.description}
                  </td>
                  <td>{prod.location_1}</td>
                  <td>{prod.location_2}</td>
                  <td>
                    <input
                      name={"new_location_1"}
                      onChange={(e) => this.handleNewLocation(e, prod)}
                      value={prod.new_location_1}
                      disabled={prod.submitted}
                    />
                  </td>
                  <td>
                    <input
                      name={"new_location_2"}
                      onChange={(e) => this.handleNewLocation(e, prod)}
                      value={prod.new_location_2}
                      disabled={prod.submitted}
                    />
                  </td>
                </tr>
              </ContextMenu>
            )
          })}
        </table>
        <br />
        <div>
          <button onClick={this.chooseProduct}>Add Product</button>
        </div>
        <br />
        <br />
        {this.state.products.length > 0 && this.state.products.every((prod) => prod.submitted) ? (
          <div>
            <button onClick={this.reset}>New Adjustments</button>
          </div>
        ) : (
          <div>
            <button onClick={this.submit}>Submit Adjustments</button>
          </div>
        )}
        {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
      </div>
    )
  }
}

export default QuantityAdj
