import React, { Component } from "react"

// import child components / helpers
import Search from "../search/Search"
import ReturnToVendor from "./ReturnToVendor"

class ReturnVendorSearch extends Component<appProps> {
  render() {
    return (
      <Search
        title={"Return to Vendor"}
        closable={true}
        preventClosedUse={true}
        queries={[
          { name: "Return Id", value: "", databaseSearch: "id", restriction: "non-zero-unsigned-int" },
          { name: "Vendor Return Id", value: "", databaseSearch: "vendor_return_id" },
          { name: "Warehouse Name", value: "", databaseSearch: "warehouse_name" },
          { name: "Vendor Name", value: "", databaseSearch: "vendor_name" },
          { name: "Product Number", value: "", databaseSearch: "product_num" },
        ]}
        resultColumns={[
          { name: "Return Id", databaseField: "id" },
          { name: "Vendor Return Id", databaseField: "vendor_return_id" },
          { name: "Warehouse Name", databaseField: "warehouse_name" },
          { name: "Vendor Name", databaseField: "vendor_name" },
          { name: "Product Number", databaseField: "product_num" },
        ]}
        apiEndpoint={"returnToVendor/search"}
        children={<ReturnToVendor />}
        {...this.props}
      />
    )
  }
}

export default ReturnVendorSearch
