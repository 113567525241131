import React, { Component } from "react"

// import child components / helpers
import LoadingSpinner from "../LoadingSpinner"
import EditableTD from "../edit/EditableTD"
import WarehouseSearch from "../warehouses/WarehouseSearch"
import SalesrepSearch from "../salesreps/SalesrepSearch"
import CreateCustomerContact from "./CreateCustomerContact"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import { toReadableDate, fireAxios } from "../helpers"

// import CSS
import "../../stylesheets/Customer.css"

type props = {
  id?: number
}

type state = {
  customerId: number | null
  customer: {
    id: number
    customer_num: string
    company_name: string
    description: string
    address_1: string
    address_2: string
    city: string
    province: string
    postal_code: string
    country: string
    phone_num: string
    fax_num: string
    hold_status: string
    credit_limit_days: number
    credit_limit_amount: number
    discount_code: string
    cod_status: boolean
    birth_date: string
    closed: boolean
    salesrep: {
      first_name: string
      last_name: string
    }
    warehouse: {
      name: string
    }
    contacts: Array<{
      id: number
      name: string
      title: string
      email: string
      phone_num: string
      fax_num: string
      cell_num: string
      send_invoice: boolean
    }>
  }
  addingContact: boolean
  focusedInput: number | string | null
  loading: boolean
  searchChild: React.ReactNode
  searchContext: string
  toSearch: boolean
  dialog: dialogProps
}

class Customer extends Component<props & appProps, state> {
  state: state = {
    customerId: null,
    customer: {
      id: 0,
      customer_num: "",
      company_name: "",
      description: "",
      address_1: "",
      address_2: "",
      city: "",
      province: "",
      postal_code: "",
      country: "",
      phone_num: "",
      fax_num: "",
      hold_status: "",
      credit_limit_days: 0,
      credit_limit_amount: 0,
      discount_code: "",
      cod_status: true,
      birth_date: "",
      closed: true,
      salesrep: {
        first_name: "",
        last_name: "",
      },
      warehouse: {
        name: "",
      },
      contacts: [],
    },
    addingContact: false,
    focusedInput: null,
    loading: false,
    searchChild: null,
    searchContext: "",
    toSearch: false,
    dialog: null,
  }

  componentDidMount() {
    this.setState(
      {
        customerId: this.props.id ? this.props.id : null,
      },
      () => {
        this.getCustomerDetails(this.state.customerId as number)
      }
    )
  }

  getCustomerDetails = (customerId: number) => {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "customers/" + customerId }, (response) => {
        this.setState(
          {
            customer: response.data,
            loading: false,
          },
          () => {
            this.props.setAppTitle && this.props.setAppTitle(this.state.customer.customer_num + " - " + this.state.customer.company_name)
          }
        )
      })
    })
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  addContact = () => {
    this.setState({
      addingContact: true,
    })
  }

  createContactResult = () => {
    this.setState(
      {
        addingContact: false,
      },
      () => {
        this.getCustomerDetails(this.state.customerId as number)
      }
    )
  }

  changeInputFocus = (position: number | string) => {
    this.setState({
      focusedInput: position,
    })
  }

  updateCustomer = (_source: any, context: string, value: any) => {
    let newCustomer = JSON.parse(JSON.stringify(this.state.customer)) // copy to submit to datebase
    // remove data that isn't a column in the `customers` table
    delete newCustomer.contacts
    delete newCustomer.salesrep
    delete newCustomer.warehouse
    // does not need to be updated / can't update
    delete newCustomer.birth_date
    delete newCustomer.customer_num

    if (context) {
      newCustomer[context] = value
    } else {
      return
    }

    console.log(newCustomer)

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "customers/update/", data: newCustomer }, () => {
        this.getCustomerDetails(this.state.customer.id)
      })
    })
  }

  convertCodStatus = () => {
    const isCod = this.state.customer.cod_status
    this.showDialog({
      title: "Are you sure?",
      message: "Are you sure you want to change this to a " + (isCod ? "customer" : "cash") + " account?",
      type: "confirm",
      handleResponse: (response) => {
        if (response) {
          this.updateCustomer("", "cod_status", !isCod)
          this.hideDialog()
        } else {
          this.hideDialog()
        }
      },
    })
  }

  openCloseCustomer = () => {
    const isClosed = this.state.customer.closed

    this.showDialog({
      title: "Are you sure?",
      message: "Are you sure you want to change this to a " + (isClosed ? "customer" : "cash") + " account?",
      type: "confirm",
      handleResponse: (response) => {
        if (response) {
          this.updateCustomer("", "closed", !isClosed)
          this.hideDialog()
        } else {
          this.hideDialog()
        }
      },
    })
  }

  updateContact = (source: any, context: string, value: any) => {
    const dataToSubmit = {
      ...source,
      [context]: value,
    }

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "customers/update/contact", data: dataToSubmit }, () => {
        this.getCustomerDetails(this.state.customer.id)
      })
    })
  }

  deleteContact = (e: any) => {
    const id = parseInt(e.target.name, 10)

    this.showDialog({
      type: "confirm",
      title: "Are you sure?",
      message: "Are you sure you want to delete this contact?",
      handleResponse: (response) => {
        if (response) {
          this.setState({ loading: true }, () => {
            fireAxios({ method: "post", url: "customers/delete/contact", data: { id: id } }, () => {
              this.getCustomerDetails(this.state.customer.id)
            })
          })
        }
        this.hideDialog()
      },
    })
  }

  returnSearchResult = (result: any) => {
    this.setState(
      {
        toSearch: false,
        focusedInput: "",
      },
      () => {
        if (this.props.handleSearchDepth) {
          this.props.handleSearchDepth(0)
        }
        this.updateCustomer(null, this.state.searchContext, result.id)
      }
    )
  }

  toSearch = (searchChild: React.ReactNode, searchContext: string) => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(1)
    }

    this.setState({
      toSearch: true,
      searchChild: searchChild,
      searchContext: searchContext,
    })
  }

  backFromSearch = () => {
    if (this.props.handleSearchDepth) {
      this.props.handleSearchDepth(0)
    }

    this.setState({
      toSearch: false,
    })
  }

  render() {
    if (this.state.toSearch) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {this.state.customer.customer_num + " - " + this.state.customer.company_name}</button>
          <br />
          <br />
          {React.cloneElement(this.state.searchChild as React.ReactElement, { returnResult: this.returnSearchResult })}
        </div>
      )
    }

    return (
      <div>
        {this.state.loading ? (
          <LoadingSpinner />
        ) : (
          <div className="customer">
            <div className="header">
              {this.state.customer.closed ? (
                <span style={{ color: "red" }}>
                  ACCOUNT CLOSED
                  <br />
                  <br />
                </span>
              ) : null}
              {this.state.customer.cod_status ? (
                <span style={{ color: "red" }}>
                  Cash Account
                  <br />
                  <br />
                </span>
              ) : null}
              <h2 className="numberName">
                <b>{this.state.customer.customer_num}</b> - {this.state.customer.company_name}
              </h2>
              <table>
                <tbody>
                  <tr>
                    <td>Description:</td>
                    <EditableTD
                      content={this.state.customer.description ? this.state.customer.description : "No Description"}
                      placeholder="description"
                      source={this.state.customer}
                      context="description"
                      focused={"description" === this.state.focusedInput}
                      focusedID={"description"}
                      changeFocus={this.changeInputFocus}
                      update={this.updateCustomer}
                    />
                  </tr>
                </tbody>
              </table>
            </div>

            <div style={{ display: "flex", justifyContent: "normal", flexWrap: "wrap" }}>
              <div style={{ padding: "12px" }}>
                Address:
                <br />
                <br />
                <table className="detailTable">
                  <tbody>
                    <tr>
                      <th>Line 1</th>
                      <EditableTD
                        content={this.state.customer.address_1}
                        placeholder={"Address Line 1"}
                        source={this.state.customer}
                        context={"address_1"}
                        focused={"Address Line 1" === this.state.focusedInput}
                        focusedID={"Address Line 1"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateCustomer}
                      />
                    </tr>
                    <tr>
                      <th>Line 2</th>
                      <EditableTD
                        content={this.state.customer.address_2}
                        placeholder={"Address Line 2"}
                        source={this.state.customer}
                        context={"address_2"}
                        focused={"Address Line 2" === this.state.focusedInput}
                        focusedID={"Address Line 2"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateCustomer}
                      />
                    </tr>
                    <tr>
                      <th>City</th>
                      <EditableTD
                        content={this.state.customer.city}
                        placeholder={"City"}
                        source={this.state.customer}
                        context={"city"}
                        focused={"City" === this.state.focusedInput}
                        focusedID={"City"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateCustomer}
                      />
                    </tr>
                    <tr>
                      <th>Province</th>
                      <EditableTD
                        content={this.state.customer.province}
                        placeholder={"province"}
                        restriction={"province"}
                        source={this.state.customer}
                        context={"province"}
                        focused={"province" === this.state.focusedInput}
                        focusedID={"province"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateCustomer}
                      />
                    </tr>
                    <tr>
                      <th>Postal Code</th>
                      <EditableTD
                        content={this.state.customer.postal_code}
                        placeholder={"postal_code"}
                        source={this.state.customer}
                        context={"postal_code"}
                        focused={"postal_code" === this.state.focusedInput}
                        focusedID={"postal_code"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateCustomer}
                      />
                    </tr>
                    <tr>
                      <th>Country</th>
                      <EditableTD
                        content={this.state.customer.country}
                        placeholder={"country"}
                        source={this.state.customer}
                        context={"country"}
                        focused={"country" === this.state.focusedInput}
                        focusedID={"country"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateCustomer}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style={{ padding: "12px" }}>
                Contact:
                <br />
                <br />
                <table className="detailTable">
                  <tbody>
                    <tr>
                      <th>Phone</th>
                      <EditableTD
                        content={this.state.customer.phone_num}
                        placeholder={"Phone"}
                        restriction={"phone-number"}
                        source={this.state.customer}
                        context={"phone_num"}
                        focused={"phone_num" === this.state.focusedInput}
                        focusedID={"phone_num"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateCustomer}
                      />
                    </tr>
                    <tr>
                      <th>Fax</th>
                      <EditableTD
                        content={this.state.customer.fax_num}
                        placeholder={"Fax"}
                        restriction={"phone-number"}
                        source={this.state.customer}
                        context={"fax_num"}
                        focused={"fax_num" === this.state.focusedInput}
                        focusedID={"fax_num"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateCustomer}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style={{ padding: "12px" }}>
                Credit:
                <br />
                <br />
                <table className="detailTable">
                  <tbody>
                    <tr>
                      <th>Hold Status</th>
                      <EditableTD
                        content={this.state.customer.hold_status ? 1 : 0}
                        source={this.state.customer}
                        options={[
                          { value: 0, name: "No Hold" },
                          { value: 1, name: "On Hold" },
                        ]}
                        context={"hold_status"}
                        focused={"hold_status" === this.state.focusedInput}
                        focusedID={"hold_status"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateCustomer}
                        inputType={"select"}
                      />
                    </tr>
                    <tr>
                      <th>Credit Limit Days</th>
                      <EditableTD
                        content={this.state.customer.credit_limit_days}
                        placeholder={"Days"}
                        restriction={"unsigned-int"}
                        source={this.state.customer}
                        context={"credit_limit_days"}
                        focused={"credit_limit_days" === this.state.focusedInput}
                        focusedID={"credit_limit_days"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateCustomer}
                      />
                    </tr>
                    <tr>
                      <th>Credit Limit Amount</th>
                      <EditableTD
                        content={this.state.customer.credit_limit_amount}
                        placeholder={"Days"}
                        restriction={"unsigned-int"}
                        source={this.state.customer}
                        context={"credit_limit_amount"}
                        focused={"credit_limit_amount" === this.state.focusedInput}
                        focusedID={"credit_limit_amount"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateCustomer}
                      />
                    </tr>
                    <tr>
                      <th>Discount</th>
                      <EditableTD
                        content={this.state.customer.discount_code}
                        source={this.state.customer}
                        options={[
                          { value: "2%", name: "2%" },
                          { value: "None", name: "None" },
                        ]}
                        context={"discount_code"}
                        focused={"discount_code" === this.state.focusedInput}
                        focusedID={"discount_code"}
                        changeFocus={this.changeInputFocus}
                        update={this.updateCustomer}
                        inputType={"select"}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style={{ padding: "12px" }}>
                Details:
                <br />
                <br />
                <table className="detailTable">
                  <tbody>
                    <tr>
                      <th>Warehouse</th>
                      <EditableTD
                        content={this.state.customer.warehouse.name}
                        source={this.state.customer}
                        context={"warehouse_id"}
                        focused={"warehouse_id" === this.state.focusedInput}
                        focusedID={"warehouse_id"}
                        changeFocus={this.changeInputFocus}
                        inputType={"search"}
                        toSearch={this.toSearch}
                        searchChild={<WarehouseSearch />}
                      />
                    </tr>
                    <tr>
                      <th>Salesrep</th>
                      <EditableTD
                        content={this.state.customer.salesrep.first_name + " " + this.state.customer.salesrep.last_name}
                        source={this.state.customer}
                        context={"salesrep_id"}
                        focused={"salesrep_id" === this.state.focusedInput}
                        focusedID={"salesrep_id"}
                        changeFocus={this.changeInputFocus}
                        inputType={"search"}
                        toSearch={this.toSearch}
                        searchChild={<SalesrepSearch />}
                      />
                    </tr>
                    <tr>
                      <th>Opened On</th>
                      <td>{toReadableDate(this.state.customer.birth_date)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <br />
            <br />

            <h2>Contacts:</h2>

            <div style={{ display: "flex", justifyContent: "normal", flexWrap: "wrap" }}>
              {this.state.customer.contacts.map((contact) => {
                return (
                  <table className="detailTable" style={{ margin: "12px" }} key={contact.id}>
                    <tbody>
                      <tr>
                        <th>Name:</th>
                        <EditableTD
                          content={contact.name}
                          placeholder={"Name"}
                          source={contact}
                          context={"name"}
                          focused={contact.id + "name" === this.state.focusedInput}
                          focusedID={contact.id + "name"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateContact}
                        />
                      </tr>
                      <tr>
                        <th>Title:</th>
                        <EditableTD
                          content={contact.title}
                          placeholder={"Title"}
                          source={contact}
                          context={"title"}
                          focused={contact.id + "title" === this.state.focusedInput}
                          focusedID={contact.id + "title"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateContact}
                        />
                      </tr>
                      <tr>
                        <th>Email:</th>
                        <EditableTD
                          content={contact.email}
                          placeholder={"Email"}
                          source={contact}
                          restriction={"email"}
                          context={"email"}
                          focused={contact.id + "email" === this.state.focusedInput}
                          focusedID={contact.id + "email"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateContact}
                        />
                      </tr>
                      <tr>
                        <th>Phone:</th>
                        <EditableTD
                          content={contact.phone_num}
                          placeholder={"Phone"}
                          source={contact}
                          restriction={"phone-number"}
                          context={"phone_num"}
                          focused={contact.id + "phone_num" === this.state.focusedInput}
                          focusedID={contact.id + "phone_num"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateContact}
                        />
                      </tr>
                      <tr>
                        <th>Fax:</th>
                        <EditableTD
                          content={contact.fax_num}
                          placeholder={"Fax"}
                          source={contact}
                          restriction={"phone-number"}
                          context={"fax_num"}
                          focused={contact.id + "fax_num" === this.state.focusedInput}
                          focusedID={contact.id + "fax_num"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateContact}
                        />
                      </tr>
                      <tr>
                        <th>Cell:</th>
                        <EditableTD
                          content={contact.cell_num}
                          placeholder={"Cell"}
                          source={contact}
                          restriction={"phone-number"}
                          context={"cell_num"}
                          focused={contact.id + "cell_num" === this.state.focusedInput}
                          focusedID={contact.id + "cell_num"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateContact}
                        />
                      </tr>
                      <tr>
                        <th>Send Invoice:</th>
                        <EditableTD
                          content={contact.send_invoice ? 1 : 0}
                          source={contact}
                          options={[
                            { value: 0, name: "No" },
                            { value: 1, name: "Yes" },
                          ]}
                          context={"send_invoice"}
                          focused={contact.id + "send_invoice" === this.state.focusedInput}
                          focusedID={contact.id + "send_invoice"}
                          changeFocus={this.changeInputFocus}
                          update={this.updateContact}
                          inputType={"select"}
                        />
                      </tr>
                      <tr>
                        <th style={{ border: "none" }}></th>
                        <td style={{ border: "none", textAlign: "right" }}>
                          <button name={contact.id.toString()} onClick={this.deleteContact}>
                            Delete
                          </button>
                          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )
              })}
            </div>

            <br />
            <br />

            <div>
              {this.state.addingContact ? (
                <div>
                  <span style={{ color: "green" }}>Add New Customer Contact</span>
                  <br />
                  <br />
                  <div style={{ border: "1px solid black", padding: "25px", display: "inline-block" }}>
                    <CreateCustomerContact parentId={{ customer_id: this.state.customerId as number }} returnResult={this.createContactResult} />
                  </div>
                </div>
              ) : (
                <button onClick={this.addContact}>Add Contact</button>
              )}
            </div>

            <br />

            <h2>Other:</h2>

            <div>
              This is a {this.state.customer.cod_status ? "Cash" : "Customer"} Account. &nbsp;{" "}
              <button onClick={this.convertCodStatus}>Convert to {this.state.customer.cod_status ? "Customer" : "Cash"} account</button>
              {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
            </div>

            <br />

            <div>
              This is account is {this.state.customer.closed ? "closed" : "open"}. &nbsp;{" "}
              <button onClick={this.openCloseCustomer}>{this.state.customer.closed ? "Open" : "Close"} account</button>
              {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Customer
