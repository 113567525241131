import React, { Component } from "react"

// import child components / helpers
import Search from "../search/Search"
import Product from "./Product"
import LoadingSpinner from "../LoadingSpinner"
import { fireAxios, restrictions } from "../helpers"

type state = {
  loading: boolean
  queries: queries
}

type queries = Array<{
  value: string
  name: string
  databaseSearch: string
  type?: string
  values?: Array<{ key: string; value: string }>
  restriction?: restrictions
}>

const initialState = {
  loading: false,
  queries: [],
}

class ProductSearch extends Component<appProps, state> {
  constructor(props: appProps) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "warehouses" }, (response) => {
        let queries: queries = [
          { name: "Line", value: "", databaseSearch: "line", restriction: "product-line" },
          { name: "Number", value: "", databaseSearch: "number" },
          { name: "Description", value: "", databaseSearch: "description" },
          { type: "select", name: "Available", value: "", values: [], databaseSearch: "warehouse_id" },
          { type: "checkbox", name: "Onhand", value: "", databaseSearch: "order_by_onhand" },
        ]

        let queryToUpdate = queries.find((x) => x.name === "Available")

        if (queryToUpdate) {
          queryToUpdate.value = "No Selection"
          queryToUpdate.values = [...queryToUpdate.values, ...[{ key: "Availables", value: "No Selection" }]]
          queryToUpdate.values = queryToUpdate.values.concat(
            response.data.reduce((acc: myAny, curr: myAny) => acc.concat([{ key: curr.name, value: curr.id }]), [])
          )
        }

        this.setState({ loading: false, queries: queries })
      })
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    return (
      <Search
        title={"Product"}
        closable={true}
        preventClosedUse={true}
        queries={this.state.queries}
        resultColumns={[
          { name: "Line", databaseField: "product_line" },
          { name: "Number", databaseField: "product_num" },
          { name: "Description", databaseField: "description" },
          { optional: true, name: "Onhand", databaseField: "qty_onhand" },
          { optional: true, name: "Available", databaseField: "qty_available" },
        ]}
        apiEndpoint={"products/search"}
        children={<Product />}
        {...this.props}
      />
    )
  }
}

export default ProductSearch
