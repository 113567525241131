import React, { Component } from "react"

// import child components / helpers
import Search from "../../search/Search"

class OTBOrderSearch extends Component<appProps> {
  render() {
    return (
      <Search
        title={"OTB Order"}
        queries={[
          { name: "Product Line", value: "", databaseSearch: "product_line", restriction: "product-line" },
          { name: "Product Number", value: "", databaseSearch: "product_num" },
          { name: "Warehouse Name", value: "", databaseSearch: "warehouse_name" },
          { name: "Order Number", value: "", databaseSearch: "order_id", restriction: "non-zero-unsigned-int" },
        ]}
        resultColumns={[
          { name: "Product Line", databaseField: "product_line" },
          { name: "Product Number", databaseField: "product_num" },
          { name: "Warehouse Name", databaseField: "warehouse_name" },
          { name: "Order Number", databaseField: "order_id" },
          { name: "Quantity", databaseField: "quantity" },
        ]}
        apiEndpoint={"purchaseOrders/openToBuy/searchOrders"}
        {...this.props}
      />
    )
  }
}

export default OTBOrderSearch
