import React, { Component } from "react"

// import child components / helpers
import Search from "../search/Search"
import Currency from "./Currency"

class CurrencySearch extends Component<appProps> {
  render() {
    return (
      <Search
        title={"Currency"}
        defaultedFields={{
          country_code: "%",
        }}
        defaultedEditable={true}
        queries={[
          { name: "Country Code", value: "", databaseSearch: "country_code", restriction: "country-code" },
          { name: "Country Name", value: "", databaseSearch: "country_name" },
          { name: "Currency Code", value: "", databaseSearch: "currency_code", restriction: "currency-code" },
        ]}
        resultColumns={[
          { name: "Country Code", databaseField: "country_code" },
          { name: "Country Name", databaseField: "country_name" },
          { name: "Currency Code", databaseField: "currency_code" },
        ]}
        apiEndpoint={"currencies/search"}
        children={<Currency />}
        {...this.props}
      />
    )
  }
}

export default CurrencySearch
