import React, { Component } from "react"

// import child components / helpers
import Search from "../search/Search"
import Transfer from "./Transfer"

class TransferSearch extends Component<appProps> {
  render() {
    return (
      <Search
        title={"Transfer"}
        closable={true}
        preventClosedUse={true}
        queries={[
          { name: "Id", value: "", databaseSearch: "transfer_id", restriction: "non-zero-unsigned-int" },
          { name: "Origin Warehouse", value: "", databaseSearch: "origin_warehouse_name" },
          { name: "Dest. Warehouse", value: "", databaseSearch: "dest_warehouse_name" },
          { name: "Product Number", value: "", databaseSearch: "product_num" },
          { type: "checkbox", name: "Cancelled", value: false, databaseSearch: "cancelled" },
        ]}
        resultColumns={[
          { name: "Id", databaseField: "id" },
          { name: "Origin Warehouse", databaseField: "origin_warehouse_name" },
          { name: "Dest. Warehouse", databaseField: "dest_warehouse_name" },
          { name: "Product Number", databaseField: "product_num" },
        ]}
        apiEndpoint={"transfers/search"}
        children={<Transfer />}
        {...this.props}
      />
    )
  }
}

export default TransferSearch
