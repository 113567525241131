import React, { Component } from "react"

import firebase from "firebase/app" // for signout
import "firebase/auth"

type props = {}

type state = {
  user: firebase.User | null
}

const initialState = {
  user: null,
}

class SignOut extends Component<props & appProps, state> {
  constructor(props: props) {
    super(props)
    this.state = initialState

    this.props.setAppTitle && this.props.setAppTitle("Sign Out")
  }

  componentDidMount() {
    var user = firebase.auth().currentUser
    if (user) {
      // signed in user
      this.setState({ user: user })
    } else {
      // no signed in user
      this.setState({ user: null })
    }
  }

  signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // logout success
        // force a reload on webpage to get latest user settings fetched in App.js
        // false is denoted so all assests aren't downloaded again
        window.location.reload(false)
      })
      .catch((error) => {
        alert("Error: " + error)
      })
  }

  render() {
    return (
      <div>
        <div>
          Signed in as: <b>{this.state.user ? this.state.user.email : null}</b>
        </div>
        <br />
        <button onClick={this.signOut}>Sign Out</button>
      </div>
    )
  }
}

export default SignOut
