import React, { Component } from "react"

// import child components / helpers
import Search from "../search/Search"

const propsToSend = {
  title: "Lot Price",
  queries: [
    { name: "Order Id", value: "", databaseSearch: "order_id" },
    { name: "Lot Price Title", value: "", databaseSearch: "title" },
  ],
  resultColumns: [
    { name: "Order Id", databaseField: "order_id" },
    { name: "Lot Price Title", databaseField: "title" },
    { name: "Cost", databaseField: "cost" },
    { name: "Comment", databaseField: "comment" },
  ],
  apiEndpoint: "orders/lotprice/search",
}

class LotPriceSearch extends Component<appProps> {
  render() {
    return <Search {...propsToSend} {...this.props} />
  }
}

export default LotPriceSearch
