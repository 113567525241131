import React, { Component } from "react"

// import child components / helpers
import TransferSearch from "../transfers/TransferSearch"
import LoadingSpinner from "../LoadingSpinner"
import NewTabText from "../newTabText/NewTabText"
import ControlledInput from "../edit/ControlledInput"
import { Dialog, props as dialogProps } from "../dialog/Dialog"
import { fireAxios } from "../helpers"

type props = {}

type warehouse = {
  id: string
  name: string
}

type product = {
  id: number
  transfer_product_id: number
  qty_to_receive: number
  qty_transfered: number
  qty_shipped: number
  product_line: string
  product_num: string
  description: string
  [index: string]: any
}

type state = {
  loading: boolean
  toChooseTransfer: boolean
  fromWarehouse?: warehouse
  toWarehouse?: warehouse
  products: Array<product>
  comment: string
  transferId?: number
  dialog: dialogProps
}

const initialState: state = {
  loading: false,
  transferId: undefined,
  toChooseTransfer: false,
  fromWarehouse: undefined,
  toWarehouse: undefined,
  products: [],
  comment: "",
  dialog: null,
}

class ReceiveTransfer extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = initialState
    this.props.setAppTitle && this.props.setAppTitle("Receive Transfer")
  }

  transferSearchResult = (transfer: any) => {
    this.loadTransfer(transfer.id)
  }

  loadTransfer = (transferId: number) => {
    this.setState({ loading: true }, () => {
      fireAxios({ method: "get", url: "receiving/transfer/" + transferId }, (response) => {
        this.setState(
          {
            loading: false,
            toChooseTransfer: false,
            ...response.data,
          },
          () => {
            this.setState(
              {
                transferId: transferId,
              },
              () => {
                this.props.setAppTitle && this.props.setAppTitle("Receive Transfer #" + this.state.transferId)
              }
            )
          }
        )
      })
    })
  }

  handleToReceiveQty = (name: string, value: string | number, prod: product) => {
    var newArray: Array<product> = JSON.parse(JSON.stringify(this.state.products))
    let foundProd = newArray.find((x) => x.transfer_product_id === prod.transfer_product_id)

    if (!foundProd) return

    foundProd[name] = value

    this.setState({
      products: newArray,
    })
  }

  backFromSearch = () => {
    this.setState({
      toChooseTransfer: false,
    })
  }

  chooseTransfer = () => {
    this.setState({
      toChooseTransfer: true,
    })
  }

  resetReceiving = () => {
    this.setState(initialState)
  }

  showDialog = (props: dialogProps) => {
    this.setState({
      dialog: props,
    })
  }

  hideDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  submitReceived = () => {
    let prodsToReceive: Array<product> = JSON.parse(
      JSON.stringify(
        this.state.products.filter((prod) => {
          return prod.qty_to_receive
        })
      )
    )

    if (
      prodsToReceive.some((prod) => {
        if (prod.qty_transfered + prod.qty_to_receive > prod.qty_shipped) {
          this.showDialog({
            type: "alert",
            title: "Error!",
            message: "Cannot receive more than sent, for product: " + prod.product_line + " - " + prod.product_num,
            handleResponse: this.hideDialog,
          })
          return true
        } else if (prod.qty_to_receive < 0) {
          this.showDialog({
            type: "alert",
            title: "Error!",
            message: "Cannot receive negative quantity, for product " + prod.product_line + " - " + prod.product_num,
            handleResponse: this.hideDialog,
          })
          return true
        }
        return false
      })
    ) {
      return
    }

    const dataToSubmit = {
      transfer_id: this.state.transferId,
      to_warehouse_id: this.state.toWarehouse ? this.state.toWarehouse.id : null,
      from_warehouse_id: this.state.fromWarehouse ? this.state.fromWarehouse.id : null,
      products: prodsToReceive,
    }

    this.setState({ loading: true }, () => {
      fireAxios({ method: "post", url: "receiving/transfer", data: dataToSubmit }, () => {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.state.transferId && this.loadTransfer(this.state.transferId)
          }
        )
      })
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChooseTransfer) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to Receive Transfer</button>
          <br />
          <br />
          <TransferSearch returnResult={this.transferSearchResult} />
        </div>
      )
    }

    if (this.state.transferId || this.state.transferId === 0) {
      return (
        <div>
          <div>
            Selected Transfer: <b>{this.state.transferId}</b> <button onClick={this.chooseTransfer}>Edit</button>
          </div>
          <br />
          <div>
            From warehouse: <b>{this.state.fromWarehouse ? this.state.fromWarehouse.name : ""}</b>
          </div>
          <br />
          <div>
            To warehouse: <b>{this.state.toWarehouse ? this.state.toWarehouse.name : ""}</b>
          </div>
          <br />
          <table>
            <tbody>
              <tr>
                <th>Product</th>
                <th>Description</th>
                <th>Shipped</th>
                <th>Received</th>
                <th>To Receive</th>
              </tr>
              {this.state.products.map((prod) => {
                return (
                  <tr key={prod.id}>
                    <td>
                      <NewTabText id={prod.id} link="product" text={prod.product_line + "-" + prod.product_num} />
                    </td>
                    <td>{prod.description}</td>
                    <td>{prod.qty_shipped ? prod.qty_shipped : 0}</td>
                    <td>{prod.qty_transfered}</td>
                    <td>
                      <ControlledInput
                        placeholder="Qty"
                        name={"qty_to_receive"}
                        restriction={"unsigned-int"}
                        value={prod.qty_to_receive}
                        handleChange={(n, v) => this.handleToReceiveQty(n, v, prod)}
                        type={"number"}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <br />
          <button onClick={this.submitReceived}>Submit Receiving</button>
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}
        </div>
      )
    }

    return (
      <div>
        To begin, choose the transfer: &nbsp;
        <button onClick={this.chooseTransfer}>Choose Transfer</button>
      </div>
    )
  }
}

export default ReceiveTransfer
