import React, { Component } from "react"

import LoadingSpinner from "../LoadingSpinner"
import { MENU_NESTING } from "../../menuStructure"

import { fireAxios } from "../helpers"

type props = {}

type printers = Array<{
  id: number
  name: string
  selected: boolean
}>

type state = {
  loading: boolean
  user?: user
  printers: printers
  selectedPrinterName?: string
}

const initialState: state = {
  loading: false,
  user: undefined,
  printers: [],
  selectedPrinterName: undefined,
}

class Settings extends Component<props & appProps, state> {
  constructor(props: props & appProps) {
    super(props)
    this.state = initialState

    if (this.props.setAppTitle) {
      this.props.setAppTitle("Settings")
    }
  }

  componentDidMount() {
    if (this.props.user) {
      this.setState(
        {
          user: this.props.user,
          loading: true,
        },
        () => this.getCloudPrinters()
      )
    }
  }

  getCloudPrinters = () => {
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "get",
          url: "printers/get/all",
        },
        (response) => {
          ;(response.data as printers).forEach((res) => (res.selected = res.id === (this.state.user && this.state.user.default_printer_id)))
          let foundPrinter = (response.data as printers).find((x) => x.selected)
          this.setState({
            selectedPrinterName: foundPrinter ? foundPrinter.name : undefined,
            printers: response.data,
            loading: false,
          })
        }
      )
    })
  }

  handlePrinterSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let newArray: printers = JSON.parse(JSON.stringify(this.state.printers))
    const selectedIndex = newArray.findIndex((x) => x.name === e.target.value)
    for (let i = 0; i < newArray.length; i++) {
      newArray[i].selected = selectedIndex === i
    }

    const selectedPrinter = newArray.find((x) => x.selected)

    this.setState({
      selectedPrinterName: selectedPrinter ? selectedPrinter.name : undefined,
      printers: newArray,
    })
  }

  handleLandingPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelect = e.target.value

    this.setState((prevState) => {
      if (!prevState.user) {
        return prevState
      } else {
        return {
          ...prevState,
          user: { ...prevState.user, landing_page: newSelect },
        }
      }
    })
  }

  handleDebugMode = () => {
    this.setState((prevState) => {
      if (!prevState.user) {
        return prevState
      } else {
        return {
          ...prevState,
          user: { ...prevState.user, debug_mode: !prevState.user.debug_mode },
        }
      }
    })
  }

  update = () => {
    let dataToSubmit = {
      user_id: this.state.user ? this.state.user.user_id : null,
      default_printer_id: this.state.selectedPrinterName ? this.state.printers.find((x) => x.name === this.state.selectedPrinterName)!.id : null,
      debug_mode: this.state.user ? this.state.user.debug_mode : null,
      landing_page: this.state.user ? this.state.user.landing_page : null,
    }

    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "users/update/settings",
          data: dataToSubmit,
        },
        () => {
          // force a reload on webpage to get latest user settings fetched in App.js
          // false is denoted so all assests aren't downloaded again
          window.location.reload(false)
        }
      )
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    return (
      <div>
        {this.state.user ? (
          <div>
            <div>
              <b>Email:</b> {this.state.user.email}
            </div>
            <br />
            <div>
              {this.state.user.salesrep ? (
                <div>
                  <b>Salesrep:</b> {this.state.user.salesrep.first_name} {this.state.user.salesrep.last_name}
                </div>
              ) : null}
            </div>
            <br />
            <div>
              <b>Default Printer:</b>&nbsp;
              <select onChange={this.handlePrinterSelection} value={this.state.selectedPrinterName}>
                {this.state.printers.map((printer) => {
                  return (
                    <option key={printer.id} value={printer.name}>
                      {printer.name}
                    </option>
                  )
                })}
              </select>
            </div>
            <br />
            <div>
              <b>Landing Page:</b>&nbsp;
              <select onChange={this.handleLandingPage} value={this.state.user ? this.state.user.landing_page : ""}>
                <option disabled={true}>Select Landing Page</option>
                {MENU_NESTING.map((mainMenu) => {
                  if (mainMenu.subItems && mainMenu.subItems.length > 0) {
                    return (
                      <optgroup label={mainMenu.mainItem} key={mainMenu.mainItem}>
                        {mainMenu.subItems.map((x) => {
                          return (
                            <option key={mainMenu.mainItem + x.link} value={x.link}>
                              {mainMenu.mainItem}: {x.name}
                            </option>
                          )
                        })}
                      </optgroup>
                    )
                  } else {
                    return ""
                  }
                })}
              </select>
            </div>
            <br />
            <div>
              <b>Debug Mode:</b>{" "}
              <input
                type="checkbox"
                name="debug_mode"
                checked={this.state.user ? this.state.user.debug_mode : false}
                onChange={this.handleDebugMode}
              />
            </div>
            <br />
            <button onClick={this.update}>Update</button>
          </div>
        ) : null}
      </div>
    )
  }
}

export default Settings
