import React, { Component } from "react"

// import child components
import Upload from "./Upload"

type props = {
  otherDataToSend: {
    [index: string]: any
  }
  onUploadSuccess: () => void
  apiRoute: string
}

const otherPropsToSend = {
  fileType: "PDF",
  extensions: ["pdf"],
}

class PDFUpload extends Component<props> {
  render() {
    return <Upload {...this.props} {...otherPropsToSend} />
  }
}
export default PDFUpload
