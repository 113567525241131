import React, { Component } from "react"

import shortid from "shortid"

// import child components / helpers
import POSearch from "./POSearch"
import ControlledInput from "../edit/ControlledInput"
import LoadingSpinner from "../LoadingSpinner"
import { Dialog, props as dialogProps } from "../dialog/Dialog"

import { fireAxios, toReadableDate } from "../helpers"

const title = "Payables"

type state = {
  loading: boolean
  toChoosePO: boolean
  selectedPOId?: number
  warehouse?: { name: string }
  vendor?: { id: number; name: string }
  charge_kinds?: Array<{ id: number; title: string }>
  charges: Array<charge>
  products: Array<product>
  invoices: Array<invoice>
  invoiceInfo?: invoice
  dialog: dialogProps
  invoice_num?: string
  invoice_date?: string
  comment?: string
  [key: string]: any
}

type product = {
  po_product_id: number
  product_line: string
  product_num: string
  qty_received: number
  po_cost: number
  price_per: number
  qty_invoiced: number
  qty_to_invoice: number
  comment: string
  [key: string]: any
}

type invoice = {
  po_invoice_id: number
  invoice_num: string
  date: Date | string
  invoice_date: Date | string
  comment: string
  total_prod_value: number
  products: Array<invoiceProd>
  charges: Array<invoiceCharges>
}

type invoiceProd = {
  id: number
  product_num: string
  product_line: string
  quantity: number
  cost: number
  price_per: number
  comment: string
}

type invoiceCharges = {
  id: number
  title: string
  amount: number
  comment: string
}

type charge = {
  id?: number
  unique_id?: string
  po_charge_kind_id?: number
  amount: number
  [key: string]: any
}

const initialState: state = {
  loading: false,
  dialog: null,
  toChoosePO: false,
  invoice_num: "",
  products: [],
  charges: [],
  invoices: [],
}

class Payables extends Component<appProps, state> {
  constructor(props: appProps) {
    super(props)
    this.state = initialState

    if (this.props.setAppTitle) {
      this.props.setAppTitle(title)
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEscapeInfo, false)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEscapeInfo, false)
  }

  handleEscapeInfo = (e: any) => {
    if (e.key === "Escape") {
      this.setState({
        invoiceInfo: undefined,
      })
    }
  }

  backFromSearch = () => {
    this.setState({
      toChoosePO: false,
    })
  }

  choosePO = () => {
    this.setState({ toChoosePO: true })
  }

  handlePOSearch = (result: { id: number }) => {
    this.setState(
      {
        loading: true,
        selectedPOId: result.id,
        toChoosePO: false,
      },
      () => {
        this.props.setAppTitle && this.props.setAppTitle(title + " PO#: " + this.state.selectedPOId)
        fireAxios(
          {
            url: "payables/get/" + this.state.selectedPOId,
          },
          (response) => {
            this.setState({
              loading: false,
              warehouse: response.data.warehouse,
              vendor: response.data.vendor,
              products: response.data.products,
              charge_kinds: response.data.charge_kinds,
              invoices: response.data.invoices,
            })
          }
        )
      }
    )
  }

  save = () => {
    let prodError =
      this.state.products.filter((x) => {
        return x.qty_invoiced + x.qty_to_invoice > x.qty_received
      }).length > 0

    if (prodError) {
      this.showDialog({
        title: "Quantity Error",
        message: "You cannot invoice more than are received.",
        type: "alert",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (!this.state.invoice_num) {
      this.showDialog({
        title: "Insufficient Information",
        message: "You must include the vendor's invoice number when submitting an invoice.",
        type: "alert",
        handleResponse: this.hideDialog,
      })
      return
    }

    if (!this.state.invoice_date) {
      this.showDialog({
        title: "Insufficient Information",
        message: "You must include the vendor's invoice date when submitting an invoice.",
        type: "alert",
        handleResponse: this.hideDialog,
      })
      return
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        fireAxios(
          {
            url: "payables/create",
            method: "post",
            data: {
              po_id: this.state.selectedPOId,
              invoice_num: this.state.invoice_num,
              invoice_date: this.state.invoice_date,
              comment: this.state.comment,
              products: this.state.products
                .map((x) => {
                  return {
                    po_product_id: x.po_product_id,
                    cost: x.po_cost,
                    quantity: x.qty_to_invoice,
                    price_per: x.price_per,
                    comment: x.comment,
                  }
                })
                .filter((x) => x.quantity > 0),
              charges: this.state.charges.map((x) => {
                return {
                  po_charge_kind_id: x.po_charge_kind_id,
                  amount: x.amount,
                  comment: x.comment,
                }
              }),
            },
          },
          () => {
            this.setState(initialState, () => this.handlePOSearch({ id: this.state.selectedPOId || 0 }))
          }
        )
      }
    )
  }

  deleteInvoice = (invoiceId: number) => {
    this.setState({ loading: true }, () => {
      fireAxios(
        {
          method: "post",
          url: "payables/delete/" + invoiceId,
        },
        (_response) => {
          this.setState({ initialState }, () => this.handlePOSearch({ id: this.state.selectedPOId || 0 }))
        }
      )
    })
  }

  handleInvoiceInput = (name: string, value: React.ReactText) => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  handleProdInput = (name: string, value: React.ReactText, poProductId: number) => {
    this.setState((prevState) => ({
      ...prevState,
      products: prevState.products.map((x) => {
        if (x.po_product_id === poProductId) {
          x[name] = value
        }
        return x
      }),
    }))
  }

  handleChargeInput = (name: string, value: React.ReactText, id: number | string) => {
    this.setState((prevState) => ({
      ...prevState,
      charges: prevState.charges.map((x) => {
        if (x.id === id || x.unique_id === id.toString()) {
          x[name] = value
        }
        return x
      }),
    }))
  }

  selectChargeKind = (kindId: number, id: number | string) => {
    this.setState((prevState) => ({
      ...prevState,
      charges: prevState.charges.map((x) => {
        if (x.id === id || x.unique_id === id.toString()) {
          x.po_charge_kind_id = kindId
        }
        return x
      }),
    }))
  }

  addCharge = () => {
    this.setState((prevState) => ({
      ...prevState,
      charges: prevState.charges.concat([
        {
          po_charge_kind_id: prevState.charge_kinds ? prevState.charge_kinds[0].id : 1,
          amount: 0,
          unique_id: shortid.generate(),
          comment: "",
        },
      ]),
    }))
  }

  deleteCharge = (id: string) => {
    this.setState((prevState) => ({
      ...prevState,
      charges: prevState.charges.filter((x) => x.unique_id !== id),
    }))
  }

  showInvoiceInfo = (id: number) => {
    this.setState({
      invoiceInfo: this.state.invoices.find((x) => x.po_invoice_id === id),
    })
  }

  hideInvoiceInfo = () => {
    this.setState({ invoiceInfo: undefined })
  }

  showDialog = (props: dialogProps) => {
    this.setState({ dialog: props })
  }

  hideDialog = () => {
    this.setState({ dialog: null })
  }

  stopPropagation = (e: any) => {
    // prevents the overlay from closing when the body of the overlay is clicked
    e.stopPropagation()
  }

  render() {
    const headerStyle: React.CSSProperties = {
      backgroundColor: "#ccc",
      padding: "10px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    }

    const overlay: React.CSSProperties = {
      position: "fixed" /* Sit on top of the page content */,
      display: "block" /* Hidden by default */,
      width: "100%" /* Full width (cover the whole page) */,
      height: "100%" /* Full height (cover the whole page) */,
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.5)" /* Black background with opacity */,
      zIndex: 99 /* Specify a stack order in case you're using a different order for other elements */,
      cursor: "pointer" /* Add a pointer on hover */,
    }

    const overlayBody: React.CSSProperties = {
      position: "absolute",
      top: "15%",
      left: "25%",
      width: "50%",
      height: "70%",
      zIndex: 100,
      boxShadow: "0px 0px 30px #000000",
      backgroundColor: "white",
      cursor: "default",
      padding: "20px",
      overflow: "auto",
    }

    const borderlessCell: React.CSSProperties = { border: "0" }

    if (this.state.loading) {
      return <LoadingSpinner />
    }

    if (this.state.toChoosePO) {
      return (
        <div>
          <button onClick={this.backFromSearch}>Back to {title}</button>
          <br />
          <br />
          <POSearch returnResult={this.handlePOSearch} />
        </div>
      )
    }

    if (this.state.selectedPOId) {
      return (
        <div>
          {this.state.dialog ? <Dialog {...this.state.dialog} /> : null}

          {this.state.invoiceInfo ? (
            <div style={overlay} onClick={() => this.hideInvoiceInfo()}>
              <div style={overlayBody} onClick={(e) => this.stopPropagation(e)}>
                {this.state.invoiceInfo.comment ? (
                  <div style={headerStyle}>
                    Comment: <span style={{ fontWeight: "normal" }}>{this.state.invoiceInfo.comment}</span>
                  </div>
                ) : null}
                <div style={headerStyle}>Products</div>
                <table className="searchResults">
                  <tbody>
                    <tr>
                      <th>Product</th>
                      <th>Qty Invoiced</th>
                      <th>Cost Invoiced</th>
                      <th>Per</th>
                      <th>Comment</th>
                    </tr>
                    {this.state.invoiceInfo.products.map((x) => (
                      <tr key={x.id}>
                        <td>
                          <b>{x.product_num}</b>
                        </td>
                        <td>{x.quantity}</td>
                        <td>${x.cost.toFixed(2)}</td>
                        <td>{x.price_per}</td>
                        <td>{x.comment}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div style={headerStyle}>Charges</div>
                <table className="searchResults">
                  <tbody>
                    <tr>
                      <th>Kind</th>
                      <th>Amount</th>
                      <th>Comment</th>
                    </tr>
                    {this.state.invoiceInfo.charges.map((x) => (
                      <tr key={x.id}>
                        <td>{x.title}</td>
                        <td>${x.amount.toFixed(2)}</td>
                        <td>{x.comment}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}

          <div style={headerStyle}>
            <span style={{ fontWeight: "normal" }}>Selected P.O.:</span> {this.state.selectedPOId}
            &nbsp;<button onClick={this.choosePO}>Edit</button>
          </div>
          <div style={headerStyle}>
            <span style={{ fontWeight: "normal" }}>Vendor:</span> {this.state.vendor && this.state.vendor.name}
            <span style={{ fontWeight: "normal" }}> ({this.state.vendor && this.state.vendor.id})</span>
          </div>
          <div style={headerStyle}>
            <span style={{ fontWeight: "normal" }}>Ship to:</span> {this.state.warehouse && this.state.warehouse.name}
          </div>
          <div>
            <table className="searchResults">
              <tbody>
                <tr>
                  <th>Product</th>
                  <th>Cost on P.O.</th>
                  <th>Per</th>
                  <th>Received</th>
                  <th>Invoiced</th>
                  <th>to Invoice</th>
                  <th>Total</th>
                  <th>Comment</th>
                </tr>
                {this.state.products.map((x) => (
                  <tr key={x.po_product_id}>
                    <td>
                      <b>{x.product_num}</b>
                    </td>
                    <td>$ {x.po_cost.toFixed(2)}</td>
                    <td>{x.price_per}</td>
                    <td
                      style={{
                        border: x.qty_invoiced + x.qty_to_invoice > x.qty_received ? "2px solid red" : "1px solid black",
                      }}
                    >
                      {x.qty_received}
                    </td>
                    <td>{x.qty_invoiced}</td>
                    <td onClick={(e) => this.stopPropagation(e)}>
                      <ControlledInput
                        name={"qty_to_invoice"}
                        value={x.qty_to_invoice || ""}
                        restriction={"unsigned-int"}
                        handleChange={(n, v) => this.handleProdInput(n, v, x.po_product_id)}
                      />
                    </td>
                    <td>${((x.po_cost * (x.qty_to_invoice || 0)) / x.price_per || 0).toFixed(2)}</td>
                    <td onClick={(e) => this.stopPropagation(e)}>
                      <textarea
                        name={"comment"}
                        value={x.comment || ""}
                        onChange={(e) => this.handleProdInput(e.currentTarget.name, e.currentTarget.value, x.po_product_id)}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <th style={borderlessCell}></th>
                  <th style={borderlessCell}></th>
                  <th style={borderlessCell}></th>
                  <th style={borderlessCell}></th>
                  <th style={borderlessCell}></th>
                  <th style={borderlessCell}></th>
                  <th>
                    $
                    {this.state.products
                      .reduce((acc, curr) => (acc += (curr.po_cost * (curr.qty_to_invoice || 0)) / curr.price_per || 0), 0)
                      .toFixed(2)}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={headerStyle}>Charges</div>
          <table className="searchResults">
            <tbody>
              <tr>
                <th>Kind</th>
                <th>Amount</th>
                <th>Comment</th>
                <th />
              </tr>
              {this.state.charges.map((x) => (
                <tr key={x.id || x.unique_id}>
                  <td>
                    <select
                      value={x.po_charge_kind_id}
                      onChange={(e) => this.selectChargeKind(parseInt(e.currentTarget.value), x.id || x.unique_id || "")}
                    >
                      {this.state.charge_kinds &&
                        this.state.charge_kinds.map((x) => (
                          <option key={x.id} value={x.id}>
                            {x.title}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <ControlledInput
                      name={"amount"}
                      value={x.amount || ""}
                      restriction={"unsigned-float"}
                      handleChange={(n, v) => this.handleChargeInput(n, v, x.id || x.unique_id || "")}
                    />
                  </td>
                  <td>
                    <textarea
                      name={"comment"}
                      value={x.comment || ""}
                      onChange={(e) => this.handleChargeInput(e.currentTarget.name, e.currentTarget.value, x.id || x.unique_id || "")}
                    />
                  </td>
                  <td>{!x.id ? <button onClick={() => this.deleteCharge(x.unique_id || "")}>Delete</button> : null}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <br />
          <button onClick={this.addCharge}>Add Charge</button>
          <br />
          <br />
          <div style={headerStyle}>Invoice Info</div>
          <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
            <div>
              Vendor Invoice #: &nbsp;
              <input
                name={"invoice_num"}
                value={this.state.invoice_num || ""}
                onChange={(e) => this.handleInvoiceInput(e.target.name, e.target.value)}
              />
              &nbsp;
              {!this.state.invoice_num ? <span style={{ color: "red" }}>*</span> : null}
            </div>
            <div>
              Invoice Date: &nbsp;
              <input
                type={"date"}
                value={this.state.invoice_date || ""}
                name={"invoice_date"}
                onChange={(e) => this.handleInvoiceInput(e.currentTarget.name, e.currentTarget.value)}
              />
              &nbsp;
              {!this.state.invoice_date ? <span style={{ color: "red" }}>*</span> : null}
            </div>
            <div>
              Comment:{" "}
              <textarea name={"comment"} value={this.state.comment || ""} onChange={(e) => this.handleInvoiceInput(e.target.name, e.target.value)} />
            </div>
          </div>
          <br />
          <br />
          <div style={headerStyle}>Submit Invoice</div>
          <button onClick={this.save}>Submit</button>
          <br />
          <br />
          <div style={headerStyle}>Previous Invoices</div>
          <table className="searchResults">
            <tbody>
              <tr>
                <th>Date</th>
                <th>Invoice Date</th>
                <th>Our Invoice Id</th>
                <th>Invoice Number</th>
                <th>Total Value</th>
                <th></th>
              </tr>
              {this.state.invoices.map((x) => (
                <tr key={x.po_invoice_id} onClick={() => this.showInvoiceInfo(x.po_invoice_id)} className={"row"}>
                  <td>{toReadableDate(x.date)}</td>
                  <td>{toReadableDate(x.invoice_date)}</td>
                  <td>{x.po_invoice_id}</td>
                  <td>{x.invoice_num}</td>
                  <td>${(x.total_prod_value + x.charges.reduce((acc, curr) => (acc += curr.amount), 0)).toFixed(2)}</td>
                  <td onClick={(e) => e.stopPropagation()}>
                    <button onClick={() => this.deleteInvoice(x.po_invoice_id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }

    return (
      <div>
        To begin, choose a P.O. <button onClick={this.choosePO}>Choose P.O.</button>
      </div>
    )
  }
}

export default Payables
