import React, { Component } from "react"

// import child components
import Search from "../search/Search"
import User from "./User"

class UserSearch extends Component<appProps> {
  render() {
    return (
      <Search
        title={"User"}
        queries={[{ name: "User Email", value: "", databaseSearch: "email", restriction: "email" }]}
        resultColumns={[
          { name: "Id", databaseField: "id" },
          { name: "User Email", databaseField: "email" },
        ]}
        apiEndpoint={"users/search"}
        children={<User />}
        {...this.props}
      />
    )
  }
}

export default UserSearch
