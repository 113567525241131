import React, { Component } from "react"

import ControlledInput from "../edit/ControlledInput"

// import helpers
import { regexStandards, restrictions } from "../helpers"

// import CSS
import "../../stylesheets/EditableTD.css"

type props = {
  focused: boolean
  content: string | number
  inputType?: "search" | "select"
  restriction?: restrictions
  focusedID: string | number
  changeFocus: (id: string | number) => void
  source: any
  context: string
  update?: (source: any, context: string, cleanValue: string | number) => void
  placeholder?: string
  disabled?: boolean
  options?: Array<{
    name: string
    value: any
  }>
  searchChild?: React.ReactNode
  searchName?: string
  toSearch?: (child: React.ReactNode, context: string) => void
}

type state = {
  focused: boolean
  originalContent: string | number
  content: string | number
  inputStyle: React.CSSProperties
}

// An editable </td>
class EditableTD extends Component<props, state> {
  state = {
    focused: this.props.focused,
    originalContent: this.props.content,
    content: this.props.content,
    inputStyle: {},
  }

  componentDidUpdate() {
    // check if focus has changed
    if (this.state.focused !== this.props.focused) {
      this.setState(
        {
          focused: this.props.focused,
        },
        () => {
          if (!this.state.focused && this.props.inputType) {
            this.setState({
              content: this.state.originalContent,
              inputStyle: {},
            })
          }
        }
      )
    }
  }

  handleRestriction = () => {
    var valueToTest = this.state.content

    if (!this.props.restriction) {
      return true
    }

    const regex = regexStandards(this.props.restriction)

    if (regex) {
      return regex.test(String(valueToTest))
    }
    return true
  }

  cleanContent = (value: string | number) => {
    if (this.props.restriction === "currency" && value.toString().charAt(0) === "$") {
      // content to send back should be converted to a float (in string format)
      // this content contains a dollar sign ($), remove so the "float-string" is clean to
      // submit to the database
      return value.toString().substr(1)
    }
    return value
  }

  toggleEdit = () => {
    this.setState(
      {
        focused: true,
      },
      () => {
        this.props.changeFocus(this.props.focusedID)
      }
    )
  }

  saveContent = () => {
    if (this.handleRestriction()) {
      // restriction passes, or there is no restriction, update
      this.setState(
        {
          focused: false,
          inputStyle: {},
        },
        () => {
          this.props.changeFocus(0)
          this.props.update && this.props.update(this.props.source, this.props.context, this.cleanContent(this.state.content))
        }
      )
    } else {
      // restriction fails, set red border on input, don't update
      this.setState({
        inputStyle: {
          borderStyle: "solid",
          borderColor: "red",
        },
      })
    }
  }

  handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      this.saveContent()
    } else if (e.key === "Escape") {
      this.setState(
        {
          focused: false,
        },
        () => {
          this.props.changeFocus("")
        }
      )
    }
  }

  handleInputChange = (value: string | number) => {
    this.setState({
      content: value,
    })
  }

  render() {
    const len = this.state.content ? this.state.content.toString().length : 0

    if (this.state.focused) {
      if (this.props.inputType === "search") {
        return (
          <td>
            {!this.props.content ? (
              <button onClick={() => this.props.toSearch && this.props.toSearch(this.props.searchChild, this.props.context)}>
                Choose {this.props.searchName}
              </button>
            ) : (
              <div>
                {this.props.content} &nbsp;
                <button onClick={() => this.props.toSearch && this.props.toSearch(this.props.searchChild, this.props.context)}>Edit</button>
              </div>
            )}
          </td>
        )
      }

      if (this.props.inputType === "select") {
        return (
          <td>
            <select onChange={(e) => this.handleInputChange(e.target.value)} value={this.state.content}>
              {this.props.options &&
                this.props.options.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  )
                })}
            </select>
            <button onClick={this.saveContent}>Save</button>
          </td>
        )
      }

      return (
        <td>
          <ControlledInput
            style={{ width: (len >= 7 ? len * 8 : 50).toString() + "px", ...this.state.inputStyle }}
            restriction={this.props.restriction}
            placeholder={this.props.placeholder}
            value={this.state.content || ""}
            handleChange={(_n, v) => this.handleInputChange(v)}
            onKeyDown={(e) => this.handleKeyPress(e)}
          />
          <button onClick={this.saveContent}>Save</button>
        </td>
      )
    }

    let placeholder: string | number = this.state.content

    if (this.props.inputType === "select") {
      let nameForValue = this.props.options && this.props.options.find((x) => x.value === this.state.content)
      placeholder = nameForValue ? nameForValue.name : ""
    }

    return (
      <td className={!this.props.disabled ? "editable-td" : ""} onClick={!this.props.disabled ? this.toggleEdit : undefined}>
        {placeholder}
      </td>
    )
  }
}

export default EditableTD
