import React, { Component } from "react"

// import child components / helpers
import Search from "../search/Search"
import Warehouse from "./Warehouse"

const propsToSend = {
  title: "Warehouse",
  closable: true,
  // preventClosedUse: true,
  defaultedFields: {
    name: "%",
  },
  defaultedEditable: true,
  queries: [{ name: "Name", value: "", databaseSearch: "name" }],
  resultColumns: [
    { name: "Name", databaseField: "name" },
    { name: "Phone Number", databaseField: "phone_num" },
    { name: "Address Line 1", databaseField: "address_1" },
  ],
  apiEndpoint: "warehouses/search",
  children: <Warehouse />,
}

class WarehouseSearch extends Component<appProps> {
  render() {
    return <Search {...propsToSend} {...this.props} />
  }
}

export default WarehouseSearch
