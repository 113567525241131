import React, { Component } from "react"

// import child components
import Upload from "./Upload"

type props = {
  otherDataToSend: {
    [index: string]: any
  }
  onUploadSuccess: () => void
  apiRoute: string
}

const otherPropsToSend = {
  fileType: "image",
  extensions: ["jpg", "png"],
}

class ImageUpload extends Component<props> {
  render() {
    return <Upload {...this.props} {...otherPropsToSend} />
  }
}

export default ImageUpload
